@use '../abstracts' as *;

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 16px;
}
.btn a,
.btn button {
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 180px;
  padding: 12px;
  color: var(--clr-main);
  border-bottom: 1px solid var(--clr-main-op);
  font-family: 'Josefin Sans', "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004", sans-serif;
  text-align: center;
  svg {
    margin-left: 16px;
  }
  @include mq(sp) {
    max-width: 100%;
    min-width: 120px;
    letter-spacing: .08em;
    padding-left: 24px;
    padding-right: 24px;
    svg {
      margin-left: 0;
      width: 14px;
    }
  }
}  
.flex > .btn a { max-width: 100%; }
.btn.txt-ctr a {
  @include auto-margin;
}
.btn:not(.btn-box) a:hover {
  opacity: .5;
  border-bottom-color: transparent !important;
}

.btn.btn-next_page a {
  max-width: 100%;
  border-radius: 0;
  text-align: left;
}
/* --- btn-box --- */
.btn.btn-box a,
.btn.btn-box button {
  display: block;
  width: 100%;
  max-width: inherit;
  padding: 20px;
  font-family: var(--font-jp01-b);
  font-size: rem(17);
  line-height: var(--line-height-default, 1.8);
  color: var(--clr-wht);
  letter-spacing: .12em;
  background-color: var(--clr-br);
  border: 1px solid var(--clr-wht);
  transition: var(--transit-default);
  cursor: pointer;
  @include mq(sp) {
    padding: 12px;
  }
  svg {
    position: absolute;
    top: calc(50% - 2px);
    right: 72px;
    transition: var(--transit-default, all .4s ease-out);
    @include mq(sp) {
      right: 16px;
    }
    .cls-1 {
      fill: var(--clr-wht);
    }
  }
  &:hover {
    background-color: var(--clr-main);
  }
}
.btn.btn-box-l a {
  padding-top: 32px;
  padding-bottom: 32px;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
.btn-box.btn-box-s {
  a,
  button {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: rem(15);
    line-height: 1;
    svg {
      right: 24px;
      margin-left: 0;
    }
  }
  .step-back svg {
    right: inherit;
    left: 24px;
    rotate: 180deg;
  }
  &.btn-2col {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    a {
      width: calc((100% - 16px) / 2);
    }
  }
}
.btn.btn-download {
  @include mq(sp) {
    margin-bottom: 0 !important;
  }
}
.btn.btn-download a {
  display: flex;
  padding: 8px 32px;
  @include mq(sp) {
    align-items: center;
    padding: 4px 24px;
  }
  svg {
    margin-left: 24px;
    @include mq(sp) {
      margin-left: 16px;
    }
  }
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #333);
  background-color: var(--btn-bg-wht, #fff);
}
.btn-wh svg {
  fill: var(--btn-clr-wht, #333);
}
.btn-wh a:hover {
  color: var(--btn-clr-wht-hvr, #fff);
  background-color: var(--btn-bg-wht-hvr, #333);
}
.btn-wh a:hover svg {
  fill: var(--btn-clr-wht-hvr, #fff);
}
@include mq(sp) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}
/* --- products--work_btn --- */
.btn.products--work_btn a {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: rem(15);
  letter-spacing: .12em;
}

/* --- favorite_btn --- */
.favorite_btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  font-family: var(--font-jp01-b);
  font-size: rem(14);
  color: var(--clr-main);
  letter-spacing: .08em;
  border: 1px solid var(--clr-main);
  transition: var(--transit-default);
  svg {
    fill: var(--clr-main);
    margin-right: 8px;
  }
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-main);
    svg {
      fill: var(--clr-wht);
    }
  }
}
.simplefavorite-button {
  cursor: pointer;
}
.simplefavorite-button.active {
  opacity: 1;
}

.btn-box2 a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: rem(380);
  height: rem(48);
  @include p-lr(rem(64));
  border: 1px solid currentColor !important;
  svg {
    position: absolute;
    right: rem(26);
    .cls-1 {
      fill: currentColor;
    }
  }
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-main);
  }
  @include mq(sp) {
    width: 100%;
  }
}


/* simplefavorites-clear
**************************************** */
#js-clearbtn {
  display: none;
  text-align: center;
  margin-top: 32px;
  transition: var(--transit-default);
}
#js-clearbtn .simplefavorites-clear {
  margin: auto;
}
.simplefavorites-clear {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 16px 32px;
  font-family: var(--font-jp01-b);
  font-size: rem(14);
  color: var(--clr-wht);
  border: 1px solid var(--clr-wht);
  background-color: var(--clr-br2);
  cursor: pointer;
  transition: background-color .4s ease-in-out;
  .mark {
    position: relative;
    @include rect(16);
    margin-right: 8px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: calc( 50% - 1px);
      width: 100%;
      height: 2px;
      background-color: var(--clr-wht);
    }
    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }
  &::before {
    content: '';
    font-family: var(--font-jp01-b);
  }
  &:disabled {
    background-color: #ccc;
    pointer-events: none;
    cursor: default;
  }
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-main);
  }
}