@use '../abstracts' as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: 80px;
  overflow: hidden;
}

/* --- post --- */
.post {
  position: relative;
  @include mq(sp) {
    svg {
      display: none;
    }
  }
}
.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 10;
}
.post--date {
  font-family: var(--font-en);
  color: var(--clr-br3);
  line-height: 1;
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.post--ttl {
  width: 100%;
  font-family: var(--font-jp01-b);
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
} 
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-family: var(--font-jp01-b);
  font-size: .813rem; /* 13px */
  color: var(--clr-main);
  border: 1px solid var(--clr-main);
  padding: .25em 1em;
  float: left;
  white-space: nowrap;
  margin: 2px;
}
.cat_list a:hover {
  opacity: .7;
}

@include mq(1100) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@include mq(sp) {
  .main_column {
    margin-bottom: 0;
  }
}

/* side_column
**************************************** */
.side_column {
  width: 264px;
  padding-top: 16px;
  @include mq(1100) {
    width: 100%;
  }
  @include mq(sp) {
    margin-top: 64px;
  }
}
.side_section:not(:last-child) {
  margin-bottom: 64px;
}
.side--ttl {
  color: var(--clr-wht);
  letter-spacing: .12em;
  line-height: 1;
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--clr-wht);
}
.side--ttl small {
  color: var(--clr-main);
  letter-spacing: .12em;
  display: block;
  line-height: 1;
  margin-top: 8px;
}

/* --- list --- */
.side--list a {
  position: relative;
  display: block;
  padding-left: 16px;
  margin-left: 8px;
  margin-bottom: 8px;
  font-family: var(--font-jp01-b);
&::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    @include rect(5);
    border-radius: 50vw;
    background-color: var(--clr-main);
  }
  &:hover {
    opacity: .7;
  }
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: 16px;
}
.posts-side .post--img {
  margin-right: 16px;
  width: 64px;
  height: 64px;
}
.posts-side .txtarea {
  flex: 1;
}
.posts-side .post--date {
  margin-bottom: 0;
}
.posts-side .post--ttl {
  line-height: 1.4;
}

/* --- archive --- */
.side_column .archive_list--ttl {
  cursor: pointer;
  font-family: var(--font-jp01-b);
  font-weight: 500;
  line-height: 1.8;
}
.side_column .archive_list--ttl::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--trans-default, all .4s ease-out);
}
.side_column .archive_list--ttl.active::after {
  transform: rotate(315deg);
}
.side_column .archive_list {
  margin-bottom: 8px;
}
.side_column .archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 99;
}
.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}
.archive-pulldown .archive_list a {
  display: block;
  padding: .25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.archive-pulldown .archive_list a:hover {
  background-color: #eee;
}
.archive-pulldown .archive_list--label {
  margin-right: 8px;
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: .25em 4em .25em 2em;
  background-color: var(--clr-wht);
  color: var(--clr-main);
  border: 1px solid var(--clr-main-op);
  font-family: var(--font-main, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: .08em;
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: .4em;
  right: .8em;
  margin: auto;
  width: .6em;
  height: .6em;
  border-bottom: 1px solid var(--clr-main);
  border-right: 1px solid var(--clr-main);
  transform: rotate(45deg);
  transition: all .2s ease-out;
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--clr-wht);
  border: 1px solid var(--clr-main-op);
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all .2s ease-out;
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}


/* blog
**************************************** */
.archive-blog .post--ttl {
  font-size: rem(24);
}
.posts-blog .post {
  padding-bottom: 48px;
  padding-top: 48px;
  border-bottom: 1px solid var(--clr-wht);
}
.posts-blog .post:first-child {
  border-top: 1px solid  var(--clr-wht);
}
.posts-blog .post--txtarea {
  flex: 1;
  word-break: break-word;
}
.posts-blog .post--img {
  width: vw(360);
  max-width: 360px;
  margin-right: 40px;
}
.posts-blog .post--img::before {
  padding-top: 75%;
}

@include mq(sp) {  
  .posts-blog .post {
    display: block;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .posts-blog .post--img {
    width: 80%;
    padding-left: 0;
    @include auto-margin;
    margin-bottom: 16px;
    text-align: center;
  }
  .posts-blog .post--img img {
    width: auto;
    max-width: 100%;
  }
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: #fff;
}

/* --- news_list --- */
.news_list .post {
  padding: 40px 32px;
  margin-bottom: 0;
  border-bottom: 1px solid var(--clr-main-op);
  @include mq(sp) {
    display: block;
  }
}
.news_list .post--date {
  width: 124px;
}
.news_list .post--info {
  margin-right: 32px;
  @include mq(sp) {
    margin-bottom: 8px;
    margin-right: 0;
  }
}
.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main);
}
.news_list .post--link:hover svg {
  fill: var(--clr-main);
}
.news_list .post--info.flex .post--date + .cat_list {
  margin-left: 16px;
}

/* products
**************************************** */
.products_search_box {
  position: relative;
}
.products_search_box input[type="text"] {
  width: 100%;
  height: 64px;
  color: var(--clr-main);
  background-color: var(--clr-berge5);
  border: 1px solid var(--clr-wht);
  border-radius: 4px;
  font-size: rem(18);
  padding: rem(18) rem(24);
  outline: 0;
  &::placeholder {
    font-family: var(--font-jp01) !important;
    color: rgba($clr-main , .7);
  }
}
.products_search_box button {
  position: absolute;
  right: rem(16);
  top: 0;
  bottom: 0;
  border: none;
  background: none;
  cursor: pointer;
}
.products_search_box button svg {
  fill: var(--clr-wht);
}
.products_search_box button:hover svg {
  fill: #827762;
  fill: var(--clr-body);
}

.posts-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &.posts-ctr {
    justify-content: center;
    gap: 30px;
  }
  &::before,
  &::after {
    content: "";
    width: calc((100% - 100px) / 3);
    order: 1;
  }
  .products_item {
    padding: 16px;
    margin-bottom: 40px;
    width: calc((100% - 100px) / 3);
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background-color: var(--clr-berge);
      outline: 1px solid var(--clr-wht);
      outline-offset: -1px;
      opacity: 0;
      top: 0;
      left: 0;
      transition: var(--transit-default);
      z-index: -1;
    }
    &:hover::before {
      opacity: 1;
    }
    .products_item--img {
      position: relative;
      display: flex;
      @include center-flex;
      pointer-events: none;
      aspect-ratio: 420 / 280;
      .hover--text {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        @include center-flex;
        gap: 20px;
        margin: auto;
        color: var(--clr-wht);
        font-size: rem(15);
        font-weight: 600;
        z-index: 4;
        opacity: 0;
        transition: var(--transit-default);
        svg .cls-1 {
          fill: var(--clr-wht);
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        background-color: rgba(0, 0, 0, .05);
        z-index: 2;
        opacity: 0;
        transition: var(--transit-default);
      }
    }
    &:hover {
      .hover--text { 
        opacity: 1;
      }
      .products_item--img::after {
        opacity: 1;
      }
    }
    .products_item--text {
      .item--ttl a,
      .item--paint {
        color: var(--clr-main);
        font-family: var(--font-jp01-b);
        letter-spacing: .08em;
      }
      .item--ttl {
        font-size: rem(22);
      }
      .item--paint {
        font-size: rem(14);
      }
      .item--ttl_en {
        color: var(--clr-berge2);
        font-family: var(--font-en);
        font-size: rem(12);
        letter-spacing: .12em;
        text-transform: uppercase;
      }
      .item--info {
        font-family: var(--font-main-r);
        font-size: rem(15);
      }
    }
  }
  @include mq(sp) {
    &::before,
    &::after {
      content: "";
      width: calc((100% - 16px) / 2);
      order: 1;
    }
    .products_item {
      padding: 0;
      margin-bottom: 24px;
      width: calc((100% - 16px) / 2);
      &::before {
        content: none;
      }
      .products_item--img {
        margin-bottom: 16px;
        .hover--text {
          display: none;
        }
        &::after {
          content: none;
        }
      }
      .products_item--text {
        .item--ttl a,
        .item--paint {
          color: var(--clr-main);
          font-family: var(--font-jp01-b);
          letter-spacing: .08em;
        }
        .item--ttl {
          font-size: rem(22);
        }
        .item--paint {
          font-size: rem(14);
        }
        .item--ttl_en {
          color: var(--clr-berge2);
          font-family: var(--font-en);
          font-size: rem(12);
          letter-spacing: .12em;
          text-transform: uppercase;
        }
        .item--info {
          font-family: var(--font-main-r);
          font-size: rem(15);
        }
      }
    }
  }
}

.products_filebtn {
  gap: vw(40);
  margin-top: 40px;
}
.products_filebtn li {
  @include mq(sp) {
    width: 80%;
  }
}
.products_filebtn .btn a {
  width: 300px;
  @include mq(sp) {
    width: 100%;
  }
}
/* products--pulldown
**************************************** */
.products--pulldown {
  margin-bottom: 56px;
  @include mq(sp) {
    margin-bottom: 32px;
  }
}
.products--select {
  position: relative;
  width: 300px;
}
.pulldown--mark {
  font-size: rem(28);
  margin-left: 16px;
  margin-right: 16px;
}
.products--pulldown select:focus,
.products--pulldown button:focus {
  outline: none;
}
.products--pulldown select {
  width: 100%;
  padding: 16px;
  color: var(--clr-wht);
  font-family: var(--font-jp01);
  font-size: rem(18);
  letter-spacing: .12em;
  background-color: var(--clr-br2);
  border: 1px solid var(--clr-wht);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align:-webkit-center;
  text-align: center;
}
.products--select::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1em;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: var(--clr-wht) transparent transparent transparent;
  transition: all .4s ease-out;
  pointer-events: none;
  z-index: 1;
}
.products--pulldown option {
  color: var(--clr-main);
  font-size: rem(15);
  background-color: var(--clr-wht);
  text-align:-webkit-center;
  text-align: center;
}
.products--pulldown option:focus {
  background-color: var(--clr-br);
}

.products--pulldown button {
  display: flex;
  @include center-flex;
  @include rect(58);
  color: var(--clr-wht);
  border: none;
  margin-left: 16px;
  border: 1px solid var(--clr-wht);
  background-color: var(--clr-br);
  transition: all .4s ease-out;
  cursor: pointer;
  svg {
    fill: var(--clr-wht);
  }
  @include mq(sp) {
    @include rect(48);
    margin-top: 16px;
    margin-left: 0;
  }
}
.products--pulldown button:hover {
  background-color: var(--clr-main);
}

/* works
**************************************** */
.archive-works main {
  width: 100%;
}
.posts-works {
  gap: 32px;
  &::after {
    content: "";
    width: calc((100% - 64px) / 3);
    height: 0;
  }
  .works_item {
    padding: 16px;
    width: calc((100% - 64px) / 3);
    .works_item--img {
      position: relative;
      aspect-ratio: 16/9;
      margin-bottom: 16px;
      .hover--text {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        @include center-flex;
        gap: 20px;
        width: 100%;
        margin: auto;
        color: var(--clr-wht);
        font-size: rem(15);
        z-index: 4;
        opacity: 0;
        cursor: pointer;
        pointer-events: none;
        transition: var(--transit-default);
        svg .cls-1 {
          fill: var(--clr-wht);
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        background-color: rgba(0, 0, 0, .1);
        z-index: 2;
        opacity: 0;
        pointer-events: none;
        transition: var(--transit-default);
      }
    }
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background-color: var(--clr-berge);
      outline: 1px solid var(--clr-wht);
      outline-offset: -1px;
      opacity: 0;
      top: 0;
      left: 0;
      transition: var(--transit-default);
      z-index: -1;
    }
    &:hover {
      .hover--text,
      .works_item--img::after,
      &::before { 
        opacity: 1;
      }
    }
    .posttype {
      font-family: var(--font-jp01-b);
      font-size: rem(16);
    }
    .item--ttl {
      a {
        font-family: var(--font-jp01-b);
        font-size: rem(22);
      }
    }
    .item--info {
      font-size: rem(14);
      font-family: var(--font-jp01);
    }
  }
  @include mq(sp) {
    gap: 24px;
    &::after {
      content: "";
      width: calc((100% - 24px) / 2);
      height: 0;
    }
    .works_item {
      padding: 0;
      margin-bottom: 16px;
      width: calc((100% - 24px) / 2);
      &::before {
        content: none;
      }
      .works_item--img {
        margin-bottom: 16px;
        .hover--text {
          display: none;
        }
      }
      .item--ttl {
        a {
          font-family: var(--font-jp01-b);
          font-size: rem(18);
        }
      }
      .item--info {
        font-size: rem(14);
        font-family: var(--font-jp01);
      }
    }
  }
}