@charset "UTF-8";
:root {
  --ltr-space-default: .08em;
  --line-height-default: 1.8;
  --line-height-hdr: 1.4;
  --line-height-none: 1;
  --font-main: "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
  --font-main-r: "こぶりなゴシック W3 JIS2004", "Koburina Gothic W3 JIS2004";
  --font-jp01: "Shuei KakuGo Gin M" , sans-serif;
  --font-jp01-b: "Shuei KakuGo Gin B" , sans-serif;
  --font-jp02: "Shuei MaruGo B" , sans-serif;
  --font-en: "Josefin Sans", sans-serif;
  --fz-primary: 1.125rem;
  --fz-half: .5rem;
  --clr-main: #827762;
  --clr-main-op: rgba(130, 119, 98, .5);
  --clr-body: #827762;
  --clr-wht: #fff;
  --clr-wht2: #EAE8E3;
  --clr-rd: #D79E8C;
  --clr-bl: #9AA6B2;
  --clr-br: #AAA18F;
  --clr-br2: rgba(161, 154, 135, .5);
  --clr-br3: #BBB09A;
  --clr-br4: rgba(161, 154, 135, .15);
  --clr-gr: #535353;
  --clr-berge: #E2DDD0;
  --clr-berge2: #BFB9A9;
  --clr-berge3: rgba(130, 119, 98, .15);
  --clr-berge4: rgba(175, 167, 149, .5);
  --clr-berge5: rgba(168, 162, 137, .25);
  --clr-berge6: rgba(171, 159, 137, .2);
  --clr-berge7: #AB9F89;
  --clr-txt-sub: #A19A87;
  --clr-dark-blue: #101560;
  --clr-link: var(--clr-main);
  --clr-blk: #000;
  --bg-main: #ebe6da;
  --bg-sub: #eee;
  --bg-wht: #fff;
  --bg-off_wht: #dcedfc;
  --bg-blk: #000;
  --btn-bg-main: var(--clr-main);
  --btn-bg-main-hvr: var(--clr-dark-blue);
  --btn-clr-main: #fff;
  --btn-clr--sec: #fff;
  --btn-clr-wht: #333;
  --btn-bg-wht: #fff;
  --btn-clr-wht-hvr: #fff;
  --btn-bg-wht-hvr: var(--clr-dark-blue);
  --brd-main: #1D7FB7;
  --transit-default: all 0.4s ease-out;
}

/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes scroll_progress {
  0% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(6.25vw);
  }
  100% {
    transform: translateY(6.25vw);
  }
}
@keyframes circle-pop {
  0% {
    opacity: 0.8;
    scale: 0.4;
  }
  100% {
    opacity: 0;
    scale: 1.2;
  }
}
/* ##############################################################################

    style info : リセット及びフォントサイズ

############################################################################## */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

*,
*:after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
  background: #fff;
  color: #333;
}

article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary, main {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ins {
  background-color: #ff9;
  color: #333;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #333;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #333;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/*-- ClearFix --*/
/* For modern browsers */
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* For IE 6/7 (trigger hasLayout) */
.clearfix {
  zoom: 1;
}

/*隙間消し*/
li img {
  vertical-align: bottom;
}

/* Reset input[type="search"] */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

input[type=search]:focus {
  outline-offset: -2px;
}

input[type=search]::-webkit-search-decoration {
  display: none;
}

/* iOS reset */
input[type=submit] {
  -webkit-appearance: none;
}

@media print, screen and (min-width: 768px) {
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  @-moz-document url-prefix() {
    * {
      font-feature-settings: "palt";
    }
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 0) {
  * {
    font-feature-settings: "palt";
  }
}
/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: 16px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  html {
    overflow-y: auto;
    overflow-x: auto;
  }
}
@media screen and (max-width: 47.9375em) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: var(--font-main, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  line-height: var(--line-height-default, 1.8);
  font-weight: 500;
  font-feature-settings: "palt";
  letter-spacing: var(--ltr-space-default, 0.08em);
  *font-size: small;
  *font: x-small;
  color: var(--clr-body, #333);
  -webkit-font-smoothing: antialiased;
  height: 100%;
  word-break: break-word;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn01.png);
  background-repeat: repeat;
}
@media screen and (max-width: 67.5em) {
  body {
    padding-left: 0;
    padding-top: 64px;
  }
  body.home {
    padding-top: 0;
  }
}
@media print, screen and (min-width: 67.5625em) {
  body {
    padding-left: 200px;
    height: auto;
  }
  body:not(.page-simulator) {
    overflow: hidden;
  }
}
@media screen and (max-width: 67.5em) {
  body {
    min-width: 1000px;
  }
}
@media screen and (max-width: 47.9375em) {
  body {
    min-width: inherit;
    -webkit-text-size-adjust: none;
  }
}

ul,
ol {
  list-style: none;
}

small {
  font-size: 0.875rem; /* 14px */
}
@media screen and (max-width: 47.9375em) {
  small {
    font-size: 0.813rem; /* 13px */
  }
}

/*! purgecss start ignore */
a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-link, #333);
  text-decoration: none;
  transition: var(--transit-default, all 0.4s ease-out);
  outline: none;
}

a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*! purgecss end ignore */
a:focus {
  text-decoration: none !important;
}

img {
  vertical-align: middle;
}
@media screen and (max-width: 47.9375em) {
  img {
    max-width: 100%;
    height: auto;
  }
}

address,
em,
i {
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr, 1.4);
}

svg {
  transition: var(--transit-default, all 0.4s ease-out);
}

/* Wysiwyg editor
 **************************************** */
.mce-content-body ol {
  list-style: decimal;
}

.mce-content-body ul:not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 24px;
}

.mce-content-body ul:not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 24px;
}

.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin: 0;
  border-bottom: none;
  padding: 0;
  margin-left: 2em;
  padding-top: 0.5em;
  line-height: 2;
}

.mce-content-body ul li {
  list-style: none;
}

.mce-content-body ul li::before {
  content: "";
  position: absolute;
  top: 1.2em;
  left: -1.3em;
  width: 10px;
  height: 10px;
  border: 1px solid var(--clr-berge7);
  background-color: var(--clr-berge7);
}

.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: transparent;
}

.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}

.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}

.mce-content-body hr {
  border-color: var(--clr-main);
  margin-top: 2em;
  margin-bottom: 2em;
  opacity: 0.3;
}

.bg-ptn .mce-content-body hr {
  border-color: var(--clr-wht);
  opacity: 1;
}

.mce-content-body .box {
  padding: 64px;
  font-size: 0.9375rem;
  line-height: 2;
  letter-spacing: 0.16em;
  background-color: var(--clr-br4);
}

.mce-content-body .box2 {
  color: var(--clr-wht);
  background-color: var(--clr-br2);
}

.mce-content-body .box-01 {
  padding: 1.5rem 2.5rem;
  border: 2px solid var(--clr-main);
}
.mce-content-body .box-01 .box--ttl {
  text-align: center;
  font-size: 1.25rem;
  color: var(--clr-main);
}
.mce-content-body .box-01 .box--ttl:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body .box-02 {
  padding: 1.5rem 2.5rem;
  background-color: var(--clr-main);
}
.mce-content-body .box-02 .box--ttl {
  text-align: center;
  font-size: 1.25rem;
  color: var(--clr-wht);
}
.mce-content-body .box-02 .box--ttl:not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body .box-02 .box--txt {
  color: var(--clr-wht);
}

.mce-content-body .box:not(:first-child) {
  margin-top: 2em;
}

.mce-content-body .box:not(:last-child) {
  margin-bottom: 2em;
}

.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}

.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}

.mce-content-body .aligncenter {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

.mce-content-body img.aligncenter {
  display: block;
}

.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}

.mce-content-body p {
  letter-spacing: 0.16em;
}

.mce-content-body p:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body strong {
  font-weight: bold;
}

.mce-content-body blockquote {
  margin: 2em 0;
  padding: 15px 40px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: var(--clr-main);
  background-color: var(--clr-br4);
}

.mce-content-body blockquote p {
  margin-bottom: 0;
}

.mce-content-body blockquote::before,
.mce-content-body blockquote::after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: 0.25;
}

.mce-content-body blockquote::before {
  background-image: resolve("icon-quote_left.svg");
  top: 13px;
  left: 10px;
}

.mce-content-body blockquote::after {
  background-image: url("../images/common/icon-quote_right.svg");
  bottom: 13px;
  right: 10px;
}

.mce-content-body a {
  text-decoration: underline;
}

.mce-content-body a:hover {
  text-decoration: none;
}

.mce-content-body .footer_text small {
  display: block;
  margin-top: 8px;
  text-align: right;
}

.mce-content-body table {
  font-family: var(--font-jp01-b);
  height: auto !important;
}

.mce-content-body table:not(:first-child) {
  margin-top: 1em;
}

.mce-content-body table:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}

.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid var(--clr-br3);
  vertical-align: middle;
}

.mce-content-body th {
  min-width: 120px;
  text-align: left;
}

.mce-content-body th + th:not(:last-child) {
  border-bottom-color: var(--clr-main-op);
}

.mce-content-body sup {
  font-size: 0.75rem; /* 12px */
  color: #555;
}

.bold {
  font-weight: bold;
  color: var(--clr-rd);
}

/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
}

.mce-content-body iframe:not(:first-child) {
  margin-top: 2em;
}

.mce-content-body iframe:not(:last-child) {
  margin-bottom: 2em;
}

/* table scroll */
@media screen and (max-width: 47.9375em) {
  .table-sp-scroll {
    white-space: nowrap;
  }
}

.scroll {
  overflow-x: scroll;
  margin-bottom: 2em;
  -webkit-overflow-scrolling: touch;
}

.scroll table {
  margin: 0 0 8px;
}

.scroll--cap {
  text-align: right;
  margin: 1em 0 0 !important;
  opacity: 0.25;
  font-size: 0.875rem; /* 14px */
}

/* scrollbar
**************************************** */
.scroll::-webkit-scrollbar {
  margin-top: 2px;
  width: 4px;
}

.scroll::-webkit-scrollbar:horizontal {
  height: 4px;
}

.scroll::-webkit-scrollbar-track {
  background-color: var(--clr-berge4);
  border-radius: 10px;
  border: none;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: var(--clr-main-op);
  border-radius: 10px;
  box-shadow: none;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body p:not([class]) {
    text-align: left !important;
  }
  .mce-content-body .alignright,
  .mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 15px;
  }
  .mce-content-body blockquote::before {
    top: 0;
    left: 0;
  }
  .mce-content-body blockquote::after {
    bottom: 0;
    right: 0;
  }
  .mce-content-body ul li::before {
    width: 8px;
    height: 8px;
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: 0.5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body .box {
    padding: 24px;
  }
  /* --- scrollbar --- */
  .scroll::-webkit-scrollbar {
    width: inherit;
  }
  .scroll::-webkit-scrollbar:horizontal {
    height: inherit;
  }
  .scroll::-webkit-scrollbar-track {
    background-color: inherit;
    border-radius: inherit;
    border: inherit;
  }
  .scroll::-webkit-scrollbar-thumb {
    background-color: inherit;
    border-radius: inherit;
    box-shadow: inherit;
  }
}
/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor {
  padding: 24px !important;
  max-height: 100vh !important;
  overflow-y: scroll !important;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
/* add quicktag
*************************************************** */
/* 見出し */
.editor-ttl,
.editor-ttl2,
.editor-ttl3 {
  font-family: var(--font-jp01-b);
  letter-spacing: 0.12em;
}

.editor-ttl:not(:first-child),
.editor-ttl2:not(:first-child),
.editor-ttl3:not(:first-child) {
  margin-top: 56px;
}

.editor-ttl:not(:last-child),
.editor-ttl2:not(:last-child),
.editor-ttl3:not(:last-child) {
  margin-bottom: 32px;
}

.editor-ttl {
  position: relative;
  padding-left: 3.5rem;
  font-size: 2rem; /* 32px */
  line-height: 1.875;
}

.editor-ttl::before {
  content: "";
  position: absolute;
  top: 0.9375em;
  left: 0;
  width: 2rem;
  height: 1px;
  background-color: var(--clr-main);
}

.editor-ttl2 {
  font-size: 1.5rem; /* 24px */
  line-height: 2;
}

.editor-ttl3 {
  position: relative;
  font-size: 1.125rem; /* 18px */
  line-height: 1.7777777778;
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: 0.875rem; /* 14px */
  color: var(--clr-rd);
}

.txt-attention::before {
  content: "※";
}

/* テーブル */
.mce-content-body table:not(.table1) th,
.table2 th {
  background-color: var(--clr-berge6);
}

.table1 tr {
  position: relative;
}

.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
}

.table1 th {
  min-width: 180px;
  color: var(--clr-main);
  font-size: 1.125rem; /* 18px */
}

.table1 tr::before,
.table1 th::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: calc(100% - 3px);
}

.table1 tr::before {
  right: 0;
  height: 1px;
  background-color: var(--clr-main-op);
}

.table1 th::before {
  left: 0;
  height: 2px;
  background-color: var(--clr-main-op);
}

.table2 {
  border: 1px solid var(--clr-br3);
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn01.png);
  background-repeat: repeat;
}

.table2 th {
  min-width: 240px;
  border-top-color: #f0ede6;
  border-bottom-color: #f0ede6;
  border-right-color: transparent;
}
@media screen and (max-width: 47.9375em) {
  .table2 th {
    min-width: 110px;
  }
}

.table2 th:has(+ th),
.table2 th + th:last-of-type {
  border-right-color: var(--clr-br3);
}

.table2 tr:first-of-type th {
  border-top-color: var(--clr-br3);
}

.table2 tr:last-of-type th {
  border-bottom-color: var(--clr-br3);
}

/* IE */
_:-ms-input-placeholder, :root .table1 th {
  border-bottom: 2px solid var(--clr-main);
}

_:-ms-input-placeholder, :root .table1 td {
  border-bottom: 1px solid #D3D3D3;
}

_:-ms-input-placeholder, :root .table1 th::before,
_:-ms-input-placeholder, :root .table1 td::before {
  display: none;
}

@media screen and (max-width: 47.9375em) {
  .editor-ttl {
    font-size: 1.5rem;
    padding-left: 1.75rem;
  }
  .editor-ttl::before {
    top: 0.9375em;
    width: 1rem;
  }
  .editor-ttl2 {
    font-size: 1.125rem;
  }
  .editor-ttl3 {
    font-size: 1rem;
  }
  /* テーブル */
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #D3D3D3;
  }
  .table1.table-sp-block tr::before {
    content: none;
  }
  .table1.table-sp-block th {
    padding-bottom: 0.5em;
  }
  .table1.table-sp-block td {
    padding-top: 0.5em;
    padding-bottom: 1.5em;
  }
  .table1.table-sp-block th::before,
  .table1.table-sp-block td::before {
    display: none;
  }
  /* 注意書き */
  .txt-attention {
    font-size: 0.929rem; /* 13px */
  }
}
/* --- font-size --- */
.fz-8 {
  font-size: 0.5rem;
}

.fz-9 {
  font-size: 0.5625rem;
}

.fz-10 {
  font-size: 0.625rem;
}

.fz-11 {
  font-size: 0.6875rem;
}

.fz-12 {
  font-size: 0.75rem;
}

.fz-13 {
  font-size: 0.8125rem;
}

.fz-14 {
  font-size: 0.875rem;
}

.fz-15 {
  font-size: 0.9375rem;
}

.fz-16 {
  font-size: 1rem;
}

.fz-17 {
  font-size: 1.0625rem;
}

.fz-18 {
  font-size: 1.125rem;
}

.fz-19 {
  font-size: 1.1875rem;
}

.fz-20 {
  font-size: 1.25rem;
}

.fz-21 {
  font-size: 1.3125rem;
}

.fz-22 {
  font-size: 1.375rem;
}

.fz-23 {
  font-size: 1.4375rem;
}

.fz-24 {
  font-size: 1.5rem;
}

.fz-25 {
  font-size: 1.5625rem;
}

.fz-26 {
  font-size: 1.625rem;
}

.fz-27 {
  font-size: 1.6875rem;
}

.fz-28 {
  font-size: 1.75rem;
}

.fz-29 {
  font-size: 1.8125rem;
}

.fz-30 {
  font-size: 1.875rem;
}

.fz-31 {
  font-size: 1.9375rem;
}

.fz-32 {
  font-size: 2rem;
}

.fz-33 {
  font-size: 2.0625rem;
}

.fz-34 {
  font-size: 2.125rem;
}

.fz-35 {
  font-size: 2.1875rem;
}

.fz-36 {
  font-size: 2.25rem;
}

.fz-37 {
  font-size: 2.3125rem;
}

.fz-38 {
  font-size: 2.375rem;
}

.fz-39 {
  font-size: 2.4375rem;
}

.fz-40 {
  font-size: 2.5rem;
}

.fz-41 {
  font-size: 2.5625rem;
}

.fz-42 {
  font-size: 2.625rem;
}

.fz-43 {
  font-size: 2.6875rem;
}

.fz-44 {
  font-size: 2.75rem;
}

.fz-45 {
  font-size: 2.8125rem;
}

.fz-46 {
  font-size: 2.875rem;
}

.fz-47 {
  font-size: 2.9375rem;
}

.fz-48 {
  font-size: 3rem;
}

.fz-49 {
  font-size: 3.0625rem;
}

.fz-50 {
  font-size: 3.125rem;
}

.fz-51 {
  font-size: 3.1875rem;
}

.fz-52 {
  font-size: 3.25rem;
}

.fz-53 {
  font-size: 3.3125rem;
}

.fz-54 {
  font-size: 3.375rem;
}

.fz-55 {
  font-size: 3.4375rem;
}

.fz-56 {
  font-size: 3.5rem;
}

.fz-57 {
  font-size: 3.5625rem;
}

.fz-58 {
  font-size: 3.625rem;
}

.fz-59 {
  font-size: 3.6875rem;
}

.fz-60 {
  font-size: 3.75rem;
}

.fz-61 {
  font-size: 3.8125rem;
}

.fz-62 {
  font-size: 3.875rem;
}

.fz-63 {
  font-size: 3.9375rem;
}

.fz-64 {
  font-size: 4rem;
}

.fz-65 {
  font-size: 4.0625rem;
}

.fz-66 {
  font-size: 4.125rem;
}

.fz-67 {
  font-size: 4.1875rem;
}

.fz-68 {
  font-size: 4.25rem;
}

.fz-69 {
  font-size: 4.3125rem;
}

.fz-70 {
  font-size: 4.375rem;
}

.fz-71 {
  font-size: 4.4375rem;
}

.fz-72 {
  font-size: 4.5rem;
}

.fz-73 {
  font-size: 4.5625rem;
}

.fz-74 {
  font-size: 4.625rem;
}

.fz-75 {
  font-size: 4.6875rem;
}

.fz-76 {
  font-size: 4.75rem;
}

.fz-77 {
  font-size: 4.8125rem;
}

.fz-78 {
  font-size: 4.875rem;
}

.fz-79 {
  font-size: 4.9375rem;
}

.fz-80 {
  font-size: 5rem;
}

.fz-81 {
  font-size: 5.0625rem;
}

.fz-82 {
  font-size: 5.125rem;
}

.fz-83 {
  font-size: 5.1875rem;
}

.fz-84 {
  font-size: 5.25rem;
}

.fz-85 {
  font-size: 5.3125rem;
}

.fz-86 {
  font-size: 5.375rem;
}

.fz-87 {
  font-size: 5.4375rem;
}

.fz-88 {
  font-size: 5.5rem;
}

.fz-89 {
  font-size: 5.5625rem;
}

.fz-90 {
  font-size: 5.625rem;
}

.fz-91 {
  font-size: 5.6875rem;
}

.fz-92 {
  font-size: 5.75rem;
}

.fz-93 {
  font-size: 5.8125rem;
}

.fz-94 {
  font-size: 5.875rem;
}

.fz-95 {
  font-size: 5.9375rem;
}

.fz-96 {
  font-size: 6rem;
}

.fz-97 {
  font-size: 6.0625rem;
}

.fz-98 {
  font-size: 6.125rem;
}

.fz-99 {
  font-size: 6.1875rem;
}

.fz-100 {
  font-size: 6.25rem;
}

.fz-101 {
  font-size: 6.3125rem;
}

.fz-102 {
  font-size: 6.375rem;
}

.fz-103 {
  font-size: 6.4375rem;
}

.fz-104 {
  font-size: 6.5rem;
}

.fz-105 {
  font-size: 6.5625rem;
}

.fz-106 {
  font-size: 6.625rem;
}

.fz-107 {
  font-size: 6.6875rem;
}

.fz-108 {
  font-size: 6.75rem;
}

.fz-109 {
  font-size: 6.8125rem;
}

.fz-110 {
  font-size: 6.875rem;
}

.fz-111 {
  font-size: 6.9375rem;
}

.fz-112 {
  font-size: 7rem;
}

.fz-113 {
  font-size: 7.0625rem;
}

.fz-114 {
  font-size: 7.125rem;
}

.fz-115 {
  font-size: 7.1875rem;
}

.fz-116 {
  font-size: 7.25rem;
}

.fz-117 {
  font-size: 7.3125rem;
}

.fz-118 {
  font-size: 7.375rem;
}

.fz-119 {
  font-size: 7.4375rem;
}

.fz-120 {
  font-size: 7.5rem;
}

.fz-121 {
  font-size: 7.5625rem;
}

.fz-122 {
  font-size: 7.625rem;
}

.fz-123 {
  font-size: 7.6875rem;
}

.fz-124 {
  font-size: 7.75rem;
}

.fz-125 {
  font-size: 7.8125rem;
}

.fz-126 {
  font-size: 7.875rem;
}

.fz-127 {
  font-size: 7.9375rem;
}

.fz-128 {
  font-size: 8rem;
}

.fz-129 {
  font-size: 8.0625rem;
}

.fz-130 {
  font-size: 8.125rem;
}

.fz-131 {
  font-size: 8.1875rem;
}

.fz-132 {
  font-size: 8.25rem;
}

.fz-133 {
  font-size: 8.3125rem;
}

.fz-134 {
  font-size: 8.375rem;
}

.fz-135 {
  font-size: 8.4375rem;
}

.fz-136 {
  font-size: 8.5rem;
}

.fz-137 {
  font-size: 8.5625rem;
}

.fz-138 {
  font-size: 8.625rem;
}

.fz-139 {
  font-size: 8.6875rem;
}

@media screen and (max-width: 47.9375em) {
  .fz-8 {
    font-size: 0.5rem;
  }
  .fz-9 {
    font-size: 0.5714285714rem;
  }
  .fz-10 {
    font-size: 0.6428571429rem;
  }
  .fz-11 {
    font-size: 0.7142857143rem;
  }
  .fz-12 {
    font-size: 0.7857142857rem;
  }
  .fz-13 {
    font-size: 0.8571428571rem;
  }
  .fz-14 {
    font-size: 0.9285714286rem;
  }
  .fz-15 {
    font-size: 1rem;
  }
  .fz-16 {
    font-size: 1.0714285714rem;
  }
  .fz-17 {
    font-size: 1.1428571429rem;
  }
  .fz-18 {
    font-size: 1.2142857143rem;
  }
  .fz-19 {
    font-size: 1.2857142857rem;
  }
  .fz-20 {
    font-size: 1.3571428571rem;
  }
  .fz-21 {
    font-size: 1.4285714286rem;
  }
  .fz-22 {
    font-size: 1.5rem;
  }
  .fz-23 {
    font-size: 1.5714285714rem;
  }
  .fz-24 {
    font-size: 1.6428571429rem;
  }
  .fz-25 {
    font-size: 1.7142857143rem;
  }
  .fz-26 {
    font-size: 1.7857142857rem;
  }
  .fz-27 {
    font-size: 1.8571428571rem;
  }
  .fz-28 {
    font-size: 1.9285714286rem;
  }
  .fz-29 {
    font-size: 2rem;
  }
  .fz-30 {
    font-size: 2.0714285714rem;
  }
  .fz-31 {
    font-size: 2.1428571429rem;
  }
  .fz-32 {
    font-size: 2.2142857143rem;
  }
  .fz-33 {
    font-size: 2.2857142857rem;
  }
  .fz-34 {
    font-size: 2.3571428571rem;
  }
  .fz-35 {
    font-size: 2.4285714286rem;
  }
  .fz-36 {
    font-size: 2.5rem;
  }
  .fz-37 {
    font-size: 2.5714285714rem;
  }
  .fz-38 {
    font-size: 2.6428571429rem;
  }
  .fz-39 {
    font-size: 2.7142857143rem;
  }
  .fz-40 {
    font-size: 2.7857142857rem;
  }
  .fz-41 {
    font-size: 2.8571428571rem;
  }
  .fz-42 {
    font-size: 2.9285714286rem;
  }
  .fz-43 {
    font-size: 3rem;
  }
  .fz-44 {
    font-size: 3.0714285714rem;
  }
  .fz-45 {
    font-size: 3.1428571429rem;
  }
  .fz-46 {
    font-size: 3.2142857143rem;
  }
  .fz-47 {
    font-size: 3.2857142857rem;
  }
  .fz-48 {
    font-size: 3.3571428571rem;
  }
  .fz-49 {
    font-size: 3.4285714286rem;
  }
  .fz-50 {
    font-size: 3.5rem;
  }
  .fz-51 {
    font-size: 3.5714285714rem;
  }
  .fz-52 {
    font-size: 3.6428571429rem;
  }
  .fz-53 {
    font-size: 3.7142857143rem;
  }
  .fz-54 {
    font-size: 3.7857142857rem;
  }
  .fz-55 {
    font-size: 3.8571428571rem;
  }
  .fz-56 {
    font-size: 3.9285714286rem;
  }
  .fz-57 {
    font-size: 4rem;
  }
  .fz-58 {
    font-size: 4.0714285714rem;
  }
  .fz-59 {
    font-size: 4.1428571429rem;
  }
  .fz-60 {
    font-size: 4.2142857143rem;
  }
  .fz-61 {
    font-size: 4.2857142857rem;
  }
  .fz-62 {
    font-size: 4.3571428571rem;
  }
  .fz-63 {
    font-size: 4.4285714286rem;
  }
  .fz-64 {
    font-size: 4.5rem;
  }
  .fz-65 {
    font-size: 4.5714285714rem;
  }
  .fz-66 {
    font-size: 4.6428571429rem;
  }
  .fz-67 {
    font-size: 4.7142857143rem;
  }
  .fz-68 {
    font-size: 4.7857142857rem;
  }
  .fz-69 {
    font-size: 4.8571428571rem;
  }
  .fz-70 {
    font-size: 4.9285714286rem;
  }
  .fz-71 {
    font-size: 5rem;
  }
  .fz-72 {
    font-size: 5.0714285714rem;
  }
  .fz-73 {
    font-size: 5.1428571429rem;
  }
  .fz-74 {
    font-size: 5.2142857143rem;
  }
  .fz-75 {
    font-size: 5.2857142857rem;
  }
  .fz-76 {
    font-size: 5.3571428571rem;
  }
  .fz-77 {
    font-size: 5.4285714286rem;
  }
  .fz-78 {
    font-size: 5.5rem;
  }
  .fz-79 {
    font-size: 5.5714285714rem;
  }
  .fz-80 {
    font-size: 5.6428571429rem;
  }
  .fz-81 {
    font-size: 5.7142857143rem;
  }
  .fz-82 {
    font-size: 5.7857142857rem;
  }
  .fz-83 {
    font-size: 5.8571428571rem;
  }
  .fz-84 {
    font-size: 5.9285714286rem;
  }
  .fz-85 {
    font-size: 6rem;
  }
  .fz-86 {
    font-size: 6.0714285714rem;
  }
  .fz-87 {
    font-size: 6.1428571429rem;
  }
  .fz-88 {
    font-size: 6.2142857143rem;
  }
  .fz-89 {
    font-size: 6.2857142857rem;
  }
  .fz-90 {
    font-size: 6.3571428571rem;
  }
  .fz-91 {
    font-size: 6.4285714286rem;
  }
  .fz-92 {
    font-size: 6.5rem;
  }
  .fz-93 {
    font-size: 6.5714285714rem;
  }
  .fz-94 {
    font-size: 6.6428571429rem;
  }
  .fz-95 {
    font-size: 6.7142857143rem;
  }
  .fz-96 {
    font-size: 6.7857142857rem;
  }
  .fz-97 {
    font-size: 6.8571428571rem;
  }
  .fz-98 {
    font-size: 6.9285714286rem;
  }
  .fz-99 {
    font-size: 7rem;
  }
  .fz-100 {
    font-size: 7.0714285714rem;
  }
  .fz-101 {
    font-size: 7.1428571429rem;
  }
  .fz-102 {
    font-size: 7.2142857143rem;
  }
  .fz-103 {
    font-size: 7.2857142857rem;
  }
  .fz-104 {
    font-size: 7.3571428571rem;
  }
  .fz-105 {
    font-size: 7.4285714286rem;
  }
  .fz-106 {
    font-size: 7.5rem;
  }
  .fz-107 {
    font-size: 7.5714285714rem;
  }
  .fz-108 {
    font-size: 7.6428571429rem;
  }
  .fz-109 {
    font-size: 7.7142857143rem;
  }
  .fz-110 {
    font-size: 7.7857142857rem;
  }
  .fz-111 {
    font-size: 7.8571428571rem;
  }
  .fz-112 {
    font-size: 7.9285714286rem;
  }
  .fz-113 {
    font-size: 8rem;
  }
  .fz-114 {
    font-size: 8.0714285714rem;
  }
  .fz-115 {
    font-size: 8.1428571429rem;
  }
  .fz-116 {
    font-size: 8.2142857143rem;
  }
  .fz-117 {
    font-size: 8.2857142857rem;
  }
  .fz-118 {
    font-size: 8.3571428571rem;
  }
  .fz-119 {
    font-size: 8.4285714286rem;
  }
  .fz-120 {
    font-size: 8.5rem;
  }
  .fz-121 {
    font-size: 8.5714285714rem;
  }
  .fz-122 {
    font-size: 8.6428571429rem;
  }
  .fz-123 {
    font-size: 8.7142857143rem;
  }
  .fz-124 {
    font-size: 8.7857142857rem;
  }
  .fz-125 {
    font-size: 8.8571428571rem;
  }
  .fz-126 {
    font-size: 8.9285714286rem;
  }
  .fz-127 {
    font-size: 9rem;
  }
  .fz-128 {
    font-size: 9.0714285714rem;
  }
  .fz-129 {
    font-size: 9.1428571429rem;
  }
  .fz-130 {
    font-size: 9.2142857143rem;
  }
  .fz-131 {
    font-size: 9.2857142857rem;
  }
  .fz-132 {
    font-size: 9.3571428571rem;
  }
  .fz-133 {
    font-size: 9.4285714286rem;
  }
  .fz-134 {
    font-size: 9.5rem;
  }
  .fz-135 {
    font-size: 9.5714285714rem;
  }
  .fz-136 {
    font-size: 9.6428571429rem;
  }
  .fz-137 {
    font-size: 9.7142857143rem;
  }
  .fz-138 {
    font-size: 9.7857142857rem;
  }
  .fz-139 {
    font-size: 9.8571428571rem;
  }
}
/* txt
**************************************** */
.txt-lg {
  font-size: 1.125rem; /* 18px */
}

.txt-sm {
  font-size: 0.875rem; /* 14px */
}

.txt-wh,
.txt-wh * {
  color: var(--clr-wht, #fff);
}

.txt-main,
.txt-main * {
  color: var(--clr-main);
}

.txt-sub,
.txt-sub * {
  color: var(--clr-sub, #eee);
}

/* --- font --- */
.font-jp {
  font-family: var(--font-main);
}

.font-jp-m {
  font-family: var(--font-main-r);
}

.font-jp01 {
  font-family: var(--font-jp01);
}

.font-jp01-b {
  font-family: var(--font-jp01-b);
}

.font-jp02 {
  font-family: var(--font-jp02);
}

.font-en {
  font-family: var(--font-en);
}

/* txt
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}

.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@media screen and (max-width: 47.9375em) {
  .txt-lg {
    font-size: 1.071rem; /* 15px */
  }
  .txt-sm {
    font-size: 0.929rem; /* 13px */
  }
  .txt-ctr-pc,
  .txt-rgt-pc {
    text-align: left;
  }
}
/* bg
********************************************** */
.bg-ptnbox::before,
.bg-ptnbox::after {
  content: "";
  position: absolute;
  width: 100vw;
}

.bg-ptnbox::before {
  top: -364px;
  height: 364px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn02-top.png);
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position: center top;
}
@media screen and (max-width: 47.9375em) {
  .bg-ptnbox::before {
    top: -182px;
    height: 182px;
  }
}

.bg-ptnbox::after {
  bottom: -320px;
  height: 320px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn02-btm.png);
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position: center bottom;
}
@media screen and (max-width: 47.9375em) {
  .bg-ptnbox::after {
    bottom: -160px;
    height: 160px;
  }
}

.bg-ptnbox {
  position: absolute;
  top: 364px;
  width: 100vw;
  height: calc(100% - 364px - 320px);
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn02.jpg);
}
@media print, screen and (min-width: 67.5625em) {
  .bg-ptnbox {
    left: -200px;
  }
}
@media screen and (max-width: 47.9375em) {
  .bg-ptnbox {
    left: 0;
    top: 182px;
    height: calc(100% - 182px - 160px);
  }
}

.bg-main {
  background-color: var(--bg-main);
}

.bg-wh {
  background-color: var(--bg-wht, #fff);
}

/* --- bg-ptn 文字色 --- */
.bg-ptn {
  color: var(--clr-wht);
}
.bg-ptn .ttl-01,
.bg-ptn .btn a,
.bg-ptn .btn button {
  color: var(--clr-wht);
}
.bg-ptn .ttl-01 .font-en {
  color: var(--clr-main);
  opacity: 0.1;
}
@media screen and (max-width: 47.9375em) {
  .bg-ptn .ttl-01 .font-en {
    opacity: 0.25;
  }
}
.bg-ptn .editor-ttl::before {
  background-color: var(--clr-wht);
}
.bg-ptn .btn a,
.bg-ptn .btn button {
  border-bottom-color: var(--clr-wht);
}
.bg-ptn .btn a .cls-1,
.bg-ptn .btn a .icon-svg,
.bg-ptn .btn button .cls-1,
.bg-ptn .btn button .icon-svg {
  fill: var(--clr-wht);
}
.bg-ptn .bg-wh {
  color: var(--clr-main);
}
.bg-ptn .bg-wh .ttl-01,
.bg-ptn .bg-wh .btn a,
.bg-ptn .bg-wh .btn button {
  color: var(--clr-main);
}
.bg-ptn .bg-wh .btn a,
.bg-ptn .bg-wh .btn button {
  border-bottom-color: var(--clr-main);
}
.bg-ptn .bg-wh .btn a .cls-1,
.bg-ptn .bg-wh .btn button .cls-1 {
  fill: var(--clr-main);
}

/* --- pc or sp --- */
.pc-none,
.pc-none-inline,
.pc-none-table,
.pc-none-flex {
  display: none;
}

.sp-none {
  display: block;
}

.sp-none-inline {
  display: inline;
}

.sp-none-table {
  display: table;
}

.sp-none-flex {
  display: flex;
}

@media screen and (max-width: 47.9375em) {
  /* --- pc or sp --- */
  .sp-none,
  .sp-none-inline,
  .sp-none-table,
  .sp-none-flex {
    display: none;
  }
  .pc-none {
    display: block;
  }
  .pc-none-inline {
    display: inline;
  }
  .pc-none-table {
    display: table;
  }
  .pc-none-flex {
    display: flex;
  }
}
/* img
********************************************** */
.img-ctr {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-trim {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-trim.pos_rel::before {
  content: "";
  display: block;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-cover.pos_ab {
  inset: 0;
  margin: auto;
}

.img-contain {
  object-fit: contain;
}

/* --- margin 　古い・削除--- */
.mgn-btm8 {
  margin-bottom: 8px;
}

.mgn-btm16 {
  margin-bottom: 16px;
}

.mgn-btm24 {
  margin-bottom: 24px;
}

.mgn-btm32 {
  margin-bottom: 32px;
}

.mgn-btm40 {
  margin-bottom: 40px;
}

.mgn-btm48 {
  margin-bottom: 48px;
}

.mgn-btm56 {
  margin-bottom: 56px;
}

.mgn-btm64 {
  margin-bottom: 64px;
}

.mgn-btm72 {
  margin-bottom: 72px;
}

.mgn-btm80 {
  margin-bottom: 80px;
}

.mgn-btm80 {
  margin-bottom: 120px;
}

.mgn-0 {
  margin: 0 rem;
}

.mgn-top0 {
  margin-top: 0 rem;
}

.mgn-rgt0 {
  margin-right: 0 rem;
}

.mgn-btm0 {
  margin-bottom: 0 rem;
}

.mgn-lft0 {
  margin-left: 0 rem;
}

.mgn-1 {
  margin: 0.0625 rem;
}

.mgn-top1 {
  margin-top: 0.0625 rem;
}

.mgn-rgt1 {
  margin-right: 0.0625 rem;
}

.mgn-btm1 {
  margin-bottom: 0.0625 rem;
}

.mgn-lft1 {
  margin-left: 0.0625 rem;
}

.mgn-2 {
  margin: 0.125 rem;
}

.mgn-top2 {
  margin-top: 0.125 rem;
}

.mgn-rgt2 {
  margin-right: 0.125 rem;
}

.mgn-btm2 {
  margin-bottom: 0.125 rem;
}

.mgn-lft2 {
  margin-left: 0.125 rem;
}

.mgn-3 {
  margin: 0.1875 rem;
}

.mgn-top3 {
  margin-top: 0.1875 rem;
}

.mgn-rgt3 {
  margin-right: 0.1875 rem;
}

.mgn-btm3 {
  margin-bottom: 0.1875 rem;
}

.mgn-lft3 {
  margin-left: 0.1875 rem;
}

.mgn-4 {
  margin: 0.25 rem;
}

.mgn-top4 {
  margin-top: 0.25 rem;
}

.mgn-rgt4 {
  margin-right: 0.25 rem;
}

.mgn-btm4 {
  margin-bottom: 0.25 rem;
}

.mgn-lft4 {
  margin-left: 0.25 rem;
}

.mgn-5 {
  margin: 0.3125 rem;
}

.mgn-top5 {
  margin-top: 0.3125 rem;
}

.mgn-rgt5 {
  margin-right: 0.3125 rem;
}

.mgn-btm5 {
  margin-bottom: 0.3125 rem;
}

.mgn-lft5 {
  margin-left: 0.3125 rem;
}

.mgn-6 {
  margin: 0.375 rem;
}

.mgn-top6 {
  margin-top: 0.375 rem;
}

.mgn-rgt6 {
  margin-right: 0.375 rem;
}

.mgn-btm6 {
  margin-bottom: 0.375 rem;
}

.mgn-lft6 {
  margin-left: 0.375 rem;
}

.mgn-7 {
  margin: 0.4375 rem;
}

.mgn-top7 {
  margin-top: 0.4375 rem;
}

.mgn-rgt7 {
  margin-right: 0.4375 rem;
}

.mgn-btm7 {
  margin-bottom: 0.4375 rem;
}

.mgn-lft7 {
  margin-left: 0.4375 rem;
}

.mgn-8 {
  margin: 0.5 rem;
}

.mgn-top8 {
  margin-top: 0.5 rem;
}

.mgn-rgt8 {
  margin-right: 0.5 rem;
}

.mgn-btm8 {
  margin-bottom: 0.5 rem;
}

.mgn-lft8 {
  margin-left: 0.5 rem;
}

.mgn-9 {
  margin: 0.5625 rem;
}

.mgn-top9 {
  margin-top: 0.5625 rem;
}

.mgn-rgt9 {
  margin-right: 0.5625 rem;
}

.mgn-btm9 {
  margin-bottom: 0.5625 rem;
}

.mgn-lft9 {
  margin-left: 0.5625 rem;
}

.mgn-10 {
  margin: 0.625 rem;
}

.mgn-top10 {
  margin-top: 0.625 rem;
}

.mgn-rgt10 {
  margin-right: 0.625 rem;
}

.mgn-btm10 {
  margin-bottom: 0.625 rem;
}

.mgn-lft10 {
  margin-left: 0.625 rem;
}

.mgn-11 {
  margin: 0.6875 rem;
}

.mgn-top11 {
  margin-top: 0.6875 rem;
}

.mgn-rgt11 {
  margin-right: 0.6875 rem;
}

.mgn-btm11 {
  margin-bottom: 0.6875 rem;
}

.mgn-lft11 {
  margin-left: 0.6875 rem;
}

.mgn-12 {
  margin: 0.75 rem;
}

.mgn-top12 {
  margin-top: 0.75 rem;
}

.mgn-rgt12 {
  margin-right: 0.75 rem;
}

.mgn-btm12 {
  margin-bottom: 0.75 rem;
}

.mgn-lft12 {
  margin-left: 0.75 rem;
}

.mgn-13 {
  margin: 0.8125 rem;
}

.mgn-top13 {
  margin-top: 0.8125 rem;
}

.mgn-rgt13 {
  margin-right: 0.8125 rem;
}

.mgn-btm13 {
  margin-bottom: 0.8125 rem;
}

.mgn-lft13 {
  margin-left: 0.8125 rem;
}

.mgn-14 {
  margin: 0.875 rem;
}

.mgn-top14 {
  margin-top: 0.875 rem;
}

.mgn-rgt14 {
  margin-right: 0.875 rem;
}

.mgn-btm14 {
  margin-bottom: 0.875 rem;
}

.mgn-lft14 {
  margin-left: 0.875 rem;
}

.mgn-15 {
  margin: 0.9375 rem;
}

.mgn-top15 {
  margin-top: 0.9375 rem;
}

.mgn-rgt15 {
  margin-right: 0.9375 rem;
}

.mgn-btm15 {
  margin-bottom: 0.9375 rem;
}

.mgn-lft15 {
  margin-left: 0.9375 rem;
}

.mgn-16 {
  margin: 1 rem;
}

.mgn-top16 {
  margin-top: 1 rem;
}

.mgn-rgt16 {
  margin-right: 1 rem;
}

.mgn-btm16 {
  margin-bottom: 1 rem;
}

.mgn-lft16 {
  margin-left: 1 rem;
}

.mgn-17 {
  margin: 1.0625 rem;
}

.mgn-top17 {
  margin-top: 1.0625 rem;
}

.mgn-rgt17 {
  margin-right: 1.0625 rem;
}

.mgn-btm17 {
  margin-bottom: 1.0625 rem;
}

.mgn-lft17 {
  margin-left: 1.0625 rem;
}

.mgn-18 {
  margin: 1.125 rem;
}

.mgn-top18 {
  margin-top: 1.125 rem;
}

.mgn-rgt18 {
  margin-right: 1.125 rem;
}

.mgn-btm18 {
  margin-bottom: 1.125 rem;
}

.mgn-lft18 {
  margin-left: 1.125 rem;
}

.mgn-19 {
  margin: 1.1875 rem;
}

.mgn-top19 {
  margin-top: 1.1875 rem;
}

.mgn-rgt19 {
  margin-right: 1.1875 rem;
}

.mgn-btm19 {
  margin-bottom: 1.1875 rem;
}

.mgn-lft19 {
  margin-left: 1.1875 rem;
}

.mgn-20 {
  margin: 1.25 rem;
}

.mgn-top20 {
  margin-top: 1.25 rem;
}

.mgn-rgt20 {
  margin-right: 1.25 rem;
}

.mgn-btm20 {
  margin-bottom: 1.25 rem;
}

.mgn-lft20 {
  margin-left: 1.25 rem;
}

.mgn-21 {
  margin: 1.3125 rem;
}

.mgn-top21 {
  margin-top: 1.3125 rem;
}

.mgn-rgt21 {
  margin-right: 1.3125 rem;
}

.mgn-btm21 {
  margin-bottom: 1.3125 rem;
}

.mgn-lft21 {
  margin-left: 1.3125 rem;
}

.mgn-22 {
  margin: 1.375 rem;
}

.mgn-top22 {
  margin-top: 1.375 rem;
}

.mgn-rgt22 {
  margin-right: 1.375 rem;
}

.mgn-btm22 {
  margin-bottom: 1.375 rem;
}

.mgn-lft22 {
  margin-left: 1.375 rem;
}

.mgn-23 {
  margin: 1.4375 rem;
}

.mgn-top23 {
  margin-top: 1.4375 rem;
}

.mgn-rgt23 {
  margin-right: 1.4375 rem;
}

.mgn-btm23 {
  margin-bottom: 1.4375 rem;
}

.mgn-lft23 {
  margin-left: 1.4375 rem;
}

.mgn-24 {
  margin: 1.5 rem;
}

.mgn-top24 {
  margin-top: 1.5 rem;
}

.mgn-rgt24 {
  margin-right: 1.5 rem;
}

.mgn-btm24 {
  margin-bottom: 1.5 rem;
}

.mgn-lft24 {
  margin-left: 1.5 rem;
}

.mgn-25 {
  margin: 1.5625 rem;
}

.mgn-top25 {
  margin-top: 1.5625 rem;
}

.mgn-rgt25 {
  margin-right: 1.5625 rem;
}

.mgn-btm25 {
  margin-bottom: 1.5625 rem;
}

.mgn-lft25 {
  margin-left: 1.5625 rem;
}

.mgn-26 {
  margin: 1.625 rem;
}

.mgn-top26 {
  margin-top: 1.625 rem;
}

.mgn-rgt26 {
  margin-right: 1.625 rem;
}

.mgn-btm26 {
  margin-bottom: 1.625 rem;
}

.mgn-lft26 {
  margin-left: 1.625 rem;
}

.mgn-27 {
  margin: 1.6875 rem;
}

.mgn-top27 {
  margin-top: 1.6875 rem;
}

.mgn-rgt27 {
  margin-right: 1.6875 rem;
}

.mgn-btm27 {
  margin-bottom: 1.6875 rem;
}

.mgn-lft27 {
  margin-left: 1.6875 rem;
}

.mgn-28 {
  margin: 1.75 rem;
}

.mgn-top28 {
  margin-top: 1.75 rem;
}

.mgn-rgt28 {
  margin-right: 1.75 rem;
}

.mgn-btm28 {
  margin-bottom: 1.75 rem;
}

.mgn-lft28 {
  margin-left: 1.75 rem;
}

.mgn-29 {
  margin: 1.8125 rem;
}

.mgn-top29 {
  margin-top: 1.8125 rem;
}

.mgn-rgt29 {
  margin-right: 1.8125 rem;
}

.mgn-btm29 {
  margin-bottom: 1.8125 rem;
}

.mgn-lft29 {
  margin-left: 1.8125 rem;
}

.mgn-30 {
  margin: 1.875 rem;
}

.mgn-top30 {
  margin-top: 1.875 rem;
}

.mgn-rgt30 {
  margin-right: 1.875 rem;
}

.mgn-btm30 {
  margin-bottom: 1.875 rem;
}

.mgn-lft30 {
  margin-left: 1.875 rem;
}

.mgn-31 {
  margin: 1.9375 rem;
}

.mgn-top31 {
  margin-top: 1.9375 rem;
}

.mgn-rgt31 {
  margin-right: 1.9375 rem;
}

.mgn-btm31 {
  margin-bottom: 1.9375 rem;
}

.mgn-lft31 {
  margin-left: 1.9375 rem;
}

.mgn-32 {
  margin: 2 rem;
}

.mgn-top32 {
  margin-top: 2 rem;
}

.mgn-rgt32 {
  margin-right: 2 rem;
}

.mgn-btm32 {
  margin-bottom: 2 rem;
}

.mgn-lft32 {
  margin-left: 2 rem;
}

.mgn-33 {
  margin: 2.0625 rem;
}

.mgn-top33 {
  margin-top: 2.0625 rem;
}

.mgn-rgt33 {
  margin-right: 2.0625 rem;
}

.mgn-btm33 {
  margin-bottom: 2.0625 rem;
}

.mgn-lft33 {
  margin-left: 2.0625 rem;
}

.mgn-34 {
  margin: 2.125 rem;
}

.mgn-top34 {
  margin-top: 2.125 rem;
}

.mgn-rgt34 {
  margin-right: 2.125 rem;
}

.mgn-btm34 {
  margin-bottom: 2.125 rem;
}

.mgn-lft34 {
  margin-left: 2.125 rem;
}

.mgn-35 {
  margin: 2.1875 rem;
}

.mgn-top35 {
  margin-top: 2.1875 rem;
}

.mgn-rgt35 {
  margin-right: 2.1875 rem;
}

.mgn-btm35 {
  margin-bottom: 2.1875 rem;
}

.mgn-lft35 {
  margin-left: 2.1875 rem;
}

.mgn-36 {
  margin: 2.25 rem;
}

.mgn-top36 {
  margin-top: 2.25 rem;
}

.mgn-rgt36 {
  margin-right: 2.25 rem;
}

.mgn-btm36 {
  margin-bottom: 2.25 rem;
}

.mgn-lft36 {
  margin-left: 2.25 rem;
}

.mgn-37 {
  margin: 2.3125 rem;
}

.mgn-top37 {
  margin-top: 2.3125 rem;
}

.mgn-rgt37 {
  margin-right: 2.3125 rem;
}

.mgn-btm37 {
  margin-bottom: 2.3125 rem;
}

.mgn-lft37 {
  margin-left: 2.3125 rem;
}

.mgn-38 {
  margin: 2.375 rem;
}

.mgn-top38 {
  margin-top: 2.375 rem;
}

.mgn-rgt38 {
  margin-right: 2.375 rem;
}

.mgn-btm38 {
  margin-bottom: 2.375 rem;
}

.mgn-lft38 {
  margin-left: 2.375 rem;
}

.mgn-39 {
  margin: 2.4375 rem;
}

.mgn-top39 {
  margin-top: 2.4375 rem;
}

.mgn-rgt39 {
  margin-right: 2.4375 rem;
}

.mgn-btm39 {
  margin-bottom: 2.4375 rem;
}

.mgn-lft39 {
  margin-left: 2.4375 rem;
}

.mgn-40 {
  margin: 2.5 rem;
}

.mgn-top40 {
  margin-top: 2.5 rem;
}

.mgn-rgt40 {
  margin-right: 2.5 rem;
}

.mgn-btm40 {
  margin-bottom: 2.5 rem;
}

.mgn-lft40 {
  margin-left: 2.5 rem;
}

.mgn-41 {
  margin: 2.5625 rem;
}

.mgn-top41 {
  margin-top: 2.5625 rem;
}

.mgn-rgt41 {
  margin-right: 2.5625 rem;
}

.mgn-btm41 {
  margin-bottom: 2.5625 rem;
}

.mgn-lft41 {
  margin-left: 2.5625 rem;
}

.mgn-42 {
  margin: 2.625 rem;
}

.mgn-top42 {
  margin-top: 2.625 rem;
}

.mgn-rgt42 {
  margin-right: 2.625 rem;
}

.mgn-btm42 {
  margin-bottom: 2.625 rem;
}

.mgn-lft42 {
  margin-left: 2.625 rem;
}

.mgn-43 {
  margin: 2.6875 rem;
}

.mgn-top43 {
  margin-top: 2.6875 rem;
}

.mgn-rgt43 {
  margin-right: 2.6875 rem;
}

.mgn-btm43 {
  margin-bottom: 2.6875 rem;
}

.mgn-lft43 {
  margin-left: 2.6875 rem;
}

.mgn-44 {
  margin: 2.75 rem;
}

.mgn-top44 {
  margin-top: 2.75 rem;
}

.mgn-rgt44 {
  margin-right: 2.75 rem;
}

.mgn-btm44 {
  margin-bottom: 2.75 rem;
}

.mgn-lft44 {
  margin-left: 2.75 rem;
}

.mgn-45 {
  margin: 2.8125 rem;
}

.mgn-top45 {
  margin-top: 2.8125 rem;
}

.mgn-rgt45 {
  margin-right: 2.8125 rem;
}

.mgn-btm45 {
  margin-bottom: 2.8125 rem;
}

.mgn-lft45 {
  margin-left: 2.8125 rem;
}

.mgn-46 {
  margin: 2.875 rem;
}

.mgn-top46 {
  margin-top: 2.875 rem;
}

.mgn-rgt46 {
  margin-right: 2.875 rem;
}

.mgn-btm46 {
  margin-bottom: 2.875 rem;
}

.mgn-lft46 {
  margin-left: 2.875 rem;
}

.mgn-47 {
  margin: 2.9375 rem;
}

.mgn-top47 {
  margin-top: 2.9375 rem;
}

.mgn-rgt47 {
  margin-right: 2.9375 rem;
}

.mgn-btm47 {
  margin-bottom: 2.9375 rem;
}

.mgn-lft47 {
  margin-left: 2.9375 rem;
}

.mgn-48 {
  margin: 3 rem;
}

.mgn-top48 {
  margin-top: 3 rem;
}

.mgn-rgt48 {
  margin-right: 3 rem;
}

.mgn-btm48 {
  margin-bottom: 3 rem;
}

.mgn-lft48 {
  margin-left: 3 rem;
}

.mgn-49 {
  margin: 3.0625 rem;
}

.mgn-top49 {
  margin-top: 3.0625 rem;
}

.mgn-rgt49 {
  margin-right: 3.0625 rem;
}

.mgn-btm49 {
  margin-bottom: 3.0625 rem;
}

.mgn-lft49 {
  margin-left: 3.0625 rem;
}

.mgn-50 {
  margin: 3.125 rem;
}

.mgn-top50 {
  margin-top: 3.125 rem;
}

.mgn-rgt50 {
  margin-right: 3.125 rem;
}

.mgn-btm50 {
  margin-bottom: 3.125 rem;
}

.mgn-lft50 {
  margin-left: 3.125 rem;
}

.mgn-51 {
  margin: 3.1875 rem;
}

.mgn-top51 {
  margin-top: 3.1875 rem;
}

.mgn-rgt51 {
  margin-right: 3.1875 rem;
}

.mgn-btm51 {
  margin-bottom: 3.1875 rem;
}

.mgn-lft51 {
  margin-left: 3.1875 rem;
}

.mgn-52 {
  margin: 3.25 rem;
}

.mgn-top52 {
  margin-top: 3.25 rem;
}

.mgn-rgt52 {
  margin-right: 3.25 rem;
}

.mgn-btm52 {
  margin-bottom: 3.25 rem;
}

.mgn-lft52 {
  margin-left: 3.25 rem;
}

.mgn-53 {
  margin: 3.3125 rem;
}

.mgn-top53 {
  margin-top: 3.3125 rem;
}

.mgn-rgt53 {
  margin-right: 3.3125 rem;
}

.mgn-btm53 {
  margin-bottom: 3.3125 rem;
}

.mgn-lft53 {
  margin-left: 3.3125 rem;
}

.mgn-54 {
  margin: 3.375 rem;
}

.mgn-top54 {
  margin-top: 3.375 rem;
}

.mgn-rgt54 {
  margin-right: 3.375 rem;
}

.mgn-btm54 {
  margin-bottom: 3.375 rem;
}

.mgn-lft54 {
  margin-left: 3.375 rem;
}

.mgn-55 {
  margin: 3.4375 rem;
}

.mgn-top55 {
  margin-top: 3.4375 rem;
}

.mgn-rgt55 {
  margin-right: 3.4375 rem;
}

.mgn-btm55 {
  margin-bottom: 3.4375 rem;
}

.mgn-lft55 {
  margin-left: 3.4375 rem;
}

.mgn-56 {
  margin: 3.5 rem;
}

.mgn-top56 {
  margin-top: 3.5 rem;
}

.mgn-rgt56 {
  margin-right: 3.5 rem;
}

.mgn-btm56 {
  margin-bottom: 3.5 rem;
}

.mgn-lft56 {
  margin-left: 3.5 rem;
}

.mgn-57 {
  margin: 3.5625 rem;
}

.mgn-top57 {
  margin-top: 3.5625 rem;
}

.mgn-rgt57 {
  margin-right: 3.5625 rem;
}

.mgn-btm57 {
  margin-bottom: 3.5625 rem;
}

.mgn-lft57 {
  margin-left: 3.5625 rem;
}

.mgn-58 {
  margin: 3.625 rem;
}

.mgn-top58 {
  margin-top: 3.625 rem;
}

.mgn-rgt58 {
  margin-right: 3.625 rem;
}

.mgn-btm58 {
  margin-bottom: 3.625 rem;
}

.mgn-lft58 {
  margin-left: 3.625 rem;
}

.mgn-59 {
  margin: 3.6875 rem;
}

.mgn-top59 {
  margin-top: 3.6875 rem;
}

.mgn-rgt59 {
  margin-right: 3.6875 rem;
}

.mgn-btm59 {
  margin-bottom: 3.6875 rem;
}

.mgn-lft59 {
  margin-left: 3.6875 rem;
}

.mgn-60 {
  margin: 3.75 rem;
}

.mgn-top60 {
  margin-top: 3.75 rem;
}

.mgn-rgt60 {
  margin-right: 3.75 rem;
}

.mgn-btm60 {
  margin-bottom: 3.75 rem;
}

.mgn-lft60 {
  margin-left: 3.75 rem;
}

.mgn-61 {
  margin: 3.8125 rem;
}

.mgn-top61 {
  margin-top: 3.8125 rem;
}

.mgn-rgt61 {
  margin-right: 3.8125 rem;
}

.mgn-btm61 {
  margin-bottom: 3.8125 rem;
}

.mgn-lft61 {
  margin-left: 3.8125 rem;
}

.mgn-62 {
  margin: 3.875 rem;
}

.mgn-top62 {
  margin-top: 3.875 rem;
}

.mgn-rgt62 {
  margin-right: 3.875 rem;
}

.mgn-btm62 {
  margin-bottom: 3.875 rem;
}

.mgn-lft62 {
  margin-left: 3.875 rem;
}

.mgn-63 {
  margin: 3.9375 rem;
}

.mgn-top63 {
  margin-top: 3.9375 rem;
}

.mgn-rgt63 {
  margin-right: 3.9375 rem;
}

.mgn-btm63 {
  margin-bottom: 3.9375 rem;
}

.mgn-lft63 {
  margin-left: 3.9375 rem;
}

.mgn-64 {
  margin: 4 rem;
}

.mgn-top64 {
  margin-top: 4 rem;
}

.mgn-rgt64 {
  margin-right: 4 rem;
}

.mgn-btm64 {
  margin-bottom: 4 rem;
}

.mgn-lft64 {
  margin-left: 4 rem;
}

.mgn-65 {
  margin: 4.0625 rem;
}

.mgn-top65 {
  margin-top: 4.0625 rem;
}

.mgn-rgt65 {
  margin-right: 4.0625 rem;
}

.mgn-btm65 {
  margin-bottom: 4.0625 rem;
}

.mgn-lft65 {
  margin-left: 4.0625 rem;
}

.mgn-66 {
  margin: 4.125 rem;
}

.mgn-top66 {
  margin-top: 4.125 rem;
}

.mgn-rgt66 {
  margin-right: 4.125 rem;
}

.mgn-btm66 {
  margin-bottom: 4.125 rem;
}

.mgn-lft66 {
  margin-left: 4.125 rem;
}

.mgn-67 {
  margin: 4.1875 rem;
}

.mgn-top67 {
  margin-top: 4.1875 rem;
}

.mgn-rgt67 {
  margin-right: 4.1875 rem;
}

.mgn-btm67 {
  margin-bottom: 4.1875 rem;
}

.mgn-lft67 {
  margin-left: 4.1875 rem;
}

.mgn-68 {
  margin: 4.25 rem;
}

.mgn-top68 {
  margin-top: 4.25 rem;
}

.mgn-rgt68 {
  margin-right: 4.25 rem;
}

.mgn-btm68 {
  margin-bottom: 4.25 rem;
}

.mgn-lft68 {
  margin-left: 4.25 rem;
}

.mgn-69 {
  margin: 4.3125 rem;
}

.mgn-top69 {
  margin-top: 4.3125 rem;
}

.mgn-rgt69 {
  margin-right: 4.3125 rem;
}

.mgn-btm69 {
  margin-bottom: 4.3125 rem;
}

.mgn-lft69 {
  margin-left: 4.3125 rem;
}

.mgn-70 {
  margin: 4.375 rem;
}

.mgn-top70 {
  margin-top: 4.375 rem;
}

.mgn-rgt70 {
  margin-right: 4.375 rem;
}

.mgn-btm70 {
  margin-bottom: 4.375 rem;
}

.mgn-lft70 {
  margin-left: 4.375 rem;
}

.mgn-71 {
  margin: 4.4375 rem;
}

.mgn-top71 {
  margin-top: 4.4375 rem;
}

.mgn-rgt71 {
  margin-right: 4.4375 rem;
}

.mgn-btm71 {
  margin-bottom: 4.4375 rem;
}

.mgn-lft71 {
  margin-left: 4.4375 rem;
}

.mgn-72 {
  margin: 4.5 rem;
}

.mgn-top72 {
  margin-top: 4.5 rem;
}

.mgn-rgt72 {
  margin-right: 4.5 rem;
}

.mgn-btm72 {
  margin-bottom: 4.5 rem;
}

.mgn-lft72 {
  margin-left: 4.5 rem;
}

.mgn-73 {
  margin: 4.5625 rem;
}

.mgn-top73 {
  margin-top: 4.5625 rem;
}

.mgn-rgt73 {
  margin-right: 4.5625 rem;
}

.mgn-btm73 {
  margin-bottom: 4.5625 rem;
}

.mgn-lft73 {
  margin-left: 4.5625 rem;
}

.mgn-74 {
  margin: 4.625 rem;
}

.mgn-top74 {
  margin-top: 4.625 rem;
}

.mgn-rgt74 {
  margin-right: 4.625 rem;
}

.mgn-btm74 {
  margin-bottom: 4.625 rem;
}

.mgn-lft74 {
  margin-left: 4.625 rem;
}

.mgn-75 {
  margin: 4.6875 rem;
}

.mgn-top75 {
  margin-top: 4.6875 rem;
}

.mgn-rgt75 {
  margin-right: 4.6875 rem;
}

.mgn-btm75 {
  margin-bottom: 4.6875 rem;
}

.mgn-lft75 {
  margin-left: 4.6875 rem;
}

.mgn-76 {
  margin: 4.75 rem;
}

.mgn-top76 {
  margin-top: 4.75 rem;
}

.mgn-rgt76 {
  margin-right: 4.75 rem;
}

.mgn-btm76 {
  margin-bottom: 4.75 rem;
}

.mgn-lft76 {
  margin-left: 4.75 rem;
}

.mgn-77 {
  margin: 4.8125 rem;
}

.mgn-top77 {
  margin-top: 4.8125 rem;
}

.mgn-rgt77 {
  margin-right: 4.8125 rem;
}

.mgn-btm77 {
  margin-bottom: 4.8125 rem;
}

.mgn-lft77 {
  margin-left: 4.8125 rem;
}

.mgn-78 {
  margin: 4.875 rem;
}

.mgn-top78 {
  margin-top: 4.875 rem;
}

.mgn-rgt78 {
  margin-right: 4.875 rem;
}

.mgn-btm78 {
  margin-bottom: 4.875 rem;
}

.mgn-lft78 {
  margin-left: 4.875 rem;
}

.mgn-79 {
  margin: 4.9375 rem;
}

.mgn-top79 {
  margin-top: 4.9375 rem;
}

.mgn-rgt79 {
  margin-right: 4.9375 rem;
}

.mgn-btm79 {
  margin-bottom: 4.9375 rem;
}

.mgn-lft79 {
  margin-left: 4.9375 rem;
}

.mgn-80 {
  margin: 5 rem;
}

.mgn-top80 {
  margin-top: 5 rem;
}

.mgn-rgt80 {
  margin-right: 5 rem;
}

.mgn-btm80 {
  margin-bottom: 5 rem;
}

.mgn-lft80 {
  margin-left: 5 rem;
}

.mgn-81 {
  margin: 5.0625 rem;
}

.mgn-top81 {
  margin-top: 5.0625 rem;
}

.mgn-rgt81 {
  margin-right: 5.0625 rem;
}

.mgn-btm81 {
  margin-bottom: 5.0625 rem;
}

.mgn-lft81 {
  margin-left: 5.0625 rem;
}

.mgn-82 {
  margin: 5.125 rem;
}

.mgn-top82 {
  margin-top: 5.125 rem;
}

.mgn-rgt82 {
  margin-right: 5.125 rem;
}

.mgn-btm82 {
  margin-bottom: 5.125 rem;
}

.mgn-lft82 {
  margin-left: 5.125 rem;
}

.mgn-83 {
  margin: 5.1875 rem;
}

.mgn-top83 {
  margin-top: 5.1875 rem;
}

.mgn-rgt83 {
  margin-right: 5.1875 rem;
}

.mgn-btm83 {
  margin-bottom: 5.1875 rem;
}

.mgn-lft83 {
  margin-left: 5.1875 rem;
}

.mgn-84 {
  margin: 5.25 rem;
}

.mgn-top84 {
  margin-top: 5.25 rem;
}

.mgn-rgt84 {
  margin-right: 5.25 rem;
}

.mgn-btm84 {
  margin-bottom: 5.25 rem;
}

.mgn-lft84 {
  margin-left: 5.25 rem;
}

.mgn-85 {
  margin: 5.3125 rem;
}

.mgn-top85 {
  margin-top: 5.3125 rem;
}

.mgn-rgt85 {
  margin-right: 5.3125 rem;
}

.mgn-btm85 {
  margin-bottom: 5.3125 rem;
}

.mgn-lft85 {
  margin-left: 5.3125 rem;
}

.mgn-86 {
  margin: 5.375 rem;
}

.mgn-top86 {
  margin-top: 5.375 rem;
}

.mgn-rgt86 {
  margin-right: 5.375 rem;
}

.mgn-btm86 {
  margin-bottom: 5.375 rem;
}

.mgn-lft86 {
  margin-left: 5.375 rem;
}

.mgn-87 {
  margin: 5.4375 rem;
}

.mgn-top87 {
  margin-top: 5.4375 rem;
}

.mgn-rgt87 {
  margin-right: 5.4375 rem;
}

.mgn-btm87 {
  margin-bottom: 5.4375 rem;
}

.mgn-lft87 {
  margin-left: 5.4375 rem;
}

.mgn-88 {
  margin: 5.5 rem;
}

.mgn-top88 {
  margin-top: 5.5 rem;
}

.mgn-rgt88 {
  margin-right: 5.5 rem;
}

.mgn-btm88 {
  margin-bottom: 5.5 rem;
}

.mgn-lft88 {
  margin-left: 5.5 rem;
}

.mgn-89 {
  margin: 5.5625 rem;
}

.mgn-top89 {
  margin-top: 5.5625 rem;
}

.mgn-rgt89 {
  margin-right: 5.5625 rem;
}

.mgn-btm89 {
  margin-bottom: 5.5625 rem;
}

.mgn-lft89 {
  margin-left: 5.5625 rem;
}

.mgn-90 {
  margin: 5.625 rem;
}

.mgn-top90 {
  margin-top: 5.625 rem;
}

.mgn-rgt90 {
  margin-right: 5.625 rem;
}

.mgn-btm90 {
  margin-bottom: 5.625 rem;
}

.mgn-lft90 {
  margin-left: 5.625 rem;
}

.mgn-91 {
  margin: 5.6875 rem;
}

.mgn-top91 {
  margin-top: 5.6875 rem;
}

.mgn-rgt91 {
  margin-right: 5.6875 rem;
}

.mgn-btm91 {
  margin-bottom: 5.6875 rem;
}

.mgn-lft91 {
  margin-left: 5.6875 rem;
}

.mgn-92 {
  margin: 5.75 rem;
}

.mgn-top92 {
  margin-top: 5.75 rem;
}

.mgn-rgt92 {
  margin-right: 5.75 rem;
}

.mgn-btm92 {
  margin-bottom: 5.75 rem;
}

.mgn-lft92 {
  margin-left: 5.75 rem;
}

.mgn-93 {
  margin: 5.8125 rem;
}

.mgn-top93 {
  margin-top: 5.8125 rem;
}

.mgn-rgt93 {
  margin-right: 5.8125 rem;
}

.mgn-btm93 {
  margin-bottom: 5.8125 rem;
}

.mgn-lft93 {
  margin-left: 5.8125 rem;
}

.mgn-94 {
  margin: 5.875 rem;
}

.mgn-top94 {
  margin-top: 5.875 rem;
}

.mgn-rgt94 {
  margin-right: 5.875 rem;
}

.mgn-btm94 {
  margin-bottom: 5.875 rem;
}

.mgn-lft94 {
  margin-left: 5.875 rem;
}

.mgn-95 {
  margin: 5.9375 rem;
}

.mgn-top95 {
  margin-top: 5.9375 rem;
}

.mgn-rgt95 {
  margin-right: 5.9375 rem;
}

.mgn-btm95 {
  margin-bottom: 5.9375 rem;
}

.mgn-lft95 {
  margin-left: 5.9375 rem;
}

.mgn-96 {
  margin: 6 rem;
}

.mgn-top96 {
  margin-top: 6 rem;
}

.mgn-rgt96 {
  margin-right: 6 rem;
}

.mgn-btm96 {
  margin-bottom: 6 rem;
}

.mgn-lft96 {
  margin-left: 6 rem;
}

.mgn-97 {
  margin: 6.0625 rem;
}

.mgn-top97 {
  margin-top: 6.0625 rem;
}

.mgn-rgt97 {
  margin-right: 6.0625 rem;
}

.mgn-btm97 {
  margin-bottom: 6.0625 rem;
}

.mgn-lft97 {
  margin-left: 6.0625 rem;
}

.mgn-98 {
  margin: 6.125 rem;
}

.mgn-top98 {
  margin-top: 6.125 rem;
}

.mgn-rgt98 {
  margin-right: 6.125 rem;
}

.mgn-btm98 {
  margin-bottom: 6.125 rem;
}

.mgn-lft98 {
  margin-left: 6.125 rem;
}

.mgn-99 {
  margin: 6.1875 rem;
}

.mgn-top99 {
  margin-top: 6.1875 rem;
}

.mgn-rgt99 {
  margin-right: 6.1875 rem;
}

.mgn-btm99 {
  margin-bottom: 6.1875 rem;
}

.mgn-lft99 {
  margin-left: 6.1875 rem;
}

.mgn-100 {
  margin: 6.25 rem;
}

.mgn-top100 {
  margin-top: 6.25 rem;
}

.mgn-rgt100 {
  margin-right: 6.25 rem;
}

.mgn-btm100 {
  margin-bottom: 6.25 rem;
}

.mgn-lft100 {
  margin-left: 6.25 rem;
}

.mgn-101 {
  margin: 6.3125 rem;
}

.mgn-top101 {
  margin-top: 6.3125 rem;
}

.mgn-rgt101 {
  margin-right: 6.3125 rem;
}

.mgn-btm101 {
  margin-bottom: 6.3125 rem;
}

.mgn-lft101 {
  margin-left: 6.3125 rem;
}

.mgn-102 {
  margin: 6.375 rem;
}

.mgn-top102 {
  margin-top: 6.375 rem;
}

.mgn-rgt102 {
  margin-right: 6.375 rem;
}

.mgn-btm102 {
  margin-bottom: 6.375 rem;
}

.mgn-lft102 {
  margin-left: 6.375 rem;
}

.mgn-103 {
  margin: 6.4375 rem;
}

.mgn-top103 {
  margin-top: 6.4375 rem;
}

.mgn-rgt103 {
  margin-right: 6.4375 rem;
}

.mgn-btm103 {
  margin-bottom: 6.4375 rem;
}

.mgn-lft103 {
  margin-left: 6.4375 rem;
}

.mgn-104 {
  margin: 6.5 rem;
}

.mgn-top104 {
  margin-top: 6.5 rem;
}

.mgn-rgt104 {
  margin-right: 6.5 rem;
}

.mgn-btm104 {
  margin-bottom: 6.5 rem;
}

.mgn-lft104 {
  margin-left: 6.5 rem;
}

.mgn-105 {
  margin: 6.5625 rem;
}

.mgn-top105 {
  margin-top: 6.5625 rem;
}

.mgn-rgt105 {
  margin-right: 6.5625 rem;
}

.mgn-btm105 {
  margin-bottom: 6.5625 rem;
}

.mgn-lft105 {
  margin-left: 6.5625 rem;
}

.mgn-106 {
  margin: 6.625 rem;
}

.mgn-top106 {
  margin-top: 6.625 rem;
}

.mgn-rgt106 {
  margin-right: 6.625 rem;
}

.mgn-btm106 {
  margin-bottom: 6.625 rem;
}

.mgn-lft106 {
  margin-left: 6.625 rem;
}

.mgn-107 {
  margin: 6.6875 rem;
}

.mgn-top107 {
  margin-top: 6.6875 rem;
}

.mgn-rgt107 {
  margin-right: 6.6875 rem;
}

.mgn-btm107 {
  margin-bottom: 6.6875 rem;
}

.mgn-lft107 {
  margin-left: 6.6875 rem;
}

.mgn-108 {
  margin: 6.75 rem;
}

.mgn-top108 {
  margin-top: 6.75 rem;
}

.mgn-rgt108 {
  margin-right: 6.75 rem;
}

.mgn-btm108 {
  margin-bottom: 6.75 rem;
}

.mgn-lft108 {
  margin-left: 6.75 rem;
}

.mgn-109 {
  margin: 6.8125 rem;
}

.mgn-top109 {
  margin-top: 6.8125 rem;
}

.mgn-rgt109 {
  margin-right: 6.8125 rem;
}

.mgn-btm109 {
  margin-bottom: 6.8125 rem;
}

.mgn-lft109 {
  margin-left: 6.8125 rem;
}

.mgn-110 {
  margin: 6.875 rem;
}

.mgn-top110 {
  margin-top: 6.875 rem;
}

.mgn-rgt110 {
  margin-right: 6.875 rem;
}

.mgn-btm110 {
  margin-bottom: 6.875 rem;
}

.mgn-lft110 {
  margin-left: 6.875 rem;
}

.mgn-111 {
  margin: 6.9375 rem;
}

.mgn-top111 {
  margin-top: 6.9375 rem;
}

.mgn-rgt111 {
  margin-right: 6.9375 rem;
}

.mgn-btm111 {
  margin-bottom: 6.9375 rem;
}

.mgn-lft111 {
  margin-left: 6.9375 rem;
}

.mgn-112 {
  margin: 7 rem;
}

.mgn-top112 {
  margin-top: 7 rem;
}

.mgn-rgt112 {
  margin-right: 7 rem;
}

.mgn-btm112 {
  margin-bottom: 7 rem;
}

.mgn-lft112 {
  margin-left: 7 rem;
}

.mgn-113 {
  margin: 7.0625 rem;
}

.mgn-top113 {
  margin-top: 7.0625 rem;
}

.mgn-rgt113 {
  margin-right: 7.0625 rem;
}

.mgn-btm113 {
  margin-bottom: 7.0625 rem;
}

.mgn-lft113 {
  margin-left: 7.0625 rem;
}

.mgn-114 {
  margin: 7.125 rem;
}

.mgn-top114 {
  margin-top: 7.125 rem;
}

.mgn-rgt114 {
  margin-right: 7.125 rem;
}

.mgn-btm114 {
  margin-bottom: 7.125 rem;
}

.mgn-lft114 {
  margin-left: 7.125 rem;
}

.mgn-115 {
  margin: 7.1875 rem;
}

.mgn-top115 {
  margin-top: 7.1875 rem;
}

.mgn-rgt115 {
  margin-right: 7.1875 rem;
}

.mgn-btm115 {
  margin-bottom: 7.1875 rem;
}

.mgn-lft115 {
  margin-left: 7.1875 rem;
}

.mgn-116 {
  margin: 7.25 rem;
}

.mgn-top116 {
  margin-top: 7.25 rem;
}

.mgn-rgt116 {
  margin-right: 7.25 rem;
}

.mgn-btm116 {
  margin-bottom: 7.25 rem;
}

.mgn-lft116 {
  margin-left: 7.25 rem;
}

.mgn-117 {
  margin: 7.3125 rem;
}

.mgn-top117 {
  margin-top: 7.3125 rem;
}

.mgn-rgt117 {
  margin-right: 7.3125 rem;
}

.mgn-btm117 {
  margin-bottom: 7.3125 rem;
}

.mgn-lft117 {
  margin-left: 7.3125 rem;
}

.mgn-118 {
  margin: 7.375 rem;
}

.mgn-top118 {
  margin-top: 7.375 rem;
}

.mgn-rgt118 {
  margin-right: 7.375 rem;
}

.mgn-btm118 {
  margin-bottom: 7.375 rem;
}

.mgn-lft118 {
  margin-left: 7.375 rem;
}

.mgn-119 {
  margin: 7.4375 rem;
}

.mgn-top119 {
  margin-top: 7.4375 rem;
}

.mgn-rgt119 {
  margin-right: 7.4375 rem;
}

.mgn-btm119 {
  margin-bottom: 7.4375 rem;
}

.mgn-lft119 {
  margin-left: 7.4375 rem;
}

.mgn-120 {
  margin: 7.5 rem;
}

.mgn-top120 {
  margin-top: 7.5 rem;
}

.mgn-rgt120 {
  margin-right: 7.5 rem;
}

.mgn-btm120 {
  margin-bottom: 7.5 rem;
}

.mgn-lft120 {
  margin-left: 7.5 rem;
}

.mgn-121 {
  margin: 7.5625 rem;
}

.mgn-top121 {
  margin-top: 7.5625 rem;
}

.mgn-rgt121 {
  margin-right: 7.5625 rem;
}

.mgn-btm121 {
  margin-bottom: 7.5625 rem;
}

.mgn-lft121 {
  margin-left: 7.5625 rem;
}

.mgn-122 {
  margin: 7.625 rem;
}

.mgn-top122 {
  margin-top: 7.625 rem;
}

.mgn-rgt122 {
  margin-right: 7.625 rem;
}

.mgn-btm122 {
  margin-bottom: 7.625 rem;
}

.mgn-lft122 {
  margin-left: 7.625 rem;
}

.mgn-123 {
  margin: 7.6875 rem;
}

.mgn-top123 {
  margin-top: 7.6875 rem;
}

.mgn-rgt123 {
  margin-right: 7.6875 rem;
}

.mgn-btm123 {
  margin-bottom: 7.6875 rem;
}

.mgn-lft123 {
  margin-left: 7.6875 rem;
}

.mgn-124 {
  margin: 7.75 rem;
}

.mgn-top124 {
  margin-top: 7.75 rem;
}

.mgn-rgt124 {
  margin-right: 7.75 rem;
}

.mgn-btm124 {
  margin-bottom: 7.75 rem;
}

.mgn-lft124 {
  margin-left: 7.75 rem;
}

.mgn-125 {
  margin: 7.8125 rem;
}

.mgn-top125 {
  margin-top: 7.8125 rem;
}

.mgn-rgt125 {
  margin-right: 7.8125 rem;
}

.mgn-btm125 {
  margin-bottom: 7.8125 rem;
}

.mgn-lft125 {
  margin-left: 7.8125 rem;
}

.mgn-126 {
  margin: 7.875 rem;
}

.mgn-top126 {
  margin-top: 7.875 rem;
}

.mgn-rgt126 {
  margin-right: 7.875 rem;
}

.mgn-btm126 {
  margin-bottom: 7.875 rem;
}

.mgn-lft126 {
  margin-left: 7.875 rem;
}

.mgn-127 {
  margin: 7.9375 rem;
}

.mgn-top127 {
  margin-top: 7.9375 rem;
}

.mgn-rgt127 {
  margin-right: 7.9375 rem;
}

.mgn-btm127 {
  margin-bottom: 7.9375 rem;
}

.mgn-lft127 {
  margin-left: 7.9375 rem;
}

.mgn-128 {
  margin: 8 rem;
}

.mgn-top128 {
  margin-top: 8 rem;
}

.mgn-rgt128 {
  margin-right: 8 rem;
}

.mgn-btm128 {
  margin-bottom: 8 rem;
}

.mgn-lft128 {
  margin-left: 8 rem;
}

.mgn-129 {
  margin: 8.0625 rem;
}

.mgn-top129 {
  margin-top: 8.0625 rem;
}

.mgn-rgt129 {
  margin-right: 8.0625 rem;
}

.mgn-btm129 {
  margin-bottom: 8.0625 rem;
}

.mgn-lft129 {
  margin-left: 8.0625 rem;
}

.mgn-130 {
  margin: 8.125 rem;
}

.mgn-top130 {
  margin-top: 8.125 rem;
}

.mgn-rgt130 {
  margin-right: 8.125 rem;
}

.mgn-btm130 {
  margin-bottom: 8.125 rem;
}

.mgn-lft130 {
  margin-left: 8.125 rem;
}

.mgn-131 {
  margin: 8.1875 rem;
}

.mgn-top131 {
  margin-top: 8.1875 rem;
}

.mgn-rgt131 {
  margin-right: 8.1875 rem;
}

.mgn-btm131 {
  margin-bottom: 8.1875 rem;
}

.mgn-lft131 {
  margin-left: 8.1875 rem;
}

.mgn-132 {
  margin: 8.25 rem;
}

.mgn-top132 {
  margin-top: 8.25 rem;
}

.mgn-rgt132 {
  margin-right: 8.25 rem;
}

.mgn-btm132 {
  margin-bottom: 8.25 rem;
}

.mgn-lft132 {
  margin-left: 8.25 rem;
}

.mgn-133 {
  margin: 8.3125 rem;
}

.mgn-top133 {
  margin-top: 8.3125 rem;
}

.mgn-rgt133 {
  margin-right: 8.3125 rem;
}

.mgn-btm133 {
  margin-bottom: 8.3125 rem;
}

.mgn-lft133 {
  margin-left: 8.3125 rem;
}

.mgn-134 {
  margin: 8.375 rem;
}

.mgn-top134 {
  margin-top: 8.375 rem;
}

.mgn-rgt134 {
  margin-right: 8.375 rem;
}

.mgn-btm134 {
  margin-bottom: 8.375 rem;
}

.mgn-lft134 {
  margin-left: 8.375 rem;
}

.mgn-135 {
  margin: 8.4375 rem;
}

.mgn-top135 {
  margin-top: 8.4375 rem;
}

.mgn-rgt135 {
  margin-right: 8.4375 rem;
}

.mgn-btm135 {
  margin-bottom: 8.4375 rem;
}

.mgn-lft135 {
  margin-left: 8.4375 rem;
}

.mgn-136 {
  margin: 8.5 rem;
}

.mgn-top136 {
  margin-top: 8.5 rem;
}

.mgn-rgt136 {
  margin-right: 8.5 rem;
}

.mgn-btm136 {
  margin-bottom: 8.5 rem;
}

.mgn-lft136 {
  margin-left: 8.5 rem;
}

.mgn-137 {
  margin: 8.5625 rem;
}

.mgn-top137 {
  margin-top: 8.5625 rem;
}

.mgn-rgt137 {
  margin-right: 8.5625 rem;
}

.mgn-btm137 {
  margin-bottom: 8.5625 rem;
}

.mgn-lft137 {
  margin-left: 8.5625 rem;
}

.mgn-138 {
  margin: 8.625 rem;
}

.mgn-top138 {
  margin-top: 8.625 rem;
}

.mgn-rgt138 {
  margin-right: 8.625 rem;
}

.mgn-btm138 {
  margin-bottom: 8.625 rem;
}

.mgn-lft138 {
  margin-left: 8.625 rem;
}

.mgn-139 {
  margin: 8.6875 rem;
}

.mgn-top139 {
  margin-top: 8.6875 rem;
}

.mgn-rgt139 {
  margin-right: 8.6875 rem;
}

.mgn-btm139 {
  margin-bottom: 8.6875 rem;
}

.mgn-lft139 {
  margin-left: 8.6875 rem;
}

.mgn-140 {
  margin: 8.75 rem;
}

.mgn-top140 {
  margin-top: 8.75 rem;
}

.mgn-rgt140 {
  margin-right: 8.75 rem;
}

.mgn-btm140 {
  margin-bottom: 8.75 rem;
}

.mgn-lft140 {
  margin-left: 8.75 rem;
}

.mgn-141 {
  margin: 8.8125 rem;
}

.mgn-top141 {
  margin-top: 8.8125 rem;
}

.mgn-rgt141 {
  margin-right: 8.8125 rem;
}

.mgn-btm141 {
  margin-bottom: 8.8125 rem;
}

.mgn-lft141 {
  margin-left: 8.8125 rem;
}

.mgn-142 {
  margin: 8.875 rem;
}

.mgn-top142 {
  margin-top: 8.875 rem;
}

.mgn-rgt142 {
  margin-right: 8.875 rem;
}

.mgn-btm142 {
  margin-bottom: 8.875 rem;
}

.mgn-lft142 {
  margin-left: 8.875 rem;
}

.mgn-143 {
  margin: 8.9375 rem;
}

.mgn-top143 {
  margin-top: 8.9375 rem;
}

.mgn-rgt143 {
  margin-right: 8.9375 rem;
}

.mgn-btm143 {
  margin-bottom: 8.9375 rem;
}

.mgn-lft143 {
  margin-left: 8.9375 rem;
}

.mgn-144 {
  margin: 9 rem;
}

.mgn-top144 {
  margin-top: 9 rem;
}

.mgn-rgt144 {
  margin-right: 9 rem;
}

.mgn-btm144 {
  margin-bottom: 9 rem;
}

.mgn-lft144 {
  margin-left: 9 rem;
}

.mgn-145 {
  margin: 9.0625 rem;
}

.mgn-top145 {
  margin-top: 9.0625 rem;
}

.mgn-rgt145 {
  margin-right: 9.0625 rem;
}

.mgn-btm145 {
  margin-bottom: 9.0625 rem;
}

.mgn-lft145 {
  margin-left: 9.0625 rem;
}

.mgn-146 {
  margin: 9.125 rem;
}

.mgn-top146 {
  margin-top: 9.125 rem;
}

.mgn-rgt146 {
  margin-right: 9.125 rem;
}

.mgn-btm146 {
  margin-bottom: 9.125 rem;
}

.mgn-lft146 {
  margin-left: 9.125 rem;
}

.mgn-147 {
  margin: 9.1875 rem;
}

.mgn-top147 {
  margin-top: 9.1875 rem;
}

.mgn-rgt147 {
  margin-right: 9.1875 rem;
}

.mgn-btm147 {
  margin-bottom: 9.1875 rem;
}

.mgn-lft147 {
  margin-left: 9.1875 rem;
}

.mgn-148 {
  margin: 9.25 rem;
}

.mgn-top148 {
  margin-top: 9.25 rem;
}

.mgn-rgt148 {
  margin-right: 9.25 rem;
}

.mgn-btm148 {
  margin-bottom: 9.25 rem;
}

.mgn-lft148 {
  margin-left: 9.25 rem;
}

.mgn-149 {
  margin: 9.3125 rem;
}

.mgn-top149 {
  margin-top: 9.3125 rem;
}

.mgn-rgt149 {
  margin-right: 9.3125 rem;
}

.mgn-btm149 {
  margin-bottom: 9.3125 rem;
}

.mgn-lft149 {
  margin-left: 9.3125 rem;
}

.mgn-150 {
  margin: 9.375 rem;
}

.mgn-top150 {
  margin-top: 9.375 rem;
}

.mgn-rgt150 {
  margin-right: 9.375 rem;
}

.mgn-btm150 {
  margin-bottom: 9.375 rem;
}

.mgn-lft150 {
  margin-left: 9.375 rem;
}

@media screen and (max-width: 47.9375em) {
  /* --- margin --- 古い・削除*/
  .mgn-btm16 {
    margin-bottom: 8px;
  }
  .mgn-btm24 {
    margin-bottom: 16px;
  }
  .mgn-btm32 {
    margin-bottom: 16px;
  }
  .mgn-btm40 {
    margin-bottom: 24px;
  }
  .mgn-btm48 {
    margin-bottom: 24px;
  }
  .mgn-btm56 {
    margin-bottom: 32px;
  }
  .mgn-btm64 {
    margin-bottom: 32px;
  }
  .mgn-btm72 {
    margin-bottom: 40px;
  }
  .mgn-btm80 {
    margin-bottom: 40px;
  }
  .mgn-btm120 {
    margin-bottom: 60px;
  }
}
.mgn-0_em {
  margin: 0em;
}

.mgn-top0_em {
  margin-top: 0em;
}

.mgn-rgt0_em {
  margin-right: 0em;
}

.mgn-btm0_em {
  margin-bottom: 0em;
}

.mgn-lft0_em {
  margin-left: 0em;
}

.mgn-1_em {
  margin: 1em;
}

.mgn-top1_em {
  margin-top: 1em;
}

.mgn-rgt1_em {
  margin-right: 1em;
}

.mgn-btm1_em {
  margin-bottom: 1em;
}

.mgn-lft1_em {
  margin-left: 1em;
}

.mgn-2_em {
  margin: 2em;
}

.mgn-top2_em {
  margin-top: 2em;
}

.mgn-rgt2_em {
  margin-right: 2em;
}

.mgn-btm2_em {
  margin-bottom: 2em;
}

.mgn-lft2_em {
  margin-left: 2em;
}

.mgn-3_em {
  margin: 3em;
}

.mgn-top3_em {
  margin-top: 3em;
}

.mgn-rgt3_em {
  margin-right: 3em;
}

.mgn-btm3_em {
  margin-bottom: 3em;
}

.mgn-lft3_em {
  margin-left: 3em;
}

.mgn-4_em {
  margin: 4em;
}

.mgn-top4_em {
  margin-top: 4em;
}

.mgn-rgt4_em {
  margin-right: 4em;
}

.mgn-btm4_em {
  margin-bottom: 4em;
}

.mgn-lft4_em {
  margin-left: 4em;
}

.mgn-5_em {
  margin: 5em;
}

.mgn-top5_em {
  margin-top: 5em;
}

.mgn-rgt5_em {
  margin-right: 5em;
}

.mgn-btm5_em {
  margin-bottom: 5em;
}

.mgn-lft5_em {
  margin-left: 5em;
}

.mgn-6_em {
  margin: 6em;
}

.mgn-top6_em {
  margin-top: 6em;
}

.mgn-rgt6_em {
  margin-right: 6em;
}

.mgn-btm6_em {
  margin-bottom: 6em;
}

.mgn-lft6_em {
  margin-left: 6em;
}

.mgn-7_em {
  margin: 7em;
}

.mgn-top7_em {
  margin-top: 7em;
}

.mgn-rgt7_em {
  margin-right: 7em;
}

.mgn-btm7_em {
  margin-bottom: 7em;
}

.mgn-lft7_em {
  margin-left: 7em;
}

.mgn-8_em {
  margin: 8em;
}

.mgn-top8_em {
  margin-top: 8em;
}

.mgn-rgt8_em {
  margin-right: 8em;
}

.mgn-btm8_em {
  margin-bottom: 8em;
}

.mgn-lft8_em {
  margin-left: 8em;
}

.mgn-9_em {
  margin: 9em;
}

.mgn-top9_em {
  margin-top: 9em;
}

.mgn-rgt9_em {
  margin-right: 9em;
}

.mgn-btm9_em {
  margin-bottom: 9em;
}

.mgn-lft9_em {
  margin-left: 9em;
}

.mgn-10_em {
  margin: 10em;
}

.mgn-top10_em {
  margin-top: 10em;
}

.mgn-rgt10_em {
  margin-right: 10em;
}

.mgn-btm10_em {
  margin-bottom: 10em;
}

.mgn-lft10_em {
  margin-left: 10em;
}

.mgn-11_em {
  margin: 11em;
}

.mgn-top11_em {
  margin-top: 11em;
}

.mgn-rgt11_em {
  margin-right: 11em;
}

.mgn-btm11_em {
  margin-bottom: 11em;
}

.mgn-lft11_em {
  margin-left: 11em;
}

.mgn-12_em {
  margin: 12em;
}

.mgn-top12_em {
  margin-top: 12em;
}

.mgn-rgt12_em {
  margin-right: 12em;
}

.mgn-btm12_em {
  margin-bottom: 12em;
}

.mgn-lft12_em {
  margin-left: 12em;
}

.mgn-13_em {
  margin: 13em;
}

.mgn-top13_em {
  margin-top: 13em;
}

.mgn-rgt13_em {
  margin-right: 13em;
}

.mgn-btm13_em {
  margin-bottom: 13em;
}

.mgn-lft13_em {
  margin-left: 13em;
}

.mgn-14_em {
  margin: 14em;
}

.mgn-top14_em {
  margin-top: 14em;
}

.mgn-rgt14_em {
  margin-right: 14em;
}

.mgn-btm14_em {
  margin-bottom: 14em;
}

.mgn-lft14_em {
  margin-left: 14em;
}

.mgn-15_em {
  margin: 15em;
}

.mgn-top15_em {
  margin-top: 15em;
}

.mgn-rgt15_em {
  margin-right: 15em;
}

.mgn-btm15_em {
  margin-bottom: 15em;
}

.mgn-lft15_em {
  margin-left: 15em;
}

.pdg-0 {
  padding: 0px;
}

.pdg-top0 {
  padding-top: 0px;
}

.pdg-rgt0 {
  padding-right: 0px;
}

.pdg-btm0 {
  padding-bottom: 0px;
}

.pdg-lft0 {
  padding-left: 0px;
}

.pdg-1 {
  padding: 1px;
}

.pdg-top1 {
  padding-top: 1px;
}

.pdg-rgt1 {
  padding-right: 1px;
}

.pdg-btm1 {
  padding-bottom: 1px;
}

.pdg-lft1 {
  padding-left: 1px;
}

.pdg-2 {
  padding: 2px;
}

.pdg-top2 {
  padding-top: 2px;
}

.pdg-rgt2 {
  padding-right: 2px;
}

.pdg-btm2 {
  padding-bottom: 2px;
}

.pdg-lft2 {
  padding-left: 2px;
}

.pdg-3 {
  padding: 3px;
}

.pdg-top3 {
  padding-top: 3px;
}

.pdg-rgt3 {
  padding-right: 3px;
}

.pdg-btm3 {
  padding-bottom: 3px;
}

.pdg-lft3 {
  padding-left: 3px;
}

.pdg-4 {
  padding: 4px;
}

.pdg-top4 {
  padding-top: 4px;
}

.pdg-rgt4 {
  padding-right: 4px;
}

.pdg-btm4 {
  padding-bottom: 4px;
}

.pdg-lft4 {
  padding-left: 4px;
}

.pdg-5 {
  padding: 5px;
}

.pdg-top5 {
  padding-top: 5px;
}

.pdg-rgt5 {
  padding-right: 5px;
}

.pdg-btm5 {
  padding-bottom: 5px;
}

.pdg-lft5 {
  padding-left: 5px;
}

.pdg-6 {
  padding: 6px;
}

.pdg-top6 {
  padding-top: 6px;
}

.pdg-rgt6 {
  padding-right: 6px;
}

.pdg-btm6 {
  padding-bottom: 6px;
}

.pdg-lft6 {
  padding-left: 6px;
}

.pdg-7 {
  padding: 7px;
}

.pdg-top7 {
  padding-top: 7px;
}

.pdg-rgt7 {
  padding-right: 7px;
}

.pdg-btm7 {
  padding-bottom: 7px;
}

.pdg-lft7 {
  padding-left: 7px;
}

.pdg-8 {
  padding: 8px;
}

.pdg-top8 {
  padding-top: 8px;
}

.pdg-rgt8 {
  padding-right: 8px;
}

.pdg-btm8 {
  padding-bottom: 8px;
}

.pdg-lft8 {
  padding-left: 8px;
}

.pdg-9 {
  padding: 9px;
}

.pdg-top9 {
  padding-top: 9px;
}

.pdg-rgt9 {
  padding-right: 9px;
}

.pdg-btm9 {
  padding-bottom: 9px;
}

.pdg-lft9 {
  padding-left: 9px;
}

.pdg-10 {
  padding: 10px;
}

.pdg-top10 {
  padding-top: 10px;
}

.pdg-rgt10 {
  padding-right: 10px;
}

.pdg-btm10 {
  padding-bottom: 10px;
}

.pdg-lft10 {
  padding-left: 10px;
}

.pdg-11 {
  padding: 11px;
}

.pdg-top11 {
  padding-top: 11px;
}

.pdg-rgt11 {
  padding-right: 11px;
}

.pdg-btm11 {
  padding-bottom: 11px;
}

.pdg-lft11 {
  padding-left: 11px;
}

.pdg-12 {
  padding: 12px;
}

.pdg-top12 {
  padding-top: 12px;
}

.pdg-rgt12 {
  padding-right: 12px;
}

.pdg-btm12 {
  padding-bottom: 12px;
}

.pdg-lft12 {
  padding-left: 12px;
}

.pdg-13 {
  padding: 13px;
}

.pdg-top13 {
  padding-top: 13px;
}

.pdg-rgt13 {
  padding-right: 13px;
}

.pdg-btm13 {
  padding-bottom: 13px;
}

.pdg-lft13 {
  padding-left: 13px;
}

.pdg-14 {
  padding: 14px;
}

.pdg-top14 {
  padding-top: 14px;
}

.pdg-rgt14 {
  padding-right: 14px;
}

.pdg-btm14 {
  padding-bottom: 14px;
}

.pdg-lft14 {
  padding-left: 14px;
}

.pdg-15 {
  padding: 15px;
}

.pdg-top15 {
  padding-top: 15px;
}

.pdg-rgt15 {
  padding-right: 15px;
}

.pdg-btm15 {
  padding-bottom: 15px;
}

.pdg-lft15 {
  padding-left: 15px;
}

.pdg-16 {
  padding: 16px;
}

.pdg-top16 {
  padding-top: 16px;
}

.pdg-rgt16 {
  padding-right: 16px;
}

.pdg-btm16 {
  padding-bottom: 16px;
}

.pdg-lft16 {
  padding-left: 16px;
}

.pdg-17 {
  padding: 17px;
}

.pdg-top17 {
  padding-top: 17px;
}

.pdg-rgt17 {
  padding-right: 17px;
}

.pdg-btm17 {
  padding-bottom: 17px;
}

.pdg-lft17 {
  padding-left: 17px;
}

.pdg-18 {
  padding: 18px;
}

.pdg-top18 {
  padding-top: 18px;
}

.pdg-rgt18 {
  padding-right: 18px;
}

.pdg-btm18 {
  padding-bottom: 18px;
}

.pdg-lft18 {
  padding-left: 18px;
}

.pdg-19 {
  padding: 19px;
}

.pdg-top19 {
  padding-top: 19px;
}

.pdg-rgt19 {
  padding-right: 19px;
}

.pdg-btm19 {
  padding-bottom: 19px;
}

.pdg-lft19 {
  padding-left: 19px;
}

.pdg-20 {
  padding: 20px;
}

.pdg-top20 {
  padding-top: 20px;
}

.pdg-rgt20 {
  padding-right: 20px;
}

.pdg-btm20 {
  padding-bottom: 20px;
}

.pdg-lft20 {
  padding-left: 20px;
}

.pdg-21 {
  padding: 21px;
}

.pdg-top21 {
  padding-top: 21px;
}

.pdg-rgt21 {
  padding-right: 21px;
}

.pdg-btm21 {
  padding-bottom: 21px;
}

.pdg-lft21 {
  padding-left: 21px;
}

.pdg-22 {
  padding: 22px;
}

.pdg-top22 {
  padding-top: 22px;
}

.pdg-rgt22 {
  padding-right: 22px;
}

.pdg-btm22 {
  padding-bottom: 22px;
}

.pdg-lft22 {
  padding-left: 22px;
}

.pdg-23 {
  padding: 23px;
}

.pdg-top23 {
  padding-top: 23px;
}

.pdg-rgt23 {
  padding-right: 23px;
}

.pdg-btm23 {
  padding-bottom: 23px;
}

.pdg-lft23 {
  padding-left: 23px;
}

.pdg-24 {
  padding: 24px;
}

.pdg-top24 {
  padding-top: 24px;
}

.pdg-rgt24 {
  padding-right: 24px;
}

.pdg-btm24 {
  padding-bottom: 24px;
}

.pdg-lft24 {
  padding-left: 24px;
}

.pdg-25 {
  padding: 25px;
}

.pdg-top25 {
  padding-top: 25px;
}

.pdg-rgt25 {
  padding-right: 25px;
}

.pdg-btm25 {
  padding-bottom: 25px;
}

.pdg-lft25 {
  padding-left: 25px;
}

.pdg-26 {
  padding: 26px;
}

.pdg-top26 {
  padding-top: 26px;
}

.pdg-rgt26 {
  padding-right: 26px;
}

.pdg-btm26 {
  padding-bottom: 26px;
}

.pdg-lft26 {
  padding-left: 26px;
}

.pdg-27 {
  padding: 27px;
}

.pdg-top27 {
  padding-top: 27px;
}

.pdg-rgt27 {
  padding-right: 27px;
}

.pdg-btm27 {
  padding-bottom: 27px;
}

.pdg-lft27 {
  padding-left: 27px;
}

.pdg-28 {
  padding: 28px;
}

.pdg-top28 {
  padding-top: 28px;
}

.pdg-rgt28 {
  padding-right: 28px;
}

.pdg-btm28 {
  padding-bottom: 28px;
}

.pdg-lft28 {
  padding-left: 28px;
}

.pdg-29 {
  padding: 29px;
}

.pdg-top29 {
  padding-top: 29px;
}

.pdg-rgt29 {
  padding-right: 29px;
}

.pdg-btm29 {
  padding-bottom: 29px;
}

.pdg-lft29 {
  padding-left: 29px;
}

.pdg-30 {
  padding: 30px;
}

.pdg-top30 {
  padding-top: 30px;
}

.pdg-rgt30 {
  padding-right: 30px;
}

.pdg-btm30 {
  padding-bottom: 30px;
}

.pdg-lft30 {
  padding-left: 30px;
}

.pdg-31 {
  padding: 31px;
}

.pdg-top31 {
  padding-top: 31px;
}

.pdg-rgt31 {
  padding-right: 31px;
}

.pdg-btm31 {
  padding-bottom: 31px;
}

.pdg-lft31 {
  padding-left: 31px;
}

.pdg-32 {
  padding: 32px;
}

.pdg-top32 {
  padding-top: 32px;
}

.pdg-rgt32 {
  padding-right: 32px;
}

.pdg-btm32 {
  padding-bottom: 32px;
}

.pdg-lft32 {
  padding-left: 32px;
}

.pdg-33 {
  padding: 33px;
}

.pdg-top33 {
  padding-top: 33px;
}

.pdg-rgt33 {
  padding-right: 33px;
}

.pdg-btm33 {
  padding-bottom: 33px;
}

.pdg-lft33 {
  padding-left: 33px;
}

.pdg-34 {
  padding: 34px;
}

.pdg-top34 {
  padding-top: 34px;
}

.pdg-rgt34 {
  padding-right: 34px;
}

.pdg-btm34 {
  padding-bottom: 34px;
}

.pdg-lft34 {
  padding-left: 34px;
}

.pdg-35 {
  padding: 35px;
}

.pdg-top35 {
  padding-top: 35px;
}

.pdg-rgt35 {
  padding-right: 35px;
}

.pdg-btm35 {
  padding-bottom: 35px;
}

.pdg-lft35 {
  padding-left: 35px;
}

.pdg-36 {
  padding: 36px;
}

.pdg-top36 {
  padding-top: 36px;
}

.pdg-rgt36 {
  padding-right: 36px;
}

.pdg-btm36 {
  padding-bottom: 36px;
}

.pdg-lft36 {
  padding-left: 36px;
}

.pdg-37 {
  padding: 37px;
}

.pdg-top37 {
  padding-top: 37px;
}

.pdg-rgt37 {
  padding-right: 37px;
}

.pdg-btm37 {
  padding-bottom: 37px;
}

.pdg-lft37 {
  padding-left: 37px;
}

.pdg-38 {
  padding: 38px;
}

.pdg-top38 {
  padding-top: 38px;
}

.pdg-rgt38 {
  padding-right: 38px;
}

.pdg-btm38 {
  padding-bottom: 38px;
}

.pdg-lft38 {
  padding-left: 38px;
}

.pdg-39 {
  padding: 39px;
}

.pdg-top39 {
  padding-top: 39px;
}

.pdg-rgt39 {
  padding-right: 39px;
}

.pdg-btm39 {
  padding-bottom: 39px;
}

.pdg-lft39 {
  padding-left: 39px;
}

.pdg-40 {
  padding: 40px;
}

.pdg-top40 {
  padding-top: 40px;
}

.pdg-rgt40 {
  padding-right: 40px;
}

.pdg-btm40 {
  padding-bottom: 40px;
}

.pdg-lft40 {
  padding-left: 40px;
}

.pdg-41 {
  padding: 41px;
}

.pdg-top41 {
  padding-top: 41px;
}

.pdg-rgt41 {
  padding-right: 41px;
}

.pdg-btm41 {
  padding-bottom: 41px;
}

.pdg-lft41 {
  padding-left: 41px;
}

.pdg-42 {
  padding: 42px;
}

.pdg-top42 {
  padding-top: 42px;
}

.pdg-rgt42 {
  padding-right: 42px;
}

.pdg-btm42 {
  padding-bottom: 42px;
}

.pdg-lft42 {
  padding-left: 42px;
}

.pdg-43 {
  padding: 43px;
}

.pdg-top43 {
  padding-top: 43px;
}

.pdg-rgt43 {
  padding-right: 43px;
}

.pdg-btm43 {
  padding-bottom: 43px;
}

.pdg-lft43 {
  padding-left: 43px;
}

.pdg-44 {
  padding: 44px;
}

.pdg-top44 {
  padding-top: 44px;
}

.pdg-rgt44 {
  padding-right: 44px;
}

.pdg-btm44 {
  padding-bottom: 44px;
}

.pdg-lft44 {
  padding-left: 44px;
}

.pdg-45 {
  padding: 45px;
}

.pdg-top45 {
  padding-top: 45px;
}

.pdg-rgt45 {
  padding-right: 45px;
}

.pdg-btm45 {
  padding-bottom: 45px;
}

.pdg-lft45 {
  padding-left: 45px;
}

.pdg-46 {
  padding: 46px;
}

.pdg-top46 {
  padding-top: 46px;
}

.pdg-rgt46 {
  padding-right: 46px;
}

.pdg-btm46 {
  padding-bottom: 46px;
}

.pdg-lft46 {
  padding-left: 46px;
}

.pdg-47 {
  padding: 47px;
}

.pdg-top47 {
  padding-top: 47px;
}

.pdg-rgt47 {
  padding-right: 47px;
}

.pdg-btm47 {
  padding-bottom: 47px;
}

.pdg-lft47 {
  padding-left: 47px;
}

.pdg-48 {
  padding: 48px;
}

.pdg-top48 {
  padding-top: 48px;
}

.pdg-rgt48 {
  padding-right: 48px;
}

.pdg-btm48 {
  padding-bottom: 48px;
}

.pdg-lft48 {
  padding-left: 48px;
}

.pdg-49 {
  padding: 49px;
}

.pdg-top49 {
  padding-top: 49px;
}

.pdg-rgt49 {
  padding-right: 49px;
}

.pdg-btm49 {
  padding-bottom: 49px;
}

.pdg-lft49 {
  padding-left: 49px;
}

.pdg-50 {
  padding: 50px;
}

.pdg-top50 {
  padding-top: 50px;
}

.pdg-rgt50 {
  padding-right: 50px;
}

.pdg-btm50 {
  padding-bottom: 50px;
}

.pdg-lft50 {
  padding-left: 50px;
}

.pdg-51 {
  padding: 51px;
}

.pdg-top51 {
  padding-top: 51px;
}

.pdg-rgt51 {
  padding-right: 51px;
}

.pdg-btm51 {
  padding-bottom: 51px;
}

.pdg-lft51 {
  padding-left: 51px;
}

.pdg-52 {
  padding: 52px;
}

.pdg-top52 {
  padding-top: 52px;
}

.pdg-rgt52 {
  padding-right: 52px;
}

.pdg-btm52 {
  padding-bottom: 52px;
}

.pdg-lft52 {
  padding-left: 52px;
}

.pdg-53 {
  padding: 53px;
}

.pdg-top53 {
  padding-top: 53px;
}

.pdg-rgt53 {
  padding-right: 53px;
}

.pdg-btm53 {
  padding-bottom: 53px;
}

.pdg-lft53 {
  padding-left: 53px;
}

.pdg-54 {
  padding: 54px;
}

.pdg-top54 {
  padding-top: 54px;
}

.pdg-rgt54 {
  padding-right: 54px;
}

.pdg-btm54 {
  padding-bottom: 54px;
}

.pdg-lft54 {
  padding-left: 54px;
}

.pdg-55 {
  padding: 55px;
}

.pdg-top55 {
  padding-top: 55px;
}

.pdg-rgt55 {
  padding-right: 55px;
}

.pdg-btm55 {
  padding-bottom: 55px;
}

.pdg-lft55 {
  padding-left: 55px;
}

.pdg-56 {
  padding: 56px;
}

.pdg-top56 {
  padding-top: 56px;
}

.pdg-rgt56 {
  padding-right: 56px;
}

.pdg-btm56 {
  padding-bottom: 56px;
}

.pdg-lft56 {
  padding-left: 56px;
}

.pdg-57 {
  padding: 57px;
}

.pdg-top57 {
  padding-top: 57px;
}

.pdg-rgt57 {
  padding-right: 57px;
}

.pdg-btm57 {
  padding-bottom: 57px;
}

.pdg-lft57 {
  padding-left: 57px;
}

.pdg-58 {
  padding: 58px;
}

.pdg-top58 {
  padding-top: 58px;
}

.pdg-rgt58 {
  padding-right: 58px;
}

.pdg-btm58 {
  padding-bottom: 58px;
}

.pdg-lft58 {
  padding-left: 58px;
}

.pdg-59 {
  padding: 59px;
}

.pdg-top59 {
  padding-top: 59px;
}

.pdg-rgt59 {
  padding-right: 59px;
}

.pdg-btm59 {
  padding-bottom: 59px;
}

.pdg-lft59 {
  padding-left: 59px;
}

.pdg-60 {
  padding: 60px;
}

.pdg-top60 {
  padding-top: 60px;
}

.pdg-rgt60 {
  padding-right: 60px;
}

.pdg-btm60 {
  padding-bottom: 60px;
}

.pdg-lft60 {
  padding-left: 60px;
}

.pdg-61 {
  padding: 61px;
}

.pdg-top61 {
  padding-top: 61px;
}

.pdg-rgt61 {
  padding-right: 61px;
}

.pdg-btm61 {
  padding-bottom: 61px;
}

.pdg-lft61 {
  padding-left: 61px;
}

.pdg-62 {
  padding: 62px;
}

.pdg-top62 {
  padding-top: 62px;
}

.pdg-rgt62 {
  padding-right: 62px;
}

.pdg-btm62 {
  padding-bottom: 62px;
}

.pdg-lft62 {
  padding-left: 62px;
}

.pdg-63 {
  padding: 63px;
}

.pdg-top63 {
  padding-top: 63px;
}

.pdg-rgt63 {
  padding-right: 63px;
}

.pdg-btm63 {
  padding-bottom: 63px;
}

.pdg-lft63 {
  padding-left: 63px;
}

.pdg-64 {
  padding: 64px;
}

.pdg-top64 {
  padding-top: 64px;
}

.pdg-rgt64 {
  padding-right: 64px;
}

.pdg-btm64 {
  padding-bottom: 64px;
}

.pdg-lft64 {
  padding-left: 64px;
}

.pdg-65 {
  padding: 65px;
}

.pdg-top65 {
  padding-top: 65px;
}

.pdg-rgt65 {
  padding-right: 65px;
}

.pdg-btm65 {
  padding-bottom: 65px;
}

.pdg-lft65 {
  padding-left: 65px;
}

.pdg-66 {
  padding: 66px;
}

.pdg-top66 {
  padding-top: 66px;
}

.pdg-rgt66 {
  padding-right: 66px;
}

.pdg-btm66 {
  padding-bottom: 66px;
}

.pdg-lft66 {
  padding-left: 66px;
}

.pdg-67 {
  padding: 67px;
}

.pdg-top67 {
  padding-top: 67px;
}

.pdg-rgt67 {
  padding-right: 67px;
}

.pdg-btm67 {
  padding-bottom: 67px;
}

.pdg-lft67 {
  padding-left: 67px;
}

.pdg-68 {
  padding: 68px;
}

.pdg-top68 {
  padding-top: 68px;
}

.pdg-rgt68 {
  padding-right: 68px;
}

.pdg-btm68 {
  padding-bottom: 68px;
}

.pdg-lft68 {
  padding-left: 68px;
}

.pdg-69 {
  padding: 69px;
}

.pdg-top69 {
  padding-top: 69px;
}

.pdg-rgt69 {
  padding-right: 69px;
}

.pdg-btm69 {
  padding-bottom: 69px;
}

.pdg-lft69 {
  padding-left: 69px;
}

.pdg-70 {
  padding: 70px;
}

.pdg-top70 {
  padding-top: 70px;
}

.pdg-rgt70 {
  padding-right: 70px;
}

.pdg-btm70 {
  padding-bottom: 70px;
}

.pdg-lft70 {
  padding-left: 70px;
}

.pdg-71 {
  padding: 71px;
}

.pdg-top71 {
  padding-top: 71px;
}

.pdg-rgt71 {
  padding-right: 71px;
}

.pdg-btm71 {
  padding-bottom: 71px;
}

.pdg-lft71 {
  padding-left: 71px;
}

.pdg-72 {
  padding: 72px;
}

.pdg-top72 {
  padding-top: 72px;
}

.pdg-rgt72 {
  padding-right: 72px;
}

.pdg-btm72 {
  padding-bottom: 72px;
}

.pdg-lft72 {
  padding-left: 72px;
}

.pdg-73 {
  padding: 73px;
}

.pdg-top73 {
  padding-top: 73px;
}

.pdg-rgt73 {
  padding-right: 73px;
}

.pdg-btm73 {
  padding-bottom: 73px;
}

.pdg-lft73 {
  padding-left: 73px;
}

.pdg-74 {
  padding: 74px;
}

.pdg-top74 {
  padding-top: 74px;
}

.pdg-rgt74 {
  padding-right: 74px;
}

.pdg-btm74 {
  padding-bottom: 74px;
}

.pdg-lft74 {
  padding-left: 74px;
}

.pdg-75 {
  padding: 75px;
}

.pdg-top75 {
  padding-top: 75px;
}

.pdg-rgt75 {
  padding-right: 75px;
}

.pdg-btm75 {
  padding-bottom: 75px;
}

.pdg-lft75 {
  padding-left: 75px;
}

.pdg-76 {
  padding: 76px;
}

.pdg-top76 {
  padding-top: 76px;
}

.pdg-rgt76 {
  padding-right: 76px;
}

.pdg-btm76 {
  padding-bottom: 76px;
}

.pdg-lft76 {
  padding-left: 76px;
}

.pdg-77 {
  padding: 77px;
}

.pdg-top77 {
  padding-top: 77px;
}

.pdg-rgt77 {
  padding-right: 77px;
}

.pdg-btm77 {
  padding-bottom: 77px;
}

.pdg-lft77 {
  padding-left: 77px;
}

.pdg-78 {
  padding: 78px;
}

.pdg-top78 {
  padding-top: 78px;
}

.pdg-rgt78 {
  padding-right: 78px;
}

.pdg-btm78 {
  padding-bottom: 78px;
}

.pdg-lft78 {
  padding-left: 78px;
}

.pdg-79 {
  padding: 79px;
}

.pdg-top79 {
  padding-top: 79px;
}

.pdg-rgt79 {
  padding-right: 79px;
}

.pdg-btm79 {
  padding-bottom: 79px;
}

.pdg-lft79 {
  padding-left: 79px;
}

.pdg-80 {
  padding: 80px;
}

.pdg-top80 {
  padding-top: 80px;
}

.pdg-rgt80 {
  padding-right: 80px;
}

.pdg-btm80 {
  padding-bottom: 80px;
}

.pdg-lft80 {
  padding-left: 80px;
}

.pdg-81 {
  padding: 81px;
}

.pdg-top81 {
  padding-top: 81px;
}

.pdg-rgt81 {
  padding-right: 81px;
}

.pdg-btm81 {
  padding-bottom: 81px;
}

.pdg-lft81 {
  padding-left: 81px;
}

.pdg-82 {
  padding: 82px;
}

.pdg-top82 {
  padding-top: 82px;
}

.pdg-rgt82 {
  padding-right: 82px;
}

.pdg-btm82 {
  padding-bottom: 82px;
}

.pdg-lft82 {
  padding-left: 82px;
}

.pdg-83 {
  padding: 83px;
}

.pdg-top83 {
  padding-top: 83px;
}

.pdg-rgt83 {
  padding-right: 83px;
}

.pdg-btm83 {
  padding-bottom: 83px;
}

.pdg-lft83 {
  padding-left: 83px;
}

.pdg-84 {
  padding: 84px;
}

.pdg-top84 {
  padding-top: 84px;
}

.pdg-rgt84 {
  padding-right: 84px;
}

.pdg-btm84 {
  padding-bottom: 84px;
}

.pdg-lft84 {
  padding-left: 84px;
}

.pdg-85 {
  padding: 85px;
}

.pdg-top85 {
  padding-top: 85px;
}

.pdg-rgt85 {
  padding-right: 85px;
}

.pdg-btm85 {
  padding-bottom: 85px;
}

.pdg-lft85 {
  padding-left: 85px;
}

.pdg-86 {
  padding: 86px;
}

.pdg-top86 {
  padding-top: 86px;
}

.pdg-rgt86 {
  padding-right: 86px;
}

.pdg-btm86 {
  padding-bottom: 86px;
}

.pdg-lft86 {
  padding-left: 86px;
}

.pdg-87 {
  padding: 87px;
}

.pdg-top87 {
  padding-top: 87px;
}

.pdg-rgt87 {
  padding-right: 87px;
}

.pdg-btm87 {
  padding-bottom: 87px;
}

.pdg-lft87 {
  padding-left: 87px;
}

.pdg-88 {
  padding: 88px;
}

.pdg-top88 {
  padding-top: 88px;
}

.pdg-rgt88 {
  padding-right: 88px;
}

.pdg-btm88 {
  padding-bottom: 88px;
}

.pdg-lft88 {
  padding-left: 88px;
}

.pdg-89 {
  padding: 89px;
}

.pdg-top89 {
  padding-top: 89px;
}

.pdg-rgt89 {
  padding-right: 89px;
}

.pdg-btm89 {
  padding-bottom: 89px;
}

.pdg-lft89 {
  padding-left: 89px;
}

.pdg-90 {
  padding: 90px;
}

.pdg-top90 {
  padding-top: 90px;
}

.pdg-rgt90 {
  padding-right: 90px;
}

.pdg-btm90 {
  padding-bottom: 90px;
}

.pdg-lft90 {
  padding-left: 90px;
}

.pdg-91 {
  padding: 91px;
}

.pdg-top91 {
  padding-top: 91px;
}

.pdg-rgt91 {
  padding-right: 91px;
}

.pdg-btm91 {
  padding-bottom: 91px;
}

.pdg-lft91 {
  padding-left: 91px;
}

.pdg-92 {
  padding: 92px;
}

.pdg-top92 {
  padding-top: 92px;
}

.pdg-rgt92 {
  padding-right: 92px;
}

.pdg-btm92 {
  padding-bottom: 92px;
}

.pdg-lft92 {
  padding-left: 92px;
}

.pdg-93 {
  padding: 93px;
}

.pdg-top93 {
  padding-top: 93px;
}

.pdg-rgt93 {
  padding-right: 93px;
}

.pdg-btm93 {
  padding-bottom: 93px;
}

.pdg-lft93 {
  padding-left: 93px;
}

.pdg-94 {
  padding: 94px;
}

.pdg-top94 {
  padding-top: 94px;
}

.pdg-rgt94 {
  padding-right: 94px;
}

.pdg-btm94 {
  padding-bottom: 94px;
}

.pdg-lft94 {
  padding-left: 94px;
}

.pdg-95 {
  padding: 95px;
}

.pdg-top95 {
  padding-top: 95px;
}

.pdg-rgt95 {
  padding-right: 95px;
}

.pdg-btm95 {
  padding-bottom: 95px;
}

.pdg-lft95 {
  padding-left: 95px;
}

.pdg-96 {
  padding: 96px;
}

.pdg-top96 {
  padding-top: 96px;
}

.pdg-rgt96 {
  padding-right: 96px;
}

.pdg-btm96 {
  padding-bottom: 96px;
}

.pdg-lft96 {
  padding-left: 96px;
}

.pdg-97 {
  padding: 97px;
}

.pdg-top97 {
  padding-top: 97px;
}

.pdg-rgt97 {
  padding-right: 97px;
}

.pdg-btm97 {
  padding-bottom: 97px;
}

.pdg-lft97 {
  padding-left: 97px;
}

.pdg-98 {
  padding: 98px;
}

.pdg-top98 {
  padding-top: 98px;
}

.pdg-rgt98 {
  padding-right: 98px;
}

.pdg-btm98 {
  padding-bottom: 98px;
}

.pdg-lft98 {
  padding-left: 98px;
}

.pdg-99 {
  padding: 99px;
}

.pdg-top99 {
  padding-top: 99px;
}

.pdg-rgt99 {
  padding-right: 99px;
}

.pdg-btm99 {
  padding-bottom: 99px;
}

.pdg-lft99 {
  padding-left: 99px;
}

.pdg-100 {
  padding: 100px;
}

.pdg-top100 {
  padding-top: 100px;
}

.pdg-rgt100 {
  padding-right: 100px;
}

.pdg-btm100 {
  padding-bottom: 100px;
}

.pdg-lft100 {
  padding-left: 100px;
}

.pdg-101 {
  padding: 101px;
}

.pdg-top101 {
  padding-top: 101px;
}

.pdg-rgt101 {
  padding-right: 101px;
}

.pdg-btm101 {
  padding-bottom: 101px;
}

.pdg-lft101 {
  padding-left: 101px;
}

.pdg-102 {
  padding: 102px;
}

.pdg-top102 {
  padding-top: 102px;
}

.pdg-rgt102 {
  padding-right: 102px;
}

.pdg-btm102 {
  padding-bottom: 102px;
}

.pdg-lft102 {
  padding-left: 102px;
}

.pdg-103 {
  padding: 103px;
}

.pdg-top103 {
  padding-top: 103px;
}

.pdg-rgt103 {
  padding-right: 103px;
}

.pdg-btm103 {
  padding-bottom: 103px;
}

.pdg-lft103 {
  padding-left: 103px;
}

.pdg-104 {
  padding: 104px;
}

.pdg-top104 {
  padding-top: 104px;
}

.pdg-rgt104 {
  padding-right: 104px;
}

.pdg-btm104 {
  padding-bottom: 104px;
}

.pdg-lft104 {
  padding-left: 104px;
}

.pdg-105 {
  padding: 105px;
}

.pdg-top105 {
  padding-top: 105px;
}

.pdg-rgt105 {
  padding-right: 105px;
}

.pdg-btm105 {
  padding-bottom: 105px;
}

.pdg-lft105 {
  padding-left: 105px;
}

.pdg-106 {
  padding: 106px;
}

.pdg-top106 {
  padding-top: 106px;
}

.pdg-rgt106 {
  padding-right: 106px;
}

.pdg-btm106 {
  padding-bottom: 106px;
}

.pdg-lft106 {
  padding-left: 106px;
}

.pdg-107 {
  padding: 107px;
}

.pdg-top107 {
  padding-top: 107px;
}

.pdg-rgt107 {
  padding-right: 107px;
}

.pdg-btm107 {
  padding-bottom: 107px;
}

.pdg-lft107 {
  padding-left: 107px;
}

.pdg-108 {
  padding: 108px;
}

.pdg-top108 {
  padding-top: 108px;
}

.pdg-rgt108 {
  padding-right: 108px;
}

.pdg-btm108 {
  padding-bottom: 108px;
}

.pdg-lft108 {
  padding-left: 108px;
}

.pdg-109 {
  padding: 109px;
}

.pdg-top109 {
  padding-top: 109px;
}

.pdg-rgt109 {
  padding-right: 109px;
}

.pdg-btm109 {
  padding-bottom: 109px;
}

.pdg-lft109 {
  padding-left: 109px;
}

.pdg-110 {
  padding: 110px;
}

.pdg-top110 {
  padding-top: 110px;
}

.pdg-rgt110 {
  padding-right: 110px;
}

.pdg-btm110 {
  padding-bottom: 110px;
}

.pdg-lft110 {
  padding-left: 110px;
}

.pdg-111 {
  padding: 111px;
}

.pdg-top111 {
  padding-top: 111px;
}

.pdg-rgt111 {
  padding-right: 111px;
}

.pdg-btm111 {
  padding-bottom: 111px;
}

.pdg-lft111 {
  padding-left: 111px;
}

.pdg-112 {
  padding: 112px;
}

.pdg-top112 {
  padding-top: 112px;
}

.pdg-rgt112 {
  padding-right: 112px;
}

.pdg-btm112 {
  padding-bottom: 112px;
}

.pdg-lft112 {
  padding-left: 112px;
}

.pdg-113 {
  padding: 113px;
}

.pdg-top113 {
  padding-top: 113px;
}

.pdg-rgt113 {
  padding-right: 113px;
}

.pdg-btm113 {
  padding-bottom: 113px;
}

.pdg-lft113 {
  padding-left: 113px;
}

.pdg-114 {
  padding: 114px;
}

.pdg-top114 {
  padding-top: 114px;
}

.pdg-rgt114 {
  padding-right: 114px;
}

.pdg-btm114 {
  padding-bottom: 114px;
}

.pdg-lft114 {
  padding-left: 114px;
}

.pdg-115 {
  padding: 115px;
}

.pdg-top115 {
  padding-top: 115px;
}

.pdg-rgt115 {
  padding-right: 115px;
}

.pdg-btm115 {
  padding-bottom: 115px;
}

.pdg-lft115 {
  padding-left: 115px;
}

.pdg-116 {
  padding: 116px;
}

.pdg-top116 {
  padding-top: 116px;
}

.pdg-rgt116 {
  padding-right: 116px;
}

.pdg-btm116 {
  padding-bottom: 116px;
}

.pdg-lft116 {
  padding-left: 116px;
}

.pdg-117 {
  padding: 117px;
}

.pdg-top117 {
  padding-top: 117px;
}

.pdg-rgt117 {
  padding-right: 117px;
}

.pdg-btm117 {
  padding-bottom: 117px;
}

.pdg-lft117 {
  padding-left: 117px;
}

.pdg-118 {
  padding: 118px;
}

.pdg-top118 {
  padding-top: 118px;
}

.pdg-rgt118 {
  padding-right: 118px;
}

.pdg-btm118 {
  padding-bottom: 118px;
}

.pdg-lft118 {
  padding-left: 118px;
}

.pdg-119 {
  padding: 119px;
}

.pdg-top119 {
  padding-top: 119px;
}

.pdg-rgt119 {
  padding-right: 119px;
}

.pdg-btm119 {
  padding-bottom: 119px;
}

.pdg-lft119 {
  padding-left: 119px;
}

.pdg-120 {
  padding: 120px;
}

.pdg-top120 {
  padding-top: 120px;
}

.pdg-rgt120 {
  padding-right: 120px;
}

.pdg-btm120 {
  padding-bottom: 120px;
}

.pdg-lft120 {
  padding-left: 120px;
}

.pdg-121 {
  padding: 121px;
}

.pdg-top121 {
  padding-top: 121px;
}

.pdg-rgt121 {
  padding-right: 121px;
}

.pdg-btm121 {
  padding-bottom: 121px;
}

.pdg-lft121 {
  padding-left: 121px;
}

.pdg-122 {
  padding: 122px;
}

.pdg-top122 {
  padding-top: 122px;
}

.pdg-rgt122 {
  padding-right: 122px;
}

.pdg-btm122 {
  padding-bottom: 122px;
}

.pdg-lft122 {
  padding-left: 122px;
}

.pdg-123 {
  padding: 123px;
}

.pdg-top123 {
  padding-top: 123px;
}

.pdg-rgt123 {
  padding-right: 123px;
}

.pdg-btm123 {
  padding-bottom: 123px;
}

.pdg-lft123 {
  padding-left: 123px;
}

.pdg-124 {
  padding: 124px;
}

.pdg-top124 {
  padding-top: 124px;
}

.pdg-rgt124 {
  padding-right: 124px;
}

.pdg-btm124 {
  padding-bottom: 124px;
}

.pdg-lft124 {
  padding-left: 124px;
}

.pdg-125 {
  padding: 125px;
}

.pdg-top125 {
  padding-top: 125px;
}

.pdg-rgt125 {
  padding-right: 125px;
}

.pdg-btm125 {
  padding-bottom: 125px;
}

.pdg-lft125 {
  padding-left: 125px;
}

.pdg-126 {
  padding: 126px;
}

.pdg-top126 {
  padding-top: 126px;
}

.pdg-rgt126 {
  padding-right: 126px;
}

.pdg-btm126 {
  padding-bottom: 126px;
}

.pdg-lft126 {
  padding-left: 126px;
}

.pdg-127 {
  padding: 127px;
}

.pdg-top127 {
  padding-top: 127px;
}

.pdg-rgt127 {
  padding-right: 127px;
}

.pdg-btm127 {
  padding-bottom: 127px;
}

.pdg-lft127 {
  padding-left: 127px;
}

.pdg-128 {
  padding: 128px;
}

.pdg-top128 {
  padding-top: 128px;
}

.pdg-rgt128 {
  padding-right: 128px;
}

.pdg-btm128 {
  padding-bottom: 128px;
}

.pdg-lft128 {
  padding-left: 128px;
}

.pdg-129 {
  padding: 129px;
}

.pdg-top129 {
  padding-top: 129px;
}

.pdg-rgt129 {
  padding-right: 129px;
}

.pdg-btm129 {
  padding-bottom: 129px;
}

.pdg-lft129 {
  padding-left: 129px;
}

.pdg-130 {
  padding: 130px;
}

.pdg-top130 {
  padding-top: 130px;
}

.pdg-rgt130 {
  padding-right: 130px;
}

.pdg-btm130 {
  padding-bottom: 130px;
}

.pdg-lft130 {
  padding-left: 130px;
}

.pdg-131 {
  padding: 131px;
}

.pdg-top131 {
  padding-top: 131px;
}

.pdg-rgt131 {
  padding-right: 131px;
}

.pdg-btm131 {
  padding-bottom: 131px;
}

.pdg-lft131 {
  padding-left: 131px;
}

.pdg-132 {
  padding: 132px;
}

.pdg-top132 {
  padding-top: 132px;
}

.pdg-rgt132 {
  padding-right: 132px;
}

.pdg-btm132 {
  padding-bottom: 132px;
}

.pdg-lft132 {
  padding-left: 132px;
}

.pdg-133 {
  padding: 133px;
}

.pdg-top133 {
  padding-top: 133px;
}

.pdg-rgt133 {
  padding-right: 133px;
}

.pdg-btm133 {
  padding-bottom: 133px;
}

.pdg-lft133 {
  padding-left: 133px;
}

.pdg-134 {
  padding: 134px;
}

.pdg-top134 {
  padding-top: 134px;
}

.pdg-rgt134 {
  padding-right: 134px;
}

.pdg-btm134 {
  padding-bottom: 134px;
}

.pdg-lft134 {
  padding-left: 134px;
}

.pdg-135 {
  padding: 135px;
}

.pdg-top135 {
  padding-top: 135px;
}

.pdg-rgt135 {
  padding-right: 135px;
}

.pdg-btm135 {
  padding-bottom: 135px;
}

.pdg-lft135 {
  padding-left: 135px;
}

.pdg-136 {
  padding: 136px;
}

.pdg-top136 {
  padding-top: 136px;
}

.pdg-rgt136 {
  padding-right: 136px;
}

.pdg-btm136 {
  padding-bottom: 136px;
}

.pdg-lft136 {
  padding-left: 136px;
}

.pdg-137 {
  padding: 137px;
}

.pdg-top137 {
  padding-top: 137px;
}

.pdg-rgt137 {
  padding-right: 137px;
}

.pdg-btm137 {
  padding-bottom: 137px;
}

.pdg-lft137 {
  padding-left: 137px;
}

.pdg-138 {
  padding: 138px;
}

.pdg-top138 {
  padding-top: 138px;
}

.pdg-rgt138 {
  padding-right: 138px;
}

.pdg-btm138 {
  padding-bottom: 138px;
}

.pdg-lft138 {
  padding-left: 138px;
}

.pdg-139 {
  padding: 139px;
}

.pdg-top139 {
  padding-top: 139px;
}

.pdg-rgt139 {
  padding-right: 139px;
}

.pdg-btm139 {
  padding-bottom: 139px;
}

.pdg-lft139 {
  padding-left: 139px;
}

.pdg-140 {
  padding: 140px;
}

.pdg-top140 {
  padding-top: 140px;
}

.pdg-rgt140 {
  padding-right: 140px;
}

.pdg-btm140 {
  padding-bottom: 140px;
}

.pdg-lft140 {
  padding-left: 140px;
}

.pdg-141 {
  padding: 141px;
}

.pdg-top141 {
  padding-top: 141px;
}

.pdg-rgt141 {
  padding-right: 141px;
}

.pdg-btm141 {
  padding-bottom: 141px;
}

.pdg-lft141 {
  padding-left: 141px;
}

.pdg-142 {
  padding: 142px;
}

.pdg-top142 {
  padding-top: 142px;
}

.pdg-rgt142 {
  padding-right: 142px;
}

.pdg-btm142 {
  padding-bottom: 142px;
}

.pdg-lft142 {
  padding-left: 142px;
}

.pdg-143 {
  padding: 143px;
}

.pdg-top143 {
  padding-top: 143px;
}

.pdg-rgt143 {
  padding-right: 143px;
}

.pdg-btm143 {
  padding-bottom: 143px;
}

.pdg-lft143 {
  padding-left: 143px;
}

.pdg-144 {
  padding: 144px;
}

.pdg-top144 {
  padding-top: 144px;
}

.pdg-rgt144 {
  padding-right: 144px;
}

.pdg-btm144 {
  padding-bottom: 144px;
}

.pdg-lft144 {
  padding-left: 144px;
}

.pdg-145 {
  padding: 145px;
}

.pdg-top145 {
  padding-top: 145px;
}

.pdg-rgt145 {
  padding-right: 145px;
}

.pdg-btm145 {
  padding-bottom: 145px;
}

.pdg-lft145 {
  padding-left: 145px;
}

.pdg-146 {
  padding: 146px;
}

.pdg-top146 {
  padding-top: 146px;
}

.pdg-rgt146 {
  padding-right: 146px;
}

.pdg-btm146 {
  padding-bottom: 146px;
}

.pdg-lft146 {
  padding-left: 146px;
}

.pdg-147 {
  padding: 147px;
}

.pdg-top147 {
  padding-top: 147px;
}

.pdg-rgt147 {
  padding-right: 147px;
}

.pdg-btm147 {
  padding-bottom: 147px;
}

.pdg-lft147 {
  padding-left: 147px;
}

.pdg-148 {
  padding: 148px;
}

.pdg-top148 {
  padding-top: 148px;
}

.pdg-rgt148 {
  padding-right: 148px;
}

.pdg-btm148 {
  padding-bottom: 148px;
}

.pdg-lft148 {
  padding-left: 148px;
}

.pdg-149 {
  padding: 149px;
}

.pdg-top149 {
  padding-top: 149px;
}

.pdg-rgt149 {
  padding-right: 149px;
}

.pdg-btm149 {
  padding-bottom: 149px;
}

.pdg-lft149 {
  padding-left: 149px;
}

.pdg-150 {
  padding: 150px;
}

.pdg-top150 {
  padding-top: 150px;
}

.pdg-rgt150 {
  padding-right: 150px;
}

.pdg-btm150 {
  padding-bottom: 150px;
}

.pdg-lft150 {
  padding-left: 150px;
}

/* --- position --- */
.pos_rel {
  position: relative;
}

.pos_ab {
  position: absolute;
}

/* --- float --- */
.flt-rgt {
  float: right;
}

.flt-lft {
  float: left;
}

/* breadcrumbs
**************************************** */
.breadcrumbs {
  font-size: 0.8125rem;
  font-family: var(--font-main-r);
  padding: 8px 0;
}
@media screen and (max-width: 47.9375em) {
  .breadcrumbs {
    font-size: 0.625rem;
  }
}
.breadcrumbs .inner > span {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5em;
}
@media screen and (max-width: 47.9375em) {
  .breadcrumbs .inner > span {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0.5em 0;
  }
}
.breadcrumbs .arrow {
  display: inline-block;
  width: 5px;
  height: 8px;
  margin: 0 5px;
  background-color: var(--clr-main);
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}

.single-products .breadcrumbs {
  padding-bottom: 0;
}

.breadcrumbs a {
  color: var(--clr-main);
}

.breadcrumbs a:hover {
  text-decoration: none;
}

.breadcrumbs .breadcrumb_last {
  display: table-cell;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.6;
}

.page .breadcrumbs {
  transform: translateY(-40px);
  height: 0;
  padding: 0;
}

@media print, screen and (min-width: 48em) {
  .single-works .breadcrumbs {
    height: 0;
    padding: 0;
    margin-top: 8px;
  }
}
@media screen and (max-width: 47.9375em) {
  .single .breadcrumbs .inner {
    justify-content: flex-start;
  }
}
/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 16px;
}

.btn a,
.btn button {
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 180px;
  padding: 12px;
  color: var(--clr-main);
  border-bottom: 1px solid var(--clr-main-op);
  font-family: "Josefin Sans", "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004", sans-serif;
  text-align: center;
}
.btn a svg,
.btn button svg {
  margin-left: 16px;
}
@media screen and (max-width: 47.9375em) {
  .btn a,
  .btn button {
    max-width: 100%;
    min-width: 120px;
    letter-spacing: 0.08em;
    padding-left: 24px;
    padding-right: 24px;
  }
  .btn a svg,
  .btn button svg {
    margin-left: 0;
    width: 14px;
  }
}

.flex > .btn a {
  max-width: 100%;
}

.btn.txt-ctr a {
  margin-left: auto;
  margin-right: auto;
}

.btn:not(.btn-box) a:hover {
  opacity: 0.5;
  border-bottom-color: transparent !important;
}

.btn.btn-next_page a {
  max-width: 100%;
  border-radius: 0;
  text-align: left;
}

/* --- btn-box --- */
.btn.btn-box a,
.btn.btn-box button {
  display: block;
  width: 100%;
  max-width: inherit;
  padding: 20px;
  font-family: var(--font-jp01-b);
  font-size: 1.0625rem;
  line-height: var(--line-height-default, 1.8);
  color: var(--clr-wht);
  letter-spacing: 0.12em;
  background-color: var(--clr-br);
  border: 1px solid var(--clr-wht);
  transition: var(--transit-default);
  cursor: pointer;
}
@media screen and (max-width: 47.9375em) {
  .btn.btn-box a,
  .btn.btn-box button {
    padding: 12px;
  }
}
.btn.btn-box a svg,
.btn.btn-box button svg {
  position: absolute;
  top: calc(50% - 2px);
  right: 72px;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 47.9375em) {
  .btn.btn-box a svg,
  .btn.btn-box button svg {
    right: 16px;
  }
}
.btn.btn-box a svg .cls-1,
.btn.btn-box button svg .cls-1 {
  fill: var(--clr-wht);
}
.btn.btn-box a:hover,
.btn.btn-box button:hover {
  background-color: var(--clr-main);
}

.btn.btn-box-l a {
  padding-top: 32px;
  padding-bottom: 32px;
}
@media screen and (max-width: 47.9375em) {
  .btn.btn-box-l a {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.btn-box.btn-box-s a,
.btn-box.btn-box-s button {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 0.9375rem;
  line-height: 1;
}
.btn-box.btn-box-s a svg,
.btn-box.btn-box-s button svg {
  right: 24px;
  margin-left: 0;
}
.btn-box.btn-box-s .step-back svg {
  right: inherit;
  left: 24px;
  rotate: 180deg;
}
.btn-box.btn-box-s.btn-2col {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.btn-box.btn-box-s.btn-2col a {
  width: calc((100% - 16px) / 2);
}

@media screen and (max-width: 47.9375em) {
  .btn.btn-download {
    margin-bottom: 0 !important;
  }
}

.btn.btn-download a {
  display: flex;
  padding: 8px 32px;
}
@media screen and (max-width: 47.9375em) {
  .btn.btn-download a {
    align-items: center;
    padding: 4px 24px;
  }
}
.btn.btn-download a svg {
  margin-left: 24px;
}
@media screen and (max-width: 47.9375em) {
  .btn.btn-download a svg {
    margin-left: 16px;
  }
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #333);
  background-color: var(--btn-bg-wht, #fff);
}

.btn-wh svg {
  fill: var(--btn-clr-wht, #333);
}

.btn-wh a:hover {
  color: var(--btn-clr-wht-hvr, #fff);
  background-color: var(--btn-bg-wht-hvr, #333);
}

.btn-wh a:hover svg {
  fill: var(--btn-clr-wht-hvr, #fff);
}

@media screen and (max-width: 47.9375em) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}
/* --- products--work_btn --- */
.btn.products--work_btn a {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 0.9375rem;
  letter-spacing: 0.12em;
}

/* --- favorite_btn --- */
.favorite_btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  font-family: var(--font-jp01-b);
  font-size: 0.875rem;
  color: var(--clr-main);
  letter-spacing: 0.08em;
  border: 1px solid var(--clr-main);
  transition: var(--transit-default);
}
.favorite_btn a svg {
  fill: var(--clr-main);
  margin-right: 8px;
}
.favorite_btn a:hover {
  color: var(--clr-wht);
  background-color: var(--clr-main);
}
.favorite_btn a:hover svg {
  fill: var(--clr-wht);
}

.simplefavorite-button {
  cursor: pointer;
}

.simplefavorite-button.active {
  opacity: 1;
}

.btn-box2 a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23.75rem;
  height: 3rem;
  padding-left: 4rem;
  padding-right: 4rem;
  border: 1px solid currentColor !important;
}
.btn-box2 a svg {
  position: absolute;
  right: 1.625rem;
}
.btn-box2 a svg .cls-1 {
  fill: currentColor;
}
.btn-box2 a:hover {
  color: var(--clr-wht);
  background-color: var(--clr-main);
}
@media screen and (max-width: 47.9375em) {
  .btn-box2 a {
    width: 100%;
  }
}

/* simplefavorites-clear
**************************************** */
#js-clearbtn {
  display: none;
  text-align: center;
  margin-top: 32px;
  transition: var(--transit-default);
}

#js-clearbtn .simplefavorites-clear {
  margin: auto;
}

.simplefavorites-clear {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 16px 32px;
  font-family: var(--font-jp01-b);
  font-size: 0.875rem;
  color: var(--clr-wht);
  border: 1px solid var(--clr-wht);
  background-color: var(--clr-br2);
  cursor: pointer;
  transition: background-color 0.4s ease-in-out;
}
.simplefavorites-clear .mark {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.simplefavorites-clear .mark::before, .simplefavorites-clear .mark::after {
  content: "";
  position: absolute;
  left: 0;
  top: calc(50% - 1px);
  width: 100%;
  height: 2px;
  background-color: var(--clr-wht);
}
.simplefavorites-clear .mark::before {
  transform: rotate(-45deg);
}
.simplefavorites-clear .mark::after {
  transform: rotate(45deg);
}
.simplefavorites-clear::before {
  content: "";
  font-family: var(--font-jp01-b);
}
.simplefavorites-clear:disabled {
  background-color: #ccc;
  pointer-events: none;
  cursor: default;
}
.simplefavorites-clear:hover {
  color: var(--clr-wht);
  background-color: var(--clr-main);
}

/* ##############################################################################

  FORMY

############################################################################## */
/*! purgecss start ignore */
#formy_form {
  padding-bottom: 0;
}

#formy_form table {
  width: 100%;
}

#formy_form th,
#formy_form td {
  padding: 16px;
  vertical-align: middle;
  border-bottom: solid 1px var(--clr-main-op);
}
@media screen and (max-width: 47.9375em) {
  #formy_form th,
  #formy_form td {
    display: block;
    width: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

#formy_form th {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  width: 34%;
}
@media screen and (max-width: 47.9375em) {
  #formy_form th {
    width: 100%;
    border-bottom: none;
    padding-bottom: 0;
    white-space: normal;
    font-weight: bold;
  }
}

#formy_form table td {
  font-family: var(--font-main-r);
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form table textarea {
  width: 100%;
  border-radius: 3px;
  vertical-align: bottom;
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: 5px 15px;
  border: 1px solid #ccc;
  font: inherit;
  font-size: 1rem;
}

#formy_form textarea {
  height: 100px;
}
#formy_form textarea.your-dtl {
  height: 220px;
}

#formy_form select {
  height: 40px;
}

#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#formy_form input:hover {
  opacity: 0.7;
}

#formy_form textarea:hover {
  opacity: 0.7;
}

#formy_form input:focus {
  outline: none;
}

#formy_form .parsley-validated {
  background-color: #eee;
}

#formy_form .parsley-error {
  background-color: #fee;
}

#formy_form .parsley-success {
  background-color: #fff;
}

p:has(.help_text) {
  padding-left: 1em;
  text-indent: -1em;
}

.help_text {
  display: inline-block;
  margin-top: 0.5rem;
  font-size: 0.875rem; /* 14px */
  line-height: 1.4;
  color: #999;
}

.hidden_help {
  display: none;
}

.formy_privacy div {
  overflow-y: scroll;
  height: 140px;
  border: solid 1px #ccc;
  font-size: 0.875rem; /* 14px */
  padding: 8px 16px;
}

.requiredIcon {
  background-color: var(--clr-rd);
  color: #fff;
  margin: 0 0 0 1em;
  font-size: 0.75rem; /* 12px */
  padding: 2px 5px;
  border-radius: 3px;
  float: right;
}

#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: 32px;
  text-align: center;
}
@media screen and (max-width: 47.9375em) {
  #formy_btn {
    padding-top: 8px;
  }
}

#formy_btn input {
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: #fff;
  border-radius: 3px;
  padding: 16px 32px;
  margin-bottom: 24px;
  -webkit-appearance: none;
  appearance: none;
}
@media screen and (max-width: 47.9375em) {
  #formy_btn input {
    width: 100%;
    background-size: contain;
    background-position: center;
  }
}

#formy_form ul li input[type=radio],
#formy_form ul li input[type=checkbox] {
  display: none !important;
}

#formy_form ul li label {
  position: relative;
  display: inline-block;
  padding: 0.5em 8px 0.5em 40px;
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
}

#formy_form ul li label:hover {
  opacity: 0.7;
}

#formy_form ul li label::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid #788b93;
  left: 16px;
  top: 12px;
}

#formy_form ul li input[type=radio] + label::before {
  border-radius: 10px;
}

#formy_form ul li input[type=radio]:checked + label,
#formy_form ul li input[type=checkbox]:checked + label {
  color: #e75f5f;
  font-weight: bold;
}

#formy_form ul li input[type=radio]:checked + label::before,
#formy_form ul li input[type=checkbox]:checked + label::before {
  border-color: #e75f5f;
}

#formy_form ul li input[type=radio]:checked + label::after,
#formy_form ul li input[type=checkbox]:checked + label::after {
  content: "";
  width: 10px;
  height: 18px;
  top: 4px;
  left: 20px;
  border-right: 2px solid #e75f5f;
  border-bottom: 2px solid #e75f5f;
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}

.formy_confirm {
  background-color: var(--clr-main);
}

.formy_submit_disabled {
  background-color: #ccc;
}

#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}

#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: 8px 27px;
  min-width: auto;
  font-size: 92%;
  border: 1px solid #aaa;
  border-radius: 32px;
}

.formy_send {
  background-color: var(--clr-main);
}

#total_required {
  padding: 16px;
  color: var(--clr-rd);
  text-align: center;
}

@media print, screen and (min-width: 768px) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form td {
    padding-top: 0;
  }
  .autoConfirmBack {
    margin-bottom: 8px;
  }
}
/*! purgecss end ignore */
.formy_confirm,
.wpcf7-confirm {
  background-color: var(--clr-main);
}

.formy_submit_disabled {
  background-color: #ccc;
}

#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}

.autoConfirmBack,
.wpcf7-back {
  background-color: #aaa;
}

.formy_send,
.wpcf7-submit {
  background-color: var(--clr-main);
}

#total_required {
  padding: 16px;
  color: var(--clr-rd);
  text-align: center;
}

span.wpcf7-not-valid-tip {
  display: inline;
}

.wpcf7c-conf {
  background-color: transparent;
  border: 0 !important;
}

.wpcf7c-conf[type=checkbox]:checked + input[type=hidden] + label,
.wpcf7c-conf[type=radio]:checked + input[type=hidden] + label {
  padding-left: 15px !important;
  color: #333 !important;
}

.wpcf7c-conf[type=checkbox]:checked + input[type=hidden] + label::before,
.wpcf7c-conf[type=checkbox]:checked + input[type=hidden] + label::after,
.wpcf7c-conf[type=checkbox] + label,
.wpcf7c-conf[type=radio]:checked + input[type=hidden] + label::before,
.wpcf7c-conf[type=radio]:checked + input[type=hidden] + label::after,
.wpcf7c-conf[type=radio] + label {
  display: none !important;
}

.wpcf7-spinner {
  display: block;
  margin: 16px auto;
}

@media print, screen and (min-width: 768px) {
  #formy_btn .wpcf7-form-control:not(:last-child) {
    margin-right: 6px;
  }
}
@media screen and (max-width: 767px) {
  .autoConfirmBack,
  #formy_btn .wpcf7-form-control:not(:last-child) {
    margin-bottom: 8px;
  }
}
/* リキャプチャ・キャプション
**************************************** */
.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha_policy {
  padding: 0;
  margin-top: 2rem;
  color: #333;
  font-size: 0.625rem; /* 10px */
  text-align: center;
  letter-spacing: 0;
}

.recaptcha_policy a {
  color: #69ABDB;
  font-size: 0.75rem; /* 12px */
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .recaptcha_policy {
    text-align: left;
  }
}
.wpcf7-response-output {
  font-family: sans-serif;
  font-weight: 600;
}

#formy_form .removeFile {
  display: inline-block;
  margin-left: 0.5rem;
  background-color: #a5977d;
  color: #fff;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 2px;
  transition: all 0.4s ease-out;
}
#formy_form .removeFile:hover {
  opacity: 0.5;
}

/* --- anchor --- */
.anchor {
  position: absolute;
  top: -80px; /* 固定ヘッダーの高さ */
  left: 0;
  pointer-events: none;
}
@media screen and (max-width: 67.5em) {
  .anchor .anchor {
    top: -64px; /* 固定ヘッダーの高さ */
  }
}

/* contact
********************************************** */
@media screen and (max-width: 47.9375em) {
  .page-contact .header {
    position: absolute;
  }
}

.form_content {
  padding-bottom: 80px;
}

.contact .cta_tel {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.contact .cta_tel a {
  padding: 24px 40px;
}
@media screen and (max-width: 47.9375em) {
  .contact .cta_tel a .contact .cta_tel a {
    display: block;
    padding: 16px;
  }
}

.btn.btn-box.add-sample {
  margin-bottom: 0;
}
.btn.btn-box.add-sample a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  padding: 16px;
  margin-right: 16px;
  font-size: 0.875rem;
  line-height: 1;
}
.btn.btn-box.add-sample a.hide {
  opacity: 0;
}

/* input file
**************************************** */
.contact_file label {
  display: inline-block;
  padding: 8px 25px;
  margin-bottom: 4px;
  color: var(--clr-main);
  line-height: 1;
  border: 1px solid var(--clr-main);
  cursor: pointer;
}
.contact_file input[type=file] {
  display: none;
}
.contact_file #js-filetxt {
  display: inline-block;
  font-family: var(--font-main-r);
  font-size: 0.875rem;
  margin-left: 8px;
}

/* sample contact
**************************************** */
.select_favorite .ttl-01 {
  font-size: 1.5rem;
  padding-bottom: 24px;
}
.select_favorite .ttl-01 .ttl-deco {
  right: 0;
  margin: auto;
}
.select_favorite .ttl-01 .ttl-deco::after {
  left: -200%;
}
.select_favorite .posts-products {
  justify-content: flex-start;
  gap: 16px;
}
.select_favorite .posts-products.posts-ctr {
  justify-content: center;
}
.select_favorite .posts-products::before,
.select_favorite .posts-products::after {
  content: none;
}
.select_favorite .posts-products .products_item {
  padding: 10px;
  margin-bottom: 0;
  width: calc((100% - 48px) / 4);
}
.select_favorite .posts-products .products_item .products_item--text .item--ttl {
  font-size: 0.875rem;
}
.select_favorite .products_item .add--text {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: auto;
  color: var(--clr-wht);
  font-size: 0.9375rem;
  font-weight: 600;
  z-index: 4;
  opacity: 0;
  transition: var(--transit-default);
}
.select_favorite .products_item .add--text::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 2;
  opacity: 0;
  transition: var(--transit-default);
}
.select_favorite .products_item.added {
  pointer-events: none;
  cursor: default;
}
.select_favorite .products_item.added::before {
  opacity: 1;
}
.select_favorite .products_item.added .add--text {
  opacity: 1;
}
.select_favorite .products_item.added .add--text::after {
  opacity: 1;
}
.select_favorite .products_item.added .hover--text {
  opacity: 0 !important;
}
.select_favorite a.js-add-sample,
.select_favorite a.js-add-sample::before {
  cursor: pointer;
}
@media screen and (max-width: 47.9375em) {
  .select_favorite.inner {
    width: 100%;
  }
  .select_favorite .posts-products.posts-ctr {
    gap: 8px;
  }
  .select_favorite .posts-products .products_item {
    padding: 8px;
    margin-bottom: 0;
    width: calc((100% - 20px) / 2);
  }
  .select_favorite .posts-products .products_item .products_item--img {
    margin-bottom: 8px;
  }
  .select_favorite .posts-products .products_item.added::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--clr-berge);
    outline: 1px solid var(--clr-wht);
    outline-offset: -1px;
    top: 0;
    left: 0;
    transition: var(--transit-default);
    z-index: -1;
  }
}

/* flex
**************************************** */
/* --- ブロック要素 --- */
.flex {
  display: flex;
}

/* --- インライン要素 --- */
.flex-inline {
  display: inline-flex;
}

/* --- 逆向き --- */
.flex-reverse {
  flex-direction: row-reverse;
}

/* --- 縦並び --- */
.flex-column {
  flex-direction: column;
}

/* --- 水平方向揃え --- */
.flex-j-start {
  justify-content: flex-start;
}

.flex-j-end {
  justify-content: flex-end;
}

.flex-j-ctr {
  justify-content: center;
}

.flex-j-between {
  justify-content: space-between;
}

.flex-j-around {
  justify-content: space-around;
}

/* --- 垂直方向揃え --- */
.flex-a-start {
  align-items: flex-start;
}

.flex-a-end {
  align-items: flex-end;
}

.flex-a-ctr {
  align-items: center;
}

.flex-a-baseline {
  align-items: baseline;
}

.flex-a-stretch {
  align-items: stretch;
}

/* --- 子要素の折り返し設定 --- */
.flex-c-nowrap {
  flex-wrap: nowrap;
}

.flex-c-wrap {
  flex-wrap: wrap;
}

/* --- 子要素の複数行設定 --- */
.flex-c-reverse {
  flex-wrap: wrap-reverse;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-end {
  align-content: flex-end;
}

.flex-c-ctr {
  align-content: center;
}

.flex-c-baseline {
  align-content: baseline;
}

.flex-c-stretch {
  align-content: stretch;
}

@media screen and (max-width: 47.9375em) {
  /* --- 縦並び - sp --- */
  .flex-sp-block {
    flex-direction: column;
  }
}
.flex-col2,
.flex-col3,
.flex-col4 {
  flex-wrap: wrap;
}

.flex-col1 > * {
  width: 100%;
}

.flex-col2 > * {
  width: 50%;
}

.flex-col3 > * {
  width: 33.3333333333%;
}

.flex-col4 > * {
  width: 25%;
}

.flex-col2.gap {
  gap: 4%;
}

.flex-col3.gap {
  gap: 3%;
}

.flex-col4.gap {
  gap: 2%;
}

.flex-col2.gap > * {
  width: 48%;
  margin-bottom: 4%;
}

.flex-col3.gap > * {
  width: 31.33%;
  margin-bottom: 3%;
}

.flex-col4.gap > * {
  width: 23.5%;
  margin-bottom: 2%;
}

@media screen and (min-width: 48em) {
  .flex-col2.gap-wide {
    gap: 6%;
  }
  .flex-col3.gap-wide {
    gap: 5%;
  }
  .flex-col4.gap-wide {
    gap: 4%;
  }
  .flex-col2.gap-wide > * {
    width: 47%;
    margin-bottom: 6%;
  }
  .flex-col3.gap-wide > * {
    width: 30%;
    margin-bottom: 5%;
  }
  .flex-col4.gap-wide > * {
    width: 22%;
    margin-bottom: 4%;
  }
  .flex-col2.column-gap > *,
  .flex-col3.column-gap > *,
  .flex-col4.column-gap > * {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 47.9375em) {
  .flex-col2.flex-sp-block > *,
  .flex-col3.flex-sp-block > *,
  .flex-col4.flex-sp-block > * {
    width: 100%;
  }
  .flex-col2.gap.flex-sp-block > *,
  .flex-col3.gap.flex-sp-block > *,
  .flex-col4.gap.flex-sp-block > * {
    margin-bottom: 24px;
  }
  .flex-col2.gap.flex-sp-block > *:last-child,
  .flex-col3.gap.flex-sp-block > *:last-child,
  .flex-col4.gap.flex-sp-block > *:last-child {
    margin-bottom: 0;
  }
  .flex-col4.gap.flex-sp-col3 > * {
    width: 32%;
  }
  .flex-col4.gap.flex-sp-col2 > * {
    width: 49%;
  }
  .flex-col3.gap.flex-sp-col2 > * {
    width: 48.5%;
  }
}
/* ##############################################################################

    FOOTER

############################################################################## */
.footer {
  position: relative;
  color: var(--clr-wht);
}
.footer .bg-ptnbox {
  top: 0;
  height: 100%;
}
@media screen and (max-width: 67.5em) {
  .footer .bg-ptnbox {
    left: 0;
  }
}
.footer .bg-ptnbox::before,
.footer .bg-ptnbox::after {
  content: none;
}
.footer .footer_inner {
  position: relative;
  padding: 120px 100px 100px;
  border-top: 1px solid rgba(255, 255, 255, 0.8);
}
.footer .footer_inner .footer_content {
  position: relative;
  z-index: 10;
}

@media screen and (max-width: 92.5em) {
  .footer {
    padding: 80px 40px 60px;
  }
  .footer .footer_content {
    flex-direction: column;
    align-items: center;
  }
  .footer .footer_content .footer_company {
    display: flex;
    gap: 80px;
  }
}
@media screen and (max-width: 47.9375em) {
  .footer {
    padding: 0;
  }
  .footer .bg-ptnbox {
    left: 0;
  }
  .footer .footer_inner {
    position: relative;
    padding: 60px 40px 40px;
  }
  .footer .footer_content {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .footer .footer_content .footer_company {
    flex-direction: column;
    align-items: center;
    gap: 0;
    width: 100%;
    margin-bottom: 32px;
  }
  .footer .footer_content .footer_company .footer_logo {
    width: 60%;
    margin-bottom: 40px;
  }
}
.home .footer .footer_inner {
  padding-bottom: 180px;
}
@media screen and (max-width: 47.9375em) {
  .home .footer .footer_inner {
    padding-bottom: 100px;
  }
}

/* footer_company
********************************************** */
.footer_company .footer_logo {
  display: block;
  margin-bottom: 72px;
}
.footer_company .footer_info {
  font-family: var(--font-main-r);
  font-size: 0.9375rem;
}
.footer_company .footer_info a {
  color: var(--clr-wht);
}

/* footer_nav--box
********************************************** */
@media screen and (max-width: 47.9375em) {
  .footer_nav--box {
    width: 100%;
  }
}

/* ---fnav --- */
.fnav {
  padding-top: 16px;
}
@media screen and (max-width: 47.9375em) {
  .fnav {
    width: 100%;
  }
}

.fnav--menu {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: column;
  width: 23.4375vw;
  gap: 24px;
}
.fnav--menu a {
  font-size: 0.9375rem;
  font-weight: 600;
  font-family: "Josefin Sans", "Shuei KakuGo Gin B", sans-serif;
  color: var(--clr-wht);
  padding: 0;
}
.fnav--menu a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 47.9375em) {
  .fnav--menu a {
    position: relative;
    padding: 0.5em 0.5em 0.5em 0;
  }
  .fnav--menu a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1em;
    margin: auto;
    width: 6px;
    height: 6px;
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
  }
}

.footer_cta a {
  display: block;
  text-align: center;
  width: 280px;
  margin-bottom: 8px;
  color: var(--clr-wht);
  font-size: 14px;
  line-height: 40px;
  border: 1px solid var(--clr-wht);
}

.footer_sample {
  margin-bottom: 32px;
}
.footer_sample a {
  color: var(--clr-wht);
  font-family: var(--font-main-r);
  font-size: 0.875rem;
  letter-spacing: 0.16em;
}
.footer_sample a svg .cls-1 {
  fill: var(--clr-wht);
}
@media print, screen and (min-width: 48em) {
  .footer_sample {
    gap: 70px;
  }
}

@media screen and (max-width: 47.9375em) {
  .fnav--menu {
    margin-right: 0;
    gap: 24px;
    width: auto;
  }
  .fnav--menu a {
    display: flex;
    font-size: 0.9375rem;
    font-weight: 600;
    font-family: "Josefin Sans", "Shuei KakuGo Gin B", sans-serif;
    color: var(--clr-wht);
    padding: 0 1em 0 0;
  }
  .fnav--menu a:hover {
    opacity: 0.7;
  }
  .footer_cta a {
    display: block;
    text-align: center;
    width: 280px;
    margin-bottom: 8px;
    color: var(--clr-wht);
    font-size: 14px;
    line-height: 40px;
    border: 1px solid var(--clr-wht);
  }
  .footer_sample li {
    margin-bottom: 16px;
  }
  .footer_sample a svg {
    margin-left: 16px;
  }
  .footer_sample a svg .cls-1 {
    fill: var(--clr-wht);
  }
}
/* footer_copy
*************************************************** */
.footer_copy {
  align-items: center;
  gap: 20px;
}
@media screen and (max-width: 47.9375em) {
  .footer_copy {
    gap: 0;
  }
}

.pbl a:hover {
  opacity: 0.5;
}

/* ---sns_area --- */
.sns_area {
  margin-top: 32px;
  margin-bottom: 24px;
}
@media screen and (max-width: 47.9375em) {
  .sns_area {
    margin-top: 24px;
  }
  .sns_area .sns--list {
    justify-content: center;
  }
}

.sns--list li:not(:last-child) {
  margin-right: 12px;
}

.sns--list a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 1.5rem; /* 24px */
}
.sns--list a svg {
  width: 100%;
  fill: var(--clr-wht);
}

.sns--list a:hover {
  opacity: 0.7;
}

/* ##############################################################################

    HEADER

############################################################################## */
/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh;
  padding: 1% 10px;
  border-right: 1px solid var(--clr-wht);
  z-index: 9999;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: var(--transit-default, all 0.4s ease-out);
  background-color: rgba(233, 228, 216, 0.3);
  backdrop-filter: blur(2px);
}

.logo-copy {
  font-size: 0.625rem;
  color: var(--clr-gr);
  line-height: 1.3;
  letter-spacing: -0.06em;
}
@media screen and (max-width: 67.5em) {
  .logo-copy {
    display: none;
  }
}

.header--logo a,
.footer--logo a {
  display: block;
}

.header--logo a:hover,
.footer--logo a:hover {
  opacity: 0.7;
}

@media print, screen and (min-width: 67.5625em) {
  .header--logo .logo-pc {
    display: block;
  }
}
@media screen and (max-width: 67.5em) {
  .header--logo .logo-pc {
    display: none;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .header--logo .logo-sp {
    display: none;
  }
}
@media screen and (max-width: 67.5em) {
  .header--logo .logo-sp {
    display: block;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .logo-copy {
    justify-content: center;
    align-items: center;
  }
  .header--logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 48px;
  }
  .header--logo a {
    width: 105px;
  }
  .header--logo a .logo-pc {
    fill: var(--clr-gr);
  }
}
/* gnav
********************************************** */
.gnav {
  padding-bottom: 32px;
}

.gnav--menu {
  margin-left: 4px;
}

.gnav a {
  display: flex;
  font-size: 0.9375rem;
  font-weight: 600;
  font-family: "Josefin Sans", "Shuei KakuGo Gin B", sans-serif;
  color: var(--clr-main);
  white-space: nowrap;
}

.gnav--link {
  padding: 8px 0 8px 30px;
}
.gnav--link .jp {
  display: none;
}
.gnav--link .jp, .gnav--link .en {
  width: 100px;
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--link:hover .jp, .gnav--link.active .jp {
    display: inline;
  }
  .gnav--link:hover .en, .gnav--link.active .en {
    display: none;
  }
}

.head_sub_nav--wrap {
  display: none;
  width: 100%;
}

.menu-item-has-children {
  position: relative;
}

.head_sub_nav {
  padding-top: 8px;
  padding-left: 16px;
}

.head_sub_nav li:not(:last-child) {
  margin-bottom: 16px;
}

.head_sub_nav a {
  display: block;
}

.menu-item-has-children > .gnav--link {
  position: relative;
  cursor: pointer;
}

.menu-item-has-children > .gnav--link::after {
  content: "";
  width: 6px;
  height: 6px;
  margin: 2px 6px;
  border-left: 2px solid var(--clr-main);
  border-bottom: 2px solid var(--clr-main);
  transition: var(--transit-default, all 0.4s ease-out);
  transform: rotate(-45deg);
}

.menu-item-has-children > .gnav--link.active::after {
  transform: rotate(-225deg);
}

/* ---CTA --- */
.cta_btn {
  position: relative;
  line-height: 1;
  margin-bottom: 8px;
  transition: var(--transit-default);
}
.cta_btn::before, .cta_btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 18px;
  margin: auto;
  border-radius: 50vw;
  background-color: var(--clr-wht);
  transition: transform 0.2s ease-in-out;
}
.cta_btn::before {
  width: 8px;
  height: 8px;
  left: 21px;
}
.cta_btn::after {
  width: 14px;
  height: 14px;
  opacity: 0.3;
}
.cta_btn:hover {
  opacity: 0.7;
}
.cta_btn:hover::before, .cta_btn:hover::after {
  transform: scale(1.2);
}
.cta_btn a {
  font-family: var(--font-jp01);
  justify-content: center;
  align-items: center;
  color: var(--clr-wht);
  padding: 24px 24px 24px 48px;
}
.cta_btn.cta_contact {
  background-color: var(--clr-rd);
}
.cta_btn.cta_estimate {
  background-color: var(--clr-bl);
}
.cta_btn.cta_catalogue {
  background-color: var(--clr-br);
}

.cta_tel a {
  display: flex !important;
  color: var(--clr-main);
  font-family: var(--font-en);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
}

.cta_tel a > span {
  display: block;
}

.cta_tel .tel {
  font-size: 2rem;
}

.cta_tel .hours {
  font-size: 0.9375rem;
  font-family: var(--font-main-r);
  letter-spacing: 0.16em;
}

.gnav--tel {
  margin-bottom: 8px;
}

.gnav--tel .tel {
  font-size: 1.5625rem;
}

.gnav--tel a {
  display: flex !important;
  flex-direction: column;
}

/* --- search_box --- */
.search_box {
  position: relative;
  margin-bottom: 16px;
  background-color: rgb(236, 231, 219);
}
.search_box input {
  width: 100%;
  height: 48px;
  font-family: var(--font-jp01) !important;
  color: var(--clr-main);
  background-color: var(--clr-berge5);
  border: 1px solid var(--clr-wht);
  padding: 0;
  text-indent: 0.75em;
}
@media screen and (max-width: 47.9375em) {
  .search_box input {
    font-size: 18px;
  }
}
.search_box input:focus {
  outline: none;
}
.search_box input::placeholder {
  font-family: var(--font-jp01) !important;
  color: rgba(130, 119, 98, 0.7);
}
.search_box button {
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  border: none;
  background: none;
  cursor: pointer;
}
.search_box button svg {
  fill: var(--clr-wht);
}
.search_box button:hover svg {
  fill: var(--clr-body);
}

/* --- favorite_link --- */
.favorite_link {
  margin-bottom: 8px;
}
.favorite_link a {
  font-size: 0.875rem;
  font-family: var(--font-jp01-b);
  line-height: 1.4;
  color: var(--clr-main);
  justify-content: center;
  align-items: center;
}
.favorite_link svg {
  fill: var(--clr-main);
  margin-right: 8px;
}
.favorite_link:hover {
  opacity: 0.7;
}

.favorite-icon {
  margin-right: 8px;
}

@media print, screen and (min-width: 67.5625em) {
  .header > .header--inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
  .gnav_btn,
  .tel_btn {
    display: none;
  }
  .gnav--link {
    position: relative;
    line-height: 1;
  }
  .gnav--menu .menu-item:not(:last-child) {
    margin-bottom: 8px;
  }
  .gnav--menu > li:not(.menu-item-has-children) > .gnav--link:not([href="javascript:void(0);"]):hover::before,
  .gnav--menu > li.current-menu-item:not(.menu-item-has-children) > .gnav--link::before {
    content: "";
    position: absolute;
    top: -4px;
    bottom: 0;
    left: 10px;
    margin: auto;
    width: 6px;
    height: 6px;
    border-radius: 50vw;
    background-color: var(--clr-main);
    transition: var(--transit-default);
  }
  .gnav_sub_toggle {
    display: none;
  }
  /* active */
  .gnav--menu .menu-item-has-children.active .head_sub_nav--wrap {
    pointer-events: auto;
  }
  .head_sub_nav a:hover {
    opacity: 0.7;
  }
  .gnav a:not([href]) {
    pointer-events: none;
  }
}
@media screen and (max-width: 67.5em) {
  .header--logo span {
    height: 100%;
    width: 100%;
  }
  .header--logo svg {
    height: 100%;
    width: auto;
  }
  .header--logo .logo-sp {
    fill: var(--clr-gr);
    transition: var(--transit-default);
  }
  .active .header--logo .logo-sp {
    fill: var(--clr-wht);
  }
  .header {
    right: 0;
    height: 70px;
    width: 100vw;
    padding: 4px 0;
    background-color: transparent;
    backdrop-filter: inherit;
    border-right: none;
    overflow: inherit;
    z-index: 9999;
  }
  .header--logo a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    margin: auto;
    width: auto;
    height: 100%;
    max-height: 46px;
    z-index: 9999;
  }
  .header--logo a img {
    height: 100%;
    width: auto;
    max-width: inherit;
  }
  .gnav,
  .gnav_overlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    opacity: 0;
    padding-top: 64px;
    pointer-events: none;
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .gnav {
    width: 100%;
    margin: 0;
    padding: 100px 7% 80px;
    overflow: scroll;
    z-index: 300;
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
  }
  .gnav::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn02-top.png), url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn02-btm.png), url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn02.jpg);
    background-position: top center, bottom center;
    background-repeat: repeat;
    mask-image: linear-gradient(to bottom, black 60%, transparent 80%);
    z-index: -1;
  }
  .gnav_overlay {
    width: 100%;
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn01.png);
    background-repeat: repeat;
  }
  .gnav.active,
  .gnav_overlay.active {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav.active {
    transform: translateX(0);
  }
  .gnav--menu,
  .gnav--cta {
    display: block;
  }
  .gnav--menu {
    margin-left: 8px;
  }
  .gnav--menu li {
    position: relative;
    display: block;
    width: 100%;
  }
  .gnav--menu a {
    display: block;
    font-size: 1.4285rem;
    color: var(--clr-wht);
    padding: 13px 0 11px;
    line-height: 1;
  }
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav li:not(:last-child) {
    margin-bottom: 0;
  }
  .head_sub_nav a {
    padding-left: 16px !important;
  }
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    left: 9em;
    width: 42px;
    height: 42px;
    transition: var(--transit-default, all 0.4s ease-out);
    color: var(--clr-wht);
  }
  .gnav_sub_toggle::before,
  .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 14px;
    height: 1px;
    border-bottom: 1px solid;
    transition: var(--transit-default);
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .menu-item-has-children.active .gnav_sub_toggle::after {
    transform: rotate(180deg);
  }
  .menu-item-has-children > .gnav--link::after {
    content: none;
  }
  .head_sub_nav {
    position: relative;
    padding-top: 4px;
  }
  .gnav_btn {
    position: fixed;
    top: 0;
    width: 64px;
    height: 64px;
    z-index: 9999;
  }
  .gnav_btn {
    right: 8px;
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .gnav_btn--lines {
    position: relative;
    width: 24px;
    height: 13px;
  }
  .gnav_btn--lines span {
    transition: var(--transit-default, all 0.4s ease-out);
    box-sizing: border-box;
  }
  .gnav_btn--lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--clr-main);
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
    transform: translateX(4px);
  }
  .gnav_btn--lines span:nth-of-type(2) {
    top: 6px;
  }
  .gnav_btn--lines span:nth-of-type(3) {
    bottom: 0;
    transform: translateX(-4px);
  }
  .active .gnav_btn--lines span {
    background-color: var(--clr-wht);
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(6px) rotate(-45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-6px) rotate(45deg);
  }
  /* ---CTA --- */
  .gnav--cta li:not(:last-child) {
    margin-bottom: 16px;
  }
  .gnav--tel {
    margin-left: 0;
    text-align: center;
  }
  .header_sp .header_sp--link {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  .header_sp .header_sp--link li {
    width: 50%;
    margin-bottom: 8px;
  }
  .header_sp .header_sp--link a {
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-size: 13px;
    color: var(--clr-wht);
  }
  .header_sp .header_sp--link svg .cls-1 {
    fill: var(--clr-wht);
  }
  .header_sp .header_sp--contact dt {
    font-size: 1.14285rem;
    margin-bottom: 4px;
  }
  .header_sp .header_sp--contact .gnav--tel span {
    font-size: 13px;
    font-family: var(--font-main-r);
  }
  .header_sp .header_sp--contact .gnav--tel .font-en {
    font-family: var(--font-en);
    font-weight: 400;
    font-size: 2.1428rem;
  }
  .header_sp .contact_link--box {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .header_sp .gnav--favorite {
    margin-bottom: 16px;
  }
}
/* scrollbar
**************************************** */
.header::-webkit-scrollbar {
  width: 4px;
}

.header::-webkit-scrollbar:horizontal {
  height: 4px;
}

.header::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
  border: none;
}

.header::-webkit-scrollbar-thumb {
  background-color: var(--clr-main-op);
  border-radius: 10px;
  box-shadow: none;
}

@media screen and (max-width: 47.9375em) {
  .header::-webkit-scrollbar {
    width: inherit;
  }
  .header::-webkit-scrollbar:horizontal {
    height: inherit;
  }
  .header::-webkit-scrollbar-track {
    background-color: inherit;
    border-radius: inherit;
    border: inherit;
  }
  .header::-webkit-scrollbar-thumb {
    background-color: inherit;
    border-radius: inherit;
    box-shadow: inherit;
  }
}
/* header-wh
**************************************** */
.header-wh {
  background-color: transparent;
  backdrop-filter: inherit;
}
.header-wh .gnav a {
  color: var(--clr-wht);
}
.header-wh .gnav--menu > li:not(.menu-item-has-children) > .gnav--link:not([href="javascript:void(0);"]):hover::before,
.header-wh .gnav--menu > li.current-menu-item:not(.menu-item-has-children) > .gnav--link::before {
  background-color: var(--clr-wht);
}
.header-wh .menu-item-has-children > .gnav--link::after {
  border-color: var(--clr-wht);
}
.header-wh .favorite_link a {
  color: var(--clr-main);
}

/* --- favotite_btn-fix --- */
.favotite_btn-fix {
  position: fixed;
  top: 50px;
  right: 5%;
  z-index: 9999;
}
.favotite_btn-fix a {
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: 0.875rem;
  font-family: var(--font-jp01-b);
  line-height: 1;
  color: var(--clr-wht);
  background-color: var(--clr-berge4);
  border: 1px solid var(--clr-wht);
}
.favotite_btn-fix a svg {
  fill: var(--clr-wht);
  margin-right: 8px;
}
.favotite_btn-fix a:hover {
  background-color: var(--clr-body);
}
@media screen and (max-width: 47.9375em) {
  .favotite_btn-fix {
    top: 74px;
    right: 10px;
  }
  .favotite_btn-fix a {
    padding: 10px;
    font-size: 0.75rem;
  }
}

/* section_contact
********************************************** */
.home .section_contact {
  padding-top: 340px;
}
@media screen and (max-width: 47.9375em) {
  .home .section_contact {
    padding-top: 140px;
  }
}

.section_contact {
  padding-top: 9.375vw;
  padding-bottom: 128px;
}
.section_contact .bg-ptnbox {
  height: calc(100% - 364px);
}
.section_contact .bg-ptnbox::after {
  content: none;
}
.section_contact .inner {
  position: relative;
}
.section_contact .ttl-01 {
  position: relative;
  font-size: 1.375rem;
  padding-top: 0;
  padding-bottom: 32px;
  margin-bottom: 32px;
}
.section_contact .ttl-01 .font-en {
  position: static;
  margin-left: 0;
  width: 100%;
  font-size: clamp(3rem, 7.291666vw, 8.75rem);
  color: var(--clr-wht);
  text-indent: 0;
  opacity: 0.3;
  z-index: 10;
}
.section_contact .section_contact--txt {
  font-size: 1.125rem;
  letter-spacing: 0.16em;
  line-height: 2.3333333333;
}
.section_contact .section_contact--content dl {
  width: 50%;
  padding: 32px 5.2083vw 40px;
}
.section_contact .section_contact--content dl:last-child {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.section_contact .section_contact--content dl dt {
  margin-bottom: 32px;
  letter-spacing: 0.12em;
}
.section_contact .section_contact--content dl dd {
  width: 100%;
}
.section_contact .contact_tel--link a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--clr-wht);
}
.section_contact .contact_tel--link a .tel {
  font-size: clamp(2.125rem, 2.5vw, 3rem);
  letter-spacing: 0.12em;
}
@media print, screen and (min-width: 48em) {
  .section_contact .inner {
    margin-left: 5.20833vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .section_contact {
    padding-top: 48px;
    padding-bottom: 64px;
  }
  .section_contact .bg-ptnbox {
    height: calc(100% - 182px);
  }
  .section_contact .ttl-01 {
    font-size: 1rem;
    padding-bottom: 24px;
    margin-bottom: 24px;
    opacity: 1;
  }
  .section_contact .section_contact--txt {
    font-size: 0.875rem;
  }
  .section_contact .section_contact--txt br {
    display: none;
  }
  .section_contact .section_contact--content dl {
    width: 100%;
    padding: 32px 0 40px;
  }
  .section_contact .section_contact--content dl:last-child {
    border-left: none;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
}

/* --- contact_link--box --- */
.contact_link--box ul {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 80px 64px;
  grid-gap: 16px;
}
.contact_link--box ul li {
  margin-bottom: 0;
}
.contact_link--box ul .cta_btn::before,
.contact_link--box ul .cta_btn::after {
  content: none;
}
.contact_link--box ul a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  letter-spacing: 0.16em;
  padding: 0 8px;
}
.contact_link--box ul a span {
  position: relative;
  padding-left: 2.0833vw;
}
.contact_link--box ul a span::before, .contact_link--box ul a span::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  border-radius: 50vw;
  background-color: #fff;
  background-color: var(--clr-wht);
  transition: transform 0.2s ease-in-out;
}
.contact_link--box ul a span::before {
  left: 3px;
  width: 8px;
  height: 8px;
}
.contact_link--box ul a span::after {
  width: 14px;
  height: 14px;
  opacity: 0.3;
}
.contact_link--box ul a:hover span::before,
.contact_link--box ul a:hover span::after {
  transform: scale(1.2);
}
.contact_link--box ul .cta_contact {
  grid-column: 1/3;
  grid-row: 1/2;
}
.contact_link--box ul .cta_contact a {
  color: var(--clr-main);
  font-size: 1.125rem;
  letter-spacing: 0.22em;
  background-color: var(--clr-wht);
}
.contact_link--box ul .cta_contact a span {
  padding-left: 48px;
}
.contact_link--box ul .cta_contact a span::before, .contact_link--box ul .cta_contact a span::after {
  background-color: var(--clr-rd);
}
@media screen and (max-width: 47.9375em) {
  .contact_link--box ul {
    grid-template-rows: 60px 48px;
    grid-gap: 8px;
  }
  .contact_link--box ul li {
    margin-bottom: 0;
  }
  .contact_link--box ul a span {
    padding-left: 24px;
  }
  .contact_link--box ul .cta_contact {
    grid-column: 1/3;
    grid-row: 1/2;
  }
  .contact_link--box ul .cta_contact a {
    color: var(--clr-main);
    font-size: 1.125rem;
    letter-spacing: 0.22em;
    background-color: var(--clr-wht);
  }
  .contact_link--box ul .cta_contact a span {
    padding-left: 48px;
  }
  .contact_link--box ul .cta_contact a span::before, .contact_link--box ul .cta_contact a span::after {
    background-color: var(--clr-rd);
  }
}

/* --- category_search --- */
.search_wrap {
  margin-bottom: 80px;
}
.search_wrap .category_search {
  display: none;
  border-top: none;
}
.search_wrap .category_search .category_search--box {
  padding-top: 32px;
}
.search_wrap .category_search .search_category .category--ttl {
  min-width: 170px;
}
.search_wrap .btn.open_btn {
  margin-bottom: 0;
  border: 1px solid var(--clr-wht);
}
.search_wrap .btn.open_btn a {
  font-size: 1.125rem;
  display: block;
  background-color: var(--clr-br2);
  border-bottom: none;
  color: var(--clr-wht);
  cursor: pointer;
}
.search_wrap .btn.open_btn a::after, .search_wrap .btn.open_btn a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 24px;
  margin: auto;
  width: 16px;
  height: 2px;
  background-color: var(--clr-wht);
  transition: var(--transit-default);
}
.search_wrap .btn.open_btn a::before {
  rotate: -90deg;
}
.search_wrap .btn.open_btn a.active::before {
  rotate: 0deg;
}
@media screen and (max-width: 47.9375em) {
  .search_wrap {
    margin-bottom: 40px;
  }
  .search_wrap .category_search {
    border-top: none;
  }
  .search_wrap .category_search .category_search--box {
    padding-top: 0;
  }
  .search_wrap .btn.open_btn a {
    cursor: pointer;
  }
  .search_wrap .btn.open_btn a::after, .search_wrap .btn.open_btn a::before {
    content: "";
    right: 16px;
    margin: auto;
    width: 12px;
    height: 2px;
  }
  .search_wrap .btn.open_btn a:hover {
    opacity: 1;
  }
}

.category_search {
  margin-bottom: 160px;
  color: var(--clr-wht);
  background-color: var(--clr-br2);
  border: 1px solid var(--clr-wht);
}
.category_search .category_search--ttl {
  margin-top: 48px;
  margin-left: 32px;
  margin-bottom: 40px;
  line-height: 0.8;
  opacity: 0.3;
}
.category_search .category_search--box {
  width: 90%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 72px;
}
.category_search .search_category {
  padding: 32px;
  border-bottom: 1px solid var(--clr-wht);
}
.category_search .search_category .category--ttl {
  min-width: 220px;
  font-size: 1.125rem;
}
.category_search .search_category .category--item.cate-txt a {
  color: var(--clr-wht);
  border: 1px solid var(--clr-wht);
}
.category_search .search_category .category--item.cate-txt a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;
  margin: auto;
  width: 5px;
  height: 5px;
  border-radius: 50vw;
  background-color: var(--clr-wht);
}
.category_search .search_category .category--item.cate-txt a:hover {
  background-color: var(--clr-br3);
}
.category_search .search_category .category--item.cate-img ul {
  gap: 16px;
}
.category_search .search_category .category--item.cate-img ul li {
  width: 64px;
}
.category_search .search_category .category--item.cate-img ul li:hover .color--img {
  outline: 1px solid var(--clr-wht);
  outline-offset: -1px;
}
.category_search .search_category .category--item.cate-img ul .color--img {
  aspect-ratio: 1/1;
  margin-bottom: 8px;
}
.category_search .search_category .category--item.cate-img ul a {
  display: block;
  color: var(--clr-wht);
  font-size: 0.75rem;
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 47.9375em) {
  .category_search {
    margin-bottom: 40px;
  }
  .category_search .category_search--ttl {
    margin-top: 32px;
    margin-left: 32px;
    margin-bottom: 8px;
    line-height: 1;
    opacity: 0.6;
  }
  .category_search .category_search--box {
    margin-bottom: 36px;
  }
  .category_search .search_category {
    width: 100%;
    flex-direction: column;
    padding: 24px 8px;
  }
  .category_search .search_category .category--ttl {
    width: 100%;
    font-size: 1.0625rem;
    margin-bottom: 8px;
  }
  .category_search .search_category .category--item {
    width: 100%;
  }
  .category_search .search_category .category--item.cate-txt a::before {
    left: 12px;
  }
  .category_search .search_category .category--item .category--link {
    width: 100%;
  }
  .category_search .search_category .category--item.cate-img ul .color--img {
    width: 56px;
    margin-bottom: 8px;
  }
  .category_search .search_category .category--item.cate-img ul a {
    font-size: 0.625rem;
  }
}

/* section_simulator
**************************************** */
.section_simulator {
  padding: 2.5rem;
  margin-top: 5rem;
  gap: 5rem;
  background-color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 47.9375em) {
  .section_simulator {
    padding: 2.2857142857rem;
    gap: 2.2857142857rem;
    margin-bottom: 4rem;
  }
}

.home .section_simulator {
  margin-top: 0;
  margin-bottom: 7.5rem;
}

.section_simulator--decottl {
  right: -0.125em;
  top: 0;
  translate: 0 -82%;
  font-size: clamp(3.5rem, 6.25vw, 7.5rem);
  font-weight: 400;
  line-height: 0.8;
  letter-spacing: 0.08em;
  color: var(--clr-wht);
  text-indent: -0.1em;
  opacity: 0.6;
  z-index: 10;
}
@media screen and (max-width: 47.9375em) {
  .section_simulator--decottl {
    letter-spacing: 0.04em;
  }
}

.section_simulator--deco {
  left: -0.5rem;
  bottom: 0;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.06em;
  line-height: 0.8;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-font-feature-settings: "pkna";
  font-feature-settings: "pkna";
  rotate: 180deg;
  translate: -100% 0;
}
@media screen and (max-width: 47.9375em) {
  .section_simulator--deco {
    font-size: 0.8571428571rem;
  }
}

.section_simulator .txtarea {
  flex: 1;
}

.section_simulator p {
  line-height: 2;
}

.section_simulator--ttl {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  line-height: 1.8;
  letter-spacing: 0.06em;
  padding-bottom: 1.5rem;
  gap: 1.5rem;
}
@media screen and (max-width: 105em) {
  .section_simulator--ttl br {
    display: none;
  }
}
@media screen and (max-width: 47.9375em) {
  .section_simulator--ttl {
    font-size: 1.2857142857rem;
    padding-bottom: 1.7142857143rem;
    gap: 1.1428571429rem;
  }
}

.section_simulator--ttl .dots {
  position: relative;
  display: block;
  width: 0.375rem;
  height: 0.375rem;
  color: var(--clr-rd);
  background-color: currentColor;
  border-radius: 50vw;
  opacity: 0.8;
}
.section_simulator--ttl .dots::before, .section_simulator--ttl .dots::after {
  content: "";
  position: absolute;
  display: block;
  width: 0.375rem;
  height: 0.375rem;
  background-color: currentColor;
  border-radius: 50vw;
}
.section_simulator--ttl .dots::before {
  left: 200%;
}
.section_simulator--ttl .dots::after {
  left: 400%;
}

.section_simulator .imgarea {
  width: 43.75rem;
}
@media screen and (max-width: 47.9375em) {
  .section_simulator .imgarea {
    width: 100%;
  }
}

/* hero
********************************************** */
.hero {
  position: relative;
  width: 100%;
  color: var(--clr-wht);
}
.hero::after {
  content: "";
  position: absolute;
  width: 100vw;
  left: -200px;
  bottom: -425px;
  height: 425px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/section-parts-message.png);
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position: center bottom;
}
.hero .hero_movie {
  position: absolute;
  z-index: -1;
  mask-image: linear-gradient(to bottom, black 92.3%, transparent 100%);
}
.hero .hero_movie video {
  width: 100vw;
}
.hero .hero-animation {
  translate: 0 -500px;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0, 0, 0.15, 1);
}
.hero .hero-animation.active {
  translate: 0 0;
  opacity: 1;
}
.hero .hero-animation.font-en {
  transition-delay: 4s;
}
.hero .hero-animation.hero--copy, .hero .hero-animation.hero--scroll {
  transition-delay: 4.5s;
}
.hero .hero-animation.hero--copy-en {
  transition-delay: 5s;
}
@media print, screen and (min-width: 48em) {
  .hero {
    height: 67.70833vw;
    max-height: 1300px;
  }
  .hero .hero_content {
    position: absolute;
    left: 75px;
    bottom: 17vw;
  }
  .hero .hero_content .font-en {
    margin-bottom: 32px;
  }
  .hero .hero_content .hero--copy h2 {
    font-family: var(--font-jp01);
    font-size: 2.75rem;
    line-height: 1.5454545455;
    letter-spacing: 0.2em;
    margin-bottom: 32px;
  }
  .hero .hero_content .hero--copy p {
    font-size: 1rem;
    line-height: 2;
  }
  .hero .hero_content .hero--copy-en {
    position: absolute;
    left: 232px;
    bottom: -50px;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .hero .hero_movie {
    left: -200px;
  }
}
@media screen and (max-width: 47.9375em) {
  .hero {
    height: 700px;
    overflow: hidden;
  }
  .hero .hero_movie {
    left: 0;
    right: 0;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .hero .hero_movie video {
    width: auto;
    height: 100%;
  }
  .hero .hero_content {
    position: absolute;
    width: 100%;
    padding-left: 20px;
    top: 82.92vw;
    z-index: 99;
  }
  .hero .hero_content .font-en {
    font-size: 0.625rem;
    line-height: 1;
    margin-bottom: 8px;
  }
  .hero .hero_content .hero--copy h2 {
    font-family: var(--font-jp01);
    font-size: 2rem;
    line-height: 1.8125;
    letter-spacing: 0.2em;
    margin-bottom: 16px;
  }
  .hero .hero_content .hero--copy p {
    font-size: 0.875rem;
    line-height: 1.7142857143;
  }
  .hero .hero_content .hero--copy-en {
    position: absolute;
    right: -2px;
    bottom: -20px;
    width: 60%;
    z-index: -1;
  }
}

.hero--scroll {
  position: absolute;
  left: 85px;
  bottom: 6.25vw;
  height: 6.25vw;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.5);
}
.hero--scroll .circle {
  position: absolute;
  left: -2px;
  margin-left: auto;
  margin-right: auto;
  width: 5px;
  height: 5px;
  border-radius: 50vw;
  background-color: var(--clr-wht);
  animation: scroll_progress 2.5s infinite;
}
@media screen and (max-width: 67.5em) {
  .hero--scroll {
    display: none;
  }
}

/* fix_contact
**************************************** */
.fix_contact--box {
  position: fixed;
  right: 0;
  bottom: 40px;
  z-index: 999;
}
.fix_contact--box .fix_contact {
  gap: 24px;
  padding: 20px 32px;
  background-color: var(--clr-wht);
}
.fix_contact--box .fix_contact .fix_contact--icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.fix_contact--box .fix_contact .fix_contact--icon .circle {
  position: relative;
  width: 16px;
  height: 16px;
}
.fix_contact--box .fix_contact .fix_contact--icon .circle::before, .fix_contact--box .fix_contact .fix_contact--icon .circle::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50vw;
  background-color: var(--clr-rd);
}
.fix_contact--box .fix_contact .fix_contact--icon .circle::before {
  width: 8px;
  height: 8px;
}
.fix_contact--box .fix_contact .fix_contact--icon .circle::after {
  width: 16px;
  height: 16px;
  opacity: 0.6;
  scale: 0.5;
  animation: circle-pop 1.8s cubic-bezier(0.2, 0.7, 0.3, 0.85) infinite;
}
.fix_contact--box .fix_contact:hover {
  background-color: var(--clr-berge);
  outline: 1px solid var(--clr-br2);
}
.fix_contact--box .fix_contact .font-en {
  color: var(--clr-rd);
  font-size: 0.625rem;
  line-height: 1;
}
.fix_contact--box .fix_contact dl {
  font-family: var(--font-jp01-b);
}
.fix_contact--box .fix_contact dl dt {
  font-size: 1.125rem;
}
.fix_contact--box .fix_contact dl dd {
  font-size: 0.75rem;
  line-height: 1.5833333333;
}
.fix_contact--box.hero-animation {
  translate: 100% 0;
  transition: all 0.8s cubic-bezier(0, 0, 0.15, 1);
}
@media print, screen and (min-width: 48em) {
  .fix_contact--box.hero-animation {
    transition-delay: 6s;
  }
  .fix_contact--box.hero-animation.active {
    translate: 0 0;
    opacity: 1;
  }
}
@media screen and (max-width: 47.9375em) {
  .fix_contact--box.hero-animation.slidein {
    translate: 0 0;
    opacity: 1;
  }
}
@media screen and (max-width: 47.9375em) {
  .fix_contact--box {
    bottom: 20px;
  }
  .fix_contact--box .fix_contact {
    gap: 12px;
    padding: 8px 16px;
  }
  .fix_contact--box .fix_contact .fix_contact--icon {
    width: 16px;
    gap: 4px;
  }
  .fix_contact--box .fix_contact .fix_contact--icon .circle {
    width: 12px;
    height: 12px;
  }
  .fix_contact--box .fix_contact .fix_contact--icon .circle::before {
    width: 6px;
    height: 6px;
  }
  .fix_contact--box .fix_contact .fix_contact--icon .circle::after {
    width: 12px;
    height: 12px;
  }
  .fix_contact--box .fix_contact dl dt {
    margin-top: 4px;
    font-size: 0.875rem;
    line-height: 1;
  }
  .fix_contact--box .fix_contact dl dd {
    display: none;
  }
}

/* home_news
********************************************** */
.home_news .home_ttl {
  padding-bottom: 32px;
}
.home_news .news_list .post {
  padding: 64px 40px;
  line-height: 1;
  border-bottom: 1px solid var(--clr-berge5);
  transition: var(--transit-default);
}
.home_news .news_list .post:last-of-type {
  margin-bottom: 40px;
}
.home_news .news_list .post:hover {
  opacity: 0.6;
}
@media print, screen and (min-width: 48em) {
  .home_news {
    padding: 170px 0 120px;
  }
  .home_news .inner {
    margin-left: 5.20833vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_news {
    padding: 60px 0;
  }
  .home_news .inner {
    margin-left: a;
  }
  .home_news .news_list .post {
    padding: 32px 20px;
  }
}

/* home_common
**************************************** */
/* --- home_ttl --- */
.home_ttl {
  position: relative;
  font-size: 1.125rem;
  padding-top: 20px;
  padding-bottom: 32px;
  margin-bottom: 40px;
}
.home_ttl .font-en {
  font-size: clamp(3.5rem, 6.25vw, 7.5rem);
  font-weight: 400;
  line-height: 0.8;
  letter-spacing: 0.08em;
  color: var(--clr-wht);
  text-indent: -0.1em;
  opacity: 0.6;
  z-index: 10;
}
.home_ttl .ttl-deco {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 50vw;
  background-color: var(--clr-wht);
}
.home_ttl .ttl-deco::before, .home_ttl .ttl-deco::after {
  content: "";
  position: absolute;
  background-color: var(--clr-wht);
  border-radius: 50vw;
  width: 5px;
  height: 5px;
}
.home_ttl .ttl-deco::before {
  left: 200%;
}
.home_ttl .ttl-deco::after {
  left: 400%;
}
@media screen and (max-width: 47.9375em) {
  .home_ttl {
    font-size: 1rem;
    padding-top: 20px;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
}

.home_ttl-ctr {
  padding-bottom: 32px;
  margin-bottom: 60px;
  text-align: center;
}
.home_ttl-ctr .ttl-deco {
  left: 0;
  right: 0;
  margin: auto;
}
.home_ttl-ctr .ttl-deco::after {
  left: -200%;
}
@media screen and (max-width: 47.9375em) {
  .home_ttl-ctr {
    padding-bottom: 16px;
    margin-bottom: 32px;
  }
}

.bg-ptn .home_ttl .font-en {
  opacity: 0.3;
}

/* --- txtarea --- */
.txtarea p {
  line-height: 2.5;
  letter-spacing: 0.16em;
}
.txtarea p + p {
  margin-top: 40px;
}
@media screen and (max-width: 47.9375em) {
  .txtarea p br {
    display: none;
  }
}
@media screen and (max-width: 102.625em) {
  .txtarea p br:not(.strong) {
    display: none;
  }
}

/* --- btn --- */
.home .btn a {
  font-size: 0.875rem;
  letter-spacing: 0.18em;
  padding-top: 4px;
  padding-bottom: 4px;
}
.home .btn a svg {
  margin-bottom: 2px;
}

/* --- home_post_list --- */
.home_post_list {
  max-width: 880px;
  width: 45.83vw;
}
@media screen and (max-width: 47.9375em) {
  .home_post_list {
    width: 100%;
  }
}

/* --- imgarea --- */
.imgarea {
  display: flex;
  justify-content: space-between;
}
.imgarea img {
  transition: translate 0.4s ease-in-out;
}

/* home_message
**************************************** */
.home_message {
  margin-top: -4vw;
  margin-bottom: 60px;
  padding-top: 4vw;
  padding-bottom: 8.333vw;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/message-illust01.png);
  background-repeat: no-repeat;
  background-position: left 23% bottom;
  background-size: 43%;
  overflow: hidden;
}
.home_message .home_ttl {
  padding-bottom: 48px;
}
.home_message .contentarea {
  width: 53%;
}
.home_message .contentarea .txtarea {
  width: 75%;
}
@media screen and (max-width: 100em) {
  .home_message .contentarea .txtarea {
    width: 70%;
  }
}
.home_message .contentarea .txtarea .btn {
  text-align: right;
  margin-right: 5%;
}
.home_message .imgarea {
  gap: 8px;
}
.home_message .imgarea.col2 img {
  width: calc((100% - 8px) / 2);
}
.home_message .imgarea.col3 img {
  width: calc((100% - 16px) / 3);
}
.home_message .imgarea01 img:nth-child(1) {
  translate: 0 0;
}
.home_message .imgarea01 img:nth-child(2) {
  translate: 0 15%;
}
.home_message .imgarea01 img:nth-child(3) {
  translate: 0 -10%;
}
.home_message .imgarea02 img:nth-child(1) {
  translate: 0 17.7%;
}
.home_message .imgarea02 img:nth-child(2) {
  translate: 0 0;
}
@media print, screen and (min-width: 48em) {
  .home_message .inner {
    flex-direction: row-reverse;
  }
  .home_message .imgarea {
    position: absolute;
  }
  .home_message .contentarea {
    margin-top: 4vw;
  }
  .home_message .imgarea01 {
    width: 33.333vw;
    top: 4vw;
    left: 30px;
  }
  .home_message .imgarea02 {
    width: 21.875vw;
    bottom: 11vw;
    right: -8vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_message {
    margin-bottom: 64px;
    padding-bottom: 8.333vw;
    background-position: left bottom 10vw;
    background-size: 50%;
    overflow: hidden;
  }
  .home_message .home_ttl {
    padding-bottom: 24px;
  }
  .home_message .contentarea {
    width: 100%;
  }
  .home_message .contentarea .txtarea {
    width: 100%;
  }
  .home_message .contentarea .txtarea .btn {
    margin-right: 0;
  }
  .home_message .imgarea {
    gap: 8px;
  }
  .home_message .imgarea.col2 {
    width: 66.66%;
  }
  .home_message .imgarea01 {
    padding-top: 10%;
    padding-bottom: 15%;
  }
  .home_message .imgarea02 {
    margin-left: auto;
    margin-right: 0;
    translate: 10vw 0;
    padding-top: 17.7%;
  }
}

/* home_products
**************************************** */
.home_products {
  padding-bottom: 160px;
}
.home_products .home-ttl {
  margin-bottom: 60px;
}
.home_products .home-ttl .font-en {
  font-size: clamp(2.5rem, 5.8333vw, 7rem);
}
.home_products .home_products--list li {
  display: flex;
  flex-direction: column;
  width: calc((100% - 18.75vw) / 4);
  text-align: center;
}
.home_products .home_products--list li:hover .home_products--img .item-more {
  opacity: 1;
}
.home_products .home_products--list li .home_products--img {
  position: relative;
  margin-bottom: 56px;
}
.home_products .home_products--list li .home_products--img::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 10px;
  left: 10px;
  border: 1px solid var(--clr-wht);
  z-index: -1;
}
.home_products .home_products--list li .home_products--img .item-more {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--bg-wht);
  opacity: 0;
  transition: var(--transit-default);
  background-color: rgba(41, 30, 9, 0.3);
}
.home_products .home_products--list li .home_products--img .item-more .cls-1 {
  fill: var(--clr-wht);
}
.home_products .home_products--list li h3 a {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  font-size: clamp(1.25rem, 1.3541666667vw, 1.625rem);
  font-family: var(--font-jp01-b);
  letter-spacing: 0.12em;
  line-height: 1;
}
.home_products .home_products--list li h3 a .font-en {
  margin-top: 20px;
  color: var(--clr-txt-sub);
  font-size: 0.75rem;
  letter-spacing: 0.12em;
}
.home_products .home_products--pdf {
  margin-top: 16px;
}
.home_products .home_products--pdf li {
  width: 100%;
  margin-bottom: 8px;
}
.home_products .home_products--pdf a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 0.75rem;
  font-family: var(--font-jp01);
}
.home_products .home_products--pdf a svg {
  fill: var(--clr-main);
}
.home_products .home_products--pdf a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 47.9375em) {
  .home_products {
    padding-bottom: 80px;
  }
  .home_products .home-ttl {
    margin-bottom: 60px;
  }
  .home_products .home-ttl .font-en {
    font-size: clamp(2.5rem, 5.8333vw, 7rem);
  }
  .home_products .home_products--list li {
    width: calc((100% - 32px) / 2);
    margin-bottom: 40px;
  }
  .home_products .home_products--list li .home_products--img {
    margin-bottom: 24px;
  }
  .home_products .home_products--list li h3 a {
    margin-bottom: 16px;
    font-size: 1.125rem;
  }
  .home_products .home_products--list li h3 a.small {
    font-size: 1rem;
  }
  .home_products .home_products--list li h3 a.small .font-en {
    margin-top: 10px;
  }
  .home_products .home_products--list li h3 a .font-en {
    margin-top: 10px;
    font-size: 0.75rem;
  }
  .home_products .home_products--list li .btn a {
    padding-left: 8px;
    padding-right: 8px;
  }
  .home_products .home_products--pdf {
    margin-top: 8px;
  }
  .home_products .home_products--pdf li {
    width: 100%;
    margin-bottom: 4px;
  }
  .home_products .home_products--pdf a {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.714rem;
  }
  .home_products .home_products--pdf a svg {
    width: 20px;
  }
}

/* home_feature
**************************************** */
.home_feature {
  overflow-x: hidden;
}
.home_feature .home_ttl {
  margin-left: 8.6vw;
}

.feature_content {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 640px;
}
.feature_content .imgarea {
  position: absolute;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.feature_content .imgarea.col1 {
  width: 10.4166vw;
  max-width: 200px;
}
.feature_content .imgarea.col1 img {
  width: 100%;
}
.feature_content .imgarea.col2 {
  width: 25.833vw;
  max-width: 496px;
}
.feature_content .imgarea.col2 img {
  width: calc((100% - 16px) / 2);
}
.feature_content .imgarea.col3 {
  width: 43.385vw;
  max-width: 833px;
}
.feature_content .imgarea.col3 img {
  width: calc((100% - 32px) / 3);
}
.feature_content .contentarea .num {
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: 0.08em;
  margin-bottom: 48px;
}
.feature_content .contentarea .ttlarea h3 {
  font-family: var(--font-jp01-b);
  font-size: 1.75rem;
  line-height: 1.9285714286;
  letter-spacing: 0.16em;
  margin-bottom: 40px;
}
@media screen and (max-width: 80em) {
  .feature_content .contentarea .ttlarea h3 br {
    display: none;
  }
}
.feature_content .imgarea01 img:nth-child(1) {
  translate: 0 0;
}
.feature_content .imgarea01 img:nth-child(2) {
  translate: 0 16.5%;
}
.feature_content .imgarea01 img:nth-child(3) {
  translate: 0 -19%;
}
.feature_content .imgarea02 img:nth-child(1) {
  translate: 0 0;
}
.feature_content .imgarea02 img:nth-child(2) {
  translate: 0 -15.5%;
}
.feature_content .imgarea02 img:nth-child(3) {
  translate: 0 8%;
}
.feature_content .imgarea03 img:nth-child(1) {
  translate: 0 -12.5%;
}
.feature_content .imgarea03 img:nth-child(2) {
  translate: 0 0;
}
.feature_content .imgarea03 img:nth-child(3) {
  translate: 0 16.5%;
}
.feature_content .imgarea04 img:nth-child(1) {
  translate: 0 -17.5%;
}
.feature_content .imgarea04 img:nth-child(2) {
  translate: 0 0;
}
@media screen and (max-width: 88.75em) {
  .feature_content:nth-of-type(3) .ttlarea h3 br {
    display: none;
  }
}
@media print, screen and (min-width: 48em) {
  .feature_content .contentarea {
    width: 31.25vw;
    max-width: 600px;
    padding: 8.33vw 0;
  }
  .feature_content:nth-of-type(even) {
    flex-direction: row-reverse;
  }
  .feature_content:nth-of-type(even) .imgarea {
    left: -45px;
  }
  .feature_content:nth-of-type(even) .contentarea {
    margin-right: 8.6vw;
  }
  .feature_content:nth-of-type(odd) .imgarea {
    right: 3.645833vw;
  }
  .feature_content:nth-of-type(odd) .contentarea {
    margin-left: 8.6vw;
  }
  .feature_content:first-of-type .contentarea {
    padding-top: 5.20833vw;
  }
  .feature_content:nth-of-type(2) .contentarea {
    padding-bottom: 11.45833vw;
  }
  .feature_content:last-of-type {
    padding-bottom: 8.33vw;
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/feature-illust01.png);
    background-repeat: no-repeat;
    background-position: left 30% bottom;
    background-size: 49%;
  }
  .feature_content .imgarea.imgarea01 {
    top: 0;
    right: -3vw;
  }
  .feature_content .imgarea.imgarea02-2 {
    left: inherit;
    right: -4vw;
    bottom: 0;
  }
  .feature_content .imgarea.imgarea03 {
    top: 10vw;
  }
  .feature_content .imgarea.imgarea04 {
    top: 4vw;
  }
}
@media screen and (max-width: 100em) {
  .feature_content .imgarea.col3 {
    width: 40vw;
  }
}
@media screen and (max-width: 87.5em) {
  .feature_content .imgarea.col3 {
    width: 35vw;
  }
  .feature_content .imgarea.imgarea01, .feature_content .imgarea.imgarea04 {
    top: 30%;
  }
  .feature_content .imgarea.imgarea03 {
    top: 50%;
  }
}
@media screen and (max-width: 47.9375em) {
  .feature_content {
    flex-direction: column-reverse;
    width: 86%;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    gap: 24px;
  }
  .feature_content:last-of-type {
    padding-bottom: 46vw;
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/feature-illust01.png);
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 80%;
  }
  .feature_content .imgarea {
    position: static;
    gap: 8px;
  }
  .feature_content .imgarea.col1 {
    width: 30vw;
  }
  .feature_content .imgarea.col2 {
    width: 60vw;
  }
  .feature_content .imgarea.col2 img {
    width: calc((100% - 8px) / 2);
  }
  .feature_content .imgarea.col3 {
    width: 100%;
  }
  .feature_content .imgarea.col3 img {
    width: calc((100% - 16px) / 3);
  }
  .feature_content .contentarea .num {
    font-size: 1.25rem;
    line-height: 1;
    letter-spacing: 0.08em;
    margin-bottom: 24px;
  }
  .feature_content .contentarea .ttlarea h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    letter-spacing: 0.12em;
  }
  .feature_content .imgarea01 {
    padding-top: 16.5%;
    padding-bottom: 19%;
  }
  .feature_content .imgarea02 {
    padding-top: 15.5%;
    padding-bottom: 8%;
  }
  .feature_content .imgarea02-2 {
    display: none;
  }
  .feature_content .imgarea03 {
    padding-top: 12.5%;
    padding-bottom: 16.5%;
  }
  .feature_content .imgarea04 {
    padding-top: 17.5%;
  }
  .feature_content:nth-of-type(2) .ttlarea h3 br {
    display: inline;
  }
}

/* home_flow
**************************************** */
.home_flow {
  padding-bottom: 30px;
}
.home_flow .flow_img {
  max-width: 713px;
  margin-left: auto;
  margin-right: auto;
}
.home_flow .home_flow--read {
  margin-bottom: 110px;
}
.home_flow .home_flow--table {
  width: 90%;
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
}
.home_flow .home_flow--table .step_ttl {
  padding-left: 0;
  padding-right: 1.66vw;
}
.home_flow .home_flow--table .step_ttl .step_th .step_num {
  margin-right: 3.5vw;
}
.home_flow .home_flow--table table tbody td {
  width: 17.395833vw;
  max-width: 334px;
  padding: 40px 1.25vw;
}
@media screen and (max-width: 100em) and (min-width: 48em) {
  .home_flow .home_flow--table .step_th br {
    display: none;
  }
  .home_flow .home_flow--table table tbody td {
    width: 20vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_flow .home_ttl {
    margin-bottom: 0;
  }
  .home_flow .flow_img {
    margin-top: 0;
    margin-bottom: 0;
  }
  .home_flow .home_flow--table {
    width: 100%;
  }
  .home_flow .home_flow--read {
    margin-bottom: 0;
  }
}

/* home_company
**************************************** */
.home_company {
  padding-top: 364px;
  padding-bottom: 200px;
}
.home_company .bg-ptnbox {
  z-index: -1;
}
.home_company .inner {
  max-width: 1440px;
  gap: 5.20833vw;
}
.home_company .imgbox {
  width: 25vw;
  max-width: 640px;
}
.home_company .home-ttl {
  padding-bottom: 48px;
}
.home_company .home_company--info dl {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 32px 24px;
  line-height: 1.75;
  letter-spacing: 0.16em;
  border-bottom: 1px solid var(--clr-wht);
}
.home_company .home_company--info dl:last-of-type {
  margin-bottom: 56px;
}
.home_company .home_company--info dl dt {
  min-width: 140px;
}
.home_company .home_company--info .btn {
  text-align: right;
}
@media screen and (max-width: 47.9375em) {
  .home_company {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .home_company .inner {
    flex-direction: column-reverse;
    margin-left: auto;
    gap: 40px;
  }
  .home_company .imgbox {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .home_company .home_ttl {
    padding-bottom: 24px;
    text-align: center;
  }
  .home_company .home_ttl .ttl-deco {
    left: 0;
    right: 0;
    margin: auto;
  }
  .home_company .home_ttl .ttl-deco::after {
    left: -200%;
  }
  .home_company .contentarea {
    width: 100%;
  }
  .home_company .home_company--info dl {
    display: flex;
    align-items: inherit;
    flex-direction: column;
    gap: 0;
    padding: 0;
    border-bottom: none;
    margin-bottom: 8px;
  }
  .home_company .home_company--info dl:last-of-type {
    margin-bottom: 32px;
  }
  .home_company .home_company--info dl dt,
  .home_company .home_company--info dl dd {
    padding: 24px 16px;
  }
  .home_company .home_company--info dl dt {
    font-size: 1.1428571429rem;
    padding-bottom: 8px;
  }
  .home_company .home_company--info dl dd {
    padding-top: 8px;
  }
  .home_company .home_company--info .btn {
    text-align: center;
  }
}

/* home_works
**************************************** */
.home_works .works--item .item-more {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--clr-wht);
  text-align: center;
  opacity: 0;
  transition: var(--transit-default);
  background-color: rgba(41, 30, 9, 0.3);
}
.home_works .works--item .item-more h3 {
  font-size: 1.0625rem;
  letter-spacing: 0.12em;
  margin-bottom: 24px;
}
.home_works .works--item .item-more .font-en {
  line-height: 1;
  margin-right: 16px;
}
.home_works .works--item .item-more .cls-1 {
  fill: var(--clr-wht);
}
.home_works .works--item:hover .item-more {
  opacity: 1;
}
@media print, screen and (min-width: 48em) {
  .home_works {
    position: relative;
    width: 100vw;
    height: 67.7083vw;
    translate: -200px 0;
    overflow-x: hidden;
  }
  .home_works .ttlarea {
    position: absolute;
    top: 23.95833vw;
    left: 20.833vw;
  }
  .home_works .ttlarea .home_ttl {
    margin-bottom: 60px;
    margin-left: 16px;
  }
  .home_works .works--item {
    position: absolute;
  }
  .home_works .works--item.item1 {
    width: 22.29166vw;
    max-width: 428px;
    aspect-ratio: 428/610;
    top: 2.34375vw;
    right: 0;
  }
  .home_works .works--item.item2 {
    width: 50vw;
    max-width: 35.729166vw;
    aspect-ratio: 686/438;
    bottom: 0;
    right: 17.08333vw;
  }
  .home_works .works--item.item3 {
    width: 21.3541666vw;
    max-width: 410px;
    aspect-ratio: 410/288;
    top: 0;
    right: 43.229vw;
  }
  .home_works .works--item.item4 {
    width: 11.979166vw;
    max-width: 230px;
    aspect-ratio: 230/275;
    top: 6.770833vw;
    right: 25.78125vw;
  }
  .home_works .works--item.item5 {
    width: 16.666vw;
    max-width: 320px;
    aspect-ratio: 1/1;
    top: 25vw;
    right: 30.729166vw;
  }
  .home_works .works--item.item6 {
    width: 14.0625vw;
    max-width: 270px;
    aspect-ratio: 270/320;
    bottom: 12.5vw;
    right: 0;
  }
  .home_works .works--item.item7 {
    width: 10.833vw;
    max-width: 208px;
    min-width: 160px;
    aspect-ratio: 208/266;
    bottom: 13.54166vw;
    left: 160px;
  }
  .home_works .works--item.item7 .item-more {
    padding-left: 3.4375vw;
  }
}
@media screen and (max-width: 100em) {
  .home_works .works--item.item7 {
    bottom: 2vw;
    right: inherit;
    left: 180px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_works {
    position: relative;
    width: 100vw;
    height: 180vw;
  }
  .home_works .ttlarea {
    position: absolute;
    top: 63.414vw;
    left: 7.31vw;
  }
  .home_works .ttlarea .home_ttl {
    margin-bottom: 60px;
    margin-left: 16px;
  }
  .home_works .works--item {
    position: absolute;
    bottom: 0;
  }
  .home_works .works--item.item1 {
    width: 29.26vw;
    aspect-ratio: 120/300;
    top: 0;
    right: 0;
  }
  .home_works .works--item.item2 {
    width: 40.24vw;
    aspect-ratio: 164/144;
    top: 12.19vw;
    left: 0;
  }
  .home_works .works--item.item3 {
    width: 34.14vw;
    aspect-ratio: 1/1;
    top: 82.92vw;
    right: 7.317vw;
  }
  .home_works .works--item.item4 {
    width: 60.975vw;
    aspect-ratio: 250/160;
    bottom: 11.7vw;
    left: 0;
  }
  .home_works .works--item.item5 {
    width: 24.39vw;
    aspect-ratio: 100/166;
    right: 0;
    bottom: 0;
  }
  .home_works .works--item.item6, .home_works .works--item.item7 {
    display: none;
  }
  .home_works .works--item .item-more {
    display: none;
  }
}

/* home_blog
**************************************** */
.home_blog .blog_list .post {
  justify-content: space-between;
  gap: 40px;
  padding: 48px 40px;
  letter-spacing: 0.16em;
  border-bottom: 1px solid var(--clr-berge5);
  transition: var(--transit-default);
}
.home_blog .blog_list .post:last-of-type {
  margin-bottom: 48px;
}
.home_blog .blog_list .post:hover {
  opacity: 0.6;
}
.home_blog .blog_list .post .post--img {
  width: 10.41666vw;
  max-width: 200px;
  aspect-ratio: 200/160;
}
.home_blog .blog_list .post .post--txtarea {
  width: calc(100% - 240px);
}
.home_blog .blog_list .post .post--info {
  margin-bottom: 16px;
}
.home_blog .blog_list .post .post--ttl a {
  margin-bottom: 8px;
  width: 100%;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.home_blog .blog_list .post .post--txt {
  font-size: 0.875rem;
  font-family: var(--font-main-r);
}
.home_blog .blog_list .post svg {
  margin-left: auto;
  min-width: 20px;
}
@media print, screen and (min-width: 48em) {
  .home_blog .inner {
    margin-left: 5.20833vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_blog .blog_list .post {
    justify-content: space-between;
    gap: 16px;
    padding: 24px 20px;
  }
  .home_blog .blog_list .post:last-of-type {
    margin-bottom: 24px;
  }
  .home_blog .blog_list .post .post--img {
    width: 20vw;
  }
  .home_blog .blog_list .post .post--txtarea {
    width: calc(100% - 20vw - 16px);
  }
  .home_blog .blog_list .post .post--info {
    margin-bottom: 16px;
  }
  .home_blog .blog_list .post .post--ttl a {
    margin-bottom: 8px;
    font-size: 0.875rem;
    white-space: inherit;
  }
  .home_blog .blog_list .post .post--txt {
    font-size: 0.75rem;
    font-family: var(--font-main-r);
  }
  .home_blog .blog_list .post svg {
    margin-left: auto;
    min-width: 14px;
  }
}

/* waypoint
**************************************** */
.waypoint:not(.imgarea):not(.works_link) {
  opacity: 0;
  translate: 0 -40px;
  transition: translate 1s cubic-bezier(0, 0.5, 0.6, 1), opacity 0.6s cubic-bezier(0, 0.5, 0.6, 1);
}
.waypoint:not(.imgarea):not(.works_link).active {
  opacity: 1;
  translate: 0 0;
}

/* --- home_products--list --- */
.home_products--list .waypoint:nth-of-type(1) {
  transition-delay: 0;
}
.home_products--list .waypoint:nth-of-type(2) {
  transition-delay: 0.2s;
}
.home_products--list .waypoint:nth-of-type(3) {
  transition-delay: 0.4s;
}
.home_products--list .waypoint:nth-of-type(4) {
  transition-delay: 0.6s;
}

/* --- imgarea --- */
.waypoint.imgarea img {
  opacity: 1;
  transition: translate 1s cubic-bezier(0, 0.5, 0.6, 1), opacity 0.6s cubic-bezier(0, 0.5, 0.6, 1);
}
.waypoint.imgarea:not(.active) img {
  opacity: 0;
  translate: 0 0;
}
.waypoint.imgarea.imgarea01:not(.active) img:nth-child(1), .waypoint.imgarea.imgarea02:not(.active) img:nth-child(1), .waypoint.imgarea.imgarea03:not(.active) img:nth-child(2), .waypoint.imgarea.imgarea04:not(.active) img:nth-child(2) {
  translate: 0 -5%;
}

/* --- works--item --- */
.waypoint .works--item {
  opacity: 0;
  translate: 0 -40px;
  transition: translate 1s cubic-bezier(0, 0.5, 0.6, 1), opacity 0.6s cubic-bezier(0, 0.5, 0.6, 1);
}
.waypoint.active .works--item {
  opacity: 1;
  translate: 0 0;
}

.works--item.item1 {
  transition-delay: 0s;
}
.works--item.item2 {
  transition-delay: 0.2s;
}
.works--item.item3 {
  transition-delay: 0.4s;
}
.works--item.item4 {
  transition-delay: 0.6s;
}
.works--item.item5 {
  transition-delay: 0.8s;
}
.works--item.item6 {
  transition-delay: 1s;
}
.works--item.item7 {
  transition-delay: 1.2s;
}

/* --- inner --- */
.inner {
  width: 90%;
  max-width: 1060px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .inner {
    width: 86%;
  }
}

.inner-sm {
  max-width: 880px;
}

.inner-m {
  max-width: 1060px;
}

.inner-lg {
  max-width: 1524px;
}

.inner-1200 {
  max-width: 1200px;
}

.inner-1400 {
  max-width: 1400px;
}

.inner-1420 {
  max-width: 1420px;
}

.inner-xl {
  max-width: calc(100% - 112px);
}
@media screen and (max-width: 67.5em) {
  .inner-xl {
    max-width: 90%;
  }
}
@media screen and (max-width: 47.9375em) {
  .inner-xl {
    max-width: 86%;
  }
}

/* --- section_pdg --- */
.section_pdg {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section_pdg.bg-ptn {
  padding-top: 300px;
  padding-bottom: 260px;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg.bg-ptn {
    padding-top: 100px;
    padding-bottom: 120px;
  }
}

.section_pdg-sm {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg-sm {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  padding-top: 80px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

.page_ttl--deco {
  position: relative;
  width: 5px;
  height: 5px;
  margin-bottom: 56px;
  background-color: var(--clr-wht);
  border-radius: 50vw;
}
.page_ttl--deco::before, .page_ttl--deco::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: var(--clr-wht);
  border-radius: 50vw;
}
.page_ttl--deco::before {
  left: 200%;
}
.page_ttl--deco::after {
  left: 400%;
}

.page_ttl-en {
  display: block;
  text-transform: uppercase;
  line-height: 1;
}

.page_ttl-jp {
  font-family: var(--font-jp01-b);
  font-size: 1rem;
  letter-spacing: 0.12em;
}

@media screen and (max-width: 47.9375em) {
  .page_ttl {
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .page_ttl--deco {
    margin-bottom: 24px;
  }
  .page_ttl-en {
    font-size: 2rem; /* 28px */
  }
  .page_ttl-jp {
    font-size: 1.143rem; /* 16px */
    margin-bottom: 16px;
  }
}
@media print, screen and (min-width: 48em) {
  .single-works .page_ttl {
    padding-bottom: 24px;
  }
  .single-works .page_ttl-jp {
    font-size: 1.75rem;
    letter-spacing: 0.2em;
    margin-top: 48px;
    margin-left: 4px;
  }
}
.page .page_ttl {
  padding-top: 170px;
  margin-left: 5.20833vw;
}
.page .page_ttl-jp {
  padding-bottom: 64px;
}
@media screen and (max-width: 47.9375em) {
  .page .page_ttl {
    padding-top: 80px;
    margin-left: auto;
  }
  .page .page_ttl-jp {
    padding-bottom: 0;
  }
  .page .page_ttl--deco {
    order: 1;
    margin-bottom: 32px;
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03 {
  font-family: var(--font-jp01-b);
  letter-spacing: 0.1em;
}

.ttl-01 {
  font-size: 2.375rem;
  color: var(--clr-main);
  padding-bottom: 48px;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01 {
    font-size: 2.143rem; /* 30px */
    padding-bottom: 24px;
  }
  .ttl-01.inner {
    width: 100%;
    padding-left: 7%;
    padding-right: 7%;
  }
}
.ttl-01.has-en {
  padding-top: 48px;
}
.ttl-01 .font-en {
  position: absolute;
  left: -300px;
  bottom: -30px;
  width: 100vw;
  width: var(--vw);
  font-size: 15rem;
  font-weight: 400;
  line-height: 1;
  text-indent: -50px;
  color: var(--clr-wht);
  letter-spacing: 0.08em;
  white-space: nowrap;
  opacity: 0.3;
  z-index: -1;
  pointer-events: none;
  overflow-x: hidden;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01 .font-en {
    position: absolute;
    left: 0;
    bottom: inherit;
    top: 0;
    width: 100vw;
    display: block;
    font-size: 3.5rem;
    text-indent: -0.25em;
  }
}
.ttl-01 .ttl-deco {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 50vw;
  background-color: var(--clr-wht);
}
.ttl-01 .ttl-deco::before, .ttl-01 .ttl-deco::after {
  content: "";
  position: absolute;
  background-color: var(--clr-wht);
  border-radius: 50vw;
  width: 5px;
  height: 5px;
}
.ttl-01 .ttl-deco::before {
  left: 200%;
}
.ttl-01 .ttl-deco::after {
  left: 400%;
}

@media screen and (max-width: 47.9375em) {
  .lps_sec .ttl-01 .ttl-deco {
    left: 7%;
  }
}
.ttl-02 {
  font-size: 2rem;
  line-height: 1.8125;
}
@media screen and (max-width: 47.9375em) {
  .ttl-02 {
    font-size: 2rem; /* 28px */
  }
}

.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: 2rem;
  line-height: 1.8125;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .ttl-03 {
    font-size: 2rem; /* 28px */
  }
}

.ttl-03 {
  font-size: 1.75rem; /* 28px */
}
@media screen and (max-width: 47.9375em) {
  .ttl-03 {
    font-size: 1.714rem; /* 24px */
  }
}

.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03 {
  position: relative;
  z-index: 1;
}

.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03:not(:last-child) {
  margin-bottom: 1.5em;
}

.ttl-04 {
  font-size: 1.5rem; /* 24px */
}

.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: 1.5rem; /* 24px */
  margin-bottom: 16px;
}
@media screen and (max-width: 47.9375em) {
  .lps_sec .column-3 .ttl-03,
  .lps_sec .column-4 .ttl-03 {
    font-size: 1.571rem; /* 24px */
  }
}

.ttl-en {
  color: var(--clr-wht);
  font-family: var(--font-en);
  font-size: max(3rem, min(5.416666vw, 6.5rem));
  font-weight: 400;
  letter-spacing: 0.07em;
  text-indent: -0.07em;
  line-height: 1;
}

.section_ttl {
  font-family: var(--font-jp01-b);
}

/* simulator
**************************************** */
@media screen and (max-width: 47.9375em) {
  .page-simulator .header,
  .page-simulator .gnav_btn {
    position: absolute;
  }
}

.page-simulator .page_ttl {
  padding-top: 80px;
}
@media print, screen and (min-width: 48em) {
  .page-simulator .page_ttl {
    margin-left: 5.2083333333vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .page-simulator .page_ttl {
    padding-top: 24px;
  }
}

.page-simulator .page_ttl-jp {
  padding-bottom: 8px;
}

@media print, screen and (min-width: 48em) {
  .simulator--box {
    margin-top: 100px;
    margin-left: 5.2083333333vw;
    gap: 4.1666666667vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .simulator--box {
    margin-top: 48px;
    gap: 2.0833333333vw;
  }
}

.simulator--deco {
  color: var(--clr-wht);
  font-size: clamp(5rem, 5.4166666667vw, 6.5rem);
  font-weight: 400;
  line-height: 0.8;
  letter-spacing: 0.08em;
  z-index: -1;
}
@media print, screen and (min-width: 48em) {
  .simulator--deco {
    position: absolute;
    top: -2.5vw;
    right: 0;
  }
}
@media screen and (max-width: 47.9375em) {
  .simulator--deco {
    font-size: 2rem;
    order: 2;
  }
}

/* --- simulator_archive --- */
.simulator_archive {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
@media screen and (max-width: 47.9375em) {
  .simulator_archive {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.simulator_link {
  padding: 1.75rem 2.9166666667vw 1.75rem 4.1666666667vw;
  gap: 1.5rem;
  background-color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 47.9375em) {
  .simulator_link {
    padding: 2rem 1rem;
    gap: 1.7142857143rem;
  }
}

.simulator_link:nth-child(even) {
  background-color: rgba(192, 187, 176, 0.5);
}

.simulator_link:not(:last-child) {
  margin-bottom: 3rem;
}

.simulator_link .simulator_link--subttl {
  margin-bottom: 1rem;
  font-size: 0.75rem;
  letter-spacing: 0.08em;
  font-weight: 600;
  opacity: 0.5;
}
@media screen and (max-width: 47.9375em) {
  .simulator_link .simulator_link--subttl {
    margin-bottom: 0.5rem;
  }
}

.simulator_link--ttl {
  margin-bottom: 4.1666666667vw;
  font-size: clamp(2.5rem, 4.1666666667vw, 4.5rem);
  line-height: 1;
  letter-spacing: 0.08em;
  font-weight: 400;
}
@media screen and (max-width: 47.9375em) {
  .simulator_link--ttl {
    font-size: 2.2857142857rem;
    letter-spacing: 0.08em;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 47.9375em) {
  .simulator_link > div {
    width: 100%;
  }
}

.simulator_link .imgarea {
  overflow: hidden;
}
@media print, screen and (min-width: 48em) {
  .simulator_link .imgarea {
    width: 33.3333333333vw;
  }
}

.simulator_link .imgarea img {
  transition: var(--transit-default);
}

.simulator_link .imgarea a:hover {
  scale: 1.05;
}

.simulator_link .btn a {
  font-size: 0.875rem;
  letter-spacing: 0.18em;
  padding-top: 4px;
  padding-bottom: 4px;
}
.simulator_link .btn a svg {
  margin-bottom: 2px;
}

@media print, screen and (min-width: 48em) {
  .simulator_link .btn.pc-none {
    display: none;
  }
}

/* --- simulator_main --- */
.simulator_main {
  width: min(63%, 960px);
}
@media print, screen and (min-width: 48em) {
  .simulator_main {
    flex: 1;
  }
}
@media screen and (max-width: 47.9375em) {
  .simulator_main {
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    height: 280px;
    width: 100vw;
    translate: -7vw 0;
    padding: 8px;
    margin-bottom: 32px;
    z-index: 100;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    background-color: var(--clr-berge);
    transition: all 0.1s ease-in;
    order: 1;
  }
}

.simulator-attention {
  padding-left: 1em;
  text-indent: -1em;
  line-height: 1.4;
}
@media print, screen and (min-width: 48em) {
  .simulator-attention {
    margin-top: 1.5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .simulator-attention {
    font-size: 0.7857142857rem;
    letter-spacing: 0.02em;
  }
}

@media print, screen and (min-width: 48em) {
  .simulator_main--content {
    position: sticky;
    top: 64px;
    max-width: 960px;
  }
}

.simulator-img {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  aspect-ratio: 960/640;
  max-width: 960px;
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .simulator-img {
    width: 300px;
    margin-inline: auto;
    margin-bottom: 8px;
  }
}

.page-campus01 .simulator-img {
  background-image: url(/wp/wp-content/themes/standard_sass/images/simulator/campus01/default.jpg);
}

.page-campus02 .simulator-img {
  background-image: url(/wp/wp-content/themes/standard_sass/images/simulator/campus02/default.jpg);
}

.page-campus03 .simulator-img {
  background-image: url(/wp/wp-content/themes/standard_sass/images/simulator/campus03/default.jpg);
}

.simulator-mask,
.simulatour-click {
  position: absolute;
  top: 0;
  width: 100%;
  aspect-ratio: 960/640;
  z-index: 10;
}

.mask--item,
.click--item {
  position: absolute;
}

.mask--item svg,
.click--item svg {
  vertical-align: bottom;
}

.click--item {
  fill: transparent;
}

.mask-svg {
  fill-rule: evenodd;
  fill: rgba(255, 255, 255, 0.4);
  stroke: #fff;
  stroke-width: 2px;
  opacity: 0;
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.mask--item.active:not(.selected) {
  pointer-events: none;
}

.mask--item.active .mask-svg {
  opacity: 1;
  cursor: default;
}

.mask--item.selected .mask-svg {
  opacity: 0;
}

@media print, screen and (min-width: 48em) {
  .simulatour-click {
    display: none;
  }
  .mask-svg:hover {
    opacity: 0.7;
  }
  .mask--item.selected .mask-svg:hover {
    opacity: 0.4;
  }
}
@media screen and (max-width: 47.9375em) {
  .simulatour-mask {
    pointer-events: none;
  }
}
.page-campus01 {
  /* --- mask --- */
  /* --- click --- */
}
.page-campus01 .mask-ceiling {
  top: 0;
  width: 100%;
}
.page-campus01 .mask-floor {
  bottom: 0;
  width: 100%;
}
.page-campus01 .mask-wall-right {
  right: 0;
  top: 17.2%;
  width: 30.25%;
}
.page-campus01 .mask-wall-back {
  width: 41%;
  top: 42.8%;
  left: 29%;
}
.page-campus01 .mask-wall-left {
  width: 29%;
  top: 19.5%;
  left: 0;
}
.page-campus01 .click-ceiling {
  top: 0;
  width: 100%;
}
.page-campus01 .click-floor {
  bottom: 0;
  width: 100%;
}
.page-campus01 .click-wall-right {
  right: 0;
  bottom: 0;
  width: 30.078125%;
}
.page-campus01 .click-wall-back {
  width: 40.9375%;
  top: 43.2%;
  left: 28.984375%;
}
.page-campus01 .click-wall-left {
  width: 28.984375%;
  bottom: 0;
  left: 0;
}

.page-campus02 {
  /* --- mask --- */
  /* --- click --- */
}
.page-campus02 .mask-ceiling {
  top: 0;
  width: 100%;
}
.page-campus02 .mask-floor {
  bottom: 1px;
  width: 100%;
}
.page-campus02 .mask-wall-right {
  right: 0;
  top: 16%;
  width: 61.09375%;
}
.page-campus02 .mask-wall-left {
  top: 3.3%;
  left: 0;
  width: 38.828125%;
}
.page-campus02 .click-ceiling {
  top: 0;
  width: 100%;
}
.page-campus02 .click-floor {
  bottom: 0;
  width: 100%;
}
.page-campus02 .click-wall-right {
  top: 16.1%;
  right: 0;
  width: 61.09375%;
}
.page-campus02 .click-wall-left {
  top: 3.3%;
  left: 0;
  width: 38.90625%;
}

.page-campus03 {
  /* --- mask --- */
  /* --- click --- */
}
.page-campus03 .mask-ceiling {
  top: 0;
  right: 0;
  width: 44.79166%;
  z-index: 1;
}
.page-campus03 .mask-floor {
  bottom: 0;
  right: 0;
  width: 93.02%;
  z-index: 1;
}
.page-campus03 .mask-wall-right {
  right: 0;
  top: 18.125%;
  width: 10.1042%;
}
.page-campus03 .mask-wall-back {
  width: 89.896%;
  top: 0;
  left: 0;
  z-index: 0;
}
.page-campus03 .click-ceiling {
  top: 0;
  right: 0;
  width: 44.79166%;
  z-index: 4;
}
.page-campus03 .click-floor {
  bottom: 0;
  right: 0;
  width: 93.02%;
  z-index: 2;
}
.page-campus03 .click-wall-right {
  right: 0;
  top: 18.125%;
  width: 10.15%;
  z-index: 3;
}
.page-campus03 .click-wall-back {
  width: 89.896%;
  top: 0;
  left: 0;
  z-index: 1;
}

.simulator_main .btn a {
  cursor: pointer;
}

/* --- simulator_menu --- */
@media print, screen and (min-width: 48em) {
  .simulator_menu {
    padding-top: 48px;
    width: clamp(368px, 40%, 480px);
  }
}
@media screen and (max-width: 47.9375em) {
  .simulator_menu {
    order: 3;
    padding-bottom: 40px;
  }
}

.menu_step {
  padding-bottom: 10px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--clr-wht);
}

.menu_step--item {
  height: 100%;
}

.menu_step--item a {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 48px;
  padding-top: 16px;
  gap: 4px;
  font-size: 0.8125rem;
  letter-spacing: normal;
  line-height: 1;
  cursor: pointer;
}

.menu_step--item a .font-en {
  display: flex;
  align-items: flex-end;
  height: 1.25rem;
  font-size: 1.125rem;
  letter-spacing: 0.1em;
  line-height: 1;
  transition: var(--transit-default);
}

.menu_step--item a.active {
  color: var(--clr-rd);
}
.menu_step--item a.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  translate: -50% 0;
  width: 4px;
  height: 4px;
  background-color: currentColor;
  border-radius: 50vw;
}
.menu_step--item a.active .font-en {
  font-size: 1.5rem;
}

.menu_list .ttlarea {
  margin-bottom: 32px;
  gap: 16px;
}
@media screen and (max-width: 47.9375em) {
  .menu_list .ttlarea {
    margin-bottom: 16px;
  }
}

a[href="javascript:void(0);"].parts-rotate {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: var(--clr-berge5);
  opacity: 0;
  pointer-events: none;
  transition: var(--transit-default);
  cursor: pointer;
}
@media screen and (max-width: 47.9375em) {
  a[href="javascript:void(0);"].parts-rotate {
    padding: 8px;
  }
}
a[href="javascript:void(0);"].parts-rotate.active {
  opacity: 1;
  pointer-events: inherit;
}
a[href="javascript:void(0);"].parts-rotate svg {
  rotate: 0deg;
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  a[href="javascript:void(0);"].parts-rotate svg {
    width: 24px;
  }
}
a[href="javascript:void(0);"].parts-rotate:hover svg {
  rotate: 90deg;
}

/* --- menu_list --- */
.menu_list {
  display: none;
}

.menu_list.active {
  display: block;
}

.menu_list--ttl {
  display: flex;
  align-items: center;
  gap: 1.25vw;
  font-size: clamp(1.125rem, 1.1458333333vw, 1.375rem);
  line-height: 1;
  letter-spacing: 0.16em;
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .menu_list--ttl {
    font-size: 1rem;
    gap: 8px;
  }
}

.menu_list--ttl .num {
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(3.3333333333vw, 56px);
  height: min(3.3333333333vw, 56px);
  padding-top: 0.15em;
  color: var(--clr-wht);
  font-size: 1.125rem;
  background-color: var(--clr-br);
  border-radius: 50vw;
}
@media screen and (max-width: 47.9375em) {
  .menu_list--ttl .num {
    width: 40px;
    height: 40px;
  }
}

.menu_list ul {
  display: grid;
  grid-template-columns: repeat(auto-fill, 80px);
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  margin-bottom: 48px;
}
@media screen and (max-width: 47.9375em) {
  .menu_list ul {
    grid-template-columns: repeat(auto-fill, calc((100% - 24px) / 4));
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    margin-bottom: 24px;
  }
}

.menu_list--item a {
  display: block;
  font-size: 0.75rem;
  line-height: 1.2;
  text-align: center;
  letter-spacing: normal;
  cursor: pointer;
}
@media screen and (max-width: 47.9375em) {
  .menu_list--item a {
    font-size: 0.7142857143rem;
  }
}

.menu_list--item a .menu_list--img {
  position: relative;
  display: block;
  width: 56px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;
  aspect-ratio: 1/1;
  overflow: hidden;
  outline: 1px solid transparent;
  outline-offset: -1px;
  transition: var(--transit-default);
}

.menu_list--item a:hover .menu_list--img {
  outline: 1px solid var(--clr-wht);
}

.menu_list--item a.selected .menu_list--img::after {
  content: "選択中";
  position: absolute;
  inset: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1;
  text-align: center;
  border: 2px solid var(--clr-wht);
  background-color: rgba(255, 255, 255, 0.7);
}

/* --- .select_parts --- */
.select_parts {
  margin-top: 40px;
  padding: 32px;
  border: 1px solid var(--clr-wht);
}
@media screen and (max-width: 47.9375em) {
  .select_parts {
    padding: 24px 16px;
  }
}

.select_parts--ttl {
  font-size: 1.125rem;
  letter-spacing: 0.16em;
  margin-bottom: 24px;
}

.select_parts--list {
  margin-bottom: 24px;
}

.select_parts--item {
  position: relative;
  margin-bottom: 16px;
}

@media screen and (max-width: 47.9375em) {
  .select_parts--item:not(:last-child) {
    padding-bottom: 8px;
    border-bottom: 1px dotted var(--clr-wht);
  }
}

.select_parts--list a.link-item {
  position: absolute;
  font-size: 0.75rem;
  color: var(--clr-wht);
  letter-spacing: normal;
  padding: 6px 20px 6px 8px;
  border: 1px solid var(--clr-wht);
  background-color: var(--clr-br);
  line-height: 1;
  opacity: 0;
  pointer-events: none;
}
@media print, screen and (min-width: 48em) {
  .select_parts--list a.link-item {
    right: 0;
    top: 50%;
    translate: 0 -50%;
  }
}
@media screen and (max-width: 47.9375em) {
  .select_parts--list a.link-item {
    top: 0;
    right: 0;
  }
}
.select_parts--list a.link-item.active {
  opacity: 1;
  pointer-events: inherit;
}
.select_parts--list a.link-item::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  translate: 0 -50%;
  width: 8px;
  height: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  rotate: 45deg;
}
.select_parts--list a.link-item:hover {
  background-color: var(--clr-main);
}

.select_parts--item input[type=checkbox] {
  display: none;
}

.select_parts--item label {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
@media print, screen and (min-width: 48em) {
  .select_parts--item label {
    padding-right: 90px;
  }
}
@media screen and (max-width: 47.9375em) {
  .select_parts--item label {
    flex-wrap: wrap;
    gap: 8px;
  }
}

.select_parts--item .check {
  position: relative;
  width: 16px;
  height: 16px;
  border: 2px solid var(--clr-berge2);
  opacity: 0;
}

.select_parts--item input:checked + label .check::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 2px;
  width: 9px;
  height: 15px;
  border-right: 2px solid var(--clr-body);
  border-bottom: 2px solid var(--clr-body);
  rotate: 45deg;
}

.select_parts--item .area {
  display: inline-block;
  min-width: 64px;
  height: 22px;
  padding-left: 6px;
  padding-right: 6px;
  color: var(--clr-main);
  font-size: 0.8125rem;
  line-height: 22px;
  text-align: center;
  border-radius: 3px;
  background-color: var(--clr-berge5);
}

.select_parts--item .name {
  font-size: 0.9375rem;
  line-height: 1.4;
  letter-spacing: 0.12em;
  transition: var(--transit-default);
}
@media print, screen and (min-width: 48em) {
  .select_parts--item .name {
    flex: 1;
  }
}
@media screen and (max-width: 47.9375em) {
  .select_parts--item .name {
    width: 100%;
    font-size: 1rem;
    padding-left: 24px;
  }
}

.select_parts--item {
  pointer-events: none;
  cursor: default;
}

.select_parts--item.active {
  pointer-events: inherit;
  cursor: pointer;
}

.select_parts--item.active .check {
  opacity: 1;
}

.select_parts--item.active label:hover .name {
  opacity: 0.7;
}

/* ##############################################################################

    ARCHIVE

############################################################################## */
/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: 80px;
  overflow: hidden;
}

/* --- post --- */
.post {
  position: relative;
}
@media screen and (max-width: 47.9375em) {
  .post svg {
    display: none;
  }
}

.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 10;
}

.post--date {
  font-family: var(--font-en);
  color: var(--clr-br3);
  line-height: 1;
}

.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}

.post--ttl {
  width: 100%;
  font-family: var(--font-jp01-b);
}
.post--ttl a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-family: var(--font-jp01-b);
  font-size: 0.813rem; /* 13px */
  color: var(--clr-main);
  border: 1px solid var(--clr-main);
  padding: 0.25em 1em;
  float: left;
  white-space: nowrap;
  margin: 2px;
}

.cat_list a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 68.75em) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@media screen and (max-width: 47.9375em) {
  .main_column {
    margin-bottom: 0;
  }
}
/* side_column
**************************************** */
.side_column {
  width: 264px;
  padding-top: 16px;
}
@media screen and (max-width: 68.75em) {
  .side_column {
    width: 100%;
  }
}
@media screen and (max-width: 47.9375em) {
  .side_column {
    margin-top: 64px;
  }
}

.side_section:not(:last-child) {
  margin-bottom: 64px;
}

.side--ttl {
  color: var(--clr-wht);
  letter-spacing: 0.12em;
  line-height: 1;
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--clr-wht);
}

.side--ttl small {
  color: var(--clr-main);
  letter-spacing: 0.12em;
  display: block;
  line-height: 1;
  margin-top: 8px;
}

/* --- list --- */
.side--list a {
  position: relative;
  display: block;
  padding-left: 16px;
  margin-left: 8px;
  margin-bottom: 8px;
  font-family: var(--font-jp01-b);
}
.side--list a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 5px;
  height: 5px;
  border-radius: 50vw;
  background-color: var(--clr-main);
}
.side--list a:hover {
  opacity: 0.7;
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: 16px;
}

.posts-side .post--img {
  margin-right: 16px;
  width: 64px;
  height: 64px;
}

.posts-side .txtarea {
  flex: 1;
}

.posts-side .post--date {
  margin-bottom: 0;
}

.posts-side .post--ttl {
  line-height: 1.4;
}

/* --- archive --- */
.side_column .archive_list--ttl {
  cursor: pointer;
  font-family: var(--font-jp01-b);
  font-weight: 500;
  line-height: 1.8;
}

.side_column .archive_list--ttl::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--trans-default, all 0.4s ease-out);
}

.side_column .archive_list--ttl.active::after {
  transform: rotate(315deg);
}

.side_column .archive_list {
  margin-bottom: 8px;
}

.side_column .archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 99;
}

.archive-pulldown .archive_list {
  position: relative;
}

.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}

.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}

.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.archive-pulldown .archive_list a:hover {
  background-color: #eee;
}

.archive-pulldown .archive_list--label {
  margin-right: 8px;
}

.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: var(--clr-wht);
  color: var(--clr-main);
  border: 1px solid var(--clr-main-op);
  font-family: var(--font-main, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
}

.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 1px solid var(--clr-main);
  border-right: 1px solid var(--clr-main);
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}

.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}

.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--clr-wht);
  border: 1px solid var(--clr-main-op);
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* blog
**************************************** */
.archive-blog .post--ttl {
  font-size: 1.5rem;
}

.posts-blog .post {
  padding-bottom: 48px;
  padding-top: 48px;
  border-bottom: 1px solid var(--clr-wht);
}

.posts-blog .post:first-child {
  border-top: 1px solid var(--clr-wht);
}

.posts-blog .post--txtarea {
  flex: 1;
  word-break: break-word;
}

.posts-blog .post--img {
  width: 18.75vw;
  max-width: 360px;
  margin-right: 40px;
}

.posts-blog .post--img::before {
  padding-top: 75%;
}

@media screen and (max-width: 47.9375em) {
  .posts-blog .post {
    display: block;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .posts-blog .post--img {
    width: 80%;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    text-align: center;
  }
  .posts-blog .post--img img {
    width: auto;
    max-width: 100%;
  }
}
/* news
**************************************** */
/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}

.news--archive li {
  margin-left: 8px;
  margin-right: 8px;
}

.news--archive a {
  color: #fff;
}

/* --- news_list --- */
.news_list .post {
  padding: 40px 32px;
  margin-bottom: 0;
  border-bottom: 1px solid var(--clr-main-op);
}
@media screen and (max-width: 47.9375em) {
  .news_list .post {
    display: block;
  }
}

.news_list .post--date {
  width: 124px;
}

.news_list .post--info {
  margin-right: 32px;
}
@media screen and (max-width: 47.9375em) {
  .news_list .post--info {
    margin-bottom: 8px;
    margin-right: 0;
  }
}

.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main);
}

.news_list .post--link:hover svg {
  fill: var(--clr-main);
}

.news_list .post--info.flex .post--date + .cat_list {
  margin-left: 16px;
}

/* products
**************************************** */
.products_search_box {
  position: relative;
}

.products_search_box input[type=text] {
  width: 100%;
  height: 64px;
  color: var(--clr-main);
  background-color: var(--clr-berge5);
  border: 1px solid var(--clr-wht);
  border-radius: 4px;
  font-size: 1.125rem;
  padding: 1.125rem 1.5rem;
  outline: 0;
}
.products_search_box input[type=text]::placeholder {
  font-family: var(--font-jp01) !important;
  color: rgba(130, 119, 98, 0.7);
}

.products_search_box button {
  position: absolute;
  right: 1rem;
  top: 0;
  bottom: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.products_search_box button svg {
  fill: var(--clr-wht);
}

.products_search_box button:hover svg {
  fill: #827762;
  fill: var(--clr-body);
}

.posts-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.posts-products.posts-ctr {
  justify-content: center;
  gap: 30px;
}
.posts-products::before, .posts-products::after {
  content: "";
  width: calc((100% - 100px) / 3);
  order: 1;
}
.posts-products .products_item {
  padding: 16px;
  margin-bottom: 40px;
  width: calc((100% - 100px) / 3);
}
.posts-products .products_item::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--clr-berge);
  outline: 1px solid var(--clr-wht);
  outline-offset: -1px;
  opacity: 0;
  top: 0;
  left: 0;
  transition: var(--transit-default);
  z-index: -1;
}
.posts-products .products_item:hover::before {
  opacity: 1;
}
.posts-products .products_item .products_item--img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  aspect-ratio: 420/280;
}
.posts-products .products_item .products_item--img .hover--text {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: auto;
  color: var(--clr-wht);
  font-size: 0.9375rem;
  font-weight: 600;
  z-index: 4;
  opacity: 0;
  transition: var(--transit-default);
}
.posts-products .products_item .products_item--img .hover--text svg .cls-1 {
  fill: var(--clr-wht);
}
.posts-products .products_item .products_item--img::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 2;
  opacity: 0;
  transition: var(--transit-default);
}
.posts-products .products_item:hover .hover--text {
  opacity: 1;
}
.posts-products .products_item:hover .products_item--img::after {
  opacity: 1;
}
.posts-products .products_item .products_item--text .item--ttl a,
.posts-products .products_item .products_item--text .item--paint {
  color: var(--clr-main);
  font-family: var(--font-jp01-b);
  letter-spacing: 0.08em;
}
.posts-products .products_item .products_item--text .item--ttl {
  font-size: 1.375rem;
}
.posts-products .products_item .products_item--text .item--paint {
  font-size: 0.875rem;
}
.posts-products .products_item .products_item--text .item--ttl_en {
  color: var(--clr-berge2);
  font-family: var(--font-en);
  font-size: 0.75rem;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}
.posts-products .products_item .products_item--text .item--info {
  font-family: var(--font-main-r);
  font-size: 0.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .posts-products::before, .posts-products::after {
    content: "";
    width: calc((100% - 16px) / 2);
    order: 1;
  }
  .posts-products .products_item {
    padding: 0;
    margin-bottom: 24px;
    width: calc((100% - 16px) / 2);
  }
  .posts-products .products_item::before {
    content: none;
  }
  .posts-products .products_item .products_item--img {
    margin-bottom: 16px;
  }
  .posts-products .products_item .products_item--img .hover--text {
    display: none;
  }
  .posts-products .products_item .products_item--img::after {
    content: none;
  }
  .posts-products .products_item .products_item--text .item--ttl a,
  .posts-products .products_item .products_item--text .item--paint {
    color: var(--clr-main);
    font-family: var(--font-jp01-b);
    letter-spacing: 0.08em;
  }
  .posts-products .products_item .products_item--text .item--ttl {
    font-size: 1.375rem;
  }
  .posts-products .products_item .products_item--text .item--paint {
    font-size: 0.875rem;
  }
  .posts-products .products_item .products_item--text .item--ttl_en {
    color: var(--clr-berge2);
    font-family: var(--font-en);
    font-size: 0.75rem;
    letter-spacing: 0.12em;
    text-transform: uppercase;
  }
  .posts-products .products_item .products_item--text .item--info {
    font-family: var(--font-main-r);
    font-size: 0.9375rem;
  }
}

.products_filebtn {
  gap: 2.0833333333vw;
  margin-top: 40px;
}

@media screen and (max-width: 47.9375em) {
  .products_filebtn li {
    width: 80%;
  }
}

.products_filebtn .btn a {
  width: 300px;
}
@media screen and (max-width: 47.9375em) {
  .products_filebtn .btn a {
    width: 100%;
  }
}

/* products--pulldown
**************************************** */
.products--pulldown {
  margin-bottom: 56px;
}
@media screen and (max-width: 47.9375em) {
  .products--pulldown {
    margin-bottom: 32px;
  }
}

.products--select {
  position: relative;
  width: 300px;
}

.pulldown--mark {
  font-size: 1.75rem;
  margin-left: 16px;
  margin-right: 16px;
}

.products--pulldown select:focus,
.products--pulldown button:focus {
  outline: none;
}

.products--pulldown select {
  width: 100%;
  padding: 16px;
  color: var(--clr-wht);
  font-family: var(--font-jp01);
  font-size: 1.125rem;
  letter-spacing: 0.12em;
  background-color: var(--clr-br2);
  border: 1px solid var(--clr-wht);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: -webkit-center;
  text-align: center;
}

.products--select::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1em;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: var(--clr-wht) transparent transparent transparent;
  transition: all 0.4s ease-out;
  pointer-events: none;
  z-index: 1;
}

.products--pulldown option {
  color: var(--clr-main);
  font-size: 0.9375rem;
  background-color: var(--clr-wht);
  text-align: -webkit-center;
  text-align: center;
}

.products--pulldown option:focus {
  background-color: var(--clr-br);
}

.products--pulldown button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  color: var(--clr-wht);
  border: none;
  margin-left: 16px;
  border: 1px solid var(--clr-wht);
  background-color: var(--clr-br);
  transition: all 0.4s ease-out;
  cursor: pointer;
}
.products--pulldown button svg {
  fill: var(--clr-wht);
}
@media screen and (max-width: 47.9375em) {
  .products--pulldown button {
    width: 48px;
    height: 48px;
    margin-top: 16px;
    margin-left: 0;
  }
}

.products--pulldown button:hover {
  background-color: var(--clr-main);
}

/* works
**************************************** */
.archive-works main {
  width: 100%;
}

.posts-works {
  gap: 32px;
}
.posts-works::after {
  content: "";
  width: calc((100% - 64px) / 3);
  height: 0;
}
.posts-works .works_item {
  padding: 16px;
  width: calc((100% - 64px) / 3);
}
.posts-works .works_item .works_item--img {
  position: relative;
  aspect-ratio: 16/9;
  margin-bottom: 16px;
}
.posts-works .works_item .works_item--img .hover--text {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: auto;
  color: var(--clr-wht);
  font-size: 0.9375rem;
  z-index: 4;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
  transition: var(--transit-default);
}
.posts-works .works_item .works_item--img .hover--text svg .cls-1 {
  fill: var(--clr-wht);
}
.posts-works .works_item .works_item--img::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: var(--transit-default);
}
.posts-works .works_item::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--clr-berge);
  outline: 1px solid var(--clr-wht);
  outline-offset: -1px;
  opacity: 0;
  top: 0;
  left: 0;
  transition: var(--transit-default);
  z-index: -1;
}
.posts-works .works_item:hover .hover--text,
.posts-works .works_item:hover .works_item--img::after, .posts-works .works_item:hover::before {
  opacity: 1;
}
.posts-works .works_item .posttype {
  font-family: var(--font-jp01-b);
  font-size: 1rem;
}
.posts-works .works_item .item--ttl a {
  font-family: var(--font-jp01-b);
  font-size: 1.375rem;
}
.posts-works .works_item .item--info {
  font-size: 0.875rem;
  font-family: var(--font-jp01);
}
@media screen and (max-width: 47.9375em) {
  .posts-works {
    gap: 24px;
  }
  .posts-works::after {
    content: "";
    width: calc((100% - 24px) / 2);
    height: 0;
  }
  .posts-works .works_item {
    padding: 0;
    margin-bottom: 16px;
    width: calc((100% - 24px) / 2);
  }
  .posts-works .works_item::before {
    content: none;
  }
  .posts-works .works_item .works_item--img {
    margin-bottom: 16px;
  }
  .posts-works .works_item .works_item--img .hover--text {
    display: none;
  }
  .posts-works .works_item .item--ttl a {
    font-family: var(--font-jp01-b);
    font-size: 1.125rem;
  }
  .posts-works .works_item .item--info {
    font-size: 0.875rem;
    font-family: var(--font-jp01);
  }
}

/* ##############################################################################

    SEARCH

############################################################################## */
#search-list .section_pdg {
  padding-top: 50px;
  padding-bottom: 50px;
}

#search-list .ttl-01 {
  margin: 70px 0 0;
}

/* ##############################################################################

    SINGLE

############################################################################## */
/* --- アイキャッチ --- */
.eyecatch {
  margin-bottom: 2rem;
}

/* --- 目次 --- */
div#ez-toc-container {
  padding: 24px;
  background: #fafafa;
}

.ez-toc-title-container {
  margin-bottom: 10px;
}

div#ez-toc-container p.ez-toc-title {
  color: #000;
  font-weight: bold;
  font-size: 1.25rem;
}

#ez-toc-container.counter-hierarchy ul {
  font-size: 1.0625rem;
}

#ez-toc-container.counter-hierarchy ul li {
  margin-top: 8px;
}

#ez-toc-container.counter-hierarchy ul ul {
  margin-left: 1rem;
}

#ez-toc-container.counter-hierarchy ul ul a {
  position: relative;
  display: inline-block;
  padding-left: 1rem;
}

#ez-toc-container.counter-hierarchy ul ul a::before {
  content: "";
  position: absolute;
  top: 0.65rem;
  left: 0;
  width: 0.6rem;
  height: 0;
  border-bottom: 1px solid;
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}

#pass input[name=post_password] {
  padding: 5px;
  border: solid 1px #aaa;
  outline: none;
}

#pass input[type=submit] {
  padding: 4px 16px;
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
}

#pass input[type=submit]:hover {
  opacity: 0.7;
}

/* blog
**************************************** */
@media screen and (max-width: 47.9375em) {
  .blog--info .section_ttl {
    font-size: 1.75rem;
  }
}

/* blog--share
**************************************** */
.blog--share {
  margin-top: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .blog--share {
    margin-top: 40px;
  }
}

.blog--share_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.375rem; /* 6px */
}
@media screen and (max-width: 47.9375em) {
  .blog--share_list {
    grid-template-columns: repeat(2, 1fr);
  }
}

.blog--share_list a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0.5em;
  line-height: 1;
  font-size: 0.75rem; /* 12px */
  font-weight: bold;
  border: 1px solid var(--clr-main-op);
}

.blog--share_list li.twitter a {
  color: #2BA1F2;
}
.blog--share_list li.facebook a {
  color: #2477F2;
}
.blog--share_list li.line a {
  color: #27C754;
}
.blog--share_list li.pocket a {
  color: #EF4056;
}
.blog--share_list li.linkedin a {
  color: #2867B2;
}
.blog--share_list li.hatena a {
  color: #29A4DE;
}

.blog--share_list img {
  margin-right: 10px;
}

.blog--share_list a:hover {
  opacity: 0.5;
}

/* author
********************************************** */
/* この記事を書いた人 */
.mce-content-body:has(+ .writer_box) {
  margin-bottom: 2.5rem;
}

.writer_box--wrapper {
  padding-block: 40px;
}

.writer_box {
  padding: 4px 40px 24px;
  border: 1px solid var(--clr-main);
}

.writer_box:has(+ .writer_box) {
  margin-bottom: 32px;
}

.writer_box--img {
  max-width: 160px;
}

.writer_box--ttl {
  width: 100%;
  transform: translateY(-20px);
}

.writer_box--ttl span {
  display: inline-block;
  background-color: var(--clr-main);
  border-radius: 40px;
  padding: 6px 24px 8px;
  color: #fff;
  font-size: 0.875rem;
}

.writer_box--txtarea {
  flex: 1;
  margin-left: 16px;
}

.writer_name {
  font-size: 1.25rem;
}

/* blog-card
********************************************** */
/* --- blog_card --- */
.blog_card {
  position: relative;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  padding: 1.5rem;
  border: 1px solid var(--clr-blk);
}

.blog_card--caption {
  position: absolute;
  top: -1em;
  left: 32px;
  z-index: 1;
  display: inline-block;
  padding: 0 24px;
  font-size: clamp(14px, 0.8333333333vw, 18px);
  line-height: 2;
  background: #fff;
  border-radius: 100vmax;
}

.blog_card--content {
  display: grid;
  grid-template-columns: 12.5rem 1fr;
  align-items: center;
  gap: 16px;
}
@media screen and (max-width: 47.9375em) {
  .blog_card--content {
    grid-template-columns: 8.5714285714rem 1fr;
  }
}

.blog_card--img {
  aspect-ratio: 3/2;
}
.blog_card--img img {
  margin: 0;
  height: 100%;
}

.blog_card--txtarea {
  flex: 1;
}

.blog_card--ttl {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

.blog_card--txt {
  font-size: 0.875rem;
}

.clip-txt2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

/* blog--related
**************************************** */
.blog--related .post--ttl {
  font-size: 1.25rem;
}

.posts_scroll {
  overflow: auto;
  padding-bottom: 2rem;
}

.posts_scroll .post {
  display: block;
  width: 46%;
  flex-shrink: 0;
}
@media screen and (max-width: 47.9375em) {
  .posts_scroll .post {
    width: 70%;
  }
}

.posts_scroll .post:not(:last-child) {
  margin-right: 1rem;
}

.posts_scroll .post--img {
  margin-bottom: 1rem;
}

.posts_scroll .post--img::before {
  padding-top: 66.666%;
}

.posts_scroll .post--txt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.posts_scroll::-webkit-scrollbar {
  height: 6px;
}

.posts_scroll::-webkit-scrollbar-thumb {
  background-color: #999;
}

.posts_scroll::-webkit-scrollbar-track-piece {
  background-color: #eee;
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi {
    margin-top: 32px;
  }
}

.archive-works .wp-pagenavi,
.archive-products .wp-pagenavi {
  margin-top: 0;
}

.wp-pagenavi .pages {
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
}

.wp-pagenavi a {
  position: relative;
  padding: 0.5em 1em;
  background-color: var(--clr-br);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wp-pagenavi a:hover {
  opacity: 0.7;
}

.wp-pagenavi a {
  color: #fff;
}

.wp-pagenavi > *:not(.pages) {
  max-width: 38.4615384615%;
}

.archive .wp-pagenavi > *:not(.pages) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
@media screen and (max-width: 47.9375em) {
  .archive .wp-pagenavi > *:not(.pages) {
    width: 24px;
    height: 30px;
  }
}

.wp-pagenavi-single a[rel=prev],
.wp-pagenavi-single a[rel=next] {
  width: 40px;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi-single a[rel=prev],
  .wp-pagenavi-single a[rel=next] {
    width: 30px;
  }
}

.wp-pagenavi > *:not(.pages) {
  margin-bottom: 4px;
}

.wp-pagenavi > *:not(.pages):not(:last-child) {
  margin-right: 4px;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi > *:not(.pages):not(:last-child) {
    margin-right: 2px;
  }
}

.wp-pagenavi .extend {
  width: 24px !important;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-top: 2px solid;
  border-right: 2px solid;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 8px;
  height: 8px;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}

.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=prev]::before {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=next]::before {
  transform: rotate(-135deg);
}

.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: -6px;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before {
    left: -5px;
  }
}

.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: -6px;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    right: -5px;
  }
}

@media screen and (max-width: 47.9375em) {
  .wp-pagenavi-single a[rel=prev],
  .wp-pagenavi-single a[rel=next] {
    width: 32px;
  }
}
/* products
**************************************** */
.single-products .page_ttl {
  margin-bottom: 80px;
}

.products_single {
  margin-bottom: 120px;
}

@media screen and (max-width: 47.9375em) {
  .single-products .page_ttl {
    margin-bottom: 8px;
  }
  .products_single {
    margin-bottom: 60px;
  }
}
/* --- prodcts_info --- */
.products_info {
  margin-bottom: 100px;
}
.products_info .products_info--img {
  width: 55.12%;
  max-width: 840px;
}
.products_info .product-photo {
  margin-bottom: 20px;
}
.products_info .product-photo .product-photo--item {
  aspect-ratio: 840/546;
}
.products_info .product-thumb .slick-track {
  margin-left: 0;
}
.products_info .product-thumb .product-thumb--item {
  width: 60px !important;
  margin-right: 20px;
  margin-bottom: 20px;
  aspect-ratio: 1/1;
  transition: var(--transit-default);
}
.products_info .product-thumb .product-thumb--item.slick-current {
  outline: 1px solid #fff;
  outline-offset: -1px;
}
.products_info .product-thumb .product-thumb--item:hover {
  opacity: 0.8;
}
.products_info .product-thumb .product-thumb--item img {
  width: 100%;
  max-width: inherit;
  height: 100%;
  object-fit: cover;
}
.products_info .products_info--dtl {
  width: 40%;
  max-width: 610px;
  padding-top: 16px;
}
.products_info .products_info--dtl .products--ttl {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  font-family: var(--font-jp01-b);
  font-size: 1.875rem;
  letter-spacing: 0.12em;
  line-height: 1.2;
}
.products_info .products_info--dtl .products--ttl span {
  margin-bottom: 8px;
}
.products_info .products_info--dtl .products--ttl .products--paint {
  padding-bottom: 0.25em;
  font-size: 1rem;
  letter-spacing: 0.08em;
  line-height: 1;
}
.products_info .products_info--dtl .products--ttl_en {
  font-family: var(--font-en);
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--clr-berge2);
  letter-spacing: 0.12em;
}
.products_info .products_info--dtl .favorite--link_ttl {
  position: absolute;
  right: 0;
  bottom: 4px;
  min-width: 160px;
  font-size: 0.75rem;
  font-family: var(--font-jp01-b);
  text-align: right;
}
.products_info .products_info--dtl .favorite--link_ttl a {
  display: flex;
  align-items: center;
  color: var(--clr-main);
}
.products_info .products_info--dtl .favorite--link_ttl a:hover {
  opacity: 0.7;
}
.products_info .products_info--dtl .products--info_txt p {
  letter-spacing: 0.1em;
  line-height: 1.75;
}
.products_info .products_info--dtl .products--standard {
  background-color: var(--clr-berge3);
  padding: 32px;
}
.products_info .products_info--dtl .products--standard .standard--ttl {
  font-size: 1.125rem;
  line-height: 1;
  padding-bottom: 15px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--clr-wht2);
}
.products_info .products_info--dtl .products--standard .standard--txt {
  font-family: var(--font-main-r);
  font-size: 0.9375rem;
  letter-spacing: 0.06em;
}
.products_info .products_info--dtl .products--standard .standard--attention {
  font-family: var(--font-main-r);
  font-size: 0.9375rem;
  color: var(--clr-gr);
  letter-spacing: 0.06em;
}
@media screen and (max-width: 47.9375em) {
  .products_info {
    margin-top: 24px;
    margin-bottom: 40px;
  }
  .products_info .products_info--img {
    width: 100%;
  }
  .products_info .product-photo {
    margin-bottom: 10px;
  }
  .products_info .product-thumb .slick-track {
    width: 100% !important;
    margin-right: 0;
  }
  .products_info .product-thumb .product-thumb--item {
    width: 40px !important;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .products_info .products_info--dtl {
    width: 100%;
  }
  .products_info .products_info--dtl .products--ttl {
    font-size: 1.625rem;
  }
  .products_info .products_info--dtl .products--ttl_en {
    font-size: 0.875rem;
    padding-right: 0;
  }
  .products_info .products_info--dtl .favorite--link_ttl {
    position: static;
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .products_info .products_info--dtl .products--standard .standard--ttl {
    font-size: 1rem;
    margin-bottom: 16px;
  }
}

/* --- prodcts_case --- */
.products_case {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 32px;
}
.products_case .ttlarea {
  position: absolute;
  top: 15px;
  left: 0;
}
.products_case .ttlarea .case--ttl_en {
  opacity: 0.7;
}
.products_case .ttlarea .case--ttl {
  margin-bottom: 24px;
  letter-spacing: 0.12em;
}
.products_case .ttlarea .case--ttl_deco {
  position: relative;
  display: block;
  width: 5px;
  height: 5px;
  margin-bottom: 60px;
  background-color: var(--clr-wht);
  border-radius: 50vw;
}
.products_case .ttlarea .case--ttl_deco::before, .products_case .ttlarea .case--ttl_deco::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: var(--clr-wht);
  border-radius: 50vw;
}
.products_case .ttlarea .case--ttl_deco::before {
  left: 200%;
}
.products_case .ttlarea .case--ttl_deco::after {
  left: 400%;
}
.products_case .case-thumb {
  width: 41.8635%;
  max-width: 638px;
  margin-right: 20px;
}
.products_case .case-thumb.len-3, .products_case .case-thumb.len-4 {
  width: 28.346%;
  max-width: 432px;
}
.products_case .case-thumb.len-3 .slick-track .case-thumb--item, .products_case .case-thumb.len-4 .slick-track .case-thumb--item {
  width: calc((100% - 10px) / 2) !important;
}
.products_case .case-thumb .slick-track {
  display: flex;
  justify-content: right;
  flex-wrap: wrap-reverse;
  width: 100% !important;
  gap: 10px;
}
.products_case .case-thumb .slick-track::before, .products_case .case-thumb .slick-track::after {
  content: none;
}
.products_case .case-thumb .case-thumb--item {
  width: calc((100% - 20px) / 3) !important;
  aspect-ratio: 212/144;
  transition: var(--transit-default);
}
.products_case .case-thumb .case-thumb--item:hover {
  opacity: 0.8;
}
.products_case .case-photo {
  width: 43.75vw;
  max-width: 840px;
}
.products_case .case-photo .case-photo--item {
  aspect-ratio: 840/546;
}
@media screen and (max-width: 47.9375em) {
  .products_case {
    flex-direction: column;
    margin-bottom: 32px;
  }
  .products_case .ttlarea {
    position: static;
    width: 100%;
  }
  .products_case .ttlarea .case--ttl_en {
    opacity: 0.7;
  }
  .products_case .ttlarea .case--ttl {
    margin-bottom: 24px;
    letter-spacing: 0.12em;
  }
  .products_case .ttlarea .case--ttl_deco {
    width: 5px;
    height: 5px;
    margin-bottom: 32px;
  }
  .products_case .case-thumb {
    width: 100%;
    margin-right: 0;
    order: 1;
  }
  .products_case .case-thumb.len-3, .products_case .case-thumb.len-4 {
    width: calc((100% - 16px) / 3);
    max-width: inherit;
  }
  .products_case .case-thumb .slick-track {
    justify-content: space-between;
  }
  .products_case .case-thumb .slick-track .case-thumb--item {
    width: calc((100% - 16px) / 3) !important;
    margin-bottom: 8px;
  }
  .products_case .case-thumb .slick-track {
    gap: 0;
  }
  .products_case .case-thumb .case-thumb--item {
    width: calc((100% - 20px) / 3) !important;
    aspect-ratio: 212/144;
  }
  .products_case .case-photo {
    width: 100%;
    margin-bottom: 8px;
  }
}

.products_btn--box {
  margin-top: 120px;
}
@media screen and (max-width: 47.9375em) {
  .products_btn--box {
    margin-top: 60px;
  }
}

.single-thumb li {
  cursor: pointer;
}

.single-thumb .slick-track {
  transform: unset !important;
}

/* --- products_works --- */
.products_works {
  padding-top: 40px;
}
.products_works .editor-ttl {
  font-size: 1.25rem;
  padding-left: 2.5rem;
  margin-bottom: 24px;
}
.products_works .editor-ttl::before {
  width: 1.75rem;
}
@media screen and (max-width: 47.9375em) {
  .products_works {
    width: 100%;
    padding-top: 16px;
  }
  .products_works .editor-ttl {
    font-size: 1.125rem;
    padding-left: 2rem;
    margin-bottom: 16px;
  }
  .products_works .editor-ttl::before {
    top: 0.75em;
    width: 1.25rem;
  }
}

.use_works {
  gap: 1.25vw;
}
.use_works .use_works--item {
  width: calc((100% - 2.5vw) / 3);
  padding: 12px;
}
.use_works .use_works--item::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--clr-berge);
  outline: 1px solid var(--clr-wht);
  outline-offset: -1px;
  opacity: 0;
  top: 0;
  left: 0;
  transition: var(--transit-default);
  z-index: -1;
}
.use_works .use_works--item:hover::before {
  opacity: 1;
}
.use_works .use_works--item .use_works--img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  aspect-ratio: 212/144;
}
.use_works .use_works--item .use_works--img .hover--text {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: auto;
  color: var(--clr-wht);
  font-size: 0.9375rem;
  z-index: 4;
  opacity: 0;
  transition: var(--transit-default);
}
.use_works .use_works--item .use_works--img .hover--text svg .cls-1 {
  fill: var(--clr-wht);
}
.use_works .use_works--item .use_works--img::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 2;
  opacity: 0;
  transition: var(--transit-default);
}
.use_works .use_works--item:hover .hover--text {
  opacity: 1;
}
.use_works .use_works--item:hover .use_works--img::after {
  opacity: 1;
}
.use_works .use_works--item .use_works--text .item--ttl {
  font-size: 1.125rem;
  margin-bottom: 4px;
}
.use_works .use_works--item .use_works--text .item--ttl a {
  color: var(--clr-main);
}
.use_works .use_works--item .use_works--text .item-deta {
  font-size: 0.875rem;
}
@media screen and (max-width: 47.9375em) {
  .use_works {
    gap: 16px;
  }
  .use_works .use_works--item {
    width: calc((100% - 16px) / 2);
    padding: 0;
  }
  .use_works .use_works--item::before {
    content: none;
  }
  .use_works .use_works--item .use_works--img .hover--text {
    display: none;
  }
  .use_works .use_works--item .use_works--img::after {
    content: none;
  }
  .use_works .use_works--item .use_works--text .item--ttl {
    font-size: 1rem;
  }
}

/* works
**************************************** */
.works_info {
  width: 94.5%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.works_info--img {
  margin-bottom: 72px;
}
.works_info--img .works-photo {
  margin-bottom: 10px;
}
.works_info--img .works-photo .slick-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  transform: rotate(45deg) skew(5deg, 5deg);
}
.works_info--img .works-photo .slick-arrow.slick-prev {
  border-bottom: 1px solid var(--clr-main);
  border-left: 1px solid var(--clr-main);
  left: -40px;
}
.works_info--img .works-photo .slick-arrow.slick-next {
  border-top: 1px solid var(--clr-main);
  border-right: 1px solid var(--clr-main);
  right: -40px;
}
.works_info--img .works-photo .works-photo--item {
  aspect-ratio: 1440/720;
  background-color: var(--clr-br4);
}
.works_info--img .works-photo .works-photo--item img {
  width: 100%;
  height: 100%;
}
.works_info--img .works-thumb .slick-track {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: 0;
  transform: unset !important;
  width: 100% !important;
}
.works_info--img .works-thumb .works-thumb--item {
  float: none;
  width: 96px !important;
  aspect-ratio: 96/68;
  margin-left: 10px;
  margin-bottom: 10px;
  background-color: var(--clr-wht);
}
.works_info--img .works-thumb .works-thumb--item.slick-current {
  opacity: 0.6;
  outline: 1px solid #fff;
  outline-offset: -1px;
}

@media screen and (max-width: 47.9375em) {
  .works_info {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .works_info--img {
    margin-bottom: 36px;
  }
  .works_info--img .works-photo {
    margin-bottom: 4px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .works_info--img .works-photo .slick-arrow {
    width: 15px;
    height: 15px;
  }
  .works_info--img .works-photo .slick-arrow.slick-prev {
    left: -16px;
  }
  .works_info--img .works-photo .slick-arrow.slick-next {
    right: -16px;
  }
  .works_info--img .works-thumb .works-thumb--item {
    width: calc((100% - 24px) / 6) !important;
    margin: 0 2px 4px;
    aspect-ratio: 16/9;
  }
}
/* --- works_info--txt --- */
.works_info--txt {
  margin-bottom: 100px;
}
.works_info--txt h2 {
  max-width: 720px;
  letter-spacing: 0.2em;
  line-height: 1.785;
}
.works_info--txt p {
  max-width: 820px;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 47.9375em) {
  .works_info--txt {
    margin-bottom: 50px;
  }
  .works_info--txt h2 {
    font-size: 1.375rem;
  }
}

/* --- works_info--products --- */
.works_info--products {
  margin-bottom: 80px;
}

.use_products {
  gap: 24px;
}
.use_products .use_products--item {
  width: 244px;
  padding: 12px;
}
.use_products .use_products--item::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--clr-berge);
  outline: 1px solid var(--clr-wht);
  outline-offset: -1px;
  opacity: 0;
  top: 0;
  left: 0;
  transition: var(--transit-default);
  z-index: -1;
}
.use_products .use_products--item:hover::before {
  opacity: 1;
}
.use_products .use_products--item .use_products--img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  aspect-ratio: 1/1;
}
.use_products .use_products--item .use_products--img .hover--text {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: auto;
  color: var(--clr-wht);
  font-size: 0.9375rem;
  z-index: 4;
  opacity: 0;
  transition: var(--transit-default);
}
.use_products .use_products--item .use_products--img .hover--text svg .cls-1 {
  fill: var(--clr-wht);
}
.use_products .use_products--item .use_products--img::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 2;
  opacity: 0;
  transition: var(--transit-default);
}
.use_products .use_products--item:hover .hover--text {
  opacity: 1;
}
.use_products .use_products--item:hover .use_products--img::after {
  opacity: 1;
}
.use_products .use_products--item .use_products--text .item--ttl {
  font-size: 1.125rem;
  margin-bottom: 4px;
}
.use_products .use_products--item .use_products--text .item--ttl a {
  color: var(--clr-main);
}
.use_products .use_products--item .use_products--text .item-deta {
  font-size: 0.875rem;
}

@media screen and (max-width: 47.9375em) {
  .works_info--products {
    margin-bottom: 40px;
  }
  .use_products {
    gap: 16px;
  }
  .use_products .use_products--item {
    width: calc((100% - 16px) / 2);
    padding: 6px;
  }
  .use_products .use_products--item::before {
    content: none;
  }
  .use_products .use_products--item .use_products--img .hover--text {
    display: none;
  }
  .use_products .use_products--item .use_products--img::after {
    content: none;
  }
  .use_products .use_products--item .use_products--text .item--ttl {
    font-size: 1rem;
    margin-bottom: 4px;
  }
  .use_products .use_products--item .use_products--text .item-deta {
    font-size: 0.875rem;
  }
}
/* --- works_info--relation --- */
.works_info--relation {
  margin-bottom: 120px;
}
.works_info--relation dl {
  gap: 16px;
  padding: 16px 24px;
  border-bottom: 1px solid var(--clr-main-op);
}
.works_info--relation dl dt {
  width: 200px;
  font-family: var(--font-jp01-b);
  letter-spacing: 0.12em;
}
.works_info--relation dl dd {
  font-size: 0.9375rem;
  font-family: var(--font-jp01);
  letter-spacing: 0.12em;
}
.works_info--relation dl dd a {
  display: block;
}
.works_info--relation dl dd a + a {
  margin-top: 4px;
}
@media screen and (max-width: 47.9375em) {
  .works_info--relation {
    margin-bottom: 48px;
  }
  .works_info--relation dl {
    gap: 16px;
    padding: 16px 12px;
  }
  .works_info--relation dl dt {
    width: 120px;
  }
}

/* --- category--link --- */
.category--link {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.category--link a {
  display: block;
  position: relative;
  padding-right: 16px;
  padding-left: 32px;
  min-width: 140px;
  color: var(--clr-main);
  font-size: 0.9375rem;
  letter-spacing: 0.12em;
  line-height: 32px;
  text-align: center;
  border: 1px solid var(--clr-main-op);
}
.category--link a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;
  margin: auto;
  width: 5px;
  height: 5px;
  border-radius: 50vw;
  background-color: var(--clr-main);
  transition: var(--transit-default);
}
.category--link a:hover {
  color: var(--clr-wht);
  background-color: var(--clr-br3);
}
.category--link a:hover::before {
  background-color: var(--clr-wht);
}
@media screen and (max-width: 47.9375em) {
  .category--link {
    gap: 8px;
  }
  .category--link a {
    padding-right: 16px;
    padding-left: 24px;
    min-width: inherit;
    font-size: 0.8125rem;
    line-height: 28px;
  }
  .category--link a::before {
    left: 10px;
  }
}

/* sitemap
*************************************************** */
.sitemap--menu {
  flex: 1;
}

.sitemap--menu a,
.sitemap--menu span {
  display: block;
}

.sitemap--menu a {
  position: relative;
}

.sitemap--menu a:hover {
  color: var(--clr-main);
}

.sitemap--menu > li > a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.125rem; /* 18px */
  border-bottom: 1px solid var(--clr-main-op);
  padding-bottom: 1em;
  padding-left: 1.25em;
  padding-right: 1.25em;
  margin-bottom: 1.25em;
}

.sitemap_sub_nav--wrap {
  padding-left: 1.25em;
  margin-bottom: 1.5em;
}

.sitemap_sub_nav a {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  padding-left: 1.25em;
}

.sitemap_sub_nav a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 1px solid var(--clr-main);
  border-right: 1px solid var(--clr-main);
  transform: rotate(45deg);
}

@media print, screen and (min-width: 48em) {
  .sitemap--menu:not(:last-child) {
    margin-right: 7.41%; /* 80px */
  }
}
@media screen and (max-width: 47.9375em) {
  .sitemap--menu:not(:last-child) {
    margin-bottom: 24px;
  }
}
/* lps_parts--button
********************************************** */
.lps_parts--button .btn:first-child {
  margin-left: auto;
}

.lps_parts--button .btn:last-child {
  margin-right: auto;
}

@media print, screen and (min-width: 48em) {
  .lps_parts--column.column-2 + .lps_parts--button,
  .lps_parts--column.column-3 + .lps_parts--button,
  .lps_parts--column.column-4 + .lps_parts--button {
    margin-top: 0;
  }
}
/* lps_parts--column
********************************************** */
.lps_parts--column:not(.column-1) + .lps_parts--column {
  margin-top: 24px;
}

.lps_parts--column .column_item {
  display: flex;
  flex-direction: column;
}

.lps_parts--column .column_item.bg-wh {
  background-color: rgba(255, 255, 255, 0.4);
}

.lps_parts--column .text {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.lps_parts--column .img {
  flex-shrink: 0;
}

.lps_parts--column .img + .text {
  padding-top: 40px;
}

.lps_parts--column .bg-wh .text {
  padding: 6%;
}

.lps_parts--column .flex-col1 .bg-wh .text {
  padding: 4% 6%;
}

.lps_parts--column .flex-col3 .bg-wh .text,
.lps_parts--column .flex-col4 .bg-wh .text {
  padding: 8%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column .flex-col3 .bg-wh .text,
  .lps_parts--column .flex-col4 .bg-wh .text {
    padding: 8% 6%;
  }
}

.lps_parts--column:not(.column-1) table {
  width: 100%;
}

.lps_parts--column .mce-content-body:not(:last-child) {
  margin-bottom: 24px;
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column,
  .lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: 24px;
  }
  .lps_parts--column .lps_parts--child {
    display: block;
  }
  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }
  .lps_parts--column .column_item {
    width: 100%;
  }
  .lps_parts--column .column_item:not(:last-child) {
    margin-bottom: 24px;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
  .lps_parts--column .column_item:not(:last-child) {
    margin-right: 0;
  }
  .lps_parts--column .text.bg-wh,
  .lps_parts--column.column-1 .box {
    padding: 24px;
  }
}
/* post-edit-link
********************************************** */
.post-edit-link {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 10;
  padding: 16px;
  border-radius: 50%;
  border: 1px solid #999;
}
@media screen and (max-width: 47.9375em) {
  .post-edit-link {
    display: none;
  }
}
.post-edit-link:hover {
  background-color: #ddd;
}
.post-edit-link::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuODrOOCpOODpOODvF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDAxIDQwMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAxIDQwMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM5OTk5OTk7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzcwLjEsMjUxLjljLTUuNSwwLTEwLDQuNS0xMCwxMHY4OC43YzAsMTYuNS0xMy40LDI5LjktMzAsMzBINDkuOWMtMTYuNSwwLTI5LjktMTMuNC0zMC0zMFY5MC4zCgljMC0xNi41LDEzLjQtMjkuOSwzMC0zMGg4OC43YzUuNSwwLDEwLTQuNSwxMC0xMGMwLTUuNS00LjUtMTAtMTAtMTBINDkuOUMyMi40LDQwLjQsMCw2Mi43LDAsOTAuM3YyNjAuMwoJYzAsMjcuNiwyMi40LDQ5LjksNDkuOSw0OS45aDI4MC4yYzI3LjYsMCw0OS45LTIyLjQsNDkuOS00OS45di04OC43QzM4MC4xLDI1Ni40LDM3NS42LDI1MS45LDM3MC4xLDI1MS45eiIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzc2LjEsMTQuN2MtMTcuNi0xNy42LTQ2LTE3LjYtNjMuNiwwTDEzNC40LDE5Mi45Yy0xLjIsMS4yLTIuMSwyLjctMi42LDQuNGwtMjMuNCw4NC42Yy0xLDMuNSwwLDcuMiwyLjYsOS43CgljMi41LDIuNSw2LjMsMy41LDkuNywyLjZsODQuNi0yMy40YzEuNy0wLjUsMy4yLTEuMyw0LjQtMi42TDM4Ny45LDkwYzE3LjUtMTcuNiwxNy41LTQ2LDAtNjMuNkwzNzYuMSwxNC43eiBNMTU2LjIsMTk5LjNMMzAyLDUzLjUKCWw0Nyw0N0wyMDMuMiwyNDYuNEwxNTYuMiwxOTkuM3ogTTE0Ni44LDIxOC4ybDM3LjYsMzcuNmwtNTIsMTQuNEwxNDYuOCwyMTguMnogTTM3My43LDc1LjhsLTEwLjYsMTAuNmwtNDctNDdsMTAuNi0xMC42CgljOS43LTkuNywyNS42LTkuNywzNS4zLDBsMTEuNywxMS43QzM4My41LDUwLjMsMzgzLjUsNjYuMSwzNzMuNyw3NS44eiIvPgo8L3N2Zz4K");
}

/* lps_parts--faq
********************************************** */
.lps_parts--faq .inner {
  max-width: 1380px;
}

.lps_parts--faq .faq_item {
  border-top: 1px solid var(--clr-wht);
}

.lps_parts--faq .faq_item:last-child {
  border-bottom: 1px solid var(--clr-wht);
}

.lps_parts--faq .ttl-03,
.lps_parts--faq .mce-content-body {
  display: flex;
  align-items: center;
  padding: 35px 150px 35px 240px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03,
  .lps_parts--faq .mce-content-body {
    padding: 16px 24px 16px 56px;
  }
}

.lps_parts--faq .ttl-03 {
  min-height: 150px;
  padding-right: 260px;
  margin-bottom: 0 !important;
  font-size: 1.125rem;
  line-height: 1.8888888889;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03 {
    min-height: 100px;
    font-size: 1rem;
    padding-right: 32px;
  }
}

.lps_parts--faq .mce-content-body {
  display: none;
  position: relative;
  font-size: 0.9375rem;
  background-color: rgba(255, 255, 255, 0.4);
  border-top: 1px solid var(--clr-wht);
  padding-top: 60px;
  padding-bottom: 60px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .mce-content-body {
    font-size: 0.875rem;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.lps_parts--faq .ttl-03::after,
.lps_parts--faq .mce-content-body::after {
  position: absolute;
  top: 38px;
  left: 112px;
  display: flex;
  justify-content: center;
  text-indent: 1px;
  line-height: 78px;
  width: 72px;
  height: 72px;
  font-size: 1.5625rem;
  font-family: var(--font-en);
  font-weight: 400;
  background-color: var(--clr-main);
  border-radius: 50vw;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03::after,
  .lps_parts--faq .mce-content-body::after {
    top: 16px;
    left: 10px;
    width: 30px;
    height: 30px;
    font-size: 1rem;
    line-height: 34px;
  }
}

.lps_parts--faq .ttl-03 span:last-child::before,
.lps_parts--faq .ttl-03 span:last-child::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 170px;
  margin: auto;
  width: 16px;
  height: 2px;
  background-color: var(--clr-berge7);
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03 span:last-child::before,
  .lps_parts--faq .ttl-03 span:last-child::after {
    width: 12px;
    height: 1px;
    right: 10px;
  }
}

.lps_parts--faq .ttl-03 span:last-child::after {
  transform: rotate(-90deg);
}

.lps_parts--faq .ttl-03.active span:last-child::after {
  transform: rotate(0);
}

.lps_parts--faq .ttl-03::after {
  content: "Q";
  color: #fff;
}

.lps_parts--faq .mce-content-body::after {
  content: "A";
  background-color: transparent;
  border: 1px solid var(--clr-main);
  color: var(--clr-main);
}

.lps_parts--faq .mce-content-body .editor-ttl2 {
  font-size: 1.125rem;
  margin-top: 0;
  margin-bottom: 8px;
}

.lps_parts--faq .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .text img {
    float: none !important;
    margin-top: 2px;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 8px !important;
    border-radius: 8px;
  }
}

.bg-ptn .lps_parts--faq .ttl-03::after {
  color: var(--clr-main);
  background-color: var(--clr-wht);
}
.bg-ptn .lps_parts--faq .mce-content-body {
  background-color: rgba(255, 255, 255, 0.1);
}
.bg-ptn .lps_parts--faq .mce-content-body::after {
  color: var(--clr-wht);
  border-color: var(--clr-wht);
}
.bg-ptn .lps_parts--faq .ttl-03 span:last-child::before,
.bg-ptn .lps_parts--faq .ttl-03 span:last-child::after {
  background-color: var(--clr-wht);
}

/* lps_parts--flow
********************************************** */
.lps_parts--flow .flow_item {
  position: relative;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 86px;
  counter-increment: number;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item {
    padding-left: 48px;
  }
}

.lps_parts--flow .flow_item {
  padding-bottom: 40px;
}

.lps_parts--flow .ttl-03 {
  font-size: 2rem; /* 32px */
  color: var(--clr-main);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .ttl-03 {
    font-size: 1.429rem; /* 20px */
  }
}
.lps_parts--flow .ttl-03::after {
  content: counter(number);
  position: absolute;
  top: -8px;
  left: -80px;
  display: flex;
  justify-content: center;
  width: 64px;
  height: 64px;
  line-height: 67px;
  text-indent: 3px;
  border-radius: 50%;
  text-align: center;
  color: var(--clr-main);
  border: 1px solid var(--clr-main);
  font-family: var(--font-en);
  font-weight: 600;
  font-size: 1.625rem; /* 26px */
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .ttl-03::after {
    top: -6px;
    left: -48px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 1.286rem; /* 18px */
  }
}

.lps_parts--flow .flow_item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 64px;
  left: 36px;
  display: block;
  width: 0;
  height: calc(100% - 80px);
  border-right: 1px solid var(--clr-main);
  opacity: 0.6;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child)::before {
    top: 40px;
    left: 18px;
    height: calc(100% - 56px);
  }
}

.lps_parts--flow .txt-wh .ttl-03 {
  color: #fff;
}

.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: #fff;
}

.lps_parts--flow .txt-wh .ttl-03::after {
  color: var(--clr-main);
  background-color: #fff;
}

.lps_parts--flow .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .text img {
    float: none !important;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 16px !important;
  }
}
.bg-ptn .lps_parts--flow .ttl-03 {
  color: var(--clr-wht);
}
.bg-ptn .lps_parts--flow .ttl-03::after {
  color: var(--clr-wht);
  border: 1px solid var(--clr-wht);
}
.bg-ptn .lps_parts--flow .flow_item:not(:last-child)::before {
  border-color: var(--clr-wht);
}

/* lps_parts--flow
********************************************** */
.lps_parts--flow-original .inner {
  max-width: 1380px;
}

.lps_parts--flow-original table {
  width: 100%;
  height: 100%;
}
.lps_parts--flow-original table thead th {
  padding-bottom: 8px;
}
.lps_parts--flow-original table thead th .th_box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-jp01);
  font-size: 0.9375rem;
  letter-spacing: 0.12em;
  line-height: 1;
}
.lps_parts--flow-original table thead th .th_box::after {
  content: "";
  position: absolute;
  top: 8px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 72px;
  height: 72px;
  border-radius: 50vw;
  background-color: var(--clr-wht);
  opacity: 0.5;
  z-index: -1;
}
.lps_parts--flow-original table thead th .th_box img {
  width: 38px;
  height: 38px;
  object-fit: contain;
  margin-bottom: 8px;
}
.lps_parts--flow-original table tbody tr {
  position: relative;
  border-bottom: 1px solid var(--clr-main);
}
.lps_parts--flow-original table tbody tr:not(:first-of-type)::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 60px;
  height: 20px;
  clip-path: polygon(0 0, 100% 0, 50% 50%);
  background-color: var(--clr-main);
}
.lps_parts--flow-original table tbody th,
.lps_parts--flow-original table tbody td {
  position: relative;
  padding: 40px 3.333vw;
  vertical-align: middle;
}
.lps_parts--flow-original table tbody th::after,
.lps_parts--flow-original table tbody td::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 1px;
  height: calc(100% - 80px);
  background-color: var(--clr-berge3);
}
.lps_parts--flow-original table tbody th {
  padding: 40px 3.333vw 40px 1.666vw;
}
.lps_parts--flow-original table tbody th .step_th {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  line-height: 1.8;
}
.lps_parts--flow-original table tbody th .step_th .step_num {
  width: 5.1vw;
  height: 5.1vw;
  max-width: 98px;
  min-width: 75px;
  max-height: 98px;
  min-height: 75px;
  margin-right: 5.2vw;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 0.75;
  color: var(--clr-body);
  border: 1px solid var(--clr-body);
  border-radius: 50vw;
  aspect-ratio: 1/1;
}
.lps_parts--flow-original table tbody th .step_th .step_num .small {
  font-size: 0.75rem;
  margin-top: 4px;
  margin-bottom: 8px;
}
.lps_parts--flow-original table tbody th .step_th .text {
  font-family: var(--font-jp01);
  letter-spacing: 0.16em;
  text-align: left;
}
.lps_parts--flow-original table tbody th .step_th .text span {
  font-size: 80%;
}
.lps_parts--flow-original table tbody td {
  width: 21.77vw;
}
.lps_parts--flow-original table tbody td .step_td {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding: 16px 24px;
  background-color: rgba(255, 255, 255, 0.5);
  font-family: var(--font-main-r);
  text-align: center;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow-original {
    position: relative;
  }
  .lps_parts--flow-original .flow--table table thead {
    position: sticky;
    top: 0;
    z-index: 99;
  }
  .lps_parts--flow-original .flow--table table thead tr {
    padding-top: 80px;
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn01.png);
    background-repeat: repeat;
  }
  .lps_parts--flow-original .flow--table table thead td {
    width: 26%;
  }
  .lps_parts--flow-original .flow--table table thead th .th_box * {
    z-index: 2;
  }
  .lps_parts--flow-original .flow--table table thead th .th_box::after {
    z-index: 1;
  }
  .lps_parts--flow-original .flow--table table thead .flow-original--th {
    padding-top: 80px;
    padding-bottom: 24px;
  }
  .lps_parts--flow-original .flow--table table tbody tr {
    border-bottom: none;
  }
  .lps_parts--flow-original .flow--table table tbody tr:last-of-type td {
    border-bottom: none;
  }
  .lps_parts--flow-original .flow--table table tbody tr:not(:first-of-type)::after {
    left: inherit;
    right: 37%;
    translate: 30px 0;
  }
  .lps_parts--flow-original .flow--table table tbody .step_ttl {
    position: absolute;
    width: 100%;
    height: 100px;
    padding: 30px 0 0;
  }
  .lps_parts--flow-original .flow--table table tbody .step_ttl .step_th {
    justify-content: space-between;
  }
  .lps_parts--flow-original .flow--table table tbody .step_ttl .step_th .text {
    font-family: var(--font-jp01-b);
    font-size: 1rem;
    padding: 12px;
    margin: 0 12px;
    background-color: rgba(255, 255, 255, 0.7);
    text-align: center;
    width: 74%;
  }
  .lps_parts--flow-original .flow--table table tbody td {
    width: 37%;
    padding: 120px 1.25vw 20px;
    border-bottom: 1px solid #827762;
  }
  .lps_parts--flow-original .flow--table table tbody td::after {
    content: "";
    height: 100%;
  }
  .lps_parts--flow-original .flow--table table tbody td:first-of-type::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 1px;
    height: 100%;
    background-color: var(--clr-berge3);
  }
  .lps_parts--flow-original .flow--table table tbody td .step_td {
    font-size: 0.8125rem;
    padding: 0;
    background-color: transparent;
  }
  .lps_parts--flow-original .flow--table table tbody th {
    padding: 40px 3.333vw 40px 1.666vw;
  }
  .lps_parts--flow-original .flow--table table tbody th .step_th {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    line-height: 1.8;
  }
  .lps_parts--flow-original .flow--table table tbody th .step_th .step_num {
    width: 5.1vw;
    max-width: 98px;
    min-width: 72px;
    margin-right: 5.2vw;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 0.75;
    color: var(--clr-body);
    border: 1px solid var(--clr-body);
    border-radius: 50vw;
    aspect-ratio: 1/1;
  }
  .lps_parts--flow-original .flow--table table tbody th .step_th .step_num .small {
    font-size: 0.75rem;
    margin-top: 4px;
    margin-bottom: 8px;
  }
  .lps_parts--flow-original .flow--table table tbody th .step_th .text {
    font-family: var(--font-jp01);
    letter-spacing: 0.16em;
    text-align: left;
  }
}

/* ##############################################################################

    Loops モジュール

############################################################################## */
@media print, screen and (min-width: 48em) {
  .lps_page .inner:not(.txt-ctr) {
    margin-left: 5.20833vw;
  }
}
.lps_parts--img_text .inner:not(.inner-lg) {
  align-items: center;
}
.lps_parts--img_text .inner:not(.inner-lg):not(.inner-sm) {
  max-width: 1400px;
}

.lps_parts--column .inner:not(.inner-lg):not(.inner-sm) {
  max-width: 1524px;
}

.btn .icon-svg {
  fill: var(--clr-main);
}

.icon-new_tab {
  fill: var(--clr-main);
  width: 14px;
  height: 14px;
}

.btn .icon-new_tab {
  right: 30px;
}

.icon-pdf {
  width: 27px;
  height: 12px;
}

.lps_sec {
  position: relative;
}
.lps_sec.contact {
  padding-bottom: 80px;
}

.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: right bottom 80px;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .lps_sec-bg {
    top: inherit;
    bottom: -80px;
    background-position: right bottom;
    background-size: 60% auto;
  }
}

@media screen and (max-width: 767px) {
  .btn .icon-new_tab {
    right: 24px;
  }
}
/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}

.lps_parts .img-detail::before {
  padding-top: 66.666%;
}

.lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
  margin-top: 80px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts + .ttl-02,
  .lps_parts--button + .ttl-02,
  .lps_parts--button + .lps_parts,
  .lps_parts + .lps_parts {
    margin-top: 40px;
  }
}

.lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
  margin-top: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column,
  .lps_parts + .lps_parts--button {
    margin-top: 16px;
  }
}

.lps_parts--button .lps_parts--child > * {
  text-align: center;
}

.lps_parts iframe {
  width: 100%;
}

.lps_parts .text:not(:last-child) {
  margin-bottom: 24px;
}

.lps_parts--column .btn,
.lps_parts--slide .btn {
  display: flex;
  text-align: center;
}

.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}

.lps_parts--column .btn a {
  margin-left: auto;
  margin-right: auto;
}

.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts .mce-content-body:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 47.9375em) {
  .lps_parts .btn {
    text-align: center;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
}
/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .lps_parts--child.bg-wh .img_text--child:only-child {
  width: 100%;
}

.lps_parts--img_text .lps_parts--child .img_text--img:only-child .img {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.lps_parts--img_text .img_text--child .img-detail {
  height: 100%;
}

.lps_parts--img_text .bg-wh .img_text--txt {
  padding: 5%;
}

.lps_parts--img_text .img_text--txt--inner {
  width: 100%;
}

.lps_parts--img_text .text {
  justify-content: flex-start;
}

.lps_parts--img_text .img_text--txt table {
  width: 100%;
}

.lps_parts--img_text .inner-lg .img_text--img {
  position: absolute;
  top: 0;
}

.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
  margin-left: auto;
  margin-right: 60px;
}

.lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
  margin-right: auto;
}

@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg .img_text--img {
    height: 100%;
  }
  .lps_parts--img_text .inner-lg .img_text--txt {
    display: flex;
    align-items: center;
    min-height: 607.64px;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding: 6%;
  }
  .lps_parts--img_text .inner-lg .img_text--child.img_text--txt {
    width: 37%;
  }
  .lps_parts--img_text .inner-lg.flex-reverse .img_text--img {
    right: 100px;
  }
  .lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--img {
    left: 60px;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .bg-wh .img_text--txt {
    padding: 24px;
  }
  .lps_parts--img_text .btn {
    text-align: center;
  }
  .lps_parts--img_text .inner-lg .img_text--txt {
    margin-right: auto;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 40px;
  }
  .lps_parts--img_text.bg-wh + .bg-wh {
    margin-top: 0;
  }
  .lps_parts--img_text .inner-lg .img_text--img {
    position: relative;
  }
  .lps_parts--img_text .inner-lg .img_text--child .img-detail {
    width: 100%;
    margin-left: auto;
  }
}
/* lps_parts--slide
********************************************** */
.lps_parts--slide .img img {
  display: inline-block;
}

.lps_parts--slide .img + .text {
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .img + .text {
    margin-top: 16px;
  }
}

.lps_parts--slide .slick-list {
  margin-bottom: 32px;
}

.lps_parts--slide .slick-arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  text-decoration: none;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-arrow {
    width: 56px;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow {
    width: 40px;
  }
}

.lps_parts--slide .slick-prev {
  left: -60px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-prev {
    left: 0;
  }
}

.lps_parts--slide .slick-next {
  right: -60px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-next {
    right: 0;
  }
}

.lps_parts--slide .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-dots {
    position: relative;
    bottom: 4px;
  }
}

.lps_parts--slide .slick-arrow::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-arrow::after {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow::after {
    width: 16px;
    height: 16px;
  }
}

.lps_parts--slide .slick-prev::after {
  border-bottom: 1px solid;
  border-left: 1px solid;
}

.lps_parts--slide .slick-next::after {
  border-top: 1px solid;
  border-right: 1px solid;
}

.lps_parts--slide .slick-dots li {
  display: block;
  width: 12px;
  height: 12px;
  background-color: var(--clr-main-op);
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
  margin: 6px;
}

.lps_parts--slide .slick-dots li.slick-active {
  background-color: var(--clr-main);
}

.lps_parts--slide .slick-dots li:hover {
  opacity: 0.7;
}

.lps_parts--slide .slick-dots button {
  padding: 0;
  cursor: pointer;
  color: transparent;
  background: transparent;
  outline: none;
  border: none;
}

/* lps_next-page
********************************************** */
.next-page {
  padding-top: 200px;
}

.next-page--ttl {
  position: absolute;
  top: 0;
  left: -300px;
  width: 100vw;
  width: var(--vw);
  font-size: 12.5rem;
  font-weight: 400;
  line-height: 1;
  text-indent: -50px;
  color: var(--clr-wht);
  letter-spacing: 0.08em;
  white-space: nowrap;
  opacity: 0.3;
  z-index: -1;
  pointer-events: none;
  overflow-x: hidden;
}

.lps_next-page {
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}
.lps_next-page a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  padding: 72px 72px 72px 100px;
  font-size: 1.375rem;
}
.lps_next-page a .font-en {
  margin-top: 8px;
  font-size: 0.75rem;
  color: var(--clr-main-op);
  text-transform: uppercase;
}
.lps_next-page a + a {
  border-left: 1px solid rgba(255, 255, 255, 0.8);
}
.lps_next-page a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 47.9375em) {
  .next-page {
    padding-top: 40px;
  }
  .next-page--ttl {
    position: static;
    font-size: 2.5rem;
    text-indent: 0;
    white-space: inherit;
    margin-bottom: 24px;
    opacity: 0.6;
  }
  .lps_next-page {
    flex-direction: column;
    padding: 0;
  }
  .lps_next-page a {
    width: 100%;
    padding: 24px;
    font-size: 1.375rem;
  }
  .lps_next-page a .font-en {
    margin-top: 8px;
    font-size: 0.75rem;
    color: var(--clr-main-op);
    text-transform: uppercase;
  }
  .lps_next-page a + a {
    border-left: none;
    border-top: 1px solid rgba(255, 255, 255, 0.8);
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}