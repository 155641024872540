@use "../abstracts" as *;

/* lps_parts--faq
********************************************** */
.lps_parts--faq .inner {
  max-width: 1380px;
}
.lps_parts--faq .faq_item {
  border-top: 1px solid var(--clr-wht);
}
.lps_parts--faq .faq_item:last-child {
  border-bottom: 1px solid var(--clr-wht);
}

.lps_parts--faq .ttl-03,
.lps_parts--faq .mce-content-body {
  display: flex;
  align-items: center;
  padding: 35px 150px 35px 240px;
  @include mq(sp) {
    padding: 16px 24px 16px 56px;
  }
}
.lps_parts--faq .ttl-03 {
  min-height: 150px;
  padding-right: 260px;
  margin-bottom: 0 !important;
  font-size: rem(18);
  @include lh(18,34);
  @include mq(sp) {
    min-height: 100px;
    font-size: rem(16);
    padding-right: 32px;

  }
}
.lps_parts--faq .mce-content-body {
  display: none;
  position: relative;
  font-size: rem(15);
  background-color: rgba( 255, 255, 255, .4);
  border-top: 1px solid var(--clr-wht);
  padding-top: 60px;
  padding-bottom: 60px;
  @include mq(sp) {
    font-size: rem(14);
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.lps_parts--faq .ttl-03::after,
.lps_parts--faq .mce-content-body::after {
  position: absolute;
  top: 38px;
  left: 112px;
  display: flex;
  justify-content: center;
  text-indent: 1px;
  line-height: 78px;
  @include rect(72);
  font-size: rem(25);
  font-family: var(--font-en);
  font-weight: 400;
  background-color: var(--clr-main);
  border-radius: 50vw;
  @include mq(sp) {
    top: 16px;
    left: 10px;
    @include rect(30);
    font-size: rem(16);
    line-height: 34px;
  }
}
.lps_parts--faq .ttl-03 span:last-child::before,
.lps_parts--faq .ttl-03 span:last-child::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 170px;
  margin: auto;
  width: 16px;
  height: 2px;
  background-color: var(--clr-berge7);
  transition: var(--transit-default);
  @include mq(sp) {
    width: 12px;
    height: 1px;
    right: 10px;

  }
}
.lps_parts--faq .ttl-03 span:last-child::after {
  transform: rotate(-90deg);
}
.lps_parts--faq .ttl-03.active span:last-child::after {
  transform: rotate(0);
}

.lps_parts--faq .ttl-03::after {
  content: "Q";
  color: #fff;
}
.lps_parts--faq .mce-content-body::after {
  content: "A";
  background-color: transparent;
  border: 1px solid var(--clr-main);
  color: var(--clr-main);
}
.lps_parts--faq .mce-content-body .editor-ttl2 {
  font-size: rem(18);
  margin-top: 0;
  margin-bottom: 8px;
}
.lps_parts--faq .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
  @include mq(sp) {
      float: none !important;
      margin-top: 2px;
      margin-left: 0;
      margin-right: auto;
      margin-bottom: 8px !important;
      border-radius: 8px;
  }
}

.bg-ptn {
  .lps_parts--faq .ttl-03::after {
    color: var(--clr-main);
    background-color: var(--clr-wht);
  }
  .lps_parts--faq .mce-content-body {
    background-color: rgba( 255, 255, 255, .1)
  }
  .lps_parts--faq .mce-content-body::after {
    color: var(--clr-wht);
    border-color: var(--clr-wht);
  }
  .lps_parts--faq .ttl-03 span:last-child::before,
  .lps_parts--faq .ttl-03 span:last-child::after {
    background-color: var(--clr-wht);
  }
  

}