@use '../abstracts' as *;
/* bg
********************************************** */
.bg-ptnbox::before,
.bg-ptnbox::after {
  content: "";
  position: absolute;
  width: 100vw;
}
.bg-ptnbox::before {
  top: -364px;
  height: 364px; 
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn02-top.png);
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position: center top;
  @include mq(sp) {
    top: -182px;
    height: 182px; 
  }  
}
.bg-ptnbox::after {
  bottom: -320px;
  height: 320px; 
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn02-btm.png);
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position: center bottom;
  @include mq(sp) {
    bottom: -160px;
    height: 160px; 
  }  
}
.bg-ptnbox {
  position: absolute;
  top: 364px;
  width: 100vw;
  height: calc(100% - 364px - 320px);
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn02.jpg);
  @include mq(med, min, ps) {
    left: -200px;
  }
  @include mq(sp) {
    left: 0;
    top: 182px;
    height: calc(100% - 182px - 160px);
  }
}
.bg-main {
  background-color: var(--bg-main);
}
.bg-wh {
  background-color: var(--bg-wht, #fff);
}

/* --- bg-ptn 文字色 --- */
.bg-ptn {
  color: var(--clr-wht);
  .ttl-01,
  .btn a,
  .btn button {
    color: var(--clr-wht);
  }
  .ttl-01 .font-en {
    color: var(--clr-main);
    opacity: .1;
    @include mq(sp) {
      opacity: .25;
    }
  }
  .editor-ttl::before {
    background-color: var(--clr-wht);
  }
  .btn a,
  .btn button {
    border-bottom-color: var(--clr-wht);
    .cls-1,
    .icon-svg {
      fill: var(--clr-wht);
    }
  }
  .bg-wh {
    color: var(--clr-main);
    .ttl-01,
    .btn a,
    .btn button {
      color: var(--clr-main);
    }
    .btn a,
    .btn button {
      border-bottom-color: var(--clr-main);
      .cls-1 {
        fill: var(--clr-main);
      }
    }
  }
}