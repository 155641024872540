@use '../abstracts' as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh;
  padding: 1% 10px;
  border-right: 1px solid var(--clr-wht);
  z-index: z(header);
  overflow-y: scroll;
  overflow-x: hidden;
  transition: var(--transit-default, all .4s ease-out);
  background-color: rgba(233, 228, 216, .3);
  backdrop-filter: blur(2px);
}
.logo-copy {
  font-size: rem(10);
  color: var(--clr-gr);
  line-height: 1.3;
  letter-spacing: -.06em;
  @include mq(med) {
    display: none;
  }
}
.header--logo a,
.footer--logo a {
  display: block;
}
.header--logo a:hover,
.footer--logo a:hover {
  opacity: .7;
}
.header--logo .logo-pc {
  @include mq(med, min, ps) {
    display: block;
  }
  @include mq(med) {
    display: none;
  }
}
.header--logo .logo-sp {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    display: block;
  }
}

@include mq(med, min, ps) {
  .logo-copy {
    @include center-flex;
  }
  .header--logo {
    display: flex;
    @include center-flex;
    margin-bottom: 48px;
  }
  .header--logo a {
    width: 105px;
    .logo-pc {
      fill: var(--clr-gr);
    }
  }
}

/* gnav
********************************************** */
.gnav {
  padding-bottom: 32px;
}
.gnav--menu {
  margin-left: 4px;
}
.gnav a {
  display: flex;
  font-size: rem(15);
  font-weight: 600;
  font-family: "Josefin Sans", "Shuei KakuGo Gin B", sans-serif;
  color: var(--clr-main);
  white-space: nowrap;
}
.gnav--link {
  padding: 8px 0 8px 30px;
  .jp {
    display: none;
  }
  .jp,.en {
    width: 100px;
  }
  @include mq(med, min, ps) {
    &:hover,
    &.active {
      .jp {
        display: inline;
      }
      .en {
        display: none;
      }
    }    
  }
}
.head_sub_nav--wrap {
  display: none;
  width: 100%;
}
.menu-item-has-children {
  position: relative;
}
.head_sub_nav {
  padding-top: 8px;
  padding-left: 16px;
}
.head_sub_nav li:not(:last-child) {
  margin-bottom: 16px;
}
.head_sub_nav a {
  display: block;
}
.menu-item-has-children > .gnav--link {
  position: relative;
  cursor: pointer;
}
.menu-item-has-children > .gnav--link::after {
  content: "";
  width: 6px;
  height: 6px;
  margin: 2px 6px;
  border-left: 2px solid var(--clr-main);
  border-bottom: 2px solid var(--clr-main);
  transition: var(--transit-default, all .4s ease-out);
  transform: rotate(-45deg);
}
.menu-item-has-children > .gnav--link.active::after {
  transform: rotate(-225deg);
}

/* ---CTA --- */
.cta_btn {
  position: relative;
  line-height: 1;
  margin-bottom: 8px;
  transition: var(--transit-default);
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 18px;
    margin: auto;
    border-radius: 50vw;
    background-color: var(--clr-wht);
    transition: transform .2s ease-in-out;
  }
  &::before {
    @include rect(8);
    left: 21px;
  }
  &::after {
    @include rect(14);
    opacity: .3;
  }
  &:hover {
    opacity: .7;
    &::before,
    &::after {
      transform: scale(1.2);
    }
  }
  a {
    font-family: var(--font-jp01);
    @include center-flex;
    color: var(--clr-wht);
    padding: 24px 24px 24px 48px;
  }
  &.cta_contact {
    background-color: var(--clr-rd);
  }
  &.cta_estimate {
    background-color: var(--clr-bl);
  }
  &.cta_catalogue {
    background-color: var(--clr-br);
  }
}
.cta_tel a {
  display: flex !important;
  color: var(--clr-main);
  font-family: var(--font-en);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
}
.cta_tel a > span {
  display: block;
}
.cta_tel .tel {
  font-size: rem(32);
}
.cta_tel .hours {
  font-size: rem(15);
  font-family: var(--font-main-r);
  letter-spacing: .16em;
}
.gnav--tel {
  margin-bottom: 8px;
}
.gnav--tel .tel {
  font-size: rem(25);
}
.gnav--tel a {
  display: flex !important;
  flex-direction: column;
}

/* --- search_box --- */
.search_box {
  position: relative;
  margin-bottom: 16px;
  background-color: rgba(236,231,219);
  input {
    width: 100%;
    height: 48px;
    font-family: var(--font-jp01) !important;
    color: var(--clr-main);
    background-color: var(--clr-berge5);
    border: 1px solid var(--clr-wht);
    padding: 0;
    text-indent: .75em;
    @include mq(sp) {
      font-size: 18px;
    }
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-family: var(--font-jp01) !important;
      color: rgba($clr-main , .7);
    }
  }
  button {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    border: none;
    background: none;
    cursor: pointer;
    svg {
      fill: var(--clr-wht);
    }
    &:hover svg {
      fill: var(--clr-body);
    }
  }
}

/* --- favorite_link --- */
.favorite_link {
  margin-bottom: 8px;
  a {
    font-size: rem(14);
    font-family: var(--font-jp01-b);
    line-height: 1.4;
    color: var(--clr-main);
    @include center-flex;
  }
  svg {
    fill: var(--clr-main);
    margin-right: 8px;
  }
  &:hover {
    opacity: .7;
  }
}
.favorite-icon {
  margin-right: 8px;
}

@include mq(med, min, ps) {
  .header {
    & > .header--inner {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
  .gnav_btn,
  .tel_btn {
    display: none;
  }
  .gnav--link {
    position: relative;
    line-height: 1;
  }
  .gnav--menu .menu-item:not(:last-child) {
    margin-bottom: 8px;
  }
  .gnav--menu > li:not(.menu-item-has-children) > .gnav--link:not([href="javascript:void(0);"]):hover::before,
  .gnav--menu > li.current-menu-item:not(.menu-item-has-children) > .gnav--link::before {
    content: "";
    position: absolute;
    top: -4px;
    bottom: 0;
    left: 10px;
    margin: auto;
    width: 6px;
    height: 6px;
    border-radius: 50vw;
    background-color: var(--clr-main);
    transition: var(--transit-default);
  }
  .gnav_sub_toggle {
    display: none;
  }
  /* active */
  .gnav--menu .menu-item-has-children.active .head_sub_nav--wrap {
    pointer-events: auto;
  }
  .head_sub_nav a:hover {
    opacity: .7;
  }
  .gnav a:not([href]) {
    pointer-events: none;
  }
}
@include mq(med) {
  .header--logo {
    span {
      height: 100%;
      width: 100%;
    }
    svg {
      height: 100%;
      width: auto;
    }
    .logo-sp {
      fill: var(--clr-gr);
      transition: var(--transit-default);
    }
  }
  .active .header--logo {
    .logo-sp {
      fill: var(--clr-wht);
    }
  }

  .header {
    right: 0;
    height: 70px;
    width: 100vw;
    padding: 4px 0;
    background-color: transparent;
    backdrop-filter: inherit;
    border-right: none;
    overflow: inherit;
    z-index: 9999;
  }
  .header--logo a{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    margin: auto;
    width: auto;
    height: 100%;
    max-height: 46px;
    z-index: 9999;
    img {
      height: 100%;
      width: auto;
      max-width: inherit;
    }
  }
  .gnav,
  .gnav_overlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    opacity: 0;
    padding-top: 64px;
    pointer-events: none;
    transition: var(--transit-default, all .4s ease-out);
  }
  .gnav {
    width: 100%;
    margin: 0;
    padding: 100px 7% 80px;
    overflow: scroll;
    z-index: 300;
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
  }
  .gnav::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include bg-ptn2;
    mask-image: linear-gradient(to bottom, black 60%, transparent 80%);
    z-index: -1;
  }
  .gnav_overlay {
    width: 100%;
    @include bg-ptn1;
  }
  .gnav.active,
  .gnav_overlay.active {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav.active {
    transform: translateX(0);
  }
  .gnav--menu,
  .gnav--cta {
    display: block;
  }
  .gnav--menu {
    margin-left: 8px;
  }
  .gnav--menu li {
    position: relative;
    display: block;
    width: 100%;
  }
  .gnav--menu a {
    display: block;
    font-size: 1.4285rem;
    color: var(--clr-wht);
    padding: 13px 0 11px;
    line-height: 1;
  }
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav li:not(:last-child) {
    margin-bottom: 0;
  }
  .head_sub_nav a {
    padding-left: 16px !important;
  }
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    left: 9em;
    width: 42px;
    height: 42px;
    transition: var(--transit-default, all .4s ease-out);
    color: var(--clr-wht);
  }
  .gnav_sub_toggle::before,
  .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 14px;
    height: 1px;
    border-bottom: 1px solid;
    transition: var(--transit-default);
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .menu-item-has-children.active .gnav_sub_toggle::after {
    transform: rotate(180deg);
  }
  .menu-item-has-children > .gnav--link::after {
    content: none;
  }
  .head_sub_nav {
    position: relative;
    padding-top: 4px;
  }
  .gnav_btn {
    position: fixed;
    top: 0;
    width: 64px;
    height: 64px;
    z-index: 9999;
  }
  .gnav_btn {
    right: 8px;
    transition: var(--transit-default, all .4s ease-out);
  }
  .gnav_btn--lines {
    position: relative;
    width: 24px;
    height: 13px;
  }
  .gnav_btn--lines span {
    transition: var(--transit-default, all .4s ease-out);
    box-sizing: border-box;
  }
  .gnav_btn--lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--clr-main);
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
    transform: translateX(4px);
  }
  .gnav_btn--lines span:nth-of-type(2) {
    top: 6px;
  }
  .gnav_btn--lines span:nth-of-type(3) {
    bottom: 0;
    transform: translateX(-4px);
  }
  .active .gnav_btn--lines span {
    background-color: var(--clr-wht);
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(6px) rotate(-45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-6px) rotate(45deg);
  }

  /* ---CTA --- */
  .gnav--cta li:not(:last-child) {
    margin-bottom: 16px;
  }
  .gnav--tel {
    margin-left: 0;
    text-align: center;
  }

  .header_sp {
    .header_sp--link {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
    }
    .header_sp--link li {
      width: 50%;
      margin-bottom: 8px;
    }
    .header_sp--link a {
      display: flex;
      align-items: center;
      gap: .5em;
      font-size: 13px;
      color: var(--clr-wht);
    }
    .header_sp--link svg .cls-1 {
      fill: var(--clr-wht);
    }
    .header_sp--contact dt {
      font-size: 1.14285rem;
      margin-bottom: 4px;
    }
    .header_sp--contact .gnav--tel span {
      font-size: 13px;
      font-family: var(--font-main-r);
    }
    .header_sp--contact .gnav--tel .font-en {
      font-family: var(--font-en);
      font-weight: 400;
      font-size: 2.1428rem;
    }
    .contact_link--box {
      width: 100%;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .gnav--favorite {
      margin-bottom: 16px;
    }
  }
}

/* scrollbar
**************************************** */
.header::-webkit-scrollbar {
  width: 4px;
}
.header::-webkit-scrollbar:horizontal {
  height: 4px;
}
.header::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
  border: none;
}
.header::-webkit-scrollbar-thumb {
  background-color: var(--clr-main-op);
  border-radius: 10px;
  box-shadow: none;
}

@include mq(sp) {
  .header::-webkit-scrollbar {
    width: inherit;
  }
  .header::-webkit-scrollbar:horizontal {
    height: inherit;
  }
  .header::-webkit-scrollbar-track {
    background-color: inherit;
    border-radius: inherit;
    border: inherit;
  }
  .header::-webkit-scrollbar-thumb {
    background-color: inherit;
    border-radius: inherit;
    box-shadow: inherit;
  }
}

/* header-wh
**************************************** */
.header-wh {
  background-color: transparent;
  backdrop-filter: inherit;
  .gnav a {
    color: var(--clr-wht);
  }
  .gnav--menu > li:not(.menu-item-has-children) > .gnav--link:not([href="javascript:void(0);"]):hover::before,
  .gnav--menu > li.current-menu-item:not(.menu-item-has-children) > .gnav--link::before {
    background-color: var(--clr-wht);
  }
  .menu-item-has-children > .gnav--link::after {
    border-color: var(--clr-wht);
  }
  .favorite_link a {
    color: var(--clr-main);
  }
}


/* --- favotite_btn-fix --- */
.favotite_btn-fix {
  position: fixed;
  top: 50px;
  right: 5%;
  z-index: 9999;
  a {
    display: flex;
    align-items: center;
    padding: 16px;
    font-size: rem(14);
    font-family: var(--font-jp01-b);
    line-height: 1;
    color: var(--clr-wht);
    background-color: var(--clr-berge4);
    border: 1px solid var(--clr-wht);
    svg {
      fill: var(--clr-wht);
      margin-right: 8px;
    }

    &:hover {
      background-color: var(--clr-body);
    }
  }
  @include mq(sp) {
    top: 74px;
    right: 10px;
    a {
      padding: 10px;
      font-size: rem(12);
    }
  }
}
