@use '../abstracts' as * ;

/* ##############################################################################

    FOOTER

############################################################################## */

  .footer {
    position: relative;
    color: var(--clr-wht);
    .bg-ptnbox {
      top: 0;
      height: 100%;
      @include mq(med) {
        left: 0;
      }
    }
    .bg-ptnbox::before,
    .bg-ptnbox::after {
      content: none;
    }
    .footer_inner {
      position: relative;
      padding: 120px 100px 100px;
      border-top: 1px solid rgba(255, 255, 255, .8);
      .footer_content {
        position: relative;
        z-index: 10;
      }
    }
  }
  @include mq(1480) {
    .footer {
      padding: 80px 40px 60px;
      .footer_content {
        flex-direction: column;
        align-items: center;
        .footer_company {
          display: flex;
          gap: 80px;
        }
      }
    }
  }
  @include mq(sp) {
    .footer {
      padding: 0;
      .bg-ptnbox {
        left: 0;
      }
      .footer_inner {
        position: relative;
        padding: 60px 40px 40px;
      }
      .footer_content {
        flex-direction: column;
        align-items: center;
        width: 100%;
        .footer_company {
          flex-direction: column;
          align-items: center;
          gap: 0;
          width: 100%;
          margin-bottom: 32px;
          .footer_logo {
            width: 60%;
            margin-bottom: 40px;
          }
        }
      }
    }
  }

  .home .footer .footer_inner {
    padding-bottom: 180px;
    @include mq(sp) {
      padding-bottom: 100px;
    }
  }

/* footer_company
********************************************** */
  .footer_company {
    .footer_logo {
      display: block;
      margin-bottom: 72px;
    }
    .footer_info {
      font-family: var(--font-main-r);
      font-size: rem(15);
      a {
        color: var(--clr-wht);
      }
    }
  }

/* footer_nav--box
********************************************** */
  .footer_nav--box {
    @include mq(sp) {
      width: 100%;
    }
  }
  /* ---fnav --- */
  .fnav {
    padding-top: 16px;
    @include mq(sp) {
      width: 100%;
    }
  }
  .fnav--menu {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
    width: 23.4375vw;
    gap: 24px;
    a {
      font-size: rem(15);
      font-weight: 600;
      font-family: "Josefin Sans", "Shuei KakuGo Gin B", sans-serif;
      color: var(--clr-wht);
      padding: 0;
      &:hover {
        opacity: .7;
      }
      @include mq(sp) {
        position: relative;
        padding: .5em .5em .5em 0;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 1em;
          margin: auto;
          width: 6px;
          height: 6px;
          border-top: 1px solid;
          border-right: 1px solid;
          transform: rotate(45deg);
        }
      }
    }
  }
  .footer_cta a {
    display: block;
    text-align: center;
    width: 280px;
    margin-bottom: 8px;
    color: var(--clr-wht);
    font-size: 14px;
    line-height: 40px;
    border: 1px solid var(--clr-wht);
  }
  .footer_sample {
    margin-bottom: 32px;
    a {
      color: var(--clr-wht);
      font-family: var(--font-main-r);
      font-size: rem(14);
      letter-spacing: .16em;
      svg .cls-1 {
        fill: var(--clr-wht);
      }
    }
    @include mq(sp, min, ps) {
      gap: 70px;
    }
  }
  @include mq(sp) {
    .fnav--menu {
      margin-right: 0;
      gap: 24px;
      width: auto;
      a {
        display: flex;
        font-size: rem(15);
        font-weight: 600;
        font-family: "Josefin Sans", "Shuei KakuGo Gin B", sans-serif;
        color: var(--clr-wht);
        padding: 0 1em 0 0;
        &:hover {
          opacity: .7;
        }
      }
    }
    .footer_cta a {
      display: block;
      text-align: center;
      width: 280px;
      margin-bottom: 8px;
      color: var(--clr-wht);
      font-size: 14px;
      line-height: 40px;
      border: 1px solid var(--clr-wht);
    }
    .footer_sample {
      li {
        margin-bottom: 16px;
      }
      a {
        svg {
          margin-left: 16px;
        }
        svg .cls-1 {
          fill: var(--clr-wht);
        }
      }
    }
  }

/* footer_copy
*************************************************** */
  .footer_copy {
    align-items: center;
    gap: 20px;
    @include mq(sp) {
      gap: 0;
    }
  }
  .pbl a {
    &:hover {
      opacity: .5;
    }
  }

  /* ---sns_area --- */
  .sns_area {
    margin-top: 32px;
    margin-bottom: 24px;
    @include mq(sp) {
      margin-top: 24px;
      .sns--list {
        justify-content: center;
      }
    }
  }
  .sns--list li:not(:last-child) {
    margin-right: 12px;
  }
  .sns--list a {
    display: flex;
    justify-content: center;
    align-items: center;
    @include rect(24);
    font-size: 1.5rem; /* 24px */
    svg {
      width: 100%;
      fill: var(--clr-wht);
    }
  }
  .sns--list a:hover {
    opacity: .7;
  }
