@use '../abstracts' as *;

/* section_contact
********************************************** */

.home .section_contact {
  padding-top: 340px;
  @include mq(sp) {
    padding-top: 140px;
  }
}
.section_contact {
  padding-top: vw(180);
  padding-bottom: 128px;
  .bg-ptnbox {
    height: calc(100% - 364px);
    &::after {
      content: none;
    }
  }
  .inner {
    position: relative;
  }
  .ttl-01 {
    position: relative;
    font-size: rem(22);
    padding-top: 0;
    padding-bottom: 32px;
    margin-bottom: 32px;
    .font-en {
      position: static;
      margin-left: 0;
      width: 100%;
      font-size: clamp(rem(48), 7.291666vw, rem(140) );
      color: var(--clr-wht);
      text-indent: 0;
      opacity: .3;
      z-index: 10;
    }
  }
  .section_contact--txt {
    font-size: rem(18);
    letter-spacing: .16em;
    @include lh(18,42);
  }
  .section_contact--content {
    dl {
      width: 50%;
      padding: 32px 5.2083vw 40px;
      &:last-child {
        border-left: 1px solid rgba(255, 255, 255, .5);
      }
      dt {
        margin-bottom: 32px;
        letter-spacing: .12em;
      }
      dd {
        width: 100%;
      }
    }
  }
  .contact_tel--link {
    a {
      display: flex !important;
      flex-direction: column;
      @include center-flex;
      color: var(--clr-wht);
      .tel {
        font-size: clamp(rem(34), 2.5vw, rem(48));
        letter-spacing: .12em;
      }
    }
  }

  @include mq(sp, min, ps) {
    .inner {
      margin-left: 5.20833vw;
    }
  }
  @include mq(sp) {
    padding-top: 48px;
    padding-bottom: 64px;
    .bg-ptnbox {
      height: calc(100% - 182px);
    }
    .ttl-01 {
      font-size: rem(16);
      padding-bottom: 24px;
      margin-bottom: 24px;
      opacity: 1;
    }
    .section_contact--txt {
      font-size: rem(14);
      br {
        display: none;
      }
    }
    .section_contact--content {
      dl {
        width: 100%;
        padding: 32px 0 40px;
        &:last-child {
          border-left: none;
          border-top: 1px solid rgba(255, 255, 255, .5);
        }
      }
    }
  }
}

/* --- contact_link--box --- */
.contact_link--box {
  ul {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 80px 64px;
    grid-gap: 16px;
    li {
      margin-bottom: 0;
    }
    .cta_btn::before,
    .cta_btn::after {
      content: none;
    }
    a {
      display: flex;
      @include center-flex;
      width: 100%;
      height: 100%;
      letter-spacing: .16em;
      padding: 0 8px;
      span {
        position: relative;
        padding-left: 2.0833vw;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          border-radius: 50vw;
          background-color: #fff;
          background-color: var(--clr-wht);
          transition: transform .2s ease-in-out;
        }
        &::before {
          left: 3px;
          @include rect(8);
        }
        &::after {
          @include rect(14);
          opacity: .3;
        }
      }
      &:hover {
        span::before,
        span::after {
          transform: scale(1.2);
        }
      }
    }
    .cta_contact {
      grid-column:1 / 3;
      grid-row:1 / 2;
      a {
        color: var(--clr-main);
        font-size: rem(18);
        letter-spacing: .22em;
        background-color: var(--clr-wht);
        span {
          padding-left: 48px;
          &::before,
          &::after {
            background-color: var(--clr-rd);
          }
        }
      }
    }
  }
  @include mq(sp) {
    ul {
      grid-template-rows: 60px 48px;
      grid-gap: 8px;
      li {
        margin-bottom: 0;
      }
      a {
        span {
          padding-left: 24px;
        }
      }
      .cta_contact {
        grid-column:1 / 3;
        grid-row:1 / 2;
        a {
          color: var(--clr-main);
          font-size: rem(18);
          letter-spacing: .22em;
          background-color: var(--clr-wht);
          span {
            padding-left: 48px;
            &::before,
            &::after {
              background-color: var(--clr-rd);
            }
          }
        }
      }
    }
  }
}

/* --- category_search --- */

.search_wrap {
  margin-bottom: 80px;
  .category_search {
    display: none;
    border-top: none;
    .category_search--box {
      padding-top: 32px;
    }
    .search_category .category--ttl {
      min-width: 170px;
    }
  }
  .btn.open_btn {
    margin-bottom: 0;
    border: 1px solid var(--clr-wht);
    a {
      font-size: rem(18);
      display: block;
      background-color: var(--clr-br2);
      border-bottom: none;
      color: var(--clr-wht);
      cursor: pointer;
      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 24px;
        margin: auto;
        width: 16px;
        height: 2px;
        background-color: var(--clr-wht);
        transition: var(--transit-default);
      }
      &::before {
        rotate: -90deg;
      }
      &.active::before {
        rotate: 0deg;
      }
    }
  }
  @include mq(sp) {
    margin-bottom: 40px;
    .category_search {
      border-top: none;
      .category_search--box {
        padding-top: 0;
      }
    }
    .btn.open_btn {
      a {
        cursor: pointer;
        &::after,
        &::before {
          content: "";
          right: 16px;
          margin: auto;
          width: 12px;
          height: 2px;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.category_search {
  margin-bottom: 160px;
  color: var(--clr-wht);
  background-color: var(--clr-br2);
  border: 1px solid var(--clr-wht);
  .category_search--ttl {
    margin-top: 48px;
    margin-left: 32px;
    margin-bottom: 40px;
    line-height: .8;
    opacity: .3;
  }
  .category_search--box {
    width: 90%;
    max-width: 1300px;
    @include auto-margin;
    margin-bottom: 72px;
  }
  .search_category {
    padding: 32px;
    border-bottom: 1px solid var(--clr-wht);
    .category--ttl {
      min-width: 220px;
      font-size: rem(18);
    }
    .category--item {
      &.cate-txt a {
        color: var(--clr-wht);
        border: 1px solid var(--clr-wht);
        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 16px;
          margin: auto;
          @include rect(5);
          border-radius: 50vw;
          background-color: var(--clr-wht);
        }
        &:hover {
          background-color: var(--clr-br3);
        }
      }
      &.cate-img {
        ul {
          gap: 16px;
          li {
            width: 64px;
          }
          li:hover .color--img {
            outline: 1px solid var(--clr-wht);
            outline-offset: -1px;
          }
          .color--img {
            aspect-ratio: 1 / 1;
            margin-bottom: 8px;
          }
          a {
            display: block;
            color: var(--clr-wht);
            font-size: rem(12);
            line-height: 1;
            text-align: center;
          }
        }
      }
    }
  }
  @include mq(sp) {
    margin-bottom: 40px;
    .category_search--ttl {
      margin-top: 32px;
      margin-left: 32px;
      margin-bottom: 8px;
      line-height: 1;
      opacity: .6;
    }
    .category_search--box {
      margin-bottom: 36px;
    }
    .search_category {
      width: 100%;
      flex-direction: column;
      padding: 24px 8px;
      .category--ttl {
        width: 100%;
        font-size: rem(17);
        margin-bottom: 8px;
      }
      .category--item {
        width: 100%;
         &.cate-txt a {
          &::before {
            left: 12px;
          }
         }
        .category--link {
          width: 100%;
        }
        &.cate-img {
          ul {
            .color--img {
              width: 56px;
              margin-bottom: 8px;
            }
            a {
              font-size: rem(10);
            }
          }
        }
      }
    }
  }
}


/* section_simulator
**************************************** */
.section_simulator {
  padding: rem(40);
  margin-top: rem(80);
  gap: rem(80);
  background-color: rgba($clr-wht, .5);
  @include mq(sp) {
    padding: sprem(32);
    gap: sprem(32);
    margin-bottom: sprem(56);
  }
}
.home .section_simulator {
  margin-top: 0;
  margin-bottom: rem(120);
}
.section_simulator--decottl {
  right: -.125em;
  top: 0;
  translate: 0 -82%;
  font-size: clamp(rem(56), 6.25vw, rem(120));
  font-weight: 400;
  line-height: .8;
  letter-spacing: .08em;
  color: var(--clr-wht);
  text-indent: -.1em;
  opacity: .6;
  z-index: 10;
  @include mq(sp) {
    letter-spacing: .04em;
  }
}
.section_simulator--deco {
  left: rem(-8);
  bottom: 0;
  font-size: rem(14);
  font-weight: 600;
  letter-spacing: .06em;
  line-height: .8;
  @include write-v;
  rotate: 180deg;
  translate: -100% 0;
  @include mq(sp) {
    font-size: sprem(12);
  }
}
.section_simulator .txtarea {
  flex: 1;
}
.section_simulator p {
  line-height: 2;
}
.section_simulator--ttl {
  display: flex;
  flex-direction: column;
  font-size: rem(24);
  line-height: 1.8;
  letter-spacing: .06em;
  padding-bottom: rem(24);
  gap: rem(24);
  @include mq(1680) {
    br {
      display: none;
    }
  }
  @include mq(sp) {
    font-size: sprem(18);
    padding-bottom: sprem(24);
    gap: sprem(16);
  }
}
.section_simulator--ttl .dots {
  position: relative;
  display: block;
  width: rem(6);
  height: rem(6);
  color: var(--clr-rd);
  background-color: currentColor;
  border-radius: 50vw;
  opacity: .8;
  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: rem(6);
    height: rem(6);
    background-color: currentColor;
    border-radius: 50vw;
  }
  &::before {
    left: 200%;
  }
  &::after {
    left: 400%;
  }
}
.section_simulator .imgarea {
  width: rem(700);
  @include mq(sp) {
    width: 100%;
  }
}