@use '../abstracts' as *;

/* lps_parts--flow
********************************************** */
.lps_parts--flow .flow_item {
  position: relative;
  max-width: 940px;
  @include auto-margin;
  padding-left: 86px;
  counter-increment: number;
  @include mq(sp) {
    padding-left: 48px;
  }
}
.lps_parts--flow .flow_item {
  padding-bottom: 40px;
}
.lps_parts--flow .ttl-03 {
  font-size: 2rem; /* 32px */
  color: var(--clr-main);
  @include mq(sp) {
    font-size: 1.429rem; /* 20px */
  }
  &::after {
    content: counter(number);
    position: absolute;
    top: -8px;
    left: -80px;
    display: flex;
    justify-content: center;
    @include rect(64);
    line-height: 67px;
    text-indent: 3px;
    border-radius: 50%;
    text-align: center;
    color: var(--clr-main);
    border: 1px solid var(--clr-main);
    font-family: var(--font-en);
    font-weight: 600;
    font-size: 1.625rem; /* 26px */
    @include mq(sp) {
      top: -6px;
      left: -48px;
      width: 36px;
      height: 36px;
      line-height: 36px;
      font-size: 1.286rem; /* 18px */
    }
  }
}
.lps_parts--flow .flow_item:not(:last-child)::before {
  content: '';
  position: absolute;
  top: 64px;
  left: 36px;
  display: block;
  width: 0;
  height: calc(100% - 80px);
  border-right: 1px solid var(--clr-main);
  opacity: .6;
  @include mq(sp) {
    top: 40px;
    left: 18px;
    height: calc(100% - 56px);
  }
}
.lps_parts--flow .txt-wh .ttl-03 {
  color: #fff;
}
.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: #fff;
}
.lps_parts--flow .txt-wh .ttl-03::after {
  color: var(--clr-main);
  background-color: #fff;
}
.lps_parts--flow .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
}
@include mq(sp) {
  .lps_parts--flow .text img {
    float: none !important;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 16px !important;
  }
}

.bg-ptn .lps_parts--flow {
  .ttl-03 {
    color: var(--clr-wht);
    &::after {
      color: var(--clr-wht);
      border: 1px solid var(--clr-wht);
    }
  }
  .flow_item:not(:last-child)::before {
    border-color: var(--clr-wht);
  }
}