/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes scroll_progress {
  0% { transform: translateY(0); }
  80% { transform: translateY(6.25vw); }
  100% { transform: translateY(6.25vw); }
}
@keyframes circle-pop {
  0% { 
    opacity: .8;
    scale: .4;
  }
  100% { 
    opacity: 0;
    scale: 1.2;
  }
}
