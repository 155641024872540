@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  padding-top: 80px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, .8);
}
.page_ttl--deco {
  position: relative;
  @include rect(5);
  margin-bottom: 56px;
  background-color: var(--clr-wht);
  border-radius: 50vw;
  &::before,&::after {
    content: "";
    position: absolute;
    @include rect(5);
    background-color: var(--clr-wht);
    border-radius: 50vw;
  }
  &::before {
    left: 200%;
  }
  &::after {
    left: 400%;
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  line-height: 1;
}
.page_ttl-jp {
  font-family: var(--font-jp01-b);
  font-size: rem(16);
  letter-spacing: .12em;
}

@include mq(sp) {
  .page_ttl {
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .page_ttl--deco {
    margin-bottom: 24px;
  }
  .page_ttl-en {
    font-size: 2rem; /* 28px */
  }
  .page_ttl-jp {
    font-size: 1.143rem; /* 16px */
    margin-bottom: 16px;
  }
}

@include mq(sp, min, ps) {
  .single-works {
    .page_ttl {
      padding-bottom: 24px;
    }
    .page_ttl-jp {
      font-size: rem(28);
      letter-spacing: .2em;
      margin-top: 48px;
      margin-left: 4px;
    }
  }
}

.page {
  .page_ttl {
    padding-top: 170px;
    margin-left: 5.20833vw;
  }
  .page_ttl-jp {
    padding-bottom: 64px;
  }
  @include mq(sp) {
    .page_ttl {
      padding-top: 80px;
      margin-left: auto;
    }
    .page_ttl-jp {
      padding-bottom: 0;
    }
    .page_ttl--deco {
      order: 1;
      margin-bottom: 32px;
    }
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03 {
  font-family: var(--font-jp01-b);
  letter-spacing: .1em;
}
.ttl-01 {
  font-size: rem(38);
  color: var(--clr-main);
  padding-bottom: 48px;
  @include mq(sp) {
    font-size: 2.143rem; /* 30px */
    padding-bottom: 24px;
    &.inner {
      width: 100%;
      padding-left: 7%;
      padding-right: 7%;
    }
  }
  &.has-en {
    padding-top: 48px;
  }
  .font-en {
    position: absolute;
    left: -300px;
    bottom: -30px;
    width: 100vw;
    width: var(--vw);
    font-size: rem(240);
    font-weight: 400;
    line-height: 1;
    text-indent: -50px;
    color: var(--clr-wht);
    letter-spacing: .08em;
    white-space: nowrap;
    opacity: .3;
    z-index: -1;
    pointer-events: none;
    overflow-x: hidden;
    @include mq(sp) {
      position: absolute;
      left: 0;
      bottom: inherit;
      top: 0;
      width: 100vw;
      display: block;
      font-size: rem(56);
      text-indent: -.25em;
    }
  }
  .ttl-deco {
    position: absolute;
    bottom: 0;
    left: 0;
    @include rect(5);
    border-radius: 50vw;
    background-color: var(--clr-wht);
    &::before,
    &::after {
      content: "";
      position: absolute;
      background-color: var(--clr-wht);
      border-radius: 50vw;
      @include rect(5);
    }
    &::before {
      left: 200%;
    }
    &::after {
      left: 400%;
    }
  }
}
@include mq(sp) {
  .lps_sec {
    .ttl-01 {
      .ttl-deco {
        left: 7%;
      }
    }
  }
}

.ttl-02 {
  font-size: rem(32);
  @include lh(32px,58px);
  @include mq(sp) {
    font-size: 2rem; /* 28px */
  }
}
.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: rem(32);
  @include lh(32px,58px);
  @include mq(sp) {
    font-size: 2rem; /* 28px */
  }
}
.ttl-03 {
  font-size: 1.75rem; /* 28px */
  @include mq(sp) {
    font-size: 1.714rem; /* 24px */
  }
}
.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03 {
  position: relative;
  z-index: 1;
}
.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03:not(:last-child) {
  margin-bottom: 1.5em;
}
.ttl-04 {
  font-size: 1.5rem; /* 24px */
}
.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: 1.5rem; /* 24px */
  margin-bottom: 16px;
  @include mq(sp) {
    font-size: 1.571rem; /* 24px */
  }
}
.ttl-en {
  color: var(--clr-wht);
  font-family: var(--font-en);
  font-size: max(3rem, min(5.416666vw, 6.5rem));
  font-weight: 400;
  letter-spacing: .07em;
  text-indent: -.07em;
  line-height: 1;
}
.section_ttl {
  font-family: var(--font-jp01-b);
}
