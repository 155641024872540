@use '../abstracts' as *;

/* hero
********************************************** */
.hero {
  position: relative;
  width: 100%;
  color: var(--clr-wht);
  &::after {
    content: "";
    position: absolute;
    width: 100vw;
    left: -200px;
    bottom: -425px;
    height: 425px; 
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/section-parts-message.png);
    background-repeat: repeat-x;
    background-size: auto 100%;
    background-position: center bottom;
  }
  .hero_movie {
    position: absolute;
    z-index: -1;
    mask-image: linear-gradient(to bottom, black 92.3%, transparent 100%);
    video {
      width: 100vw;
    }
  }
  .hero-animation {
    translate: 0 -500px;
    opacity: 0;
    transition: all .8s cubic-bezier(0, 0, .15, 1);
    &.active {
      translate: 0 0;
      opacity: 1;
    }
    &.font-en {
      transition-delay: 4s;
    }
    &.hero--copy,
    &.hero--scroll {
      transition-delay: 4.5s;
    }
    &.hero--copy-en {
      transition-delay: 5s;
    }
  }
  @include mq(sp, min ,ps) {
    height: 67.70833vw;
    max-height: 1300px;
    .hero_content {
      position: absolute;
      left: 75px;
      bottom: 17vw;
      .font-en {
        margin-bottom: 32px;
      }
      .hero--copy {
        h2 {
          font-family: var(--font-jp01);
          font-size: rem(44);
          @include lh(44, 68);
          letter-spacing: .2em;
          margin-bottom: 32px;
        }
        p {
          font-size: rem(16);
          line-height: 2;
        }
      }
      .hero--copy-en {
        position: absolute;
        left: 232px;
        bottom: -50px;
      }
    }
  }
  @include mq(med, min ,ps) {
    .hero_movie {
      left: -200px;
    }
  }
  @include mq(sp) {
    height: 700px;
    overflow: hidden;
    .hero_movie {
      left: 0;
      right: 0;
      height: 100%;
      @include auto-margin;
      video {
        width: auto;
        height: 100%;
      }
    }
    .hero_content {
      position: absolute;
      width: 100%;
      padding-left: 20px;
      top: 82.92vw;
      z-index: 99;
      .font-en {
        font-size: rem(10);
        line-height: 1;
        margin-bottom: 8px;
      }
      .hero--copy {
        h2 {
          font-family: var(--font-jp01);
          font-size: rem(32);
          @include lh(32, 58);
          letter-spacing: .2em;
          margin-bottom: 16px;
        }
        p {
          font-size: rem(14);
          @include lh(14,24)
        }
      }
      .hero--copy-en {
        position: absolute;
        right: -2px;
        bottom: -20px;
        width: 60%;
        z-index: -1;
      }
    }
  }
}

.hero--scroll {
  position: absolute;
  left: 85px;
  bottom: 6.25vw;
  height: 6.25vw;
  width: 1px;
  background-color: rgba(255, 255, 255, .5);
  .circle {
    position: absolute;
    left: -2px;
    @include auto-margin;
    @include rect(5);
    border-radius: 50vw;
    background-color: var(--clr-wht);
    animation: scroll_progress 2.5s infinite;
  }
  @include mq(med) {
    display: none;
  }
}

/* fix_contact
**************************************** */
.fix_contact--box {
  position: fixed;
  right: 0;
  bottom: 40px;
  z-index: 999;
  .fix_contact {
    gap: 24px;
    padding: 20px 32px;
    background-color: var(--clr-wht);
    .fix_contact--icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      .circle {
        position: relative;
        @include rect(16);
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          border-radius: 50vw;
          background-color: var(--clr-rd);
        }
        &::before {
          @include rect(8);
        }
        &::after {
          @include rect(16);
          opacity: .6;
          scale: .5;
          animation: circle-pop 1.8s cubic-bezier(.2,.7,.3,.85) infinite;
        }
      }
    }
    &:hover {
      background-color: var(--clr-berge);
      outline: 1px solid var(--clr-br2);
    }
    .font-en {
      color: var(--clr-rd);
      font-size: rem(10);
      line-height: 1;
    }
    dl{
      font-family: var(--font-jp01-b);
      dt {
        font-size: rem(18);
      }
      dd {
        font-size: rem(12);
        @include lh(12, 19);
      }
    }
  }

  &.hero-animation {
    translate: 100% 0;
    transition: all .8s cubic-bezier(0, 0, .15, 1);
    @include mq(sp, min, ps) {
      transition-delay: 6s;
      &.active {
        translate: 0 0;
        opacity: 1;
      }  
    }
    @include mq(sp) {
      &.slidein {
        translate: 0 0;
        opacity: 1;
      }  
    }
  }
  @include mq(sp) {
    bottom: 20px;
    .fix_contact {
      gap: 12px;
      padding: 8px 16px;
      .fix_contact--icon {
        width: 16px;
        gap: 4px;
        .circle {
          @include rect(12);
          &::before {
            @include rect(6);
          }
          &::after {
            @include rect(12);
          }
        }
      }
      dl {
        dt {
          margin-top: 4px;
          font-size: rem(14);
          line-height: 1;
        }
        dd {
          display: none;
        }
      }
    }
  }
}
