@use '../abstracts' as *;

/* contact
********************************************** */
.page-contact .header {
  @include mq(sp) {
    position: absolute;
  }
}

.form_content {
  padding-bottom: 80px;
}
.contact .cta_tel {
  width: 100%;
  max-width: 400px;
  @include auto-margin;
}
.contact .cta_tel a {
  padding: 24px 40px;
  @include mq(sp) {
    .contact .cta_tel a {
      display: block;
      padding: 16px;
    }
  }
}

.btn.btn-box.add-sample {
  margin-bottom: 0;
  a {
    display: flex;
    @include center-flex;
    width: 260px;
    padding: 16px;
    margin-right: 16px;
    font-size: rem(14);
    line-height: 1;
    &.hide {
      opacity: 0;
    }
  }
}

/* input file
**************************************** */
.contact_file {
  label {
    display: inline-block;
    padding: 8px 25px;
    margin-bottom: 4px;
    color: var(--clr-main);
    line-height: 1;
    border: 1px solid var(--clr-main);
    cursor: pointer;
  }
  input[type="file"] {
    display: none;
  }
  #js-filetxt {
    display: inline-block;
    font-family: var(--font-main-r);
    font-size: rem(14);
    margin-left: 8px;
  }

}

/* sample contact
**************************************** */
.select_favorite {
  .ttl-01 {
    font-size: rem(24);
    padding-bottom: 24px;
  }
  .ttl-01 .ttl-deco {
    right: 0;
    margin: auto;
    &::after {
      left: -200%;
    }
  }
  .posts-products {
    justify-content: flex-start;
    gap: 16px;
  }
  .posts-products.posts-ctr {
    justify-content: center;
  }
  .posts-products::before,
  .posts-products::after {
    content: none;
  }
  .posts-products .products_item {
    padding: 10px;
    margin-bottom: 0;
    width: calc((100% - 48px) / 4);
  }
  .posts-products .products_item .products_item--text .item--ttl {
    font-size: rem(14);
  }
  .products_item .add--text {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    @include center-flex;
    gap: 20px;
    margin: auto;
    color: var(--clr-wht);
    font-size: rem(15);
    font-weight: 600;
    z-index: 4;
    opacity: 0;
    transition: var(--transit-default);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      background-color: rgba(0, 0, 0, .05);
      z-index: 2;
      opacity: 0;
      transition: var(--transit-default);
    }
  }
  .products_item.added {
    pointer-events: none;
    cursor: default;
    &::before {
      opacity: 1;
    }
    .add--text {
      opacity: 1;
      &::after {
        opacity: 1;
      }
    }
    .hover--text {
      opacity: 0 !important;
    }
  }
  a.js-add-sample,
  a.js-add-sample::before {
    cursor: pointer;
  }
  @include mq(sp) {
    &.inner {
      width: 100%;
    }
    .posts-products.posts-ctr {
      gap: 8px;
    }
    .posts-products .products_item {
      padding: 8px;
      margin-bottom: 0;
      width: calc((100% - 20px) / 2);
      .products_item--img {
        margin-bottom: 8px;
      }
      &.added::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: var(--clr-berge);
        outline: 1px solid var(--clr-wht);
        outline-offset: -1px;
        top: 0;
        left: 0;
        transition: var(--transit-default);
        z-index: -1;
      }
    }
  }
}