@use '../abstracts/' as *;

/* home_news
********************************************** */
.home_news {
  .home_ttl {
    padding-bottom: 32px;
  }
  .news_list .post {
    padding: 64px 40px;
    line-height: 1;
    border-bottom: 1px solid var(--clr-berge5);
    transition: var(--transit-default);
    &:last-of-type {
      margin-bottom: 40px;
    }
    &:hover {
      opacity: .6;
    }
  }
  @include mq(sp, min, ps) {
    padding: 170px 0 120px;
    .inner {
      margin-left: 5.20833vw;
    }
  }
  @include mq(sp) {
    padding: 60px 0;
    .inner {
      margin-left: a;
    }
    .news_list .post {
      padding: 32px 20px;
    }

  }
}