@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
.breadcrumbs {
  font-size: rem(13);
  font-family: var(--font-main-r);
  padding: 8px 0;
  @include mq(sp) {
    font-size: rem(10);
  }
  .inner > span {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: .5em;
    @include mq(sp) {
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: .5em 0;
    }
  }
  .arrow {
    display: inline-block;
    width: 5px;
    height: 8px;
    margin: 0 5px;
    background-color: var(--clr-main);
    clip-path: polygon(0 0, 100% 50%, 0 100%);
  }
}
.single-products .breadcrumbs {
  padding-bottom: 0;
}
.breadcrumbs a {
  color: var(--clr-main);
}
.breadcrumbs a:hover {
  text-decoration: none;
}
.breadcrumbs .breadcrumb_last {
  display: table-cell;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: .6;
}
.page .breadcrumbs {
  transform: translateY(-40px);
  height: 0;
  padding: 0;
}
@include mq(sp, min, ps) {
  .single-works .breadcrumbs {
    height: 0;
    padding: 0;
    margin-top: 8px;
  }
}
@include mq(sp) {
  .single .breadcrumbs {
    .inner {
      justify-content: flex-start;
    }
  }
}