@use '../abstracts' as *;

/* home_common
**************************************** */
/* --- home_ttl --- */
.home_ttl {
  position: relative;
  font-size: rem(18);
  padding-top: 20px;
  padding-bottom: 32px;
  margin-bottom: 40px;
  .font-en {
    font-size: clamp(rem(56), 6.25vw, rem(120));
    font-weight: 400;
    line-height: .8;
    letter-spacing: .08em;
    color: var(--clr-wht);
    text-indent: -.1em;
    opacity: .6;
    z-index: 10;
  }
  .ttl-deco {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50vw;
    background-color: var(--clr-wht);
    &::before,
    &::after {
      content: "";
      position: absolute;
      background-color: var(--clr-wht);
      border-radius: 50vw;
      @include rect(5);
    }
    &::before {
      left: 200%;
    }
    &::after {
      left: 400%;
    }
  }
  @include mq(sp) {
    font-size: rem(16);
    padding-top: 20px;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
}
.home_ttl-ctr {
  padding-bottom: 32px;
  margin-bottom: 60px;
  text-align: center;
  .ttl-deco {
    left: 0;
    right: 0;
    margin: auto;
    &::after {
      left: -200%;
    }
  }
  @include mq(sp) {
    padding-bottom: 16px;
    margin-bottom: 32px;
  }
}
.bg-ptn {
  .home_ttl {
    .font-en {
      opacity: .3;
    }
  }
}

/* --- txtarea --- */
.txtarea p {
  @include lh(16,40);
  letter-spacing: .16em;
  & + p {
    margin-top: 40px;
  }
  @include mq(sp) {
    br {
      display: none;
    }
  }
  @include mq(1642) {
    br:not(.strong) {
      display: none;
    }
  }
}

/* --- btn --- */
.home{ 
  .btn a {
    font-size: rem(14);
    letter-spacing: .18em;
    padding-top: 4px;
    padding-bottom: 4px;
    svg {
      margin-bottom: 2px;
    }
  }
}

/* --- home_post_list --- */
.home_post_list {
  max-width: 880px;
  width: 45.83vw;
  @include mq(sp) {
    width: 100%;
  }
}

/* --- imgarea --- */
.imgarea {
  display: flex;
  justify-content: space-between;
  img {
    transition: translate .4s ease-in-out;
  }
}

/* home_message
**************************************** */
.home_message {
  margin-top: -4vw;
  margin-bottom: 60px;
  padding-top: 4vw;
  padding-bottom: 8.333vw;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/message-illust01.png);
  background-repeat: no-repeat;
  background-position: left 23% bottom;
  background-size: 43%;
  overflow: hidden;
  .home_ttl {
    padding-bottom: 48px;
  }
  .contentarea {
    width: 53%;
    .txtarea {
      width: 75%;
      @include mq(1600) {
        width: 70%;
      }
      .btn {
        text-align: right;
        margin-right: 5%;
      }
    }
  }
  .imgarea {
    gap: 8px;
    &.col2 img {
      width: calc((100% - 8px) / 2 );
    }
    &.col3 img {
      width: calc((100% - 16px) / 3 );
    }
  }
  .imgarea01 {
    img:nth-child(1) { translate: 0 0; }
    img:nth-child(2) { translate: 0 15%; }
    img:nth-child(3) { translate: 0 -10%; }
  }
  .imgarea02 {
    img:nth-child(1) { translate: 0 17.7%; }
    img:nth-child(2) { translate: 0 0; }
  }
  @include mq(sp, min ,ps) {
    .inner {
      flex-direction: row-reverse;
    }
    .imgarea {
      position: absolute;
    }
    .contentarea {
      margin-top: 4vw;
    }
    .imgarea01 {
      width: 33.333vw;
      top: 4vw;
      left: 30px;
    }
    .imgarea02 {
      width: 21.875vw;
      bottom: 11vw;
      right: -8vw;
    }
  }
  @include mq(sp) {
    margin-bottom: 64px;
    padding-bottom: 8.333vw;
    background-position: left bottom 10vw;
    background-size: 50%;
    overflow: hidden;
    .home_ttl {
      padding-bottom: 24px;
    }
    .contentarea {
      width: 100%;
      .txtarea {
        width: 100%;
        .btn {
          margin-right: 0;
        }
      }
    }
    .imgarea {
      gap: 8px;
      &.col2 {
        width: 66.66%;
      } 
    }
    .imgarea01 {
      padding-top: 10%;
      padding-bottom: 15%;
    }
    .imgarea02 {
      margin-left: auto;
      margin-right: 0;
      translate: 10vw 0;
      padding-top: 17.7%;
    }
  }
}

/* home_products
**************************************** */
.home_products {
  padding-bottom: 160px;
  .home-ttl {
    margin-bottom: 60px;
    .font-en {
      font-size: clamp(rem(40), 5.8333vw, rem(112));
    }
  }
  .home_products--list {
    li {
      display: flex;
      flex-direction: column;
      width: calc((100% - 6.25vw * 3) / 4);
      text-align: center;
      &:hover {
        .home_products--img  {
          .item-more {
            opacity: 1;
          }
        }
      }
      .home_products--img {
        position: relative;
        margin-bottom: 56px;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 10px;
          left: 10px;
          border: 1px solid var(--clr-wht);
          z-index: -1;
        }
        .item-more {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          @include center-flex;
          color: var(--bg-wht);
          opacity: 0;
          transition: var(--transit-default);
          background-color: rgba(41, 30, 9, .3);
          .cls-1 {
            fill: var(--clr-wht);
          }
        }
      }
      h3 a {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        font-size: clamp(rem(20), vw(26), rem(26));
        font-family: var(--font-jp01-b);
        letter-spacing: .12em;
        line-height: 1;
        .font-en {
          margin-top: 20px;
          color: var(--clr-txt-sub);
          font-size: rem(12);
          letter-spacing: .12em;
        }
      }
    }
  }
  .home_products--pdf {
    margin-top: 16px;
  }
  .home_products--pdf li {
    width: 100%;
    margin-bottom: 8px;
  }
  .home_products--pdf a {
    display: flex;
    flex-wrap: wrap;
    @include center-flex;
    gap: 4px;
    font-size: rem(12);
    font-family: var(--font-jp01);
    svg {
      fill: var(--clr-main);
    }
    &:hover {
      opacity: .7;
    }
  }
  @include mq(sp) {
    padding-bottom: 80px; 
    .home-ttl {
      margin-bottom: 60px;
      .font-en {
        font-size: clamp(rem(40), 5.8333vw, rem(112));
      }
    }
    .home_products--list {
      li {
        width: calc((100% - 32px) / 2);
        margin-bottom: 40px;
        .home_products--img {
          margin-bottom: 24px;
        }
        h3 a {
          margin-bottom: 16px;
          font-size: rem(18);
          &.small {
            font-size: rem(16);
            .font-en {
              margin-top: 10px;
            }
          }
          .font-en {
            margin-top: 10px;
            font-size: rem(12);
          }
        }
        .btn a {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
    .home_products--pdf {
      margin-top: 8px;
    }
    .home_products--pdf li {
      width: 100%;
      margin-bottom: 4px;
    }
    .home_products--pdf a {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: .714rem;
      svg {
        width: 20px;
      }
    }
  }
}

/* home_feature
**************************************** */
.home_feature {
  overflow-x: hidden;
  .home_ttl {
    margin-left: 8.6vw;
  }
}
.feature_content {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 640px;
  .imgarea {
    position: absolute;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    &.col1 {
      width: 10.4166vw;
      max-width: 200px;
      img {
        width: 100%;
      }
    }
    &.col2 {
      width: 25.833vw;
      max-width: 496px;
      img {
        width: calc((100% - 16px) / 2);
      }
    }
    &.col3 {
      width: 43.385vw;
      max-width: 833px;
      img {
        width: calc((100% - 32px) / 3);
      }
    }
  }
  .contentarea {
    .num {
      font-size: rem(24);
      line-height: 1;
      letter-spacing: .08em;
      margin-bottom: 48px;
    }
    .ttlarea h3 {
      font-family: var(--font-jp01-b);
      font-size: rem(28);
      @include lh(28,54);
      letter-spacing: .16em;
      margin-bottom: 40px;
      @include mq(1280) {
        br {
          display: none;
        }
      }
    }
  }
  .imgarea01 {
    img:nth-child(1) { translate: 0 0; }
    img:nth-child(2) { translate: 0 16.5% }
    img:nth-child(3) { translate: 0 -19%; }
  }
  .imgarea02 {
    img:nth-child(1) { translate: 0 0; }
    img:nth-child(2) { translate: 0 -15.5% }
    img:nth-child(3) { translate: 0 8%; }
  }
  .imgarea03 {
    img:nth-child(1) { translate: 0 -12.5%; }
    img:nth-child(2) { translate: 0 0 }
    img:nth-child(3) { translate: 0 16.5%; }
  }
  .imgarea04 {
    img:nth-child(1) { translate: 0 -17.5%; }
    img:nth-child(2) { translate: 0 0 }
  }
  @include mq(1420) {
    &:nth-of-type(3) .ttlarea h3 br {
      display: none;
    }
  }
  @include mq(sp, min ,ps) {
    .contentarea {
      width: 31.25vw;
      max-width: 600px;
      padding: 8.33vw 0;
    }
    &:nth-of-type(even) {
      flex-direction: row-reverse;
      .imgarea {
        left: -45px;
      }
      .contentarea {
        margin-right: 8.6vw;
      }
    }
    &:nth-of-type(odd) {
      .imgarea {
        right: 3.645833vw;
      }
      .contentarea {
        margin-left: 8.6vw;
      }
    }
    &:first-of-type {
      .contentarea {
        padding-top: 5.20833vw;
      }
    }
    &:nth-of-type(2) {
      .contentarea {
        padding-bottom: 11.45833vw;
      }
    }
    &:last-of-type {
      padding-bottom: 8.33vw;
      background-image: url(/wp/wp-content/themes/standard_sass/images/top/feature-illust01.png);
      background-repeat: no-repeat;
      background-position: left 30% bottom;
      background-size: 49%;
    }
    .imgarea.imgarea01 {
      top: 0;
      right: -3vw;
    }
    .imgarea.imgarea02-2 {
      left: inherit;
      right: -4vw;
      bottom: 0;
    }
    .imgarea.imgarea03 {
      top: 10vw;
    }
    .imgarea.imgarea04 {
      top: 4vw;
    }
  }
  @include mq(1600) {
    .imgarea {
      &.col3 {
        width: 40vw;
      }
    }
  }
  @include mq(1400) {
    .imgarea {
      &.col3 {
        width: 35vw;
      }
      &.imgarea01,
      &.imgarea04 {
        top: 30%;
      }
      &.imgarea03 {
        top: 50%;
      }
    }
  }
  @include mq(sp) {
    flex-direction: column-reverse;
    width: 86%;
    padding-top: 40px;
    padding-bottom: 40px;
    @include auto-margin;
    gap: 24px;
    &:last-of-type {
      padding-bottom: 46vw;
      background-image: url(/wp/wp-content/themes/standard_sass/images/top/feature-illust01.png);
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size: 80%;
    }
    .imgarea {
      position: static;
      gap: 8px;
      &.col1 {
        width: 30vw;
      }
      &.col2 {
        width: 60vw;
        img {
          width: calc((100% - 8px) / 2);
        }
      }
      &.col3 {
        width: 100%;
        img {
          width: calc((100% - 16px) / 3);
        }
      }
    }
    .contentarea {
      .num {
        font-size: rem(20);
        line-height: 1;
        letter-spacing: .08em;
        margin-bottom: 24px;
      }
      .ttlarea h3 {
        font-size: rem(24);
        margin-bottom: 20px;
        letter-spacing: .12em;
      }
    }
    .imgarea01 {
      padding-top: 16.5%;
      padding-bottom: 19%;
    }
    .imgarea02 {
      padding-top: 15.5%;
      padding-bottom: 8%;
    }
    .imgarea02-2 {
      display: none;
    }
    .imgarea03 {
      padding-top: 12.5%;
      padding-bottom: 16.5%;
    }
    .imgarea04 {
      padding-top: 17.5%;
    }
    &:nth-of-type(2) .ttlarea h3 br {
      display: inline;
    }
  }
}

/* home_flow
**************************************** */
.home_flow {
  padding-bottom: 30px;
  .flow_img {
    max-width: 713px;
    @include auto-margin;
  }
  .home_flow--read {
    margin-bottom: 110px;
  }
  .home_flow--table {
    width: 90%;
    max-width: 1160px;
    @include auto-margin;
    .step_ttl {
      padding-left: 0;
      padding-right: 1.66vw;
      .step_th {
        .step_num {
          margin-right: 3.5vw;
        }
      }  
    }
    table tbody td {
      width: 17.395833vw;
      max-width: 334px;
      padding: 40px 1.25vw;
    }
  }
  @include mq(1600) {
    @include mq(sp, min, ps) {
      .home_flow--table {
        .step_th {
          br {
            display: none;
          }
        }
        table tbody td {
          width: 20vw;
        }
      }
    }
  }
  @include mq(sp) {
    .home_ttl {
      margin-bottom: 0;
    }
    .flow_img {
      margin-top: 0;
      margin-bottom: 0;
    }
    .home_flow--table {
      width: 100%;
    }
    .home_flow--read {
      margin-bottom: 0;
    }
  }
}

/* home_company
**************************************** */
.home_company {
  padding-top: 364px;
  padding-bottom: 200px;
  .bg-ptnbox {
    z-index: -1;
  }
  .inner {
    max-width: 1440px;
    gap: 5.20833vw;
  }
  .imgbox {
    width: vw(480);
    max-width: 640px;
  }
  .home-ttl {
    padding-bottom: 48px;
  }
  .home_company--info {
    dl {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 32px 24px;
      @include lh(16, 28);
      letter-spacing: .16em;
      border-bottom: 1px solid var(--clr-wht);
      &:last-of-type {
        margin-bottom: 56px;
      }
      dt {
        min-width: 140px;
      }
    }
    .btn {
      text-align: right;
    }
  }
  @include mq(sp) {
    padding-top: 120px;
    padding-bottom: 120px;
    .inner {
      flex-direction: column-reverse;
      margin-left: auto;
      gap: 40px;
    }
    .imgbox {
      width: 80%;
      @include auto-margin;
    }
    .home_ttl {
      padding-bottom: 24px;
      text-align: center;
      .ttl-deco {
        left: 0;
        right: 0;
        margin: auto;
        &::after {
          left: -200%;
        }
      }
    }
    .contentarea {
      width: 100%;
    }
    .home_company--info {
      dl {
        display: flex;
        align-items: inherit;
        flex-direction: column;
        gap: 0;
        padding: 0;
        border-bottom: none;
        margin-bottom: 8px;
        &:last-of-type {
          margin-bottom: 32px;
        }
        dt,
        dd {
          padding: 24px 16px;
        }
        dt {
          font-size: sprem(16);
          padding-bottom: 8px;
        }
        dd {
          padding-top: 8px;
        }
      }
      .btn {
        text-align: center;
      }
    }
  }
}

/* home_works
**************************************** */
.home_works {
  .works--item {
    .item-more {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      @include center-flex;
      color: var(--clr-wht);
      text-align: center;
      opacity: 0;
      transition: var(--transit-default);
      background-color: rgba(41, 30, 9, .3);
      h3 {
        font-size: rem(17);
        letter-spacing: .12em;
        margin-bottom: 24px;
      }
      .font-en {
        line-height: 1;
        margin-right: 16px;
      }
      .cls-1 {
        fill: var(--clr-wht);
      }
    }
    &:hover .item-more {
      opacity: 1;
    }
  }
  @include mq(sp, min, ps) {
    position: relative;
    width: 100vw;
    height: 67.7083vw;
    translate: -200px 0;
    overflow-x: hidden;
    .ttlarea {
      position: absolute;
      top: 23.95833vw;
      left: 20.833vw;
      .home_ttl {
        margin-bottom: 60px;
        margin-left: 16px;
      }
    }
    .works--item {
      position: absolute;
      &.item1 {
        width: 22.29166vw;
        max-width: 428px;
        aspect-ratio: 428 / 610;
        top: 2.34375vw;
        right: 0;
      }
      &.item2 {
        width: 50vw;
        max-width: 35.729166vw;
        aspect-ratio: 686 / 438;
        bottom: 0;
        right: 17.08333vw;
      }
      &.item3 {
        width: 21.3541666vw;
        max-width: 410px;
        aspect-ratio: 410 / 288;
        top: 0;
        right: 43.229vw;
      }
      &.item4 {
        width: 11.979166vw;
        max-width: 230px;
        aspect-ratio: 230 / 275;
        top: 6.770833vw;
        right: 25.78125vw;
      }
      &.item5 {
        width: 16.666vw;
        max-width: 320px;
        aspect-ratio: 1 / 1;
        top: 25vw;
        right: 30.729166vw;
      }
      &.item6 {
        width: 14.0625vw;
        max-width: 270px;
        aspect-ratio: 270 / 320;
        bottom: 12.5vw;
        right: 0;
      }
      &.item7 {
        width: 10.833vw;
        max-width: 208px;
        min-width: 160px;
        aspect-ratio: 208 / 266;
        bottom: 13.54166vw;
        left: 160px;
        .item-more {
          padding-left: 3.4375vw;
        }
      }
    }
  }
  @include mq(1600) {
    .works--item {
      &.item7 {
        bottom: 2vw;
        right: inherit;
        left: 180px;
      }
    }
  }
  @include mq(sp) {
    position: relative;
    width: 100vw;
    height: 180vw;
    .ttlarea {
      position: absolute;
      top: 63.414vw;
      left: 7.31vw;
      .home_ttl {
        margin-bottom: 60px;
        margin-left: 16px;
      }
    }
    .works--item {
      position: absolute;
      bottom: 0;
      &.item1 {
        width: 29.26vw;
        aspect-ratio: 120 / 300;
        top: 0;
        right: 0;
      }
      &.item2 {
        width: 40.24vw;
        aspect-ratio: 164 / 144;
        top: 12.19vw;
        left: 0;
      }
      &.item3 {
        width: 34.14vw;
        aspect-ratio: 1 / 1;
        top: 82.92vw;
        right: 7.317vw;
      }
      &.item4 {
        width: 60.975vw;
        aspect-ratio: 250 / 160;
        bottom: 11.7vw;
        left: 0;
      }
      &.item5 {
        width: 24.39vw;
        aspect-ratio: 100 / 166;
        right: 0;
        bottom: 0;
      }
      &.item6,
      &.item7 {
        display: none;
      }
      .item-more {
        display: none;
      }
    }
  }
}

/* home_blog
**************************************** */
.home_blog {
  .blog_list {
    .post {
      justify-content: space-between;
      gap: 40px;
      padding: 48px 40px;
      letter-spacing: .16em;
      border-bottom: 1px solid var(--clr-berge5);
      transition: var(--transit-default);
      &:last-of-type {
        margin-bottom: 48px;
      }
      &:hover {
        opacity: .6;
      }
      .post--img {
        width: 10.41666vw;
        max-width: 200px;
        aspect-ratio: 200 / 160;
      }
      .post--txtarea {
        width: calc(100% - 240px);
      }
      .post--info {
        margin-bottom: 16px;
      }
      .post--ttl a {
        margin-bottom: 8px;
        width: 100%;
        font-size: rem(16);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .post--txt {
        font-size: rem(14);
        font-family: var(--font-main-r);
      }
      svg {
        margin-left: auto;
        min-width: 20px;
      }
    }
  }
  @include mq(sp, min, ps) {
    .inner {
      margin-left: 5.20833vw;
    }
  }
  @include mq(sp) {
    .blog_list {
      .post {
        justify-content: space-between;
        gap: 16px;
        padding: 24px 20px;
        &:last-of-type {
          margin-bottom: 24px;
        }
        .post--img {
          width: 20vw;
        }
        .post--txtarea {
          width: calc(100% - 20vw - 16px);
        }
        .post--info {
          margin-bottom: 16px;
        }
        .post--ttl a {
          margin-bottom: 8px;
          font-size: rem(14);
          white-space: inherit;
        }
        .post--txt {
          font-size: rem(12);
          font-family: var(--font-main-r);
        }
        svg {
          margin-left: auto;
          min-width: 14px;
        }
      }
    }
  }
}

/* waypoint
**************************************** */
.waypoint:not(.imgarea):not(.works_link) {
  opacity: 0;
  translate: 0 -40px;
  transition: translate 1s cubic-bezier(0, .5, .6, 1),
              opacity .6s cubic-bezier(0, .5, .6, 1);
  &.active {
    opacity: 1;
    translate: 0 0;
  }
}
/* --- home_products--list --- */
.home_products--list {
  .waypoint:nth-of-type(1) {
    transition-delay: 0;
  }
  .waypoint:nth-of-type(2) {
    transition-delay: .2s;
  }
  .waypoint:nth-of-type(3) {
    transition-delay: .4s;
  }
  .waypoint:nth-of-type(4) {
    transition-delay: .6s;
  }
}
/* --- imgarea --- */
.waypoint.imgarea {
  img {
    opacity: 1;
    transition: translate 1s cubic-bezier(0, .5, .6, 1),
                opacity .6s cubic-bezier(0, .5, .6, 1);
  }
  &:not(.active) {
    img {
      opacity: 0;
      translate: 0 0;
    }
  }
  &.imgarea01:not(.active) img:nth-child(1),
  &.imgarea02:not(.active) img:nth-child(1),
  &.imgarea03:not(.active) img:nth-child(2),
  &.imgarea04:not(.active) img:nth-child(2) {
    translate: 0 -5%;
  }
}
/* --- works--item --- */
.waypoint {
  .works--item {
    opacity: 0;
    translate: 0 -40px;
    transition: translate 1s cubic-bezier(0, .5, .6, 1),
                opacity .6s cubic-bezier(0, .5, .6, 1);
  }
  &.active {
    .works--item {
      opacity: 1;
      translate: 0 0;
    }
  }
}
.works--item {
  @for $num from 1 through 7 {
    &.item#{$num} {
      transition-delay: (($num * .2) - .2) + s;
    }
  }
}