@use "sass:map";

// SASS用の基本設定変数

// デザイン上の幅
$designWidth: 1920px;

// 色
$clr-main: #827762;
$clr-main2: #c0bbb0;
$clr-wht: #fff;


// CSS変数
:root {
  // typography
  --ltr-space-default: .08em;
  --line-height-default: 1.8;
  --line-height-hdr: 1.4;
  --line-height-none: 1;

  // font-families
  --font-main: "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
  --font-main-r: "こぶりなゴシック W3 JIS2004", "Koburina Gothic W3 JIS2004";
  --font-jp01: "Shuei KakuGo Gin M" , sans-serif;
  --font-jp01-b: "Shuei KakuGo Gin B" , sans-serif;
  --font-jp02: "Shuei MaruGo B" , sans-serif;
  --font-en: 'Josefin Sans', sans-serif;

  // font-sizes
  --fz-primary: 1.125rem;
  --fz-half: .5rem;

  // colors
  --clr-main: #827762;
  --clr-main-op: rgba(130, 119, 98, .5);//#827762
  --clr-body: #827762;
  --clr-wht: #fff;
  --clr-wht2: #EAE8E3;
  --clr-rd: #D79E8C;
  --clr-bl: #9AA6B2;
  --clr-br: #AAA18F;
  --clr-br2: rgba(161, 154, 135, .5);//#a19a87
  --clr-br3: #BBB09A;
  --clr-br4: rgba(161, 154, 135, .15);//#a19a87
  --clr-gr: #535353;
  --clr-berge: #E2DDD0;
  --clr-berge2: #BFB9A9;
  --clr-berge3: rgba(130, 119, 98, .15);//#827762
  --clr-berge4: rgba(175, 167, 149, .5);//#ebe6da
  --clr-berge5: rgba(168, 162, 137, .25);//#A8A289
  --clr-berge6: rgba(171, 159, 137, .2);//#AB9F89
  --clr-berge7: #AB9F89;//#AB9F89
  --clr-txt-sub: #A19A87;
  --clr-dark-blue: #101560;
  --clr-link: var(--clr-main);
  --clr-blk: #000;

  // background
  --bg-main: #ebe6da;
  --bg-sub: #eee;
  --bg-wht: #fff;
  --bg-off_wht: #dcedfc;
  --bg-blk: #000;

  //button colors
  --btn-bg-main: var(--clr-main);
  --btn-bg-main-hvr: var(--clr-dark-blue);
  --btn-clr-main: #fff;
  --btn-clr--sec: #fff;

  --btn-clr-wht: #333;
  --btn-bg-wht: #fff;
  --btn-clr-wht-hvr: #fff;
  --btn-bg-wht-hvr: var(--clr-dark-blue);

  // border colors
  --brd-main: #1D7FB7;

  // animation
  --transit-default: all 0.4s ease-out;

  // formy settings

}

// SASS Maps

$z-index: (
  "header" : 9999,
  "top": 9990,
  "aside" : 75,
  "middle" : 50,
  "main" : 1,
  "base" : 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160, // 2560px
  lg: 120, // 1920px
  pc: 85.375, // 1366px
  med: 67.5, // 1080px
  sp: 47.9375, // 767px
  sm: 40, // 640px
  xs: 34.375, // 500px
  se: 21.25, // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  'blue': (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  'red': (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  'green': (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  )
)