@use '../abstracts' as *;

/* simulator
**************************************** */
.page-simulator {
  .header,
  .gnav_btn {
    @include mq(sp) {
      position: absolute;
    }
  }
}
.page-simulator .page_ttl {
  padding-top: 80px;
  @include mq(sp, min, ps) {
    margin-left: vw(100);
  }
  @include mq(sp) {
    padding-top: 24px;
  }
}
.page-simulator .page_ttl-jp {
  padding-bottom: 8px;
}
.simulator--box {
  @include mq(sp, min, ps) {
    margin-top: 100px;
    margin-left: vw(100);
    gap: vw(80);
  }
  @include mq(sp) {
    margin-top: 48px;
    gap: vw(40);
  }
}
.simulator--deco {
  color: var(--clr-wht);
  font-size: clamp(rem(80), vw(104), rem(104));
  font-weight: 400;
  line-height: .8;
  letter-spacing: .08em;
  z-index: -1;
  @include mq(sp, min, ps) {
    position: absolute;
    top: vw(-48);
    right: 0;
  }
  @include mq(sp) {
    font-size: rem(32);
    order: 2;
  }
}

/* --- simulator_archive --- */
.simulator_archive {
  @include p-tb(rem(96));
  @include mq(sp) {
    @include p-tb(sprem(56));
  }
}
.simulator_link {
  padding: rem(28) vw(56) rem(28) vw(80);
  gap: rem(24);
  background-color: rgba($clr-wht, .5);
  @include mq(sp) {
    padding: 2rem 1rem;
    gap: sprem(24);
  }
}
.simulator_link:nth-child(even) {
  background-color: rgba($clr-main2, .5);
}
.simulator_link:not(:last-child) {
  margin-bottom: 3rem;
}
.simulator_link .simulator_link--subttl {
  margin-bottom: rem(16);
  font-size: rem(12);
  letter-spacing: .08em;
  font-weight: 600;
  opacity: .5;
  @include mq(sp) {
    margin-bottom: rem(8);
  }
}
.simulator_link--ttl {
  margin-bottom: vw(80);
  font-size: clamp(rem(40),vw(80),rem(72));
  line-height: 1;
  letter-spacing: .08em;
  font-weight: 400;
  @include mq(sp) {
    font-size: sprem(32);
    letter-spacing: .08em;
    margin-bottom: 0;
  }
}
.simulator_link > div {
  @include mq(sp) {
    width: 100%;
  }
}
.simulator_link .imgarea {
  overflow: hidden;
  @include mq(sp, min, ps) {
    width: vw(640);
  }
}
.simulator_link .imgarea img {
  transition: var(--transit-default);
}
.simulator_link .imgarea a:hover {
  scale: 1.05;
}
.simulator_link .btn a {
  font-size: rem(14);
  letter-spacing: .18em;
  padding-top: 4px;
  padding-bottom: 4px;
  svg {
    margin-bottom: 2px;
  }
}
.simulator_link .btn.pc-none {
  @include mq(sp, min, ps) {
    display: none;
  }
}
/* --- simulator_main --- */
.simulator_main {
  width: min(63%, 960px);
  @include mq(sp, min, ps) {
    flex: 1;
  }
  @include mq(sp) {
    position: sticky;
    display: flex;
    @include center-flex;
    top: 0;
    height: 280px;
    width: 100vw;
    translate: -7vw 0;
    padding: 8px;
    margin-bottom: 32px;
    z-index: 100;
    border-top: 1px solid rgba($clr-wht, .5);
    border-bottom: 1px solid rgba($clr-wht, .5);
    background-color: var(--clr-berge);
    transition: all .1s ease-in;
    order: 1;
  }
}
.simulator-attention {
  padding-left: 1em;
  text-indent: -1em;
  line-height: 1.4;
  @include mq(sp, min, ps) {
    margin-top: rem(24);
  }
  @include mq(sp) {
    font-size: sprem(11);
    letter-spacing: .02em;
  }
}
.simulator_main--content {
  @include mq(sp, min, ps) {
    position: sticky;
    top: 64px;
    max-width: 960px;
  }
}
.simulator-img {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  aspect-ratio: 960/640;
  max-width: 960px;
  background-size: contain;
  background-repeat: no-repeat;
  @include mq(sp) {
    width: 300px;
    margin-inline: auto;
    margin-bottom: 8px;
  }
}
.page-campus01 .simulator-img {
  background-image: url(/wp/wp-content/themes/standard_sass/images/simulator/campus01/default.jpg);
}
.page-campus02 .simulator-img {
  background-image: url(/wp/wp-content/themes/standard_sass/images/simulator/campus02/default.jpg);
}
.page-campus03 .simulator-img {
  background-image: url(/wp/wp-content/themes/standard_sass/images/simulator/campus03/default.jpg);
}
.simulator-mask,
.simulatour-click {
  position: absolute;
  top: 0;
  width: 100%;
  aspect-ratio: 960/640;
  z-index: 10;
}
.mask--item,
.click--item {
  position: absolute;
}
.mask--item svg,
.click--item svg {
  vertical-align: bottom;
}
.click--item {
  fill: transparent;
}
.mask-svg {
  fill-rule: evenodd;
  fill: rgba(255,255,255,.4);
  stroke: #fff;
  stroke-width: 2px;
  opacity: 0;
  transition: all .2s ease-out;
  cursor: pointer;
}
.mask--item.active:not(.selected) {
  pointer-events: none;
}
.mask--item.active .mask-svg {
  opacity: 1;
  cursor: default;
}
.mask--item.selected .mask-svg {
  opacity: 0;
}
@include mq(sp, min, ps) {
  .simulatour-click {
    display: none;
  }
  .mask-svg:hover {
    opacity: .7;
  }
  .mask--item.selected .mask-svg:hover {
    opacity: .4;
  }
}
@include mq(sp) {
  .simulatour-mask {
    pointer-events: none;
  }
}
.page-campus01 {
  /* --- mask --- */
  .mask-ceiling {
    top: 0;
    width: 100%;
  }
  .mask-floor {
    bottom: 0;
    width: 100%;
  }
  .mask-wall-right {
    right: 0;
    top: 17.2%;
    width: 30.25%;
  }
  .mask-wall-back {
    width: 41%;
    top: 42.8%;
    left: 29%;
  }
  .mask-wall-left {
    width: 29%;
    top: 19.5%;
    left: 0;
  }
  /* --- click --- */
  .click-ceiling {
    top: 0;
    width: 100%;
  }
  .click-floor {
    bottom: 0;
    width: 100%;
  }
  .click-wall-right {
    right: 0;
    bottom: 0;
    width: perc(385,1280,'%');
  }
  .click-wall-back {
    width: perc(524,1280,'%');
    top: 43.2%;
    left: perc(371,1280,'%');
  }
  .click-wall-left {
    width: perc(371,1280,'%');
    bottom: 0;
    left: 0;
  }
}
.page-campus02 {
  /* --- mask --- */
  .mask-ceiling {
    top: 0;
    width: 100%;
  }
  .mask-floor {
    bottom: 1px;
    width: 100%;
  }
  .mask-wall-right {
    right: 0;
    top: 16%;
    width: perc(782,1280,'%');
  }
  .mask-wall-left {
    top: 3.3%;
    left: 0;
    width: perc(497,1280,'%');
  }

  /* --- click --- */
  .click-ceiling {
    top: 0;
    width: 100%;
  }
  .click-floor {
    bottom: 0;
    width: 100%;
  }
  .click-wall-right {
    top: 16.1%;
    right: 0;
    width: perc(782,1280,'%');
  }
  .click-wall-left {
    top: 3.3%;
    left: 0;
    width: perc(498,1280,'%');
  }
}
.page-campus03 {
  /* --- mask --- */
  .mask-ceiling {
    top: 0;
    right: 0;
    width: 44.79166%;
    z-index: 1;
  }
  .mask-floor {
    bottom: 0;
    right: 0;
    width: 93.02%;
    z-index: 1;
  }
  .mask-wall-right {
    right: 0;
    top: 18.125%;
    width: 10.1042%;
  }
  .mask-wall-back {
    width: 89.896%;
    top: 0;
    left: 0;
    z-index: 0;
  }
  /* --- click --- */
  .click-ceiling {
    top: 0;
    right: 0;
    width: 44.79166%;
    z-index: 4;
  }
  .click-floor {
    bottom: 0;
    right: 0;
    width: 93.02%;
    z-index: 2;
  }
  .click-wall-right {
    right: 0;
    top: 18.125%;
    width: 10.15%;
    z-index: 3;
  }
  .click-wall-back {
    width: 89.896%;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
.simulator_main .btn a {
  cursor: pointer;
}

/* --- simulator_menu --- */
.simulator_menu {
  @include mq(sp, min, ps) {
    padding-top: 48px;
    width: clamp(368px, 40%, 480px);
  }
  @include mq(sp) {
    order: 3;
    padding-bottom: 40px;
  }
}
.menu_step {
  padding-bottom: 10px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--clr-wht);
}
.menu_step--item {
  height: 100%;
}
.menu_step--item a {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 48px;
  padding-top: 16px;
  gap: 4px;
  font-size: rem(13);
  letter-spacing: normal;
  line-height: 1;
  cursor: pointer;
}
.menu_step--item a .font-en {
  display: flex;
  align-items: flex-end;
  height: rem(20);
  font-size: rem(18);
  letter-spacing: .1em;
  line-height: 1;
  transition: var(--transit-default);
}
.menu_step--item a.active {
  color: var(--clr-rd);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    translate: -50% 0;
    width: 4px;
    height: 4px;
    background-color: currentColor;
    border-radius: 50vw;
  }
  .font-en {
    font-size: rem(24);
  }
}
.menu_list .ttlarea {
  margin-bottom: 32px;
  gap: 16px;
  @include mq(sp) {
    margin-bottom: 16px;
  }
}
a[href="javascript:void(0);"].parts-rotate {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: var(--clr-berge5);
  opacity: 0;
  pointer-events: none;
  transition: var(--transit-default);
  cursor: pointer;
  @include mq(sp) {
    padding: 8px;
  }
  &.active {
    opacity: 1;
    pointer-events: inherit;
  }
  svg {
    rotate: 0deg;
    transition: var(--transit-default);
    @include mq(sp) {
      width: 24px;
    }
  }
  &:hover {
    svg {
      rotate: 90deg;
    }
  }
}


/* --- menu_list --- */
.menu_list {
  display: none;
}
.menu_list.active {
  display: block;
}
.menu_list--ttl {
  display: flex;
  align-items: center;
  gap: vw(24);
  font-size: clamp(rem(18),vw(22),rem(22));
  line-height: 1;
  letter-spacing: .16em;
  line-height: 1;
  @include mq(sp) {
    font-size: sprem(14);
    gap: 8px;
  }
}
.menu_list--ttl .num {
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(vw(64), 56px);
  height: min(vw(64), 56px);
  padding-top: .15em;
  color: var(--clr-wht);
  font-size: rem(18);
  background-color: var(--clr-br);
  border-radius: 50vw;
  @include mq(sp) {
    width: 40px;
    height: 40px;
  }
}
.menu_list ul {
  display: grid;
  grid-template-columns: repeat(auto-fill,80px);
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  margin-bottom: 48px;
  @include mq(sp) {
    grid-template-columns: repeat(auto-fill,calc((100% - 24px) / 4));
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    margin-bottom: 24px;
  }
}
.menu_list--item a {
  display: block;
  font-size: rem(12);
  line-height: 1.2;
  text-align: center;
  letter-spacing: normal;
  cursor: pointer;
  @include mq(sp) {
    font-size: sprem(10);
  }
}
.menu_list--item a .menu_list--img {
  position: relative;
  display: block;
  width: 56px;
  @include auto-margin;
  margin-bottom: 4px;
  aspect-ratio: 1/1;
  overflow: hidden;
  outline: 1px solid transparent;
  outline-offset: -1px;
  transition: var(--transit-default);
}
.menu_list--item a:hover .menu_list--img {
  outline: 1px solid var(--clr-wht);
}
.menu_list--item a.selected .menu_list--img {
  &::after {
    content: '選択中';
    position: absolute;
    inset: 0;
    margin: auto;
    display: flex;
    @include center-flex;
    font-size: rem(14);
    line-height: 1;
    text-align: center;
    border: 2px solid var(--clr-wht);
    background-color: rgba($clr-wht, .7);
  }
}

/* --- .select_parts --- */
.select_parts {
  margin-top: 40px;
  padding: 32px;
  border: 1px solid var(--clr-wht);
  @include mq(sp) {
    padding: 24px 16px;
  }
}
.select_parts--ttl {
  font-size: rem(18);
  letter-spacing: .16em;
  margin-bottom: 24px;
}
.select_parts--list {
  margin-bottom: 24px;
}
.select_parts--item {
  position: relative;
  margin-bottom: 16px;
}
.select_parts--item:not(:last-child) {
  @include mq(sp) {
    padding-bottom: 8px;
    border-bottom: 1px dotted var(--clr-wht);
  }
}

.select_parts--list a.link-item {
  position: absolute;
  font-size: rem(12);
  color: var(--clr-wht);
  letter-spacing: normal;
  padding: 6px 20px 6px 8px;
  border: 1px solid var(--clr-wht);
  background-color: var(--clr-br);
  line-height: 1;
  opacity: 0;
  pointer-events: none;
  @include mq(sp, min, ps) {
    right: 0;
    top: 50%;
    translate: 0 -50%;
  }
  @include mq(sp) {
    top: 0;
    right: 0;
  }
  &.active {
    opacity: 1;
    pointer-events: inherit;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    translate: 0 -50%;
    width: 8px;
    height: 8px;
    border-top: 1px solid rgba($clr-wht, .5);
    border-right: 1px solid rgba($clr-wht, .5);
    rotate: 45deg;
  }
  &:hover {
    background-color: var(--clr-main);
  }
}
.select_parts--item input[type=checkbox] {
  display: none;
}
.select_parts--item label {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  @include mq(sp, min, ps) {
    padding-right: 90px;
  }
  @include mq(sp) {
    flex-wrap: wrap;
    gap: 8px;
  }
}
.select_parts--item .check {
  position: relative;
  width: 16px;
  height: 16px;
  border: 2px solid var(--clr-berge2);
  opacity: 0;
}
.select_parts--item input:checked + label .check {
  &::after {
    content: '';
    position: absolute;
    top: -5px;
    left: 2px;
    width: 9px;
    height: 15px;
    border-right: 2px solid var(--clr-body);
    border-bottom: 2px solid var(--clr-body);
    rotate: 45deg;
  }
}
.select_parts--item .area {
  display: inline-block;
  min-width: 64px;
  height: 22px;
  @include p-lr(6px);
  color: var(--clr-main);
  font-size: rem(13);
  line-height: 22px;
  text-align: center;
  border-radius: 3px;
  background-color: var(--clr-berge5);
}
.select_parts--item .name {
  font-size: rem(15);
  line-height: 1.4;
  letter-spacing: .12em;
  transition: var(--transit-default);
  @include mq(sp, min, ps) {
    flex: 1;
  }
  @include mq(sp) {
    width: 100%;
    font-size: sprem(14);
    padding-left: 24px;

  }
}
.select_parts--item {
  pointer-events: none;
  cursor: default;
}
.select_parts--item.active {
  pointer-events: inherit;
  cursor: pointer;
}
.select_parts--item.active .check {
  opacity: 1;
}
.select_parts--item.active label:hover {
  .name {
    opacity: .7;
  }
}

