@use '../abstracts' as *;

/* lps_parts--flow
********************************************** */
.lps_parts--flow-original .inner {
  max-width: 1380px;
}
.lps_parts--flow-original {
  table {
    width: 100%;
    height: 100%;
    thead {
      th {
        padding-bottom: 8px;
        .th_box {
          position: relative;
          display: flex;
          flex-direction: column;
          @include center-flex;
          font-family: var(--font-jp01);
          font-size: rem(15);
          letter-spacing: .12em;
          line-height: 1;
          &::after {
            content: "";
            position: absolute;
            top: 8px;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            @include rect(72);
            border-radius: 50vw;
            background-color: var(--clr-wht);
            opacity: .5;
            z-index: -1;
          }
          img {
            @include rect(38);
            object-fit: contain;
            margin-bottom: 8px;
          }
        }
      }
    }
    tbody {
      tr {
        position: relative;
        border-bottom: 1px solid var(--clr-main);
        &:not(:first-of-type)::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          width: 60px;
          height: 20px;
          clip-path: polygon(0 0, 100% 0, 50% 50%);
          background-color: var(--clr-main);
        }
      }
      th,
      td {
        position: relative;
        padding: 40px 3.333vw;
        vertical-align: middle;
        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 1px;
          height: calc( 100% - 80px );
          background-color: var(--clr-berge3);
        }
      }
      th {
        padding: 40px 3.333vw 40px 1.666vw;
        .step_th {
          display: flex;
          align-items: center;
          font-size: rem(20);
          @include lh(20,36);
          .step_num {
            width: 5.1vw;
            height: 5.1vw;
            max-width: 98px;
            min-width: 75px;
            max-height: 98px;
            min-height: 75px;
            margin-right: 5.2vw;
            font-size: rem(24);
            font-weight: 400;
            line-height: .75;
            color: var(--clr-body);
            border: 1px solid var(--clr-body);
            border-radius: 50vw;
            aspect-ratio: 1/1;
            .small {
              font-size: rem(12);
              margin-top: 4px;
              margin-bottom: 8px;
            }
          }
          .text {
            font-family: var(--font-jp01);
            letter-spacing: .16em;
            text-align: left;
            span {
              font-size: 80%;
            }
          }
        }
      }
      td {
        width: 21.77vw;
        .step_td {
          display: flex;
          @include center-flex;
          width: 100%;
          min-height: 100%;
          padding: 16px 24px;
          background-color: rgba(255, 255, 255, .5);
          font-family: var(--font-main-r);
          text-align: center;
        }
      }
    }
  }
  @include mq(sp) {
    position: relative;
    .flow--table {
      table {
        thead {
          position: sticky;
          top: 0;
          z-index: 99;
          tr {
            padding-top: 80px;
            @include bg-ptn1;
          }
          td {
            width: 26%;
          }
          th {
            .th_box {
              * {
                z-index: 2;
              }
              &::after {
                z-index: 1;
              }
            }
          }
          .flow-original--th {
            padding-top: 80px;
            padding-bottom: 24px;
          }
        }
        tbody {
          tr {
            border-bottom: none;
            &:last-of-type td {
              border-bottom: none;
            }
            &:not(:first-of-type)::after {
              left: inherit;
              right: 37%;
              translate: 30px 0;
            }
          }
          .step_ttl {
            position: absolute;
            width: 100%;
            height: 100px;
            padding: 30px 0 0;
            .step_th {
              justify-content: space-between;
              .text {
                font-family: var(--font-jp01-b);
                font-size: rem(16);
                padding: 12px;
                margin: 0 12px;
                background-color: rgba(255, 255, 255, .7);
                text-align: center;
                width: 74%;
              }
            }
          }
          td {
            width: 37%;
            padding: 120px 1.25vw 20px;
            border-bottom: 1px solid #827762;
            &::after {
              content: "";
              height: 100%;
            }
            &:first-of-type::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              width: 1px;
              height: 100%;
              background-color: var(--clr-berge3);
            }
            .step_td {
              font-size: rem(13);
              padding: 0;
              background-color: transparent;
            }
          }
          th {
            padding: 40px 3.333vw 40px 1.666vw;
            .step_th {
              display: flex;
              align-items: center;
              font-size: rem(20);
              @include lh(20,36);
              .step_num {
                width: 5.1vw;
                max-width: 98px;
                min-width: 72px;
                margin-right: 5.2vw;
                font-size: rem(24);
                font-weight: 400;
                line-height: .75;
                color: var(--clr-body);
                border: 1px solid var(--clr-body);
                border-radius: 50vw;
                aspect-ratio: 1/1;
                .small {
                  font-size: rem(12);
                  margin-top: 4px;
                  margin-bottom: 8px;
                }
              }
              .text {
                font-family: var(--font-jp01);
                letter-spacing: .16em;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}