@use '../abstracts' as *;

/* sitemap
*************************************************** */
.sitemap--menu {
  flex: 1;
}
.sitemap--menu a,
.sitemap--menu span {
  display: block;
}
.sitemap--menu a {
  position: relative;
}
.sitemap--menu a:hover {
  color: var(--clr-main);
}
.sitemap--menu > li > a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.125rem; /* 18px */
  border-bottom: 1px solid var(--clr-main-op);
  padding-bottom: 1em;
  padding-left: 1.25em;
  padding-right: 1.25em;
  margin-bottom: 1.25em;
}
.sitemap_sub_nav--wrap {
  padding-left: 1.25em;
  margin-bottom: 1.5em;
}
.sitemap_sub_nav a {
  padding-top: .4em;
  padding-bottom: .4em;
  padding-left: 1.25em;
}
.sitemap_sub_nav a::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 1px solid var(--clr-main);
  border-right: 1px solid var(--clr-main);
  transform: rotate(45deg);
}

@include mq(sp, min, ps) {
  .sitemap--menu:not(:last-child) {
    margin-right: 7.41%; /* 80px */
  }
}
@include mq(sp) {
  .sitemap--menu:not(:last-child) {
    margin-bottom: 24px;
  }
}