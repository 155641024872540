@use '../abstracts' as *;

/* ##############################################################################

    SINGLE

############################################################################## */

  /* --- アイキャッチ --- */
  .eyecatch {
    margin-bottom: 2rem;
  }

  /* --- 目次 --- */
  div#ez-toc-container {
    padding: 24px;
    background: #fafafa;
  }
  .ez-toc-title-container {
    margin-bottom: 10px;
  }
  div#ez-toc-container p.ez-toc-title {
    color: #000;
    font-weight: bold;
    font-size: 1.25rem;
  }
  #ez-toc-container.counter-hierarchy ul {
    font-size: 1.0625rem;
  }
  #ez-toc-container.counter-hierarchy ul li {
    margin-top: 8px;
  }
  #ez-toc-container.counter-hierarchy ul ul {
    margin-left: 1rem;
  }
  #ez-toc-container.counter-hierarchy ul ul a {
    position: relative;
    display: inline-block;
    padding-left: 1rem;
  }
  #ez-toc-container.counter-hierarchy ul ul a::before {
    content: "";
    position: absolute;
    top: .65rem;
    left: 0;
    width: .6rem;
    height: 0;
    border-bottom: 1px solid;
  }

/* pass
**************************************** */
  #pass p {
    margin-bottom: 1em;
  }
  #pass input[name="post_password"] {
    padding: 5px;
    border: solid 1px #aaa;
    outline: none;
  }
  #pass input[type="submit"] {
    padding: 4px 16px;
    letter-spacing: .15em;
    background-color: #ddd;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: var(--transit-default, all .4s ease-out);
  }
  #pass input[type="submit"]:hover {
    opacity: .7;
  }

/* blog
**************************************** */
.blog--info {
  @include mq(sp) {
    .section_ttl {
      font-size: rem(28);
    }
  }
}

/* blog--share
**************************************** */
  .blog--share {
    margin-top: 2rem;
    @include mq(sp) {
      margin-top: 40px;
    }
  }
  .blog--share_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: .375rem; /* 6px */
    @include mq(sp) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .blog--share_list a {
    display: flex;
    @include center-flex;
    height: 40px;
    padding: .5em;
    line-height: 1;
    font-size: .75rem; /* 12px */
    font-weight: bold;
    border: 1px solid var(--clr-main-op);
  }
  .blog--share_list li {
    &.twitter a { color: #2BA1F2; }
    &.facebook a { color: #2477F2; }
    &.line a { color: #27C754; }
    &.pocket a { color: #EF4056; }
    &.linkedin a { color: #2867B2; }
    &.hatena a { color: #29A4DE; }
  }
  .blog--share_list img {
    margin-right: 10px;
  }
  .blog--share_list a:hover {
    opacity: .5;
  }

/* author
********************************************** */
  /* この記事を書いた人 */
  .mce-content-body:has(+ .writer_box) {
    margin-bottom: rem(40);
  }
  .writer_box--wrapper {
    padding-block: 40px;
  }
  .writer_box {
    padding: 4px 40px 24px;
    border: 1px solid var(--clr-main);
  }
  .writer_box:has(+ .writer_box) {
    margin-bottom: 32px;
  }
  .writer_box--img {
    max-width: 160px;
  }
  .writer_box--ttl {
    width: 100%;
    transform: translateY(-20px);
  }
  .writer_box--ttl span {
    display: inline-block;
    background-color: var(--clr-main);
    border-radius: 40px;
    padding: 6px 24px 8px;
    color: #fff;
    font-size: 0.875rem;
  }
  .writer_box--txtarea {
    flex: 1;
    margin-left: 16px;
  }
  .writer_name {
    font-size: 1.25rem;
  }

/* blog-card
********************************************** */
/* --- blog_card --- */
.blog_card {
	position: relative;
  margin-top: rem(40);
  margin-bottom: rem(40);
  padding: rem(24);
  border: 1px solid var(--clr-blk);
}
.blog_card--caption {
  position: absolute;
  top: -1em;
  left: 32px;
  z-index: 1;
  display: inline-block;
  padding: 0 24px;
  font-size: clamp(14px, calc(16 / 1920 * 100vw), 18px);
  line-height: 2;
  background: #fff;
  border-radius: 100vmax;
}
.blog_card--content {
  display: grid;
  grid-template-columns: rem(200) 1fr;
  align-items: center;
  gap: 16px;
  @include mq(sp) {
    grid-template-columns: sprem(120) 1fr;
  }
}
.blog_card--img {
  aspect-ratio: 3/2;
  img {
    margin: 0;
    height: 100%;
  }
}
.blog_card--txtarea {
  flex: 1;
}
.blog_card--ttl {
  font-size: rem(18);
  margin-bottom: rem(8);
}
.blog_card--txt {
  font-size: rem(14);
}
.clip-txt2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

/* blog--related
**************************************** */
  .blog--related .post--ttl {
    font-size: rem(20);
  }
  .posts_scroll {
    overflow: auto;
    padding-bottom: 2rem;
  }
  .posts_scroll .post {
    display: block;
    width: 46%;
    flex-shrink: 0;
    @include mq(sp) {
      width: 70%;
    }
  }
  .posts_scroll .post:not(:last-child) {
    margin-right: 1rem;
  }
  .posts_scroll .post--img {
    margin-bottom: 1rem;
  }
  .posts_scroll .post--img::before {
    padding-top: 66.666%;
  }
  .posts_scroll .post--txt {
    overflow: hidden; 
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; 
  }
  .posts_scroll::-webkit-scrollbar {
    height: 6px;
  }
  .posts_scroll::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  .posts_scroll::-webkit-scrollbar-track-piece {
    background-color: #eee;
  }

/* wp-pagenavi
**************************************** */
  .wp-pagenavi {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    @include mq(sp) {
      margin-top: 32px;
    }
  }
  .archive-works .wp-pagenavi,
  .archive-products .wp-pagenavi {
    margin-top: 0;
  }
  .wp-pagenavi .pages {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
  }
  .wp-pagenavi a {
    position: relative;
    padding: .5em 1em;
    background-color: var(--clr-br);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .wp-pagenavi a:hover {
    opacity: .7;
  }
  .wp-pagenavi a {
    color: #fff;
  }
  .wp-pagenavi > *:not(.pages) {
    max-width: calc(100% / 2.6);
  }
  .archive .wp-pagenavi > *:not(.pages) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    @include mq(sp) {
      width: 24px;
      height: 30px;
    }
  }
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    width: 40px;
    @include mq(sp) {
      width: 30px;
    }
  }
  .wp-pagenavi > *:not(.pages) {
    margin-bottom: 4px;
  }
  .wp-pagenavi > *:not(.pages):not(:last-child) {
    margin-right: 4px;
    @include mq(sp) {
      margin-right: 2px;
    }
  }
  .wp-pagenavi .extend {
    width: 24px !important;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-top: 2px solid;
    border-right: 2px solid;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    width: 8px;
    height: 8px;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .first::after {
    transform: rotate(-135deg);
  }
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .last::after {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="prev"]::before {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="next"]::before {
    transform: rotate(-135deg);
  }
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before {
    left: -6px;
    @include mq(sp) {
      left: -5px;
    }
  }
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    right: -6px;
    @include mq(sp) {
      right: -5px;
    }
  }

@include mq(sp) {
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    width: 32px;
  }  
}

/* products
**************************************** */
.single-products .page_ttl {
  margin-bottom: 80px;
}
.products_single {
  margin-bottom: 120px;
}
@include mq(sp) {
  .single-products .page_ttl {
    margin-bottom: 8px;
  }
  .products_single {
    margin-bottom: 60px;
  }
}

/* --- prodcts_info --- */
.products_info {
  margin-bottom: 100px;
  .products_info--img {
    width: 55.12%;
    max-width: 840px;
  }
  .product-photo {
    margin-bottom: 20px;
    .product-photo--item {
      aspect-ratio: 840 / 546;
    }
  }
  .product-thumb {
    .slick-track {
      margin-left: 0;
    }
    .product-thumb--item {
      width: 60px !important;
      margin-right: 20px;
      margin-bottom: 20px;
      aspect-ratio: 1/1;
      transition: var(--transit-default);
      &.slick-current {
        outline: 1px solid #fff;
        outline-offset: -1px;
      }
      &:hover {
        opacity: .8;
      }
      img {
        width: 100%;
        max-width: inherit;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .products_info--dtl {
    width: 40%;
    max-width: 610px;
    padding-top: 16px;
    .products--ttl {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      font-family: var(--font-jp01-b);
      font-size: rem(30);
      letter-spacing: .12em;
      line-height: 1.2;
      span {
        margin-bottom: 8px;
      }
      .products--paint {
        padding-bottom: .25em;
        font-size: rem(16);
        letter-spacing: .08em;
        line-height: 1;
      }
    }
    .products--ttl_en {
      font-family: var(--font-en);
      font-size: rem(14);
      font-weight: 400;
      color: var(--clr-berge2);
      letter-spacing: .12em;
    }
    .favorite--link_ttl {
      position: absolute;
      right: 0;
      bottom: 4px;
      min-width: 160px;
      font-size: rem(12);
      font-family: var(--font-jp01-b);
      text-align: right;
      a {
        display: flex;
        align-items: center;
        color: var(--clr-main);
        &:hover {
          opacity: .7;
        }
      }
    }
    .products--info_txt p {
      letter-spacing: .1em;
      line-height: 1.75;
    }
    .products--standard {
      background-color: var(--clr-berge3);
      padding: 32px;
      .standard--ttl {
        font-size: rem(18);
        line-height: 1;
        padding-bottom: 15px;
        margin-bottom: 24px;
        border-bottom: 1px solid var(--clr-wht2);
      }
      .standard--txt {
        font-family: var(--font-main-r);
        font-size: rem(15);
        letter-spacing: .06em;
      }
      .standard--attention {
        font-family: var(--font-main-r);
        font-size: rem(15);
        color: var(--clr-gr);
        letter-spacing: .06em;
      }
    }
  }
  @include mq(sp) {
    margin-top: 24px;
    margin-bottom: 40px;
    .products_info--img {
      width: 100%;
    }
    .product-photo {
      margin-bottom: 10px;
    }
    .product-thumb {
      .slick-track {
        width: 100% !important;
        margin-right: 0;
      }
      .product-thumb--item {
        width: 40px !important;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
    .products_info--dtl {
      width: 100%;
      .products--ttl {
        font-size: rem(26);
      }
      .products--ttl_en {
        font-size: rem(14);
        padding-right: 0;
      }
      .favorite--link_ttl {
        position: static;
        display: flex;
        justify-content: flex-end;
        padding-top: 8px;
        padding-bottom: 8px;
      }
      .products--standard {
        .standard--ttl {
          font-size: rem(16);
          margin-bottom: 16px;
        }
      }
    }
  }
}

/* --- prodcts_case --- */
.products_case {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 32px;
  .ttlarea {
    position: absolute;
    top: 15px;
    left: 0;
    .case--ttl_en {
      opacity: .7;
    }
    .case--ttl {
      margin-bottom: 24px;
      letter-spacing: .12em;
    }
    .case--ttl_deco {
      position: relative;
      display: block;
      @include rect(5);
      margin-bottom: 60px;
      background-color: var(--clr-wht);
      border-radius: 50vw;
      &::before,&::after {
        content: "";
        position: absolute;
        @include rect(5);
        background-color: var(--clr-wht);
        border-radius: 50vw;
      }
      &::before {
        left: 200%;
      }
      &::after {
        left: 400%;
      }
    }
  }
  .case-thumb {
    width: 41.8635%;
    max-width: 638px;
    margin-right: 20px;
    &.len-3,
    &.len-4 {
      width: 28.346%;
      max-width: 432px;
      .slick-track {
        .case-thumb--item {
          width: calc((100% - 10px) / 2) !important;
        }
      }
    }
    .slick-track {
      display: flex;
      justify-content: right;
      flex-wrap: wrap-reverse;
      width: 100% !important;
      gap: 10px;
      &::before,&::after {
        content: none;
      }
    }
    .case-thumb--item {
      width: calc((100% - 20px) / 3) !important;
      aspect-ratio: 212 / 144;
      transition: var(--transit-default);
      &:hover {
        opacity: .8;
      }
    }
  }
  .case-photo {
    width: vw(840);
    max-width: 840px;
    .case-photo--item {
      aspect-ratio: 840 / 546;
    }
  }
  @include mq(sp) {
    flex-direction: column;
    margin-bottom: 32px;
    .ttlarea {
      position: static;
      width: 100%;
      .case--ttl_en {
        opacity: .7;
      }
      .case--ttl {
        margin-bottom: 24px;
        letter-spacing: .12em;
      }
      .case--ttl_deco {
        @include rect(5);
        margin-bottom: 32px;
      }
    }
    .case-thumb {
      width: 100%;
      margin-right: 0;
      order: 1;
      &.len-3,
      &.len-4 {
        width: calc((100% - 16px) / 3);
        max-width: inherit;
      }
      .slick-track {
        justify-content: space-between;
        .case-thumb--item {
          width: calc((100% - 16px) / 3) !important;
          margin-bottom: 8px;
        }
      }
      .slick-track {
        gap: 0;
      }
      .case-thumb--item {
        width: calc((100% - 20px) / 3) !important;
        aspect-ratio: 212 / 144;
      }
    }
    .case-photo {
      width: 100%;
      margin-bottom: 8px;
    }
  }
}
.products_btn--box {
  margin-top: 120px;
  @include mq(sp) {
    margin-top: 60px;
  }
}
.single-thumb li {
  cursor: pointer;
}
.single-thumb .slick-track {
  transform:unset!important;
}

/* --- products_works --- */
.products_works {
  padding-top: 40px;
  .editor-ttl {
    font-size: rem(20);
    padding-left: 2.5rem;
    margin-bottom: 24px;
    &::before {
      width: 1.75rem;
    }
  }
  @include mq(sp) {
    width: 100%;
    padding-top: 16px;
    .editor-ttl {
      font-size: rem(18);
      padding-left: 2rem;
      margin-bottom: 16px;
      &::before {
        top: .75em;
        width: 1.25rem;
      }
    }
  }
}
.use_works {
  gap: vw(24);
  .use_works--item {
    width: calc((100% - vw(48)) / 3);
    padding: 12px;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background-color: var(--clr-berge);
      outline: 1px solid var(--clr-wht);
      outline-offset: -1px;
      opacity: 0;
      top: 0;
      left: 0;
      transition: var(--transit-default);
      z-index: -1;
    }
    &:hover::before {
      opacity: 1;
    }
    .use_works--img {
      position: relative;
      display: flex;
      @include center-flex;
      pointer-events: none;
      aspect-ratio: 212/144;
      .hover--text {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        @include center-flex;
        gap: 20px;
        margin: auto;
        color: var(--clr-wht);
        font-size: rem(15);
        z-index: 4;
        opacity: 0;
        transition: var(--transit-default);
        svg .cls-1 {
          fill: var(--clr-wht);
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        background-color: rgba(0, 0, 0, .05);
        z-index: 2;
        opacity: 0;
        transition: var(--transit-default);
      }
    }
    &:hover {
      .hover--text { 
        opacity: 1;
      }
      .use_works--img::after {
        opacity: 1;
      }
    }
    .use_works--text {
      .item--ttl {
        font-size: rem(18);
        margin-bottom: 4px;
        a {
          color: var(--clr-main);
        }
      }
      .item-deta {
        font-size: rem(14);
      }
    }
  }
  @include mq(sp) {
    gap: 16px;
    .use_works--item {
      width: calc((100% - 16px) / 2);
      padding: 0;
      &::before {
        content: none;
      }
      .use_works--img {
        .hover--text {
          display: none;
        }
        &::after {
          content: none;
        }
      }
      .use_works--text {
        .item--ttl {
          font-size: rem(16);
        }
      }
    }
  }
}


/* works
**************************************** */
.works_info {
  width: 94.5%;
  max-width: 1440px;
  @include auto-margin;
}
.works_info--img {
  margin-bottom: 72px;
  .works-photo {
    margin-bottom: 10px;
    .slick-arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 20px;
      height: 20px;
      transform: rotate(45deg) skew(5deg, 5deg);
      &.slick-prev {
        border-bottom: 1px solid var(--clr-main);
        border-left: 1px solid var(--clr-main);
        left: -40px;
      }
      &.slick-next {
        border-top: 1px solid var(--clr-main);
        border-right: 1px solid var(--clr-main);
        right: -40px;
      }
    }
    .works-photo--item {
      aspect-ratio:  1440 / 720;
      background-color: var(--clr-br4);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .works-thumb {
    .slick-track {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-right: 0;
      transform: unset !important;
      width: 100% !important;
    }
    .works-thumb--item {
      float: none;
      width: 96px !important;
      aspect-ratio:  96 / 68;
      margin-left: 10px;
      margin-bottom: 10px;
      background-color: var(--clr-wht);
      &.slick-current {
        opacity: .6;
        outline: 1px solid #fff;
        outline-offset: -1px;
      }
    }
  }
}
@include mq(sp) {
  .works_info {
    width: 100%;
    @include auto-margin;
  }
  .works_info--img {
    margin-bottom: 36px;
    .works-photo {
      margin-bottom: 4px;
      padding-left: 2px;
      padding-right: 2px;
      .slick-arrow {
        width: 15px;
        height: 15px;
        &.slick-prev {
          left: -16px;
        }
        &.slick-next {
          right: -16px;
        }
      }
    }
    .works-thumb {
      .works-thumb--item {
        width: calc((100% - 24px) / 6) !important;
        margin: 0 2px 4px;
        aspect-ratio: 16 / 9;
      }
    }
  }
}

/* --- works_info--txt --- */
.works_info--txt {
  margin-bottom: 100px;
  h2 {
    max-width: 720px;
    letter-spacing: .2em;
    line-height: 1.785;
  }
  p {
    max-width: 820px;
    letter-spacing: .1em;
  }
  @include mq(sp) {
    margin-bottom: 50px;
    h2 {
      font-size: rem(22);
    }
  }
}

/* --- works_info--products --- */
.works_info--products {
  margin-bottom: 80px;
}
.use_products {
  gap: 24px;
  .use_products--item {
    width: 244px;
    padding: 12px;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background-color: var(--clr-berge);
      outline: 1px solid var(--clr-wht);
      outline-offset: -1px;
      opacity: 0;
      top: 0;
      left: 0;
      transition: var(--transit-default);
      z-index: -1;
    }
    &:hover::before {
      opacity: 1;
    }
    .use_products--img {
      position: relative;
      display: flex;
      @include center-flex;
      pointer-events: none;
      aspect-ratio: 1 / 1;
      .hover--text {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        @include center-flex;
        gap: 20px;
        margin: auto;
        color: var(--clr-wht);
        font-size: rem(15);
        z-index: 4;
        opacity: 0;
        transition: var(--transit-default);
        svg .cls-1 {
          fill: var(--clr-wht);
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        background-color: rgba(0, 0, 0, .05);
        z-index: 2;
        opacity: 0;
        transition: var(--transit-default);
      }
    }
    &:hover {
      .hover--text { 
        opacity: 1;
      }
      .use_products--img::after {
        opacity: 1;
      }
    }
    .use_products--text {
      .item--ttl {
        font-size: rem(18);
        margin-bottom: 4px;
        a {
          color: var(--clr-main);
        }
      }
      .item-deta {
        font-size: rem(14);
      }
    }
  }
}
@include mq(sp) {
  .works_info--products {
    margin-bottom: 40px;
  }
  .use_products {
    gap: 16px;
    .use_products--item {
      width: calc((100% - 16px) / 2);
      padding: 6px;
      &::before {
        content: none;
      }
      .use_products--img {
        .hover--text {
          display: none;
        }
        &::after {
          content: none;
        }
      }
      .use_products--text {
        .item--ttl {
          font-size: rem(16);
          margin-bottom: 4px;
        }
        .item-deta {
          font-size: rem(14);
        }
      }
    }
  }
}

/* --- works_info--relation --- */
.works_info--relation {
  margin-bottom: 120px;
  dl {
    gap: 16px;
    padding: 16px 24px;
    border-bottom: 1px solid var(--clr-main-op);
    dt {
      width: 200px;
      font-family: var(--font-jp01-b);
      letter-spacing: .12em;
    }
    dd {
      font-size: rem(15);
      font-family: var(--font-jp01);
      letter-spacing: .12em;
      a {
        display: block;
        & + a {
          margin-top: 4px;
        }
      }
    }
  }
  @include mq(sp) {
    margin-bottom: 48px;
    dl {
      gap: 16px;
      padding: 16px 12px;
      dt {
        width: 120px;
      }
    }
  }
}

/* --- category--link --- */
.category--link {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  a {
    display: block;
    position: relative;
    padding-right: 16px;
    padding-left: 32px;
    min-width: 140px;
    color: var(--clr-main);
    font-size: .9375rem;
    letter-spacing: .12em;
    line-height: 32px;
    text-align: center;
    border: 1px solid var(--clr-main-op);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 16px;
      margin: auto;
      width: 5px;
      height: 5px;
      border-radius: 50vw;
      background-color: var(--clr-main);
      transition: var(--transit-default);
    }
    &:hover {
      color: var(--clr-wht);
      background-color: var(--clr-br3);
      &::before {
        background-color: var(--clr-wht);
      }
    }
  }
  @include mq(sp) {
    gap: 8px;
    a {
      padding-right: 16px;
      padding-left: 24px;
      min-width: inherit;
      font-size: rem(13);
      line-height: 28px;
      &::before {
        left: 10px;
      }
    }
  }
}
