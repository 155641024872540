@use '../abstracts' as *;

/* --- section_pdg --- */
.section_pdg {
  padding-top: 120px;
  padding-bottom: 120px;
  @include mq(sp) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.section_pdg.bg-ptn {
  padding-top: 300px;
  padding-bottom: 260px;
  @include mq(sp) {
    padding-top: 100px;
    padding-bottom: 120px;
  }
}
.section_pdg-sm {
  padding-top: 80px;
  padding-bottom: 80px;
  @include mq(sp) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
