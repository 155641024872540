@use '../abstracts' as *;

/* ##############################################################################

  FORMY

############################################################################## */
/*! purgecss start ignore */
#formy_form {
  padding-bottom: 0;
}
#formy_form table { width: 100%; }
#formy_form th,
#formy_form td {
  padding: 16px;
  vertical-align: middle;
  border-bottom: solid 1px var(--clr-main-op);
  @include mq(sp) {
    display: block;
    width: auto;
    @include p-lr(0);
  }
}
#formy_form th {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  width: 34%;
  @include mq(sp) {
    width: 100%;
    border-bottom:none;
    padding-bottom: 0;
    white-space: normal;
    font-weight: bold;
  }
}
#formy_form table td {
  font-family: var(--font-main-r);
}
#formy_form table input[type="text"],
#formy_form table input[type="email"],
#formy_form table input[type="tel"],
#formy_form table input[type="date"],
#formy_form table input[type="password"],
#formy_form table textarea {
  width: 100%;
  border-radius: 3px;
  vertical-align: bottom;
}
#formy_form table input[type="text"],
#formy_form table input[type="email"],
#formy_form table input[type="tel"],
#formy_form table input[type="date"],
#formy_form table input[type="password"],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: 5px 15px;
  border: 1px solid #ccc;
  font: inherit;
  font-size: 1rem;
}
#formy_form textarea {
  height: 100px;
  &.your-dtl {
    height: 220px;
  }
}
#formy_form select { height: 40px; }
#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#formy_form input:hover { opacity:.7; }
#formy_form textarea:hover { opacity:.7; }
#formy_form input:focus { outline: none; }
#formy_form .parsley-validated {  background-color: #eee; }
#formy_form .parsley-error {  background-color: #fee; }
#formy_form .parsley-success {  background-color: #fff; }
p:has(.help_text) {
  padding-left: 1em;
  text-indent: -1em;;
}
.help_text {
  display: inline-block;
  margin-top: rem(8);
  font-size: .875rem; /* 14px */
  line-height: 1.4;
  color: #999;
}
.hidden_help { display: none; }
.formy_privacy div {
  overflow-y: scroll;
  height: 140px;
  border: solid 1px #ccc;
  font-size: .875rem; /* 14px */
  padding: 8px 16px;
}
.requiredIcon {
  background-color: var(--clr-rd);
  color: #fff;
  margin: 0 0 0 1em;
  font-size: .75rem; /* 12px */
  padding: 2px 5px;
  border-radius: 3px;
  float: right;
}
#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: 32px;
  text-align: center;
  @include mq(sp) {
    padding-top: 8px;
  }
}
#formy_btn input {
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: #fff;
  border-radius: 3px;
  padding: 16px 32px;
  margin-bottom: 24px;
  -webkit-appearance: none;
  appearance: none;
  @include mq(sp) {
    width: 100%;
    background-size: contain;
    background-position: center;
  }
}
#formy_form ul li input[type="radio"],
#formy_form ul li input[type="checkbox"] { display: none !important; }
#formy_form ul li label {
  position: relative;
  display: inline-block;
  padding: .5em 8px .5em 40px;
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
}
#formy_form ul li label:hover { opacity: .7; }
#formy_form ul li label::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid #788b93;
  left: 16px;
  top: 12px;
}
#formy_form ul li input[type="radio"] + label::before { border-radius: 10px; }
#formy_form ul li input[type="radio"]:checked + label,
#formy_form ul li input[type="checkbox"]:checked + label {
  color: #e75f5f;
  font-weight: bold;
}
#formy_form ul li input[type="radio"]:checked + label::before,
#formy_form ul li input[type="checkbox"]:checked + label::before {
  border-color: #e75f5f;
}
#formy_form ul li input[type="radio"]:checked + label::after,
#formy_form ul li input[type="checkbox"]:checked + label::after {
  content: "";
  width: 10px;
  height: 18px;
  top: 4px;
  left: 20px;
  border-right: 2px solid #e75f5f;
  border-bottom: 2px solid #e75f5f;
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}
.formy_confirm { background-color: var(--clr-main); }
.formy_submit_disabled { background-color: #ccc; }
#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}
#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: 8px 27px;
  min-width: auto;
  font-size: 92%;
  border: 1px solid #aaa;
  border-radius: 32px;
}
.formy_send { background-color: var(--clr-main); }
#total_required {
  padding: 16px;
  color: var(--clr-rd);
  text-align: center;
}

@media print, screen and (min-width: 768px) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@include mq(sp) {
  #formy_form td { padding-top: 0; }
  .autoConfirmBack { margin-bottom: 8px; }
}
/*! purgecss end ignore */

.formy_confirm,
.wpcf7-confirm { background-color: var(--clr-main); }
.formy_submit_disabled { background-color: #ccc; }
#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}
.autoConfirmBack,
.wpcf7-back { background-color: #aaa; }
.formy_send,
.wpcf7-submit { background-color: var(--clr-main); }
#total_required {
  padding: 16px;
  color: var(--clr-rd);
  text-align: center;
}
span.wpcf7-not-valid-tip {
  display: inline;
}
.wpcf7c-conf {
  background-color: transparent;
  border: 0 !important;
}
.wpcf7c-conf[type="checkbox"]:checked + input[type="hidden"] + label,
.wpcf7c-conf[type="radio"]:checked + input[type="hidden"] + label {
  padding-left: 15px !important;
  color: #333 !important;
}
.wpcf7c-conf[type="checkbox"]:checked + input[type="hidden"] + label::before,
.wpcf7c-conf[type="checkbox"]:checked + input[type="hidden"] + label::after,
.wpcf7c-conf[type="checkbox"] + label,
.wpcf7c-conf[type="radio"]:checked + input[type="hidden"] + label::before,
.wpcf7c-conf[type="radio"]:checked + input[type="hidden"] + label::after,
.wpcf7c-conf[type="radio"] + label {
  display: none !important;
}
.wpcf7-spinner {
  display: block;
  margin: 16px auto;
}

@media print, screen and (min-width: 768px) {
  #formy_btn .wpcf7-form-control:not(:last-child) {
    margin-right: 6px;
  }
}
@media screen and (max-width: 767px) {
  .autoConfirmBack,
  #formy_btn .wpcf7-form-control:not(:last-child) { margin-bottom: 8px; }
}

/* リキャプチャ・キャプション
**************************************** */
.grecaptcha-badge { visibility: hidden; }
.recaptcha_policy {
  padding: 0;
  margin-top: 2rem;
  color: #333;
  font-size: .625rem; /* 10px */
  text-align: center;
  letter-spacing: 0;
}
.recaptcha_policy a {
  color: #69ABDB;
  font-size: .75rem; /* 12px */
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .recaptcha_policy {
    text-align: left;
  }
}

.wpcf7-response-output {
  font-family: sans-serif;
  font-weight: 600;
}

#formy_form .removeFile {
  display: inline-block;
  margin-left: 0.5rem;
  background-color: #a5977d;
  color: #fff;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 2px;
  transition: all .4s ease-out;
  &:hover {
    opacity: .5;
  }
}