@use '../abstracts' as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */

@include mq(sp, min, ps) {
  .lps_page .inner:not(.txt-ctr) {
    margin-left: 5.20833vw;
  }
}
.lps_parts--img_text .inner:not(.inner-lg) {
  align-items: center;
  &:not(.inner-sm) {
    max-width: 1400px;
  }
}
.lps_parts--column .inner:not(.inner-lg):not(.inner-sm) {
  max-width: 1524px;
}

.btn .icon-svg {
  fill : var(--clr-main);
}
.icon-new_tab {
  fill : var(--clr-main);
  @include rect(14);
}
.btn .icon-new_tab {
  right: 30px;
}
.icon-pdf {
  width: 27px;
  height: 12px;
}
.lps_sec {
  position: relative;
  &.contact {
    padding-bottom: 80px;
  }
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: right bottom 80px;
  background-repeat: no-repeat;
  @include mq(sp) {
    top: inherit;
    bottom: -80px;
    background-position: right bottom;
    background-size: 60% auto;
  }
}

@media screen and (max-width: 767px) {
  .btn .icon-new_tab {
    right: 24px;
  }
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}
.lps_parts .img-detail::before {
  padding-top: 66.666%;
}
.lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
  margin-top: 80px;
  @include mq(sp) {
    margin-top: 40px;
  }
}
.lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
  margin-top: 40px;
  @include mq(sp) {
    margin-top: 16px;
  }
}
.lps_parts--button .lps_parts--child > * {
  text-align: center;
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: 24px;
}
.lps_parts--column .btn,
.lps_parts--slide .btn {
  display: flex;
  text-align: center;
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 40px;
  @include mq(sp) {
    margin-bottom: 16px;
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
}