@use '../abstracts' as *;

/* lps_next-page
********************************************** */
.next-page {
  padding-top: 200px;
}
.next-page--ttl {
  position: absolute;
  top: 0;
  left: -300px;
  width: 100vw;
  width: var(--vw);
  font-size: rem(200);
  font-weight: 400;
  line-height: 1;
  text-indent: -50px;
  color: var(--clr-wht);
  letter-spacing: .08em;
  white-space: nowrap;
  opacity: .3;
  z-index: -1;
  pointer-events: none;
  overflow-x: hidden;
}
.lps_next-page {
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid rgba(255, 255, 255, .8);
  border-bottom: 1px solid rgba(255, 255, 255, .8);
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;    
    width: 50%;
    padding: 72px 72px 72px 100px;
    font-size: rem(22);
    .font-en {
      margin-top: 8px;
      font-size: rem(12);
      color: var(--clr-main-op);
      text-transform: uppercase;
    }
    + a {
      border-left: 1px solid rgba(255, 255, 255, .8);
    }
    &:hover {
      opacity: .7;
    }
  }
}
@include mq(sp) {
  .next-page {
    padding-top: 40px;
  }
  .next-page--ttl {
    position: static;
    font-size: rem(40);
    text-indent: 0;
    white-space: inherit;
    margin-bottom: 24px;
    opacity: .6;
  }
  .lps_next-page {
    flex-direction: column;
    padding: 0;
    a {
      width: 100%;
      padding: 24px;
      font-size: rem(22);
      .font-en {
        margin-top: 8px;
        font-size: rem(12);
        color: var(--clr-main-op);
        text-transform: uppercase;
      }
      + a {
        border-left: none;
        border-top: 1px solid rgba(255, 255, 255, .8);
      }
    }
  }
}