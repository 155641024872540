@use '../abstracts' as *;

/* lps_parts--column
********************************************** */

.lps_parts--column:not(.column-1) + .lps_parts--column { margin-top: 24px; }
.lps_parts--column .column_item {
  display: flex;
  flex-direction: column;
}
.lps_parts--column .column_item.bg-wh {
  background-color: rgba(255, 255, 255, .4);
}
.lps_parts--column .text {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.lps_parts--column .img {
  flex-shrink: 0;
}
.lps_parts--column .img + .text {
  padding-top: 40px;
}
.lps_parts--column .bg-wh .text {
  padding: 6%;
}
.lps_parts--column .flex-col1 .bg-wh .text {
  padding: 4% 6%;
}
.lps_parts--column .flex-col3 .bg-wh .text,
.lps_parts--column .flex-col4 .bg-wh .text {
  padding: 8%;
  @include mq(sp) {
    padding: 8% 6%;
  }
}
.lps_parts--column:not(.column-1) table { width: 100%; }
.lps_parts--column .mce-content-body:not(:last-child) {
  margin-bottom: 24px;
}

@include mq(sp){
  .lps_parts--column + .lps_parts--column,
  .lps_parts--column:not(.column-1) + .lps_parts--column { margin-top: 24px; }
  .lps_parts--column .lps_parts--child { display: block; }
  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }
  .lps_parts--column .column_item { width: 100%; }
  .lps_parts--column .column_item:not(:last-child) { margin-bottom: 24px; }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
  .lps_parts--column .column_item:not(:last-child) { margin-right: 0; }
  .lps_parts--column .text.bg-wh,
  .lps_parts--column.column-1 .box { padding: 24px; }
}