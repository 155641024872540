@use '../abstracts' as *;
@use 'sass:math';

/* --- font-size --- */
// フォントサイズを8pxから140pxまでREMで生成
@for $i from 8 to 140 {
  .fz-#{$i} { font-size: math.div($i, 16) + rem}
}
// フォンt-サイズをREM:14引く1px
// これは古い .fz-{数字}のクラスをマネする
@include mq(sp) {
  @for $i from 8 to 140 {
    .fz-#{$i} { font-size: math.div(($i - 1), 14) + rem}
  }
}

/* txt
**************************************** */
.txt-lg { font-size: 1.125rem; /* 18px */ }
.txt-sm { font-size: .875rem; /* 14px */ }
.txt-wh,
.txt-wh * { color: var(--clr-wht, #fff); }
.txt-main,
.txt-main * { color: var(--clr-main); }
.txt-sub,
.txt-sub * { color: var(--clr-sub, #eee); }

/* --- font --- */
.font-jp {
  font-family:var(--font-main);
}
.font-jp-m {
  font-family:var(--font-main-r);
}
.font-jp01 {
  font-family: var(--font-jp01);
}
.font-jp01-b {
  font-family: var(--font-jp01-b);
}
.font-jp02 {
  font-family: var(--font-jp02);
}
.font-en {
  font-family: var(--font-en);
}

/* txt
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}
.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@include mq(sp) {
  .txt-lg { font-size: 1.071rem; /* 15px */ }
  .txt-sm { font-size: .929rem; /* 13px */ }
  .txt-ctr-pc,
  .txt-rgt-pc { text-align: left; }
}